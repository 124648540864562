export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

// PROFILE
export const GET_PROFILE = "GET_PROFILE";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_NOT_FOUND = "PROFILE_NOT_FOUND";
export const CLEAR_CURRENT_PROFILE = "CLEAR_CURRENT_PROFILE";
export const GET_PROFILES = "GET_PROFILES";

// Experience
export const GET_EXPERIENCE = "GET_EXPERIENCE";
export const CREATE_EXPERIENCE = "CREATE_EXPERIENCE";
export const EXPERIENCE_LOADING = "EXPERIENCE_LOADING";
export const EXPERIENCE_NOT_FOUND = "EXPERIENCE_NOT_FOUND";
export const CLEAR_CURRENT_EXPERIENCE = "CLEAR_CURRENT_EXPERIENCE";
export const GET_EXPERIENCES = "GET_EXPERIENCES";

// Experience
export const GET_IDENTITY = "GET_IDENTITY";
export const IDENTITY_LOADING = "IDENTITY_LOADING";
export const IDENTITY_NOT_FOUND = "IDENTITY_NOT_FOUND";
export const CLEAR_CURRENT_IDENTITY = "CLEAR_CURRENT_IDENTITY";
export const GET_IDENTITIES = "GET_IDENTITIES";
