import * as Yup from "yup";

export const ForgotPasswordSchema = Yup.object().shape({
	email: Yup.string()
		.email("Invalid email address")
		.required("Email is a required field"),
});

export const ResendConfirmationSchema = Yup.object().shape({
	email: Yup.string()
		.email("Invalid email address")
		.required("Email is a required field"),
});

export const ResetPasswordSchema = Yup.object().shape({
	password: Yup.string()
		.label("Password")
		.required()
		.min(2, "Seems a bit short..."),
	confirmPassword: Yup.string()
		.required()
		.label("Confirm password")
		.test("passwords-match", "Passwords does not match", function (value) {
			return this.parent.password === value;
		}),
});

const phoneRegExp = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
export const AgencySchema = Yup.object().shape({
	email: Yup.string()
		.email("Invalid email address")
		.required("Email is a required field"),
	first_name: Yup.string().required("First name is a required field"),
	last_name: Yup.string().required("Last name is a required field"),
	phone_number: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
	organization_name: Yup.string().required(
		"Organization name is a required field"
	),
	password: Yup.string().required("Password is a required field"),
	confirmPassword: Yup.string()
		.required()
		.label("Confirm password")
		.test("passwords-match", "Passwords does not match", function (value) {
			return this.parent.password === value;
		}),
});

export const CompanyDetailsSchema = Yup.object().shape({
	name: Yup.string().required("Company name is a required field"),
	phone_number: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
	c_address_1: Yup.string().required("Address is a required field"),
	c_city: Yup.string().required("City is a required field"),
	c_state: Yup.string().required("State is a required field"),
	c_zip: Yup.number()
	  .typeError('Zip code must be a number')
	  .positive('Zip code must be greater than zero')
	  .required('Zip code is a required field'),
	website_url: Yup.lazy((value) =>
        /^data/.test(value)
          ? Yup.string()
              .trim()
              .matches(
                /^data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*)$/i,
                'Must be a valid data URI',
              )
              .required()
          : Yup.string().trim().url('Must be a valid URL').required('Website URL is required field'),
      ),
	  linked_url: Yup.lazy((value) =>
        /^data/.test(value)
          ? Yup.string()
              .trim()
              .matches(
                /^data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*)$/i,
                'Must be a valid data URI',
              )
              .required()
          : Yup.string().trim().url('Must be a valid URL').required('Linkedin URL is required field'),
	  ),
		});


export const FreelancerSchema = Yup.object().shape({
	email: Yup.string()
		.email("Invalid email address")
		.required("Email is a required field"),
	first_name: Yup.string().required("First name is a required field"),
	last_name: Yup.string().required("Last name is a required field"),
	phone_number: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
	password: Yup.string().required("Password is a required field"),
	confirmPassword: Yup.string()
		.required()
		.label("Confirm password")
		.test("passwords-match", "Passwords does not match", function (value) {
			return this.parent.password === value;
		}),
});

export const LoginSchema = Yup.object().shape({
	email: Yup.string()
		.email("Invalid email address")
		.required("Email is a required field"),
	password: Yup.string().required("Password is a required field"),
});