import React, { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core";
import { TextField, Divider, Tabs, Tab, CircularProgress, Grid, Text } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import IgButton from "../../../Components/Form/IgButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  createQuestion,
  getQuestions,
  deleteQuestion,
} from "../../../Actions/clientAction";
import QuestionList from "./QuestionList";
import './ClientAddQuestion.css';
import Switch from "@material-ui/core/Switch";
import Zoom from '@material-ui/core/Zoom';

const styles = makeStyles(() => {
  return {
    headerContainer: { padding: "25px 15px 0px 10px", position: "relative" },
    title: { textAlign: "left", marginLeft: "0px", fontSize: "24px" },
    link: {
      marginTop: -35,
      float: "right",
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      marginRight: 10,
      padding: "8px 15px",
      backgroundColor: "#0B196A",
      height: 36,
      borderRadius: 5,
      color: "white",
    },
    questionText: {
      fontSize: 16,
      width: "100%",
      marginTop: 10,
      border: "1px solid #7900ff2e",
      borderRadius: 5,
    },
    questionType: {
      marginTop: 10,
      marginBottom: 10,
    },
    add: {
      width: "110px",
      height: "36px",
      color: "white",
      fontSize: "14px",
      background: "#0B196A",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      letterSpacing: "0.16px",
    },
    // tabItems: { fontSize: "12px", position: "absolute", left: "0px" },
    done: {
      background: "#36A720",
      borderRadius: "4px",
      color: "#ffffff",
      fontSize: "14px",
    },
    check: {
      marginTop: "15px",
    },
    backtodetails: {
      marginTop: "15px",
    },
    arrowBack : {
      position:"absolute",
      left:-5
    },
    loadingBox :{
      backgroundColor: "#ffffff",
      height: "100%",
      marginTop: 30,
      padding: 24,
      display: "flex",
      justifyContent: "center",
    },
    divider:{
      margin:"5px 0px"
    }
  };
});

const ClientAddQuestion = (props) => {
  const dispatch = useDispatch();
  const addStyles = styles();

  const {
    questions,
    isDeleteQuestionLoading,
    isPostQuestionLoading,
    deleteQuestion
  } = useSelector((state) => {
    const { client } = state;
    const {
      questions,
      isDeleteQuestionLoading,
      isPostQuestionLoading,
      deleteQuestion
    } = client;
    return {
      questions,
      isDeleteQuestionLoading,
      isPostQuestionLoading,
      deleteQuestion
    };
  });

  const [state, setState] = useState({
    list: [],
    job_id: props.job_id,
    reloaded: false,
    jobListRedirection: false,
    checkedA: false,
  });

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    dispatch(getQuestions(state.job_id));
    setState({
      ...state,
      list: questions,
    });
  }, [isPostQuestionLoading || isDeleteQuestionLoading]);

  const handleAdd = (data) => {
    dispatch(
      createQuestion(
        {
          question_text: data.question_text,
          question_type: data.question_type,
          job_id: state.job_id,
          required: state.checkedA,
        },
        state.job_id
      ),
      reset({ question_text: "", question_type: "" })
    );
    setState({
      ...state,
      list: questions,
    });
  };

  const { register, handleSubmit, reset } = useForm({
    mode: "onSubmit",
    defaultValues: { question_type: "", question_text: "" },
  });

  const handleDelete = (itemId) => {
    dispatch(deleteQuestion(itemId, state.id));
    setState({
      ...state,
      list: [],
    });
  };

  const handleTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setState({ ...state, [name]: checked });
  };

  const { checkedA } = state;

  const handleJobListRedirection = (event) => {
    event.preventDefault();
    props.makeReverseRedirection();
  };

  const { list } = state;

  return (
    <React.Fragment>
      <CssBaseline />
      <Container className="question-root">
        <Grid container className={addStyles.headerContainer}>
          <Typography variant="h5" gutterBottom className={addStyles.title}>
            Questions
          </Typography>
        </Grid>
        <Divider className={addStyles.divider} />
        <Grid container spacing={2} className={addStyles.headerContainer}>
          <Grid item xs={8} sm={8} md={8} lg={8}>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTab}
            className="tab"
            TabIndicatorProps={{
              style: { backgroundColor: "#1a237e", width: "100px" },
            }}
          >
            <Tab
            disableRipple
              label={<p className="tabItem1">Add Questions</p>}
            ></Tab>
            <Tab
              label={<p className="tabItem2">View Questions</p>}
            ></Tab>
          </Tabs>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <div
              className="mainSubtitle"
            >
              {questions && questions.length} question added successfully
            </div>
          </Grid>
        </Grid>
        <br />
        <Divider />
        {value === 0 && (
          <form onSubmit={handleSubmit(handleAdd)}>
            <Grid container className={addStyles.headerContainer}>
              <Typography>Add Question for Job Applicant</Typography>
            </Grid>
            <Grid container className={addStyles.headerContainer}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="outlined-multiline-flexible"
                  name="question_text"
                  label="Question"
                  multiline
                  fullWidth
                  className={addStyles.questionText}
                  variant="outlined"
                  placeholder="Type your question here..."
                  inputRef={register}
                />
              </Grid>
              <Grid container spacing={6}>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <TextField
                    id="outlined-multiline-flexible"
                    name="question_type"
                    label="Question type"
                    multiline
                    className={addStyles.questionType}
                    variant="outlined"
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checkedA}
                        onChange={handleChange}
                        color="primary"
                        value={checkedA}
                        name="checkedA"
                        className={addStyles.check}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <br />
            <Grid className={addStyles.headerContainer}>
            <Grid container spacing={3}>
              <Grid item>
                <IgButton
                  type="submit"
                  variant="contained"
                  label="Add more"
                  className={addStyles.add}
                ></IgButton>
              </Grid>
              <Zoom in={true} style={{ transitionDelay: '1000ms'  }}>
                <Grid item>
                  <IgButton
                    variant="contained"
                    label={questions && questions.length > 0 ? "Done" : "Skip"}
                    className={addStyles.done}
                    onClick={handleJobListRedirection}
                  ></IgButton>
                </Grid>
              </Zoom>
            </Grid>
            </Grid>
          </form>
        )}
        {value === 1 && list ? (
         (deleteQuestion && isDeleteQuestionLoading) ?
         <Grid component="div" className={addStyles.loadingBox}>
            <CircularProgress disableShrink color="primary"/>
        </Grid>
           : 
          <>
            <QuestionList
              style={{ width: "100%" }}
              key={list.id}
              items={list}
              deleteItem={handleDelete}
            />
            {questions && questions.length > 0 && (
              <IgButton
                variant="contained"
                label="Back to Details"
                className={addStyles.backtodetails}
                onClick={handleJobListRedirection}
              ></IgButton>
            )}
          </>
        ) : null}
      </Container>
    </React.Fragment>
  );
};

export default withRouter(ClientAddQuestion);
