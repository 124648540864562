import React, { useState, useEffect, Fragment } from "react";
import "antd/dist/antd.css";
import "../BrowseJobs/BrowseJobs";
import { Table, Button, Spin, Typography } from "antd";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getClients,
  clearLatestJobState,
} from "../../../Actions/clientAction";
import { getSkills } from "../../../Actions/userAction";
import { history } from "../../../Utils/history";
import JobDetails from "../JobDetails/JobDetails";
import CompanyPostAJob from "../CompanyPostAJob/CompanyPostAJob";
import { Grid } from "@material-ui/core";
import Success from "../ErrorComponent/ErrorComponent";
import Zoom from '@material-ui/core/Zoom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import "./JobList.css";

const { Text } = Typography;
let data = [];

const convert = (str) => {
  const myDate = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  }).format(Date.parse(str));
  return myDate;
};

const styles = makeStyles(() => {
  return {
    clearfilters: {
      marginLeft: 0,
      marginTop: 0,
      marginBottom: 15,
    },
    mainTitle: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "24px",
      lineHeight: "28px",
      color: "#333333",
      marginTop: -25,
      padding: "10px 0px 15px",
    },
    filters: { marginLeft: "5px" },
    create: {
      backgroundColor: "#0b196a",
      borderColor: "#0b196a",
      color: "white",
      marginRight: 0,
      marginLeft: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      position: "absolute",
      top: 0,
      right: 0,
    },
    loading: {
      color: "#ffffff",
      marginLeft: "4px",
    },
    text: { marginLeft: 90, fontSize: "16px" },
  };
});

const JobList = (props) => {
  const dispatch = useDispatch();
  const listStyles = styles();

  const {
    clients,
    isGetAllClientsLoading,
    isPutJobLoading,
    allSkills,
    new_application,
    isGetAllSkillsLoading,
    latestJob,
    isDeleted,
    jobs,
    draft_job,
    isDeleteJobLoading,
  } = useSelector((state) => {
    const { client, user } = state;
    const { allSkills, isGetAllSkillsLoading } = user;
    const {
      clients,
      isGetAllClientsLoading,
      new_application,
      isDeleteJobLoading,
      isPutJobLoading,
      latestJob,
      isDeleted,
      draft_job,
    } = client;
    const { jobs } = clients;
    return {
      clients,
      isGetAllClientsLoading,
      allSkills,
      new_application,
      isPutJobLoading,
      isDeleteJobLoading,
      isGetAllSkillsLoading,
      latestJob,
      isDeleted,
      jobs,
      draft_job,
    };
  });

  const [state, setState] = useState({
    filteredInfo: null,
    sortedInfo: null,
    currentJobId: null,
    currentJob: null,
    post: false,
    isClicked: false,
    isShow: false,
    isNotRedirected: true,
    success: true,
  });

  let size = state.isClicked ? 6 : 12;
  let table = state.isClicked ? "m_table" : "l_table";

  useEffect(() => {
    dispatch(getSkills());
    dispatch(getClients());
    props.isRedirected === "true" &&
      (size = 12 && setState({ ...state, isNotRedirected: false }));
    setTimeout(() => {
      clients.error === "User does not have any client" &&
        history.push("/company");
    }, dispatch(getClients()));
  }, []);

  const handleChange = (filters, sorter) => {
    setState({
      ...state,
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  const clearFilters = () => {
    setState({
      ...state,
      filteredInfo: null,
    });
  };

  const clearAll = () => {
    setState({
      ...state,
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  const handleDetails = (dataIndex) => {
    let instance = data.filter((index) => {
      return index.id === dataIndex;
    });
    dispatch(clearLatestJobState());
    setState({
      ...state,
      currentJobId: dataIndex,
      isGetAllSkillsLoading: false,
      currentJob: instance[0],
      isClicked: true,
      isShow: true,
      post: false,
    });
  };

  const handleSuccessClose = () => {
    setState({
      ...state,
      success: false,
    });
  };

  const handleCreateJob = () => {
    setState({
      ...state,
      post: true,
      isNotRedirected: true,
      isShow: false,
      isClicked: true,
    });
  };
  const handleCreateJobComplete = () => {
    setState({
      ...state,
      post: false,
    });
    latestJob &&
      setState({
        ...state,
        isShow: true,
        post: false,
        currentJob: latestJob,
        currentJobId: latestJob.id,
      });
    dispatch(getClients());
  };

  const makeReverseRedirection = () => {
    setState({
      ...state,
      isNotRedirected: true,
    });
  };

  let clientData = clients;
  clientData.jobs &&
    clientData.jobs &&
    clientData.jobs.forEach((index) => {
      index.created_at = convert(index.created_at);
      index.updated_at = convert(index.updated_at);
    });

  let Jobs = clientData.jobs;
  Jobs && (data = Jobs);

  let { sortedInfo, filteredInfo, post, currentJobId, currentJob, isShow } =
    state;

  sortedInfo = sortedInfo || {};
  filteredInfo = filteredInfo || {};
  let filterJob = [
    ...new Set(
      data.map((index) => {
        return { text: index.title, value: index.title };
      })
    ),
  ];
  const columns = [
    {
      title: "Job",
      dataIndex: "title",
      responsive: ["lg"],
      colSpan: 4,
      render: (dataIndex) => {
        return {
          children: <Text className="table-text">{dataIndex}</Text>,
          props: {
            colSpan: 5,
          },
        };
      },
      filters: filterJob,
      filteredValue: filteredInfo.job || null,
      onFilter: (value, record) => record.title.includes(value),
      sorter: (a, b) => a.title.length - b.title.length,
      sortOrder: sortedInfo.columnKey === "title" && sortedInfo.order,
      ellipsis: true,
      colspan: 4,
    },
    {
      title: "Category",
      dataIndex: "job_type",
      responsive: ["lg"],
      colSpan: 3,
      render: (dataIndex) => {
        return {
          children: <Text className="table-text">{dataIndex}</Text>,
          props: {
            colSpan: 2,
          },
        };
      },
      filters: [
        { text: "Contract", value: "Contract" },
        { text: "PerHour", value: "PerHour" },
        { text: "PerMilestone", value: "PerMilestone" },
      ],
      filteredValue: filteredInfo.job_type || null,
      onFilter: (value, record) => record.job_type.includes(value),
      sortOrder: sortedInfo.columnKey === "job_type" && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      colSpan: 1,
      render: (dataIndex) => {
        return {
          children: (
            <Text className={listStyles.text, "table-text"}>
              {dataIndex}
            </Text>
          ),
          props: {
            colSpan: 2,
          },
        };
      },
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "id",
      colSpan: 3,
      render: (dataIndex) => {
        return {
          children: (
            <>
            <Button
              className="show"
              onClick={() => handleDetails(dataIndex)}
            >
              Show
            </Button>
            <Button
            className="show-mob"
            onClick={() => handleDetails(dataIndex)}
          >
            <ArrowForwardIosIcon className="arrow-front"/>
          </Button>
          </>
          ),
          props: {
            colSpan: 2,
          },
        };
      },
    },
  ];
  return (
    <Grid container>
      {new_application && draft_job && isPutJobLoading && (
        <Success
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={state.success}
          label={new_application}
          autoHideDuration={6000}
          onClose={handleSuccessClose}
          severity="success"
        />
      )}
      {new_application && isPutJobLoading && draft_job === null && (
        <Success
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={state.success}
          label="Job updated Succesfully"
          autoHideDuration={6000}
          onClose={handleSuccessClose}
          severity="success"
        />
      )}
      {isDeleteJobLoading && (
        <Success
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={state.success}
          label="Job Deleted Succesfully"
          autoHideDuration={2000}
          onClose={handleSuccessClose}
          severity="error"
        />
      )}
      <Grid item lg={size} md={12} sm={12} xs={12}>
        {state.isNotRedirected && (
          <Spin spinning={isGetAllClientsLoading}>
            <div className={table}>
              <Typography className={listStyles.mainTitle}>Job List</Typography>
              <Grid container className="buttonContainer">
                <Grid item>
                  <Button
                    onClick={clearFilters}
                    className={listStyles.clearfilters}
                  >
                    Clear filters
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Button className="filters" onClick={clearAll}>
                    Clear filters and sorters
                  </Button>
                </Grid>
                <Zoom in={true} style={{ transitionDelay: '1000ms'  }}>
                <Grid item xs={12} sm={12} md={6}>
                  {!isGetAllSkillsLoading && (
                    <Button
                      onClick={handleCreateJob}
                      className={listStyles.create}
                    >
                      Create a new Job{" "}
                    </Button>
                  )}
                </Grid>
                </Zoom>
              </Grid>
              <Table
                className="tab"
                columns={columns}
                dataSource={Jobs}
                onChange={handleChange}
                rowKey={(key) => key.id}
              />
            </div>
          </Spin>
        )}
      </Grid>
      <Grid item lg={size} md={12} sm={12} xs={12}>
        {post ? (
          <CompanyPostAJob
            AllSKills={allSkills}
            handleCreateJobComplete={handleCreateJobComplete}
          />
        ) : currentJob && currentJobId ? (
          <JobDetails
            Id={currentJobId}
            job={currentJob}
            makeReverseRedirection={makeReverseRedirection}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default withRouter(JobList);
