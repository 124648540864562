import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import "./BrowseJobs.css";
import JobInfo from "./JobInfo";
import { Table, Button, Typography, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { getJobs } from "../../../Actions/clientAction";
import { clearApplied } from "../../../Actions/freelancerAction";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const { Text } = Typography;
let data = [];

const styles = makeStyles(() => {
  return {
    text: {
      fontSize: "14px",
    },
  };
});

const BrowseJobs = (props) => {
  const dispatch = useDispatch();
  const browseStyles = styles();

  const { jobs, isGetAllJobsLoading, jobApplication_Id } = useSelector(
    (state) => {
      const { jobs, isGetAllJobsLoading } = state.client;
      const { jobApplication_Id } = state.freelancer;
      return {
        jobs,
        isGetAllJobsLoading,
        errors: state.errors,
        jobApplication_Id,
      };
    }
  );

  data = jobs;

  const [state, setState] = useState({
    filteredInfo: null,
    sortedInfo: null,
    jobData: null,
    isShow: false,
    isClicked: false,
    isNotRedirected: true,
  });

  useEffect(() => {
    props.redirected && setState({ ...state, isNotRedirected: false });
    dispatch(clearApplied());
    dispatch(getJobs("Published"));
  }, []);

  let { sortedInfo, filteredInfo, jobData, isShow, isClicked } = state;

  const handleChange = (pagination, filters, sorter) => {
    setState({
      ...state,
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  const clearFilters = () => {
    setState({ ...state, filteredInfo: null });
  };

  const clearAll = () => {
    setState({
      ...state,
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  const applyJob = (id) => {
    let instance = data.filter((index) => {
      return index.id === id;
    });
    setState({
      ...state,
      jobData: instance,
      isShow: true,
      isClicked: true,
    });
  };

  let size = isClicked ? 6 : 12;
  let table = isClicked ? "m_table" : "l_table";

  sortedInfo = sortedInfo || {};
  filteredInfo = filteredInfo || {};
  let filterJob = [
    ...new Set(
      data &&
        data.map((index) => {
          return { text: index.title, value: index.title };
        })
    ),
  ];
  const columns = [
    {
      title: "Job",
      dataIndex: "title",
      colSpan: 2,
      render: (dataIndex) => {
        return {
          children: <Text className={browseStyles.text}>{dataIndex}</Text>,
          props: {
            colSpan: 2,
          },
        };
      },
      ellipsis: true,
    },
    {
      title: "Company",
      dataIndex: "client",
      responsive: ["lg"],

      colSpan: 2,
      render: (dataIndex) => {
        return {
          children: <Text className={browseStyles.text}>{dataIndex.name}</Text>,
          props: {
            colSpan: 2,
          },
        };
      },
      ellipsis: true,
    },
    {
      title: "Category",
      dataIndex: "job_type",
      responsive: ["lg"],

      filters: [
        { text: "Contract", value: "Contract" },
        { text: "PerHour", value: "PerHour" },
        { text: "PerMilestone", value: "PerMilestone" },
      ],
      filteredValue: filteredInfo.job_type || null,
      onFilter: (value, record) => record.job_type.includes(value || " "),
      ellipsis: true,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      colSpan: 1,
      responsive: ["lg"],

      render: (dataIndex) => (
        <Text className={browseStyles.text}>
          {new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "long",
            day: "2-digit",
          }).format(Date.parse(dataIndex))}
        </Text>
      ),
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (dataIndex) => (
        <>
          <Button
            className="viewButton"
            onClick={() => {
              applyJob(dataIndex);
            }}
          >
            View
          </Button>
          <Button
            className="viewButton-mob"
            onClick={() => {
              applyJob(dataIndex);
            }}
          >
            <ArrowForwardIosIcon className="arrow-front" />
          </Button>
        </>
      ),
    },
  ];
  return (
    <React.Fragment>
      <Grid container>
        {state.isNotRedirected && (
          <Grid item xs={12} sm={12} md={12} lg={size} className="tables">
            <Spin spinning={isGetAllJobsLoading}>
              <div className={table}>
                <Button onClick={clearFilters} className="clearButton">
                  Clear filters
                </Button>
                <Button onClick={clearAll}>Clear filters and sorters</Button>
                <Table
                  columns={columns}
                  dataSource={data}
                  onChange={handleChange}
                  rowKey={(record) => record.id}
                />
              </div>
            </Spin>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} lg={size}>
          {jobData && jobData ? (
            <JobInfo Id={jobData[0].id} jobData={jobData} />
          ) : null}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default withRouter(BrowseJobs);
