import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const customStyle = makeStyles((theme: Theme) =>
	createStyles({
		// Loginpage
		LoginPaper: {
			margin: "auto",
			marginTop: theme.spacing(10),
			// borderRadius: 5,
			backgroundColor: "#FFFFFF",
			padding: 50,
			maxWidth: 500,
			boxShadow: "0 12px 6px -6px #eee",
			borderRadius: 10,
		},
		error: {
			color: "#f44336",
			position: "absolute",
			fontSize: 13,
		},
		inputFormControl: {
			margin: "5px 0px",
		},
		cButton: {
			height: 40,
			margin: "10px 0px 20px",
		},
		link: {
			fontWeight: 500,
			color: "#04004A",
			fontSize: 14,
			marginLeft: 5,
			cursor: "pointer",
			"&:hover": {
				transition: "0.5s",
				marginLeft: 2,
				textDecoration: "none",
				color: "#FEBF0F",
			},
		},
		loading: {
			position: "absolute",
			top: "50%",
			left: "50%",
		},
		registerSelection: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			marginTop: 15,
			marginBottom: -55,
		},
		registerSelectionItem: {
			display: "flex",
			flexDirection: "row",
		},
		// Sidebar
	})
);

export default customStyle;
