import React, { Component } from "react"
import './Topbar.css';
import withStyles from "@material-ui/core/styles/withStyles";
import { Link, withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import { Button, Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import logo from "./../Assets/logo@2x.png";
import { history } from "./../Utils/history";
import { logoutuser } from "../Actions/authAction";
// import SearchBar from "./Form/SearchBar";
import UserAvatarMenu from "./UserAvatarMenu";
// import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
const styles = theme => ({
  appBar: {
    position: "relative",
    boxShadow: "none"
  },
  drawerPaper: {
    backgroundColor: '#ffffff',
  },
  inline: {
    display: "inline"
  },
  flex: {
    display: "flex",
    justifyContent:'space-between',
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    }
  },
  link: {
    textDecoration: "none",
    color: "inherit"
  },
  productLogo: {
    display: "inline-block",
    borderLeft: `1px solid ${theme.palette.grey["A100"]}`,
    marginLeft: 32,
    paddingLeft: 24,
    [theme.breakpoints.up("md")]: {
      paddingTop: "1.5em"
    }
  },
  tagline: {
    display: "inline-block",
    marginLeft: 10,
    [theme.breakpoints.up("md")]: {
      paddingTop: "0.8em"
    }
  },
  iconContainer: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },
  iconButton: {
    float: "right"
  },

  tabItem: {
    paddingTop: 20,
    paddingBottom: 20,
    minWidth: "auto"
  },
  logo: {
    width: "80%"
  },

  search: {
    float: "left",
    margin: theme.spacing(1, 1, 1),
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: (theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: (theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    backgroundColor: "#FFFFFF",
    },
    [theme.breakpoints.up(1410)]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(25),
      width: 'auto',
    backgroundColor: "#FFFFFF",
    },
  },

  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.25
  },

  submit: {
    margin: theme.spacing(1, 1, 1),
    textTransform: "none"
  }
});

class Topbar extends Component {
  state = {
    value: 0,
    menuDrawer: false,
    authState: false,
    name:"",
    image:""
  };
  handleFaq = () => {
    history.push('/faq')
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  mobileMenuOpen = () => {
    this.setState({ menuDrawer: true });
  };

  mobileMenuClose = () => {
    this.setState({ menuDrawer: false });
  };
  UNSAFE_componentWillMount() {
    window.scrollTo(0, 0);
    if(localStorage.jwtToken){
      const {name, avatar} = localStorage
      this.setState({
        authState: true,
        name: name,
        image: avatar
      })

    }
  }


  current = () => {
    if (this.props.currentPath === "/home") {
      return 0;
    }
    if (this.props.currentPath === "/dashboard") {
      return 1;
    }
    if (this.props.currentPath === "/signup-freelancer") {
      return 2;
    }
    if (this.props.currentPath === "/identity") {
      return 3;
    }
    if (this.props.currentPath === "/experience") {
      return 4;
    }
    if (this.props.currentPath === "/signup-agency") {
      return 5;
    }
    if (this.props.currentPath === "/login") {
      return 6;
    }
  };
  handlescrollWinClick = () => {
    history.push("/")
    setTimeout(()=>{
      window.scrollTo(0, 700);
    })
   };

   handleLoginClick = () => {
    history.push("/login");
  };
  handleLogoutClick = () => {

    this.setState({
      authState: false
    })
    this.props.logoutuser();
    history.push("/login");
  };
  handleRegisterClick = () => {
    history.push({pathname:"/register",state:{value:0}});
  };
  handleProfileClick = () => {
    if(localStorage.user_type === "Freelancer")
    history.push("/dashboard");
    else
     history.push("/dashboard-client")
 };

 handleJob = () =>{
  history.push("/find-job")
 }

  handleCompanyPostAJobClick = () => {
    if(localStorage.jwtToken)
     history.push("/create-job");

     else
      history.push("/login")
  };
  render() {
    const { classes } = this.props;
    if(localStorage.jwtToken){
      return (
        <AppBar position="absolute" color="inherit"
        className={classes.appBar}
        >
          <Toolbar>
            <Grid container alignItems="baseline">
              <Grid item xs={12} style={{display: "flex", justifyContent: "space-between"}}
              className={classes.flex}
              >
                <div className={classes.inline}>
                    <Link to="/" className={classes.link}>
                      <span className={classes.tagline}>
                        <img
                          src={logo}
                          alt="Insuregig Logo"
                          className={classes.logo}
                        />
                      </span>
                    </Link>
                </div>
                <Box display={{ xs: 'block', sm: 'block',    md: "none",
                    lg: "none",
                    xl: "none" }}>
                    <IconButton
                      onClick={this.mobileMenuOpen}
                      className={classes.iconButton}
                      color="inherit"
                      aria-label="Menu">
                      <MenuIcon />
                    </IconButton>
                </Box>
                <Drawer
                      anchor="right"
                      open={this.state.menuDrawer}
                      onClose={this.mobileMenuClose}
                      // onOpen={this.mobileMenuOpen}
                      classes={{
                        paper: classes.drawerPaper,
                      }}
                    >
                      <AppBar title="Menu" />
                      <List>
                          <ListItem
                            component={Link}
                            onClick={this.handleProfileClick}
                            button
                          >
                            <ListItemText primary={'Dashboard'} />
                          </ListItem>
                          <ListItem
                            component={Link}
                            onClick={this.handleLogoutClick}
                            button
                          >
                            <ListItemText primary={'Logout'} />
                          </ListItem>
                      </List>
                    </Drawer>
                <Box
                  display={{
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                    xl: "block"
                  }} style={{width:'100%'}}
                >
                  <Box style={{ display: 'flex', alignContent:"center" , justifyContent: 'flex-end' }}>
                  
                    {/* {(this.props.searchbar === "no") ?  null : <SearchBar /> } */}
                    {(this.props.searchbar === "no") ?  null : 
                    <Button onClick={this.handleProfileClick} >
                      <ArrowBackIosIcon style={{marginRight : 5 , marginBottom : 1,fontSize : "12px"}}/>
                      <Typography style={{color : '#000000' , fontFamily: ["Roboto, sans-serif"] , fontSize:13}} >Back to Dashboard</Typography>
                    </Button>
                    }
                    <UserAvatarMenu image={this.state.image} />
            
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      );
    }
    else{
      return (
      <AppBar position="absolute" color="inherit"
      className={classes.appBar}
      >
        <Toolbar>
          <Grid container alignItems="baseline">
            <Grid item xs={12}
            className={classes.flex}
            >
              <div className={classes.inline}>
                  <Link to="/" className={classes.link}>
                    <span className={classes.tagline}>
                      <img
                        src={logo}
                        alt="Insuregig Logo"
                        className={classes.logo}
                      />
                    </span>
                  </Link>
              </div>
              <Box display={{ xs: 'block', sm: 'block',    md: "block",
                  lg: "none",
                  xl: "none" }}>
                  <IconButton
                    onClick={this.mobileMenuOpen}
                    className={classes.iconButton}
                    color="inherit"
                    aria-label="Menu">
                    <MenuIcon />
                  </IconButton>
              </Box>
              <Drawer
                    anchor="right"
                    open={this.state.menuDrawer}
                    onClose={this.mobileMenuClose}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                  >
                    <AppBar title="Menu" />
                    <List>
                        <ListItem
                          component={Link}
                          to={'/login'}
                          button
                        >
                          <ListItemText primary={'Login'} />
                        </ListItem>
                        <ListItem
                          component={Link}
                          to={'/register'}
                          button
                        >
                          <ListItemText primary={'Sign Up'} />
                        </ListItem>
                    </List>
                  </Drawer>
              <Box
                display={{
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "block",
                  xl: "block"
                }} style={{ width:'100%'}}>
               <Box style={{display:'flex', justifyContent:'space-between'}}>
               <Box style={{ dispaly: "flex", justifyContent: "space-around"}}>
                  <Button
                    type="submit"
                    color="primary"
                    className={classes.submit}
                    onClick={() => this.handlescrollWinClick()}
                  >
                    How it Works
                  </Button>

                  {/* <Button
                    type="submit"
                    color="primary"
                    className={classes.submit}
                    onClick={() => this.handleNewsClick}
                  >
                    News
                  </Button> */}

                  <Button
                    type="submit"
                    color="primary"
                    className={classes.submit}
                    onClick={() => this.handleFaq()}
                  >
                    FAQ
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    className={classes.submit}
                    onClick={() => this.handleJob()}
                    >
                      Find a Job
                    </Button>
                </Box>
                <Box>
                  <Button
                    type="submit"
                    color="primary"
                    className={classes.submit}
                    onClick={() => this.handleLoginClick()}
                  >
                    Login
                  </Button>

                  <Button
                    type="submit"
                    color="primary"
                    className={classes.submit}
                    onClick={() => this.handleRegisterClick()}
                  >
                    Sign Up
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className={classes.submit}
                    onClick={() => this.handleCompanyPostAJobClick()}
                  >
                    Post A Job
                  </Button>
                </Box>
               </Box>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
    }
  }
}

const mapStateToProp = state => {
  return {
    auth: state.auth,
    user: state.user
  };
};

export default connect(mapStateToProp,{ logoutuser })(withRouter(withStyles(styles)(Topbar)));
