const usersConstants = {
     
    GET_ALL_USERS_PROFILES_REQUEST: 'GET_ALL_USERS_PROFILES_REQUEST',
    GET_ALL_USERS_PROFILES_SUCCESS:'GET_ALL_USERS_PROFILES_SUCCESS',
    GET_ALL_USERS_PROFILES_FAILURE:'GET_ALL_USERS_PROFILES_FAILURE',


    GET_ALL_INTERESTS_REQUEST: 'GET_ALL_INTERESTS_REQUEST',
    GET_ALL_INTERESTS_SUCCESS:'GET_ALL_INTERESTS_SUCCESS',
    GET_ALL_INTERESTS_FAILURE:'GET_ALL_INTERESTS_FAILURE',

    GET_FIND_INTERESTS_REQUEST: 'GET_FIND_INTERESTS_REQUEST',
    GET_FIND_INTERESTS_SUCCESS:'GET_FIND_INTERESTS_SUCCESS',
    GET_FIND_INTERESTS_FAILURE:'GET_FIND_INTERESTS_FAILURE',

    GET_ALL_LANGUAGES_REQUEST: 'GET_ALL_LANGUAGES_REQUEST',
    GET_ALL_LANGUAGES_SUCCESS:'GET_ALL_LANGUAGES_SUCCESS',
    GET_ALL_LANGUAGES_FAILURE:'GET_ALL_LANGUAGES_FAILURE',

    GET_FIND_LANGUAGES_REQUEST: 'GET_FIND_LANGUAGES_REQUEST',
    GET_FIND_LANGUAGES_SUCCESS:'GET_FIND_LANGUAGES_SUCCESS',
    GET_FIND_LANGUAGES_FAILURE:'GET_FIND_LANGUAGES_FAILURE',


    GET_ALL_SKILLS_REQUEST: 'GET_ALL_SKILLS_REQUEST',
    GET_ALL_SKILLS_SUCCESS:'GET_ALL_SKILLS_SUCCESS',
    GET_ALL_SKILLS_FAILURE:'GET_ALL_SKILLS_FAILURE',

    GET_FIND_SKILLS_REQUEST: 'GET_FIND_SKILLS_REQUEST',
    GET_FIND_SKILLS_SUCCESS:'GET_FIND_SKILLS_SUCCESS',
    GET_FIND_SKILLS_FAILURE:'GET_FIND_SKILLS_FAILURE',


    GET_ALL_COMMENTS_REQUEST: 'GET_ALL_COMMENTS_REQUEST',
    GET_ALL_COMMENTS_SUCCESS:'GET_ALL_COMMENTS_SUCCESS',
    GET_ALL_COMMENTS_FAILURE:'GET_ALL_COMMENTS_FAILURE',

    POST_COMMENT_REQUEST: 'POST_COMMENT_REQUEST',
    POST_COMMENT_SUCCESS:'POST_COMMENT_SUCCESS',
    POST_COMMENT_FAILURE:'POST_COMMENT_FAILURE',

    PUT_COMMENT_REQUEST: 'PUT_COMMENT_REQUEST',
    PUT_COMMENT_SUCCESS:'PUT_COMMENT_SUCCESS',
    PUT_COMMENT_FAILURE:'PUT_COMMENT_FAILURE',

    DELETE_COMMENT_REQUEST: 'DELETE_COMMENT_REQUEST',
    DELETE_COMMENT_SUCCESS:'DELETE_COMMENT_SUCCESS',
    DELETE_COMMENT_FAILURE:'DELETE_COMMENT_FAILURE',

    GET_ALL_RATES_REQUEST: 'GET_ALL_RATES_REQUEST',
    GET_ALL_RATES_SUCCESS:'GET_ALL_RATES_SUCCESS',
    GET_ALL_RATES_FAILURE:'GET_ALL_RATES_FAILURE',

    POST_RATE_REQUEST: 'POST_RATE_REQUEST',
    POST_RATE_SUCCESS:'POST_RATE_SUCCESS',
    POST_RATE_FAILURE:'POST_RATE_FAILURE',

    PUT_RATE_REQUEST: 'PUT_RATE_REQUEST',
    PUT_RATE_SUCCESS:'PUT_RATE_SUCCESS',
    PUT_RATE_FAILURE:'PUT_RATE_FAILURE',

    DELETE_RATE_REQUEST: 'DELETE_RATE_REQUEST',
    DELETE_RATE_SUCCESS:'DELETE_RATE_SUCCESS',
    DELETE_RATE_FAILURE:'DELETE_RATE_FAILURE',

}
export default usersConstants;