import { combineReducers } from "redux";
import authReducer from "./authReducer";
import experienceReducer from "./experienceReducer";
import errorReducer from "./errorReducer";
import freelancerReducer from "./freelancerReducer";
import clientReducer from "./clientReducer";
import userReducer from "./userReducer";
import { contactUsReducer } from "./contactUsReducer";

export default combineReducers({
  auth: authReducer,
  experience: experienceReducer,
  errors: errorReducer,
  freelancer: freelancerReducer,
  client: clientReducer,
  user: userReducer,
  contactUs: contactUsReducer
});
