import React, { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, Grid, Divider } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import { withRouter } from "react-router-dom";
import Link from "@material-ui/core/Link";
import {
  createQuestion,
  getQuestions,
  deleteQuestion,
} from "../../../Actions/clientAction";
import {
  getJobsAnswers,
  createJobsAnswer,
  updateJobsAnswer,
  clearQuestionState,
  deleteJobsAnswer,
} from "../../../Actions/freelancerAction";
import BrowseJobs from "./BrowseJobs";
import { history } from "../../../Utils/history";
import IgButton from "../../../Components/Form/IgButton";

const styles = makeStyles(() => {
  return {
    root: {
      backgroundColor: "#ffffff",
      marginTop: 30,
      width: "100%",
      height: "fit-content",
      position: "relative",
    },
    headContainer: {
      padding: "10px 10px",
    },
    answerTitle: { textAlign: "center", fontSize: "20px", marginTop: "10px" },
    listRoot: {
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: "#ffffff",
      padding: "10px 8px",
      marginTop: 10,
      width: "100%",
      height: "100%",
      position: "relative",
    },
    mandatory: { fontFamily: "monospace" },
    type: {
      fontSize: "13px",
      marginTop: "5px",
      fontWeight: "300",
      fontFamily: "monospace",
      color: "#000000",
      letterSpacing:"-0.04px"
    },
    text: {
      fontSize: "13px",
      marginTop: "5px",
      fontWeight: "300",
      fontFamily: "monospace",
      color: "#000000",
      letterSpacing:"-0.04px"
    },
    answer: {
      fontSize: "12px",
      marginTop: "5px",
      color: "#4F4F4F",
      fontWeight: "300",
      fontFamily: "monospace",
      color: "#000000",
      letterSpacing:"-0.04px"
    },
    value: {
      fontSize: "13px",
      fontWeight: "200",
      fontFamily: "monospace",
    },
    answerContainer: {
      display: "flex",
      flexDirection: "column",
    },
    done: {
      background: "#36A720",
      borderRadius: "4px",
      color: "#ffffff",
      fontSize: "14px",
      position: "absolute",
      right: 15,
      bottom: 15,
    },
    divider: {
      margin: "10px 0px",
    },
    createIcon: {
      color: "#0B196A",
      fontSize: "20px",
    },
    delete: {
      color: "#EB5757",
      fontSize: "20px",
    },
    buttonContainer: {
      padding: "10px 0px",
    },
  };
});

const JobQuestions = (props) => {
  const dispatch = useDispatch();
  const questionStyles = styles();

  const {
    questions,
    client,
    isPostJobsAnswerLoading,
    isPutJobsAnswerLoading,
    isDeleteJobsAnswerLoading,
    jobApplication_Id,
    jobId,
  } = useSelector((state) => {
    const { client, freelancer } = state;
    const { questions, jobId } = client;
    const {
      isPostJobsAnswerLoading,
      isPutJobsAnswerLoading,
      isDeleteJobsAnswerLoading,
      jobApplication_Id,
    } = freelancer;
    return {
      questions,
      client,
      isPostJobsAnswerLoading,
      isPutJobsAnswerLoading,
      isDeleteJobsAnswerLoading,
      jobApplication_Id,
      jobId,
    };
  });

  const [state, setState] = useState({
    list: [],
    question_text: "",
    question_type: "",
    job_id: jobId,
    required: false,
    browseJobRedirection: false,
  });

  useEffect(() => {
    dispatch(getQuestions(state.job_id));
    setState({
      ...state,
      list: questions,
    });
  }, []);

  const handleCreate = (val) => {
    dispatch(createJobsAnswer(val, state.job_id));
    setState({
      ...state,
      list: [],
    });
  };
  const handleUpdate = (val) => {
    dispatch(updateJobsAnswer(val, state.job_id));
    setState({
      ...state,
      list: [],
    });
  };

  const handleBrowseJobRedirection = (event) => {
    dispatch(clearQuestionState());
    props.jobRedirection();
    setState({
      ...state,
      browseJobRedirection: true,
    });
  };

  const { browseJobRedirection } = state;

  return browseJobRedirection && browseJobRedirection ? (
    <BrowseJobs redirected={true} />
  ) : (
    <React.Fragment>
      <CssBaseline />
      <Container className={questionStyles.root}>
        <Grid container className={questionStyles.headContainer}>
          <Typography
            variant="h5"
            gutterBottom
            className={questionStyles.answerTitle}
          >
            Answer the questions
          </Typography>
        </Grid>
        <Divider className={questionStyles.divider} />
        {/* <Grid className={questionStyles.headContainer}> */}
        <QuestionList
          items={state.list}
          jobQuestions={props.jobQuestions}
          jobId={state.job_Id}
          jobs_application_id={jobApplication_Id}
        />
        {/* </Grid> */}
        <Grid className={questionStyles.headContainer}>
          <Grid item className={questionStyles.buttonContainer}>
            <br />
            <IgButton
              variant="contained"
              label="Done"
              className={questionStyles.done}
              onClick={() => history.push("/applied-jobs")}
            ></IgButton>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

const QuestionList = (props) => {
  const dispatch = useDispatch();

  const { questions, jobsAnswers, jobApplication_Id, jobId } = useSelector(
    (state) => {
      const { client, freelancer } = state;
      const { questions, jobId } = client;
      const { jobsAnswers, jobApplication_Id } = freelancer;
      return { questions, jobsAnswers, jobApplication_Id, jobId };
    }
  );

  const state = {
    items: questions,
  };

  questions.forEach((index) => {
    let x = index.jobs_answers.filter(
      (sindex) => sindex.jobs_application_id === jobApplication_Id
    );
    if (x[0]) {
      index.answer = x[0].answer;
      index.answer_id = x[0].id;
    }
  });

  return (
    questions &&
    questions.map((index) => (
      <QuestionListItem
        key={index.id}
        item={index}
        jobId={jobId}
        jobs_application_id={jobApplication_Id}
      />
    ))
  );
};

const QuestionListItem = (props) => {
  const dispatch = useDispatch();
  const questionStyles = styles();

  const { questions, jobsAnswers, jobApplication_Id, jobId } = useSelector(
    (state) => {
      const { client, freelancer } = state;
      const { questions, jobId } = client;
      const { jobsAnswers, jobApplication_Id } = freelancer;
      return { questions, jobsAnswers, jobApplication_Id, jobId };
    }
  );

  const { answer_id } = props.item;

  let Answer = "";

  jobsAnswers &&
    jobsAnswers.map((jobAns) => {
      jobAns.id === answer_id && (Answer = jobAns);
    });

  const [state, setState] = useState({
    question_text: props.item.question_text,
    question_type: props.item.question_type,
    required: props.item.required,
    answer: props.item.answer,
    my_answer_id: answer_id,
    answerChange: "",
    id: props.item.id,
    jobId: jobId,
    open: false,
  });

  const { question_text, question_type, answer } = state;

  const handleClickOpen = () => {
    setState({
      ...state,
      open: true,
    });
  };
  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };
  const handleSubmitAnswer = () => {
    setState({
      ...state,
      open: false,
    });
    dispatch(
      createJobsAnswer(
        {
          answer: state.answerChange,
          jobs_application_id: jobApplication_Id,
          question_id: state.id,
        },
        jobId,
        jobApplication_Id
      )
    );
  };
  const handleUpdateAnswer = () => {
    setState({
      ...state,
      open: false,
    });
    dispatch(
      updateJobsAnswer(
        {
          id: answer_id,
          answer: state.answerChange,
        },
        jobApplication_Id
      )
    );
  };
  const handleAnswerChange = (event) => {
    setState({
      ...state,
      answerChange: event.target.value,
    });
  };
  const handleDelete = () => {
    dispatch(deleteJobsAnswer(answer_id, jobApplication_Id));
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Container fixed>
        <Typography component="div" className={questionStyles.listRoot}>
          <Typography variant="body2">
            {state.required ? (
              <span className={questionStyles.mandatory}>
                This question is mandatory
              </span>
            ) : null}
            <br />
            <span className={questionStyles.type}>Question Type: </span>{" "}
            <span className={questionStyles.value}>{question_type}</span>
            <br />
            <span className={questionStyles.text}>Question: </span>{" "}
            <span className={questionStyles.value}>{question_text}</span>
            <br />
            <span className={questionStyles.answer}>Answer: </span>{" "}
            <span className={questionStyles.value}>
              {Answer ? Answer.answer : answer}
            </span>
            <br />
          </Typography>
          <Divider className={questionStyles.divider} />
          {Answer.answer ? (
            <div className={questionStyles.answerContainer}>
              <div>
                <IconButton aria-label="delete" onClick={handleClickOpen}>
                  <CreateIcon className={questionStyles.createIcon} />
                </IconButton>
                <Dialog
                  open={state.open}
                  onClose={handleClose}
                  aria-labelledby="form-dialog-title"
                  maxWidth="md"
                >
                  <DialogTitle id="form-dialog-title">
                    {question_type}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>{question_text}</DialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Answer"
                      type="text"
                      onChange={handleAnswerChange}
                      fullWidth
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={handleUpdateAnswer} color="primary">
                      Update Answer
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
              <IconButton aria-label="delete" onClick={handleDelete}>
                <DeleteIcon className={questionStyles.delete} />
              </IconButton>
            </div>
          ) : (
            <div>
              <IconButton aria-label="delete" onClick={handleClickOpen}>
                <CreateIcon className={questionStyles.createIcon} />
              </IconButton>
              <Dialog
                open={state.open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
              >
                <DialogTitle id="form-dialog-title">
                  {question_type}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>{question_text}</DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Answer"
                    type="text"
                    onChange={handleAnswerChange}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleSubmitAnswer} color="primary">
                    Submit Answer
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          )}
        </Typography>
        <Divider className={questionStyles.divider} />
      </Container>
    </React.Fragment>
  );
};

export default withRouter(JobQuestions);
