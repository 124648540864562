import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import LanguageIcon from '@material-ui/icons/Language';
import WorkIcon from '@material-ui/icons/Work';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BusinessIcon from '@material-ui/icons/Business';
// import SettingsIcon from '@material-ui/icons/Settings';
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import { ReactComponent as InterestIcon } from '../../../../../../Assets/interest-icon.svg';
import { SidebarNav } from './components';


const useStyles = makeStyles(theme => ({
  drawer: {
    width: 200,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },

}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;
  const classes = useStyles();
  const clientPages = [
    {
      title: 'Dashboard',
      href: '/dashboard-client',
      icon: <DashboardIcon />
    },
    {
      title: 'Jobs',
      href: '/client-job-list',
      icon: <WorkIcon />
    },
    {
      title: 'Company',
      href: '/company-detail',
      icon: <BusinessIcon />
    },
    {
      title: 'Account',
      href: '/account',
      icon: <AccountBoxIcon />
    },
    // {
    //   title: 'Settings',
    //   href: '/settings',
    //   icon: <SettingsIcon />
    // },
    // {
    //   title: 'Logout',
    //   href: '/login',
    //   icon: <ExitToAppIcon />
    // }
  ];
  const freelancerPages = [
    {
      title: "Dashboard",
      href: "/dashboard",
      icon: <DashboardIcon />,
    },
    {
      title: "Jobs",
      href: "/browse-jobs",
      icon: <WorkIcon />,
    },
    {
      title: "Applied jobs",
      href: "/applied-jobs",
      icon: <WorkIcon />,
    },
    {
      title: "Profile",
      href: "/profile",
      icon: <PeopleIcon />,
    },
    {
      title: "Skills",
      href: "/skills",
      icon: <FitnessCenterIcon />,
    },
    {
      title: "Interests",
      href: "/interests",
      icon: <FitnessCenterIcon />,
    },
    {
      title: "Languages",
      href: "/languages",
      icon: <LanguageIcon />,
    },
    {
      title: "Identity",
      href: "/my-identity",
      icon: <FingerprintIcon />,
    },
    {
      title: "Experience",
      href: "/my-experience",
      icon: <AssignmentIcon />,
    },
    {
      title: "Account",
      href: "/account",
      icon: <AccountBoxIcon />,
    },
    // {
    //   title: 'Settings',
    //   href: '/settings',
    //   icon: <SettingsIcon />
    // },
    // {
    //   title: 'Logout',
    //   href: '/login',
    //   icon: <ExitToAppIcon />
    // }
  ];
  let pages = (localStorage.user_type === 'Client') ? clientPages : freelancerPages
  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        {/* <Profile /> */}
        {/* <Divider className={classes.divider} /> */}
        <SidebarNav
          className={classes.nav}
          pages={pages}
          onClose={onClose}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};
export default Sidebar;