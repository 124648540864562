export const options = [
  { value: "Contract", label: "Contract" },
  { value: "PerHour", label: "PerHour" },
  { value: "PerMilestone", label: "PerMilestone" },
];
export const options2 = [
  { value: 0, label: "High School" },
  { value: 1, label: "Trade School" },
  { value: 2, label: "Associate Degree" },
  { value: 3, label: "Certifications" },
  { value: 4, label: "Undergraduate Degree" },
  { value: 5, label: "Gradudate Studies" },
  { value: 6, label: "Doctor of Philosophy (PhD)" },
  { value: 7, label: "Bachelor's Degree" },
  { value: 8, label: "Master's Degree" },
  { value: 9, label: "Doctoral Degree" },
];

export const companySize = [
  { value: "1-10 employees", label: "1-10 employees" },
  { value: "10-50 employees", label: "10-50 employees" },
  { value: "50-200 employees", label: "50-200 employees" },
  { value: "200+ employees", label: "200+ employees" },
];
export const department = [
  { value: 0, label: "Actuarial" },
  { value: 1, label: "Office and Business Administration" },
  { value: 2, label: "Claims" },
  { value: 3, label: "Finance" },
  { value: 4, label: "Human Resources" },
  { value: 5, label: "Information Technology" },
  { value: 6, label: "Legal" },
  { value: 7, label: "Marketing/Communications" },
  { value: 8, label: "Project Management" },
  { value: 9, label: "Sales" },
  { value: 10, label: "Students" },
  { value: 11, label: "Underwriting" },
  { value: 12, label: "Consulting" },
  { value: 13, label: "Client and Customer Service" },
  { value: 14, label: "Strategy and Corporate Development" },
  { value: 15, label: "General Management" },
]; 

