import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { Button, CircularProgress,InputAdornment } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Grid, Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { loginUser } from "./../../../Actions/authAction";
import { history } from "./../../../Utils/history";
import Topbar from "../Topbar/Topbar";
import myStyle from "../../../Utils/style";
import { LoginSchema } from "../../../Utils/Schema";

const Login = (props) => {
  const LoginStyle = myStyle();

  const [open, setOpen] = useState(false);
  
  const [values, setValues] = useState({showPassword: false});

  const dispatch = useDispatch();

  const { isUserLoginLoading, loginError } = useSelector((state) => {
    return {
      isUserLoginLoading: state.auth.isUserLoginLoading,
      loginError: state.auth.loginError,
    };
  });
  
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleForgotPassword = (event) => {
    event.preventDefault();
    history.push("/forgot-password");
  };

  const handleResendConfirmation = (event) => {
    event.preventDefault();
    history.push("/resend-confirmation");
  };

  const handleRegister = () => {
    history.push("/register");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleUserLogin = (data) =>{
    dispatch(loginUser(data));
    (loginError) && setOpen(true);
  } 

  const { register, handleSubmit, control, errors } = useForm({
    resolver: yupResolver(LoginSchema),
  });

  const currentPath = props.location.pathname;
  return (
    <>
      <Topbar currentPath={currentPath} />
      <div className={LoginStyle.root}>
        <Grid container>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <form noValidate onSubmit={handleSubmit(handleUserLogin)}>
              {isUserLoginLoading ? (
                <CircularProgress className={LoginStyle.loading} size={20} />
              ) : (
                <Paper className={LoginStyle.LoginPaper}>
                  <Typography variant="h4" gutterBottom color="secondary">
                    Login
                  </Typography>
                  <Grid container>
                    <Typography gutterBottom variant="body2">
                      Don't have account yet?
                    </Typography>
                    <Typography
                      onClick={() => handleRegister()}
                      className={LoginStyle.link}
                    >
                      Sign Up
                    </Typography>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        inputRef={register}
                        required
                        fullWidth={true}
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        className={LoginStyle.inputFormControl}
                      />
                      {errors.email && (
                        <p className={LoginStyle.error}>
                          {errors.email.message}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                          <Input
                            inputRef={register}
                            required
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                  >
                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }
                                    />
                            </FormControl>
                      {errors.password ? (
                        <p className={LoginStyle.error}>
                          {errors.password.message}
                        </p>
                      ): loginError && <p className={LoginStyle.error}>Please activate your accountby following the instructions<br/>in the account confirmation email you recieved to proceed</p>
                    }
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Controller
                            as={Checkbox}
                            control={control}
                            name="remember"
                            color="primary"
                            defaultValue={false}
                          />
                        }
                        label="Remember me"
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        className={LoginStyle.cButton}
                      >
                        Log In
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6}>
                      <Link
                        onClick={handleForgotPassword}
                        variant="body2"
                        className={LoginStyle.link}
                      >
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item xs={6}>
                      <Link
                        onClick={handleResendConfirmation}
                        variant="body2"
                        className={LoginStyle.link}
                      >
                        Send Confirmation Email
                      </Link>
                    </Grid>
                  </Grid>
                </Paper>
              )}
            </form>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </div>
    </>
  );
};

export default Login;
