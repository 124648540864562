import React, { useState, useEffect } from "react";
import ImageUploading from "react-images-uploading";
import "./IdentityForm.css";
import EditIcon from "@material-ui/icons/Edit";
import {
  getIdentities,
  deleteIdentity,
  createIdentity,
} from "../../../Actions/freelancerAction";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import {
  Typography,
  Button,
  Tooltip,
  IconButton,
  Grid,
  Paper,
  Select,
  MenuItem,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import DatePickerField from "../../../Components/Form/DatePickerField";
import { Upload, Icon, message } from "antd";
import { makeStyles } from "@material-ui/core";
import UpdateIdentity from "./UpdateIdentity";
import { getBase64, beforeUpload } from "./base64";
import Error from "../ErrorComponent/ErrorComponent";
import { purple } from "@material-ui/core/colors";

const styles = makeStyles(() => {
  return {
    identityRoot: {
      marginLeft: 10,
      marginRight: 15,
      backgroundColor: "#F6F8FB",
    },
    loading: {
      color: "#ffffff",
      marginLeft: "4px",
    },
    addIdentity: {
      paddingBottom: 10,
      paddingTop: 10,
    },
    cards: {
      marginTop: "20px",
    },
    identityBox: {
      width: "100%",
      backgroundColor: "#ffffff",
      marginTop: 10,
      paddingLeft: "24px",
      boxShadow:
        "0 0 0 1px rgba(93, 93, 99, 0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },

    addIcon: {
      margin: "auto 20px -6px auto",
      cursor: "pointer",
    },
    imageContainer: {
      position: "relative",
      marginTop: "30px",
    },
    idImage: { textAlign: "left" },
    image: { width: "100%" },
    Hr: { marginTop: "5px" },
    box1: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      letterSpacing: "0.1px",
      color: "#000000",
    },
    box2: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      letterSpacing: "0.1px",
      color: "#9B9B9B",
      marginBottom: "10px",
    },
    toolTip: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "-10px",
    },
    imageBoxColor: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 10,
    },
    image2: {
      width: "100%",
      height: "250px",
      border: "5px solid #FFFFFF",
      borderRadius: "10px",
      marginTop: "5px",
    },
    editIcon: {
      color: "#0B196A",
    },
    deleteIcon: {
      color: "#EB5757",
      marginLeft: "-5px",
    },
    iconButton: { float: "right" },
    divider: {
      border: "none",
      borderBottom: "1px dashed #BDBDBD",
    },
  };
});

const IdentityForm = (props) => {
  const dispatch = useDispatch();
  const identityStyles = styles();

  const {
    Identity,
    isPutIdentityLoading,
    isPostIdentityLoading,
    isGetAllIdentitiesLoading,
  } = useSelector((state) => {
    const { freelancer } = state;
    const {
      Identity,
      isPutIdentityLoading,
      isPostIdentityLoading,
      isGetAllIdentitiesLoading,
    } = freelancer;
    const { storage_url } = freelancer || "";
    const { url } = storage_url || "";
    return {
      Identity,
      url,
      isPutIdentityLoading,
      isGetAllIdentitiesLoading,
      isPostIdentityLoading,
    };
  });

  const [state, setState] = useState({
    isImageUrl: true,
    open: false,
    openEdit: false,
    dialog_id: "",
    loading: false,
    name: "",
    expiry: new Date(),
    storage_url: props.storage_url,
    pop_up: false,
    drop_arrow: false,
    dialog: false,
    imageUrl: "",
  });
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    setState({
      ...state,
      imageUrl: imageList[0]["data_url"],
      isImageUrl: false,
    });
    setImages(imageList);
  };

  useEffect(() => {
    dispatch(getIdentities());
  }, []);

  const handleName = (event) => {
    setState({
      ...state,
      name: event.target.value,
    });
  };
  const handleExpiry = (val) => {
    setState({
      ...state,
      expiry: val,
    });
  };

  const handleDelete = (id) => {
    dispatch(deleteIdentity(id));
  };

  const handleCreateIdentity = () => {
    dispatch(
      createIdentity(
        {
          name: state.name,
          expiry: state.expiry,
          storage_url: state.imageUrl,
          user_id: state.user_id,
        },
        () => {
          setState({
            ...state,
            imageUrl: "",
            isImageUrl: true,
          });
        }
      )
    );
    setImages([]);
    handleClose();
  };

  // const handleClickOpen = () => {
  //   setState({
  //     ...state,
  //     open: true,
  //     pop_up : false
  //   });
  // };
  const handleClose = () => {
    setState({
      ...state,
      open: false,
      pop_up: false,
    });
  };
  // const handleChange = (info) => {
  //   info.file.status === "uploading" &&
  //     setState({
  //       ...state,
  //       loading: true,
  //       pop_up : false
  //     });

  //   info.file.status === "done" &&
  //     getBase64(info.file.originFileObj, (imageUrl) =>
  //       setState({
  //         ...state,
  //         imageUrl,
  //         loading: false,
  //         pop_up : false
  //       })
  //     )
  //     info.file.status === "error" &&
  //     setState({
  //       ...state,
  //       open : false,
  //       pop_up: true,
  //       loading: false,
  //     });
  // };
  const handleEditDialog = () => {
    setState({
      ...state,
      open: false,
      dialog_id: "",
      openEdit: false,
    });
  };

  const { imageUrl } = state;

  const uploadButton = (
    <div>
      <Icon type={state.loading ? "loading" : "plus"} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  return (
    <Grid container spacing={3} className={identityStyles.identityRoot}>
      <Typography variant="h4" className={identityStyles.addIdentity}>
        Add Identity
      </Typography>
      <Paper className={identityStyles.identityBox}>
        <Grid container alignItems="center" spacing={5}>
          <Grid
            item
            xs={12}
            lg={3}
            sm={12}
            md={4}
            className={identityStyles.imageContainer}
          >
            <Typography className={identityStyles.idImage}>
              {" "}
              ID Image
            </Typography>
            <ImageUploading
              multiple
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div
                  style={isDragging ? { color: "red" } : undefined}
                  style={{
                    fontSize: 15,
                    marginLeft: 0,
                    marginBottom: 8,
                    position: "relative",
                  }}
                >
                  {state.isImageUrl ? (
                    <>
                      <div style={{ textAlign: "right", marginRight: "-20px" }}>
                        <AddIcon
                          className={identityStyles.addIcon}
                          onClick={onImageUpload}
                          {...dragProps}
                        />
                      </div>
                      <hr className={identityStyles.Hr} />
                    </>
                  ) : (
                    ""
                  )}
                  &nbsp;
                  {images.map((image, index) => (
                    <div key={index} className="image-item">
                      <img
                        style={{ marginTop: -10 }}
                        src={image["data_url"]}
                        alt=""
                        width="120"
                      />
                      <div className="image-item__btn-wrapper">
                        {/* <Button
                          style={{ marginTop: 10 }}
                          color="primary"
                          variant="contained"
                          size="small"
                          onClick={() => onImageUpdate(index)}
                        >
                          Update Image
                        </Button> */}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </ImageUploading>
          </Grid>
          <Grid item xs={12} lg={3} sm={12} md={4}>
            <Typography> ID Type</Typography>
            <Select
              value={state.name}
              className={identityStyles.image}
              onChange={handleName}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="Driving Licence">Driving Licence</MenuItem>
              <MenuItem value="Passport">Passport</MenuItem>
              <MenuItem value="Citizenship card">Citizenship card</MenuItem>
            </Select>
          </Grid>

          <Grid item xs={12} lg={3} sm={12} md={4}>
            <Typography variant="body2">Expiry date</Typography>
            <DatePickerField
              changeDate={handleExpiry}
              selectedDate={state.expiry}
              minDate={new Date()}
            />
          </Grid>
          <Grid item xs={12} lg={3} sm={12} md={4}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={handleCreateIdentity}
            >
              {" "}
              Add Identity
              {isPostIdentityLoading && (
                <CircularProgress
                  className={identityStyles.loading}
                  size={20}
                />
              )}
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={3} className={identityStyles.cards} wrap="wrap">
        {Identity
          ? Identity.map((index, i) => (
              <Grid key={i} item xs={12} sm={6} md={4} lg={4} xl={3}>
                <Paper className="exp-item">
                  <Grid container>
                    <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                      <Typography className={identityStyles.box1}>
                        Type: {index.name}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                      className={identityStyles.toolTip}
                    >
                      <Tooltip title="Edit Identity" aria-label="edit" arrow>
                        <IconButton
                          aria-label="edit"
                          onClick={() =>
                            setState({
                              ...state,
                              openEdit: true,
                              dialog_id: index.id,
                            })
                          }
                          className={identityStyles.iconButton}
                        >
                          <EditIcon className={identityStyles.editIcon} />
                        </IconButton>
                      </Tooltip>
                      {state.openEdit && index.id === state.dialog_id && (
                        <UpdateIdentity
                          // eslint-disable-next-line
                          handleEditDialog={handleEditDialog}
                          isPutIdentityLoading={isPutIdentityLoading}
                          dialogState={state}
                          data={index}
                        />
                      )}
                      <Tooltip
                        title="Delete Identity"
                        aria-label="delete"
                        arrow
                      >
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDelete(index.id)}
                        >
                          <DeleteIcon className={identityStyles.deleteIcon} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>{" "}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className="title-container"
                  >
                    <Typography className={identityStyles.box2}>
                      Expiry: &nbsp;
                      {new Intl.DateTimeFormat("en-GB", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                      }).format(Date.parse(index.expiry))}
                    </Typography>
                  </Grid>{" "}
                  <Divider className={identityStyles.divider} />
                  <Grid item className={identityStyles.imageBoxColor}>
                    {/* {index.storage_url ? console.log("hiiii"+index.storage_url): console.log("himm")} */}
                    {index.storage_url ? (
                      <img
                        src={index.storage_url}
                        alt="identity"
                        className={identityStyles.image2}
                      />
                    ) : null}
                  </Grid>
                </Paper>
              </Grid>
            ))
          : null}
      </Grid>
    </Grid>
  );
};

export default withRouter(IdentityForm);
