export const authConstants = {
  USER_SIGN_UP_REQUEST: "USER_SIGN_UP_REQUEST",
  USER_SIGN_UP_SUCCESS: "USER_SIGN_UP_SUCCESS",
  USER_SIGN_UP_FAILURE: "USER_SIGN_UP_FAILURE",

  USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAILURE: "USER_LOGIN_FAILURE",

  USER_FORGOT_PASSWORD_REQUEST: "USER_FORGOT_PASSWORD_REQUEST",
  USER_FORGOT_PASSWORD_SUCCESS: "USER_FORGOT_PASSWORD_SUCCESS",
  USER_FORGOT_PASSWORD_FAILURE: "USER_FORGOT_PASSWORD_FAILURE",

  USER_RESET_PASSWORD_REQUEST: "USER_RESET_PASSWORD_REQUEST",
  USER_RESET_PASSWORD_SUCCESS: "USER_RESET_PASSWORD_SUCCESS",
  USER_RESET_PASSWORD_FAILURE: "USER_RESET_PASSWORD_FAILURE",

  USER_CONFIRM_EMAIL_REQUEST: "USER_CONFIRM_EMAIL_REQUEST",
  USER_CONFIRM_EMAIL_SUCCESS: "USER_CONFIRM_EMAIL_SUCCESS",
  USER_CONFIRM_EMAIL_FAILURE: "USER_CONFIRM_EMAIL_FAILURE",

  USER_RESEND_CONFIRMATION_REQUEST: "USER_RESEND_CONFIRMATION_REQUEST",
  USER_RESEND_CONFIRMATION_SUCCESS: "USER_RESEND_CONFIRMATION_SUCCESS",
  USER_RESEND_CONFIRMATION_FAILURE: "USER_RESEND_CONFIRMATION_FAILURE",

  USER_UPDATE_REQUEST: "USER_UPDATE_REQUEST",
  USER_UPDATE_SUCCESS: "USER_UPDATE_SUCCESS",
  USER_UPDATE_FAILURE: "USER_UPDATE_FAILURE",
};
