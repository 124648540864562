/*eslint-disable*/
import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Box, Button, makeStyles } from "@material-ui/core";
import DashboardWidget from "../../../Components/Form/DashboardWidget";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { history } from "../../../Utils/history";
import { getDashboardfreelancer } from "../../../Actions/freelancerAction";
import { getJobs, getAppliedJobs } from "../../../Actions/clientAction";
import { getUsersProfiles } from "../../../Actions/userAction";
import DP from "../../../Assets/Landing/freelancers.svg";
import { Grid } from "@material-ui/core";
import { CircularProgressbar } from "react-circular-progressbar";

const styles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    container: {
      height: "83vh",
    },
    innerRoot: {
      display: "flex",
      flexDirection: "row",
      marginTop: 20,
      padding: 10,
      backgroundColor: "rgb(232, 243, 252)",
    },
    boxItem: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: 15,
      justifyContent: "center",
    },
    gridItem: {
      margin: "18px 0",
    },
    imageRoot: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    image: {
      width: 400,
      height: "auto",
    },
    typography: {
      marginTop: 24,
      color: "#adadad",
      textAlign: "center",
    },
    mainTypography: {
      marginTop: 24,
      color: "#002c7c",
    },
    button: {
      minWidth: 120,
    },
  };
});

let openBids = [],
  completedJobs = [],
  inProgressJobs = [];

const FreelancerDashboard = () => {
  const dispatch = useDispatch();
  const dashBoardStyles = styles();

  const {
    dashboard,
    freelancer,
    error,
    jobs,
    percentage,
    first_name,
    usersProfile,
  } = useSelector((state) => {
    const { freelancer, client, auth, user } = state;
    const { usersProfile } = user;
    const { first_name } = auth;
    const { dashboard } = freelancer;
    const { percentage } = usersProfile || "";
    const { error } = freelancer;
    const { jobs } = client;
    return {
      dashboard,
      freelancer,
      error,
      jobs,
      percentage,
      first_name,
      usersProfile,
    };
  });

  useEffect(() => {
    dispatch(getUsersProfiles());
    dispatch(getDashboardfreelancer());
    dispatch(getJobs());
    dispatch(getAppliedJobs());
  }, []);

  let userName = "";
  dashboard &&
    dashboard.map((User) => {
      userName = User.user.first_name;
    });

  const handleApplications = () => {
    history.push("./applied-jobs");
  };

  let dashboard_length = null;

  if (dashboard) {
    dashboard_length = dashboard.length;
    openBids = dashboard.filter((index) => index.application_status === null);
    completedJobs = dashboard.filter(
      (index) => index.application_status === "completed"
    );
    inProgressJobs = dashboard.filter(
      (index) => index.application_status === "accepted"
    );
  }
  jobs &&
    openBids.forEach((index) => {
      jobs.forEach((sindex) =>
        sindex.jobs_applications.forEach((cindex) => {
          if (cindex.user_id === localStorage.user_id) {
            index.count = sindex.jobs_applications.length;
          }
        })
      );
    });

  return dashboard_length ? (
    <Box className={dashBoardStyles.root}>
      {percentage !== 100 && undefined !== percentage ? (
        <Box className={dashBoardStyles.innerRoot}>
          <CircularProgressbar
            styles={{
              root: { height: 105, width: 105 },
              path: { stroke: "#23C0F5" },
            }}
            value={percentage}
            text={`${percentage!== undefined ? percentage : 0 }%`}
          />
          <Box className={dashBoardStyles.boxItem}>
            <Typography>
              Great Start —{" "}
              <strong>
                {(first_name)? first_name.charAt(0).toUpperCase() + first_name.slice(1)
                 : 
                 userName.charAt(0).toUpperCase() + userName.slice(1)}!
              </strong>{" "}
            </Typography>
          </Box>
        </Box>
      ) : null}
      <Grid
        container
        spacing={1}
        justify="space-evenly"
        direction="row"
        wrap="wrap"
        className={dashBoardStyles.gridItem}
      >
        {openBids
          ? openBids.map((index) => (
              <DashboardWidget
                key={index.id}
                statusLineText="Open Bid"
                payment={index.payment_type}
                rate={index.proposed_rate}
                jobTitle={index.job.title}
                numberOfBids={index.count}
                text="Bids"
                handleOnclickmethod={handleApplications}
              />
            ))
          : null}
        {inProgressJobs
          ? inProgressJobs.map((index) => (
              <DashboardWidget
                key={index.id}
                statusLineText="In progress"
                payment={index.payment_type}
                rate={index.proposed_rate}
                jobTitle={index.job.title}
              />
            ))
          : null}
        {completedJobs
          ? completedJobs.map((index) => (
              <DashboardWidget
                key={index.id}
                statusLineText="Completed"
                payment={index.payment_type}
                rate={index.proposed_rate}
                jobTitle={index.job.title}
              />
            ))
          : null}
      </Grid>
    </Box>
  ) : (
    <React.Fragment>
      <Container className={dashBoardStyles.container}>
        <Box className={dashBoardStyles.root}>
          {percentage !== 100 && undefined !== percentage ? (
            <Box className={dashBoardStyles.innerRoot}>
              <CircularProgressbar
                styles={{
                  root: { height: 105, width: 105 },
                  path: { stroke: "#23C0F5" },
                }}
                value={percentage}
                text={`${percentage}%`}
              />
              <Box className={dashBoardStyles.boxItem}>
                <Typography>
                  Great Start —{" "}
                  <strong>{first_name ? first_name : userName}!</strong>{" "}
                </Typography>
              </Box>
            </Box>
          ) : null}
        </Box>
        <Box className={dashBoardStyles.imageRoot}>
          <img src={DP} alt="Dashboard" className={dashBoardStyles.image} />
          <Typography variant="h5" className={dashBoardStyles.mainTypography}>
            Apply Jobs and start managing them from here!
          </Typography>
          <Typography paragraph className={dashBoardStyles.typography}>
            Looking to get a new job?
            <br />
            Start with browsing our portal and apply to ideal job.
          </Typography>

          <Button
            variant="contained"
            color="primary"
            className={dashBoardStyles.button}
            onClick={() => history.push("/skills")}
          >
           Skills
          </Button>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(FreelancerDashboard);
