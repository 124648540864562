import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Typography } from "antd";
import CancelIcon from "@material-ui/icons/Cancel";

const { Text } = Typography;

const styles = makeStyles(() => {
  return {
    checkCircle: { color: "green" },
    statusContainer:{
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      marginTop:"5px"
    },
    selected: {
      color: "green",
      fontWeight: 700,
      marginLeft:"3px"
    },
    cancelicon: {
      color: "red",
    },
    rejected: {
      color: "red",
      fontWeight: 700,
      marginLeft:"3px"
    },
  };
});

const CandidateStatus = (props) => {
  const candidateStyles = styles();

  let s = props.data.filter((index) => {
    return index.user.id === props.statusC.id;
  });

  return s[0].application_status === "accepted" ? (
    <Box component="span" display="inline" className={candidateStyles.statusContainer}>
      <CheckCircleIcon className={candidateStyles.checkCircle} />
      <Text className={candidateStyles.selected}>selected</Text>
    </Box>
  ) : s[0].application_status === "rejected" ? (
    <>
    <Box component="span" display="inline" className={candidateStyles.statusContainer}>
      <CancelIcon className={candidateStyles.cancelicon} />
      <Text className={candidateStyles.rejected}>rejected</Text>
      </Box>
    </>
  ) : null;
};

export default CandidateStatus;
