import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  card: {
    padding: "30px",
    // marginBottom: "auto ",
    borderRadius: 10,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 8px 20px 0 rgba(224,224,224,0.5)',
  },

  mediaImage: {
    margin: "0 auto",
    marginBottom: "20px",
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontSize:15,
    height: 35,
    color: '#47495A',
    textTransform: "uppercase",
    textAlign: "center",
    fontWeight: "600"
  },
  description: {
    color: '#6A7C92',
    textAlign: "center",
    fontSize: 14,
    
  }
});

export default function FeatureCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <div className={classes.mediaImage}>
        <img src={props.image} alt="InsureGig" />
      </div>
      <CardContent>
        <Typography
          gutterBottom
          // variant="h6"
          // component="h2"
          className={classes.title}
        >
          {props.name}
        </Typography>
        <Typography
          // variant="body1"
          // color="textSecondary"
          // component="p"
          className={classes.description}
        >
          {props.description}
        </Typography>
      </CardContent>
    </Card>
  );
}
