import React from "react";
import { Button, CircularProgress } from "@material-ui/core";

export default function ContainedButtons(props) {
  const { loading, label, iconname, disabled } = props;
  const InnerIcon = iconname;
  // const x = true;
  return (
    <Button
      variant="contained"
      color="primary"
      endIcon={
        loading ? (
          <CircularProgress size={15} style={{ color: "white" }} />
        ) : iconname ? (
          <InnerIcon />
        ) : null
      }
      {...props}
      disabled={disabled}
    >
      {" "}
      {label} 
      {/* {x ? (
        <CircularProgress size={15} style={{ color: "white" }} />
      ) : iconname ? (
        <InnerIcon />
      ) : null} */}
    </Button>
  );
}
