import React from 'react';
import "./Topbar.css";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Hidden, IconButton, Box } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import UserAvatarMenu from "../../../../../../Components/UserAvatarMenu";
import logo from "./../../../../../../Assets/logo.png";
import { history } from "../../../../../../Utils/history";

const styles = makeStyles(() =>{
return {
  root : {
    backgroundColor: 'white', 
    display:'flex',
    justifyContent:'space-between'
  },
  Img : {cursor : "pointer"},
  menu : {
    display: 'flex', 
    justifyContent: 'space-between'
  }
}
});

const Topbar = props => {
  const { onSidebarOpen } = props;
  const topStyles = styles();

  return (
    <AppBar>
      <Toolbar className={topStyles.root} >
        <img
          alt="Logo"
          src={logo}
          className={topStyles.Img}
          onClick={() => history.push('/')}
        />
        <Hidden mdDown>
          <Box className={topStyles.menu}>
            <UserAvatarMenu image={localStorage.avatar}  />
          </Box>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="primary"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
