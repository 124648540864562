const contactUsConstants = {

    GET_ALL_CONTACT_US_REQUEST: 'GET_ALL_CONTACT_US_REQUEST',
    GET_ALL_CONTACT_US_SUCCESS:'GET_ALL_CONTACT_US_SUCCESS',
    GET_ALL_CONTACT_US_FAILURE:'GET_ALL_CONTACT_US_FAILURE',

    POST_CONTACT_US_REQUEST: 'POST_CONTACT_US_REQUEST',
    POST_CONTACT_US_SUCCESS:'POST_CONTACT_US_SUCCESS',
    POST_CONTACT_US_FAILURE:'POST_CONTACT_US_FAILURE',

    PUT_CONTACT_US_REQUEST: 'PUT_CONTACT_US_REQUEST',
    PUT_CONTACT_US_SUCCESS:'PUT_CONTACT_US_SUCCESS',
    PUT_CONTACT_US_FAILURE:'PUT_CONTACT_US_FAILURE',

    DELETE_CONTACT_US_REQUEST: 'DELETE_CONTACT_US_REQUEST',
    DELETE_CONTACT_US_SUCCESS:'DELETE_CONTACT_US_SUCCESS',
    DELETE_CONTACT_US_FAILURE:'DELETE_CONTACT_US_FAILURE',

}
export default contactUsConstants;