/*eslint-disable*/
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import EventNoteIcon from '@material-ui/icons/EventNote';
import Typography from "@material-ui/core/Typography";
const numeral = require("numeral");
numeral.defaultFormat("0,000");
const useStyles = makeStyles(theme => ({
    paper: {
        margin: 12,
        boxSizing: 'border-box',
        height: 301,
        width: 327,
        border: '1px solid #F0F1F5',
        borderRadius: 3,
        backgroundColor: '#FFFFFF',
        boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)'
    },
    statusLineText: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 15,
        minHeight: 71,
        fontWeight: 'bold',
        backgroundColor: "#F2F7FE",
    },
    hourlyPrice: {
        display: 'flex',
        alignItems: 'center',
        margin: 15,
        padding: 10,
        height: 30,
        width: 'max-content',
        color: "#16975D",
        borderRadius: 5,
        fontWeight: 600,
        backgroundColor: "rgba(42,213,135,0.14)"
    },
    jobTitle: {
        paddingLeft: 15,
        color: "#0C0033",
    },
    eventnote: {
        paddingLeft: 15,
        opacity: 0.5,
        color: "#0C0033",
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
    },
    number: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 30,
        maxWidth: 106,
        borderRadius: 4,
        backgroundColor: "rgba(72,171,249,0.33)",
        color: "#0064B2",
    }
}));
export default function DashboardWidget(props) {
    const classes = useStyles();
    let { statusLineText, payment, hourlyPrice, jobTitle, numberOfBids, numberOfMessages, date, rate, text, handleOnclickmethod } = props;
    // let amount1 = (payment === "hourly") ? "Hourly" : "Contract";
    // let amount2 = hourlyPrice;
    if (payment === "fixed") {
        payment = "Fixed";
    }
    let amount_total = `${payment}: $${(hourlyPrice) ? hourlyPrice : rate}`;
    return (
        <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.statusLineText}>
                {statusLineText}
            </Typography>
            {
                (undefined !== hourlyPrice && null !== hourlyPrice || undefined !== rate && null !== rate) ? <Typography variant="body2" className={classes.hourlyPrice}>
                    {amount_total}
                </Typography> : null
            }
            <Typography variant="h5" gutterBottom className={classes.jobTitle} onClick={() => handleOnclickmethod()} style={{cursor: 'pointer'}}>
                {(jobTitle !== "Draft Job") ? jobTitle : null}
            </Typography>
            {
                (undefined !== date && null !== date) ? <div className={classes.eventnote}><EventNoteIcon />{new Intl.DateTimeFormat("en-GB", { year: "numeric", month: "long", day: "2-digit" }).format(Date.parse((undefined !== date && null !== date) ? date : new Date()))}</div> : null
            }

            <div style={{ paddingLeft: 15 }}>
                <Typography className={classes.number}>
                    {numberOfMessages ? numberOfMessages : null}
                    {numberOfBids ? `${numberOfBids} ${text} `  : null}
                </Typography>
            </div>
        </Paper>
    );
}