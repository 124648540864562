import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import List from "@material-ui/core/List";
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const styles = (theme => ({
  quote: {
    marginTop: theme.spacing(3),
    display:'flex',
    justifyContent:'center',
    color: '#404158',
    opacity: 0.50,
    textAlign: 'center',
  },  
  icon: {
    display:'flex',
    justifyContent:'center',
    color: '#0C0033',
    opacity: 0.50,
  },
}));

class ForgotPasswordMailFooter extends Component {
 
  render() {
    const { classes } = this.props;
    return (
      
						<div>
							<Typography variant="caption" className={classes.quote}>
              © 2019 InsureGig  •  Read the Blog  •  123 Main St, Populartown, USA 90182<br />
                Tired of receiving these emails? Click here to unsubscribe.
							</Typography>
                          <List className={classes.icon}>
                            <FacebookIcon fontSize="large" onClick={() =>window.open("https://www.facebook.com/insuregig","_blank")} style={{cursor: 'pointer', padding:5}}></FacebookIcon>
                          
                            <TwitterIcon fontSize="large" onClick={() =>window.open("https://twitter.com/InsureGig?s=09","_blank")} style={{cursor: 'pointer', padding:5}}></TwitterIcon>

                            <LinkedInIcon fontSize="large" onClick={() => window.open("https://www.linkedin.com/company/14655913","_blank")} style={{cursor: 'pointer', padding:5}}></LinkedInIcon>

                          </List>
						</div>
           
    

    );
  }
}

export default connect()(withRouter(withStyles(styles)(ForgotPasswordMailFooter)));
