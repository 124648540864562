import React, { useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, Grid, Divider, Button } from "@material-ui/core";
import "./QuestionListItem.css";
import { deleteQuestion } from "../../../Actions/clientAction";

const styles = makeStyles(() => {
  return {
    questions: {
      fontWeight: "bold",
      fontFamily: "Roboto",
      fontSize: "14px",
      marginTop: "5px",
    },
    questionType: {
      fontSize: "12px",
      fontFamily: "Roboto",
      marginTop: "5px",
      marginBottom: "15px",
    },
    mandatory: {
      fontFamily: "Roboto",
      marginLeft: "10px",
      marginTop: "5px",
    },
    delete: {
      fontSize: "14px",
      fontFamily: "Roboto",
      color: "#EB5757",
      paddingRight: "5px",
      marginTop: "5px",
      textTransform: "capitalize",
    },
    deleteIcon: {
      fontSize: "14px",
      marginLeft: "2px",
    },
    loadingBox: {
      backgroundColor: "#ffffff",
      height: "100%",
      marginTop: 30,
      padding: 24,
      display: "flex",
      justifyContent: "center",
    },
  };
});

const QuestionListItem = (props) => {
  const dispatch = useDispatch();
  const listStyles = styles();

  const handleDelete = (itemId) => {
    dispatch(deleteQuestion(itemId, state.id));
  };

  let Question = "";
  const { client, isDeleteQuestionLoading, questions } = useSelector(
    (state) => {
      const { client } = state;
      const { isDeleteQuestionLoading, questions } = client;
      questions.map((question) => {
        question.id === props.id && (Question = question);
      });
      return { client, isDeleteQuestionLoading, questions };
    }
  );

  const [state] = useState({
    question_text: props.item.question_text,
    question_type: props.item.question_type,
    required: props.item.required,
    id: props.id,
  });

  const { question_type, question_text, id, required } = state;
  return (
    <React.Fragment>
      <CssBaseline />
      <br />
      <Container className="list-root">
        <Grid container>
          <Grid item xs={5} sm={5} md={5} lg={5}>
            <Typography className={listStyles.questions}>
              {Question.question_text}
            </Typography>
          </Grid>
          <Grid item xs={5} sm={5} md={5} lg={5}>
            {Question.required && (
              <Typography className={listStyles.mandatory}>
                Mandatory
              </Typography>
            )}
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <Button
              size="small"
              className={listStyles.delete}
              onClick={() => handleDelete(id)}
            >
              <DeleteIcon className={listStyles.deleteIcon} fontSize="small" />{" "}
              Delete
            </Button>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                className={(listStyles.questions, listStyles.questionType)}
              >
                {Question.question_type}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Divider />
    </React.Fragment>
  );
};

export default QuestionListItem;
