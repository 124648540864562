import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Typography from "@material-ui/core/Typography";
import { Box, Grid, makeStyles, Paper } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";

import { getExperiences } from "../../../Actions/freelancerAction";
import AddExperienceForm from "../../Components/Experience/AddExperienceForm";
import ExperienceCard from "../../Components/Experience/ExperienceCard";

import "./ShowExperience.css";
import ErrorComponent from "../../Components/Error/ErrorComponent";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
      backgroundColor: "#F6F8FB",
      paddingLeft: 10,
      marginTop: -10,
    },
    paper: {
      padding: theme.spacing(3),
      color: theme.palette.text.secondary,
    },
    pageTitle: {
      paddingBottom: 10,
      paddingTop: 10,
    },
    displayFlex: {
      display: "flex",
    },
    boxStyle: {
      justifyContent: "center",
      margin: "auto",
      paddingTop: "25%",
    },
  };
});

const ShowExperiences = () => {
  const showExperienceStyle = useStyles();

  const {
    Experience,
    experience_error,
    isPutExperienceLoading,
    isPostExperienceLoading,
  } = useSelector((state) => {
    const { freelancer } = state;
    const {
      Experience,
      experience_error,
      isPutExperienceLoading,
      isPostExperienceLoading,
    } = freelancer;
    return {
      Experience,
      experience_error,
      isPutExperienceLoading,
      isPostExperienceLoading,
    };
  });

  const [state, setState] = useState({
    editExperience: false,
    dialog_id: "",
    snack_open: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getExperiences());
  }, []);

  const handleClose = () => {
    setState({ ...state, snack_open: false });
  };

  return (
    // <>
    //   {isPutExperienceLoading || isPostExperienceLoading ? (
    //     <Box
    //       className={`${showExperienceStyle.displayFlex} ${showExperienceStyle.boxStyle}`}
    //     >
    //       <CircularProgress size={70} />
    //     </Box>
    //   ) : (
        <div className={showExperienceStyle.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                className={showExperienceStyle.pageTitle}
              >
                Add Experience
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper className={showExperienceStyle.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <AddExperienceForm />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            {Experience
              ? Experience.map((index) => (
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={index.id}>
                    <ExperienceCard
                      data={index}
                      state={state}
                      setState={setState}
                    />
                  </Grid>
                ))
              : null}

            <ErrorComponent
              error={experience_error}
              open={state.snack_open}
              handleClose={handleClose}
            />
          </Grid>
        </div>
    //   )}
    // </>
  );
};

export default ShowExperiences;
