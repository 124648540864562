/*eslint-disable*/
import React, { useState, useEffect } from "react";
import JobList from "../JobList/JobList";
import JobDetails from "../JobDetails/JobDetails";
import { withRouter } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Grid, makeStyles, Divider } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import IgButton from "../../../Components/Form/IgButton";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
import { Box } from "@material-ui/core";
import { history } from "../../../Utils/history";
import { getJobsAnswers } from "../../../Actions/freelancerAction";
import { updateJobsApplication } from "../../../Actions/freelancerAction";
import "./ApplicantDetails.css";

const styles = makeStyles((theme) => {
  return {
    parentBox: {
      width: "100%",
      minHeight: "100vh",
      flexGrow: 1,
      backgroundColor: "red",
      display: "flex",
      flexDirection: "space-around",
    },
    grid: {
      width: 1200,
      margin: `0 ${theme.spacing(2)}px`,
      [theme.breakpoints.down("sm")]: {
        width: "calc(100% - 20px)",
      },
    },

    paper1: {
      marginTop: theme.spacing(0.5),
      backgroundColor: "#FFFFFF",
      boxShadow:
        "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
      display: "flex",
      flexDirection: "row",
      padding: theme.spacing(5),
      width: "max-content",
    },

    large: {
      width: 110,
      height: 110,
      boxShadow: "0px 0px 5px whitesmoke",
    },
    imagename: {
      width: 110,
      height: 110,
      boxShadow: "0px 0px 5px whitesmoke",
      background: "white",
      color: "#000000",
      fontSize: 30,
      textTransform: "capitalize",
      fontWeight: 500,
      fontFamily: "Roboto",
    },
    applicant_info_box: {
      background: "linear-gradient(92.36deg, #062C84 28.97%, #277DCE 104.18%)",
      display: "flex",
      justifyContent: "flex-start",
      padding: "20px 10px",
      // borderRadius: 5,
      // height: "fit-content",
    },
    applicant_info_text: {
      marginTop: 24,
    },
    name: {
      paddingLeft: theme.spacing(2),
      color: "#ffffff",
      fontSize: 28,
      textTransform: "capitalize",
    },

    email: {
      paddingLeft: theme.spacing(2),
      color: "#ffffff",
      fontSize: "12px",
      fontWeight: "normal",
    },
    phone: {
      paddingLeft: theme.spacing(2),
      color: "#ffffff",
      fontSize: "12px",
      fontWeight: "normal",
      marginTop: "-5px",
    },
    jobquestions: {
      backgroundColor: "#FFFFFF",
      boxShadow:
        "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
      padding: 15,
      borderRadius: 5,
      margin: 20,
      width: "fit-content",
      height: "fit-content",
      position: "relative",
    },

    formheading: {
      marginTop: 10,
      color: "#0C0033",
      fontSize: 28,
      fontWeight: 600,
      textAlign: "center",
    },

    buttonBar: {
      display: "flex",
      justifyContent: "flex-end",
    },

    submit: {
      margin: theme.spacing(3, 0, 2),
      textTransform: "none",
    },
    selected: {
      fontWeight: "bold",
      color: "#41b59b",
      border: "5px solid #41b59b",
      borderRadius: 5,
      padding: "5px 7px",
    },
    rejected: {
      fontWeight: "bold",
      color: "#cc0707",
      border: "5px solid #cc0707",
      borderRadius: 5,
      padding: "5px 7px",
    },
    completed: {
      fontWeight: "bold",
      color: "#065ab5",
      border: "5px solid #065ab5",
      borderRadius: 5,
      padding: "5px 7px",
    },
    buttonGrid: {
      display: "flex",
      justifyContent: "flex-end",
    },
    rejectButton: {
      backgroundColor: "#D61600",
      marginRight: 5,
      position: "absolute",
      right: 0,
      bottom: 0,
    },
    acceptButton: {
      marginLeft: 10,
      marginRight: 5,
      backgroundColor: "#00D3A7",
      position: "absolute",
      right: 100,
      bottom: 0,
    },
    headerContainer: {
      width: "50%",
      fontFamily: "Roboto",
      fontWeight: "400",
    },
    titleContainer: {
      width: "50%",
      fontSize: "18px",
      fontFamily: "Roboto",
      fontWeight: "normal",
      marginTop: "-5px",
      marginLeft: "5px",
      color: "#9B9B9B",
    },
    subtitle: {
      fontWeight: "bold",
      padding: 5,
      color: "#000000",
      fontSize: "14px",
    },
    url: {
      fontWeight: "bold",
      width: "90%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    onCompleted: {
      display: "flex",
      justifyContent: "space-between",
      position: "relative",
      padding: "20px",
    },
    questions: { textAlign: "center" },
    Divider: { marginLeft: 10 },
    divider: { margin: "5px 3px", padding: "0px 5px" },
    arrowFont: { fontSize: "24px" },
    color: "#9B9B9B",
  };
});

let data = {};
const ApplicantDetails = (props) => {
  const dispatch = useDispatch();
  const applicantStyles = styles();

  const { isApplicant } = props;

  const { Rates, JobsAnswers, error } = useSelector((state) => {
    const { user } = state;
    const { Rates } = user;
    const { error } = user;
    const { JobsAnswers } = state.freelancer;
    return { Rates, JobsAnswers, error };
  });

  const [state, setState] = useState({
    loading: true,
    reLoaded: false,
    jobProp: null,
    jobListRedirection: false,
  });

  useEffect(() => {
    dispatch(getJobsAnswers(props.applicantData[0].id));
  }, [props.Id]);

  const handleApplicationAccept = (application_id) => {
    dispatch(
      updateJobsApplication(
        {
          application_status: "accepted",
          id: application_id,
        },
        () => {
          setState({
            ...state,
            jobListRedirection: true,
            jobProp: props.job,
          });
        }
      )
    );
    // window.location.reload(true);
    // handleJobListRedirection();
  };

  const handleApplicationReject = (application_id) => {
    dispatch(
      updateJobsApplication(
        {
          application_status: "rejected",
          id: application_id,
        },
        () => {
          setState({
            ...state,
            jobListRedirection: true,
            jobProp: props.job,
          });
        }
      )
    );
    // window.location.reload(true);
    // handleJobListRedirection();
  };

  const handleJobListRedirection = (event) => {
    // event.preventDefault();
    setState({
      ...state,
      jobProp: props.job,
    });
  };

  const handleJobDetailsRedirection = (event) => {
    props.makeReverseRedirection();
    // event.preventDefault();
  };

  data = props.applicantData[0];
  const { attachment_url } = data;
  const { job, user, application_status } = data;
  const { image_url } = user;
  const { status } = state;

  let name = user.first_name + " " + user.last_name;
  let avatar = image_url.url;

  const ApplicationStatus = () => {
    return application_status === "accepted" ? (
      <Typography variant="body1" className={applicantStyles.submit}>
        <span className={applicantStyles.selected}>Candidate selected</span>
      </Typography>
    ) : application_status === "rejected" ? (
      <Typography variant="body1" className={applicantStyles.submit}>
        <span className={applicantStyles.rejected}>Candidate rejected</span>
      </Typography>
    ) : application_status === "completed" ? (
      <Typography variant="body1" className={applicantStyles.submit}>
        <span className={applicantStyles.completed}>Job Completed</span>
      </Typography>
    ) : (
      <Grid item xs={12} lg={12} sm={6} className={applicantStyles.buttonGrid}>
        <IgButton
          type="submit"
          variant="contained"
          color="primary"
          className={(applicantStyles.submit, applicantStyles.rejectButton)}
          label="Reject"
          onClick={() => handleApplicationReject(data.id)}
        ></IgButton>
        &nbsp;&nbsp;
        <IgButton
          type="submit"
          variant="contained"
          color="primary"
          className={(applicantStyles.submit, applicantStyles.acceptButton)}
          label="Accept"
          onClick={() => handleApplicationAccept(data.id)}
        ></IgButton>
      </Grid>
    );
  };

  const { jobListRedirection, jobProp } = state;

  return jobListRedirection && jobListRedirection ? (
    <>
      <JobList isRedirected="true" />
      <JobDetails
        deleteRedirection={props.deleteRedirection}
        Id={jobProp.id}
        job={jobProp}
        status={true}
      />
    </>
  ) : jobProp && jobProp ? (
    <JobDetails job={jobProp} status={status} />
  ) : (
    <React.Fragment>
      {/* <div className="root"> */}
      {/* <Box className={applicantStyles.parentBox}> */}
      <Box className="application_details">
        <Box className="boxContainer">
          <Link color="inherit" onClick={handleJobDetailsRedirection}>
            <ArrowBackIcon className={applicantStyles.arrowFront} />
          </Link>
        </Box>
        <Divider />
        <Box className={applicantStyles.applicant_info_box}>
          {avatar ? (
            <Avatar alt={name} src={avatar} className={applicantStyles.large} />
          ) : (
            <Avatar className={applicantStyles.imagename}>{name[0]}</Avatar>
          )}
          <Box className={applicantStyles.applicant_info_text}>
            <Typography
              variant="h4"
              gutterBottom
              className={applicantStyles.name}
            >
              {name}
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              className={applicantStyles.email}
            >
              {user.email}
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              className={applicantStyles.phone}
            >
              Phone: {user.phone_number}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <br />
        <Box className="boxContainer">
          <Box className={("field-item", applicantStyles.headerContainer)}>
            <Typography variant="body1" className={applicantStyles.subtitle}>
              Job Title:
            </Typography>
          </Box>
          <Box className={("field-item", applicantStyles.titleContainer)}>
            <Typography variant="body1" className={applicantStyles.color}>
              {job.title}
            </Typography>
          </Box>
        </Box>
        <Divider className={applicantStyles.divider} />
        <Box className="boxContainer">
          <Box className={("field-item", applicantStyles.headerContainer)}>
            <Typography variant="body1" className={applicantStyles.subtitle}>
              Expected Start Date:
            </Typography>
          </Box>
          <Box className={("field-item", applicantStyles.titleContainer)}>
            <Typography variant="body1" className={applicantStyles.color}>
              {new Intl.DateTimeFormat("en-GB", {
                year: "numeric",
                month: "long",
                day: "2-digit",
              }).format(Date.parse(data.expected_start_date))}
            </Typography>
          </Box>
        </Box>
        <Divider className={applicantStyles.divider} />
        <Box className="boxContainer">
          <Box className={("field-item", applicantStyles.headerContainer)}>
            <Typography variant="body1" className={applicantStyles.subtitle}>
              Proposed rate:
            </Typography>
          </Box>
          <Box className={("field-item", applicantStyles.titleContainer)}>
            <Typography variant="body1" className={applicantStyles.color}>
              {data.proposed_rate}
            </Typography>
          </Box>
        </Box>
        <Divider className={applicantStyles.divider} />
        <Box className="boxContainer">
          <Box className={("field-item", applicantStyles.headerContainer)}>
            <Typography variant="body1" className={applicantStyles.subtitle}>
              Payment type:
            </Typography>
          </Box>
          <Box className={("field-item", applicantStyles.titleContainer)}>
            <Typography variant="body1" className={applicantStyles.color}>
              {data.payment_type}
            </Typography>
          </Box>
        </Box>
        <Divider className={applicantStyles.divider} />
        <Box className="boxContainer">
          <Box className={("field-item", applicantStyles.headerContainer)}>
            <Typography variant="body1" className={applicantStyles.subtitle}>
              Interest:
            </Typography>
          </Box>
          <Box className={("field-item", applicantStyles.titleContainer)}>
            <Typography variant="body1" className={applicantStyles.color}>
              {data.description}
            </Typography>
          </Box>
        </Box>
        <Divider className={applicantStyles.divider} />
        <Box className="boxContainer">
          <Box className={("field-item", applicantStyles.headerContainer)}>
            <Typography variant="body1" className={applicantStyles.subtitle}>
              Experience:
            </Typography>
          </Box>
          <Box className={("field-item", applicantStyles.titleContainer)}>
            <Typography variant="body1" className={applicantStyles.color}>
              {data.description_1}
            </Typography>
          </Box>
        </Box>
        <Divider className={applicantStyles.divider} />
        <Box className="boxContainer">
          <Box className={("field-item", applicantStyles.headerContainer)}>
            <Typography variant="body1" className={applicantStyles.subtitle}>
              Comments:
            </Typography>
          </Box>
          <Box className={("field-item", applicantStyles.titleContainer)}>
            <Typography variant="body1" className={applicantStyles.color}>
              {data.description_2}
            </Typography>
          </Box>
        </Box>
        <Divider className={applicantStyles.divider} />
        <Box className="boxContainer">
          <Box className={("field-item", applicantStyles.headerContainer)}>
            <Typography variant="body1" className={applicantStyles.subtitle}>
              Attachment url:
            </Typography>
          </Box>

          <Box className={("field-item", applicantStyles.titleContainer)}>
            <Typography variant="body1" className={applicantStyles.url}>
              <a onClick={() => window.open(attachment_url.url, "_blank")}>
                {attachment_url.url}
              </a>
            </Typography>
          </Box>
        </Box>
        <Divider className={applicantStyles.divider} />
        <Box className="boxContainer">
          <Box className={applicantStyles.onCompleted}>
            {application_status === "completed" ? (
              <IgButton
                type="submit"
                variant="contained"
                color="primary"
                className={applicantStyles.submit}
                label="Feedback"
                onClick={() => {
                  history.push({
                    pathname: "./feedback",
                    state: {
                      ratable_id: user.id,
                      ratable_type: "User",
                      job_title: job.title,
                      name: name,
                      avatar: avatar,
                    },
                  });
                }}
              ></IgButton>
            ) : null}
            <ApplicationStatus />
          </Box>
        </Box>
        {undefined !== JobsAnswers && JobsAnswers.length ? (
          <Paper className={applicantStyles.jobquestions}>
            <Typography
              variant="h5"
              gutterBottom
              className={applicantStyles.questions}
            >
              Job Questions
            </Typography>
            <Grid container>
              <List>
                {JobsAnswers.map((index) => {
                  return index.question ? (
                    <React.Fragment key={index.id}>
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={index.question.question_text}
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                color="textPrimary"
                              >
                                {index.question.question_type}
                              </Typography>
                              {" — " + index.answer + "…"}
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider
                        variant="inset"
                        component="li"
                        className={applicantStyles.Divider}
                      />
                    </React.Fragment>
                  ) : null;
                })}
              </List>
            </Grid>
          </Paper>
        ) : null}
      </Box>
      {/* </div> */}
    </React.Fragment>
  );
};
ApplicantDetails.propType = {
  getJobsApplication: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default withRouter(ApplicantDetails);
