import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { RouteWithLayout } from '../../../Screens/MyAccount/components';
import { Main as MainLayout, Minimal as MinimalLayout } from '../../../Screens/MyAccount/layouts';
import Profile from "../Profile/Profile";
import Dashboard from "../FreelancerDashboard/FreelancerDashboard";
import UsersSkill from "../../Pages/UsersSkill/UsersSkill";
import UsersLanguage from "../UsersLanguage/UsersLanguage";
import UsersInterest from "../../Pages/UsersInterest/UsersInterest";
import ShowIdentity from "../IdentityForm/IdentityForm";
import ShowExperience from "../../Pages/Experience/ShowExperiences";
import BrowseJobs from "../BrowseJobs/BrowseJobs";
import AppliedJobs from '../AppliedJobs/AppliedJobs';
import ClientDashboard from "../ClientDashboard/ClientDashboard";
import CompanyDetail from "../CompanyDetail/CompanyDetail";
import JobList from "../JobList/JobList";
import Account from "./Account/Account";
import {
  Settings as SettingsView,
  NotFound as NotFoundView
} from '../../../Screens/MyAccount/views';
import Wizard from "../../../Screens/Wizard/Wizard";

const Routes = () => {
  const user_type = localStorage.user_type
return (
  (user_type === 'Client') ? 
      <Switch>
        <Redirect
          exact
          from="/"
          to="/dashboard-client"
        />
        <RouteWithLayout
          component={ClientDashboard}
          exact
          layout={MainLayout}
          path="/dashboard-client"
        />
        <RouteWithLayout
          component={JobList}
          exact
          layout={MainLayout}
          path="/client-job-list"
        />
        <RouteWithLayout
          component={CompanyDetail}
          exact
          layout={MainLayout}
          path="/company-detail"
        />
        <RouteWithLayout
          component={Account}
          exact
          layout={MainLayout}
          path="/account"
        />
        <RouteWithLayout
          component={SettingsView}
          exact
          layout={MainLayout}
          path="/settings"
        />
        <RouteWithLayout
          component={NotFoundView}
          exact
          layout={MinimalLayout}
          path="/not-found"
        />
        <Redirect to="/not-found" />
      </Switch>
    :
    (user_type === "Freelancer")?
      <Switch>
        <Redirect
          exact
          from="/"
          to="/dashboard"
        />
        <RouteWithLayout
          component={Dashboard}
          exact
          layout={MainLayout}
          path="/dashboard"
        />
         <RouteWithLayout
          component={Wizard}
          exact
          layout={MainLayout}
          path="/wizard"
        />
        <RouteWithLayout
          component={BrowseJobs}
          exact
          layout={MainLayout}
          path="/browse-jobs"
        />
        <RouteWithLayout
          component={AppliedJobs}
          exact
          layout={MainLayout}
          path="/applied-jobs"
        />
        <RouteWithLayout
          component={Profile}
          exact
          layout={MainLayout}
          path="/profile"
        />
        <RouteWithLayout
          component={UsersSkill}
          exact
          layout={MainLayout}
          path="/skills"
        />
         <RouteWithLayout
          component={UsersInterest}
          exact
          layout={MainLayout}
          path="/interests"
        />
        <RouteWithLayout
          component={UsersLanguage}
          exact
          layout={MainLayout}
          path="/languages"
        />
        <RouteWithLayout
          component={ShowExperience}
          exact
          layout={MainLayout}
          path="/my-experience"
        />
        <RouteWithLayout
          component={ShowIdentity}
          exact
          layout={MainLayout}
          path="/my-identity"
        />
        <RouteWithLayout
          component={Account}
          exact
          layout={MainLayout}
          path="/account"
        />
        <RouteWithLayout
          component={SettingsView}
          exact
          layout={MainLayout}
          path="/settings"
        />
        <RouteWithLayout
          component={NotFoundView}
          exact
          layout={MinimalLayout}
          path="/not-found"
        />
        <Redirect to="/not-found" />
      </Switch>
      : null
    ) 
};
export default Routes;