
import { createMuiTheme } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import "../App.css";

// Theme constant for Dark Shade only
// Theme 1
const primaryColor = "#06006A";
const secondaryColor = "#0C0033";
const errorColor = "#A02520";
const warningColor = "#DDAC4C";
const infoColor = "#427AA9";
const sucessColor = "#4caf50";
const customboxShadow = "0 0 14px 0 rgba(53,64,82,.2)";
const customBorder = "1px solid #ddd";
// Theme 2
// const primaryColor = "#000"

export default createMuiTheme({
	palette: {
		primary: { main: primaryColor },
		secondary: { main: secondaryColor },
		error: { main: errorColor },
		warning: { main: warningColor },
		info: { main: infoColor },
		success: { main: sucessColor },
	},
	typography: {
		fontFamily: "Roboto, sans-serif !important",
		subtitle1: { fontSize: 15, fontWeight: 500 },
		h6: { fontWeight: 700, color: "rgba(0,0,0,0.87) !important", fontSize: 16 },
	},
	overrides: {
		MuiAccordion: {
			root: {
				border: "0px solid #ddd",
				boxShadow: customboxShadow,
				marginBottom: 10,
			},
		},
		MuiAppBar: {
			root: {
				backgroundColor: "#ffffff !important",
				color: "#000",
				// marginLeft: drawerWidth,
				// width: `calc(100% - ${drawerWidth}px)`,
			},
		},
		MuiAccordionSummary: {
			root: {
				borderBottom: customBorder,
			},
		},
		MuiChip: {
			root: {
				background: "#F2F2F2 !important",
				borderRadius: 4,
				margin: "5px -5px 5px 0px",
			},
		},
		MuiDrawer: {
			root: {
				flexShrink: 0,
				width: 240,
			},
			paper: {
				backgroundColor: "#ffffff",
				// backgroundImage: 'linear-gradient(to right, #112F4D , #37516A)',
				color: "rgba(255,255,255,0.6)",
				width: 240,
			},
		},
		MuiTable: {
			root: {
				width: "98%",
				margin: "10px auto",
			},
		},
		MuiTableHead: {
			root: {
				borderTop: customBorder,
				borderBottom: "2px solid #eee",
			},
		},
		MuiTableCell: {
			root: {
				padding: 10,
				fontSize: "13px !important",
			},
			head: {
				fontWeight: 600,
				backgroundColor: "#f8f8f8 !important",
			},
		},
		MuiTableBody: {
			root: {
				"& .MuiTableRow-root:nth-of-type(even)": {
					background: "#fafafa",
				},
			},
		},
		MuiTableSortLabel: {
			root: {
				textTransform: "uppercase",
			},
		},
		MuiTab: {
			root: {
				fontWeight: 700,
				textTransform: "capitalize",
			},
			textColorPrimary: {
				"& .Mui-selected": {
					color: primaryColor,
					backgroundColor: "#f8f8f8 !important",
				},
			},
		},
		MuiToolbar: {
			root: {
				borderBottom: customBorder,
			},
		},
		MuiPaper: {
			elevation2: {
				boxShadow: customboxShadow,
			},
			elevation3: {
				boxShadow: customboxShadow,
			},
			elevation4: {
				boxShadow: customboxShadow,
			},
		},
		MuiAvatar: {
			root: {
				width: 30,
				height: 30,
				fontSize: 14,
				backgroundColor: red[500],
			},
		},
		MuiListItem: {
			root: {
				paddingTop: 10,
				paddingBottom: 10,
				"&.Mui-selected": {
					backgroundColor: "rgb(255,255,255,0.10)",
					fontWeight: 600,
					borderRadius: 4,
				},
				"&:hover": {
					backgroundColor: "rgba(255,255,255,0.1) !important",
					borderRadius: 4,
					"& .hide": {
						display: "block !important",
					},
				},
			},
		},
		MuiListSubheader: {
			root: {
				color: "#fff",
				letterSpacing: 2,
				fontSize: 11,
			},
		},
		MuiListItemText: {
			root: {
				"& .MuiTypography-body1": {
					fontSize: "14px !important",
					color: "#0C0033 !important",
				},
			},
		},
		MuiListItemIcon: {
			root: {
				color: "rgba(255,255,255,0.6)",
				"& .MuiSvgIcon-root": { fontSize: "0.5rem !important", color: "#0C0033" },
				minWidth: 20,
			},
		},
		MuiDivider: {
			root: {
				backgroundColor: "rgba(255,255,255,0.1)",
			},
		},

		MuiCardHeader: {
			subheader: {
				textOverflow: "ellipsis",
				maxWidth: 140,
				overflow: "hidden",
				whiteSpace: "nowrap",
				width: "100%",
				color: "rgba(255, 255, 255, 0.4)",
				fontSize: 12,
			},
			title: {
				textOverflow: "ellipsis",
				maxWidth: 150,
				overflow: "hidden",
				whiteSpace: "nowrap",
				width: "100%",
				color: "rgba(255, 255, 255, 0.8)",
				fontSize: 12,
			},
		},
		MuiInputLabel: {
			root: {
				fontSize: 13,
			},
			asterisk: {
				color: "#ff0000",
			},
		},
		MuiInputBase: {
			input: {
				fontSize: 14,
			},
		},
		MuiFormLabel: {
			root: {
				fontSize: 14,
			},
			asterisk: {
				color: "#ff0000",
			},
		},
		MuiFormControlLabel: {
			root: {
				marginTop: "10px !important",
			},
			label: {
				fontSize: 14,
			},
		},
		MuiSelect: {
			select: {
				padding: "6px 25px 7px 10px",
			},
		},
		MuiButton: {
			root: {
				textTransform: "capitalize",
				marginTop: 5,
				fontWeight: 500,
			},
		},
		MuiCssBaseline: {
			"@global": {
				html: {
					WebkitFontSmoothing: "auto",
				},
			},
		},
	},
});
