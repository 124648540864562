/* eslint-disable */
import React from 'react';
import './CompanyProfileCompanyInformation.css';
import 'antd/dist/antd.css';
import { Upload, Icon, message } from 'antd';
import { CircularProgressbar } from 'react-circular-progressbar';
import Topbar from "../../Components/Topbar";
import IgButton from '../../Components/Form/IgButton';
import SelectField from "../../Components/Form/SelectField";
import withStyles from "@material-ui/core/styles/withStyles";
import { history } from '../../Utils/history';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createClient, getClients, getJobs } from "./../../Actions/clientAction";
import { getUsersProfiles } from "./../../Actions/userAction";
import { Box, TextField, Typography, Grid, Paper, Snackbar, CircularProgress } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const options = [
  { value: 'Admin', label: 'Admin' },
  { value: 'User', label: 'User' },
];
const options1 = [
  { value: '1-10 employees', label: '1-10 employees' },
  { value: '10-50 employees', label: '10-50 employees' },
  { value: '50-200 employees', label: '50-200 employees' },
  { value: '200+ employees', label: '200+ employees' },
];
const options2 = [
  { value: 0, label: 'Actuarial' },
  { value: 1, label: 'Office & Business Administration' },
  { value: 2, label: 'Claims' },
  { value: 3, label: 'Finance' },
  { value: 4, label: 'Human Resources' },
  { value: 5, label: 'Information Technology' },
  { value: 6, label: 'Legal' },
  { value: 7, label: 'Marketing/Communications' },
  { value: 8, label: 'Project Management' },
  { value: 9, label: 'Sales' },
  { value: 10, label: 'Students' },
  { value: 11, label: 'Underwriting' },
  { value: 12, label: 'Consulting' },
  { value: 13, label: 'Client & Customer Service' },
  { value: 14, label: 'Strategy & Corporate Development' },
  { value: 15, label: 'General Management' },
];
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}
const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#F7F9FB'
  },
  paper1: {
    marginTop: theme.spacing(0.5),
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(3)
  },
  text: {
    marginTop: theme.spacing(2)
  }
})
class CompanyProfileCompanyInformation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      imageUrl: "",
      name: "",
      phone_number: "",
      total_employee_size: "",
      company_type: "",
      c_address_1: "",
      c_address_2: "",
      c_city: "",
      company_logo: "",
      c_state: "",
      c_zip: "",
      website_url: "",
      linked_url: "",
      account_type: "Admin",
      open: false,
      createClientError: "",
      createClientResponse: {},
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.createClientError !== state.createClientError) {
      if (props.createClientError === "Unprocessable Entity") {
        return { createClientError: "All fields are required!", open: true }
      }
      else {
        return { createClientError: props.createClientError, open: true }
      }

    }
    else if (props.createClientResponse !== state.createClientResponse) {
      return { createClientResponse: { id: 1 } }
    }
    else {
      return null;
    }
  }
  componentDidMount() {
    // this.props.getClients()
    this.props.getUsersProfiles()
  }
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      open: false
    })
  }
  handleName = (event) => {
    this.setState({
      name: event.target.value
    })
  }
  handlePhone = (event) => {
    this.setState({
      phone_number: event.target.value
    })
  }
  handleCompanySize = (event) => {
    this.setState({
      total_employee_size: event.target.value
    })
  }
  handleCompanyType = (event) => {
    this.setState({
      company_type: event.target.value
    })
  }
  handleAccountType = (event) => {
    this.setState({
      account_type: event.target.value
    })
  }
  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
        }),
      );
    }
  };
  handleCity = (event) => {
    this.setState({
      c_city: event.target.value
    })
  }
  handleState = (event) => {
    this.setState({
      c_state: event.target.value
    })
  }
  handleZipcode = (event) => {
    this.setState({
      c_zip: event.target.value
    })
  }
  handleAddress1 = (event) => {
    this.setState({
      c_address_1: event.target.value
    })
  }
  handleAddress2 = (event) => {
    this.setState({
      c_address_2: event.target.value
    })
  }
  handleWebsite = (event) => {
    this.setState({
      website_url: event.target.value
    })
  }
  handleLinkedIn = (event) => {
    this.setState({
      linked_url: event.target.value
    })
  }
  handleCreateCompany = () => {
    this.props.createClient({
      name: this.state.name,
      phone_number: this.state.phone_number,
      total_employee_size: this.state.total_employee_size,
      company_type: this.state.company_type,
      c_address_1: this.state.c_address_1,
      c_address_2: this.state.c_address_2,
      c_city: this.state.c_city,
      company_logo: this.state.imageUrl,
      c_state: this.state.c_state,
      c_zip: this.state.c_zip,
      website_url: this.state.website_url,
      linked_url: this.state.linked_url,
      account_type: this.state.account_type,
    }, history)

  }
  render() {
    const { classes } = this.props;
    const { client } = this.props
    const { isPostClientLoading } = client
    const currentPath = this.props.location.pathname;
    const percentage = 50;
    const { imageUrl } = this.state;
    return (
      <React.Fragment>
        <Topbar currentPath={currentPath} searchbar="no" />
        {/* <Paper className={classes.paper1}>
          <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CircularProgressbar styles={{ root: { height: 105, width: 105 }, path: { stroke: '#23C0F5' } }} value={percentage} text={`${percentage}%`} />
          </Grid>
          <Grid item className={classes.text}>
            <Typography variant="h4" gutterBottom style={{ paddingLeft: 15, color: "#212B36" }}>
              Your Profile is Complete
                </Typography>

            {this.props.first_name ? <Typography variant="body1" gutterBottom style={{ paddingLeft: 15, color: "#637381" }}>Great Start {this.props.first_name}! Please complete remaining steps!</Typography> : null}

          </Grid>
        </Paper> */}
        {
          isPostClientLoading ? <Box component="div" style={{ backgroundColor: '#ffffff', height: '100%', marginTop: 30, padding: 24, paddingTop:200, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress disableShrink size={70} />
          </Box> : <Box className="CPCI-wrapper">
              <Typography variant="h4" style={{ textAlign: 'left', marginBottom: 24 }}>Company Information</Typography>
              <form onSubmit={() => this.handleCreateCompany()}>
                <Box className="box-one-wrapper">
                  <Box className="box-one">
                    <Box className="box-child">
                      <Typography variant="body2">Company name*</Typography>
                      <TextField required variant="outlined" onChange={this.handleName} placeholder='Company Name' />
                    </Box>
                    <Box className="box-child uplaud">
                      <Typography variant="body2">Company Logo</Typography>
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        beforeUpload={beforeUpload}
                        onChange={this.handleChange}>
                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : <div>
                          <Icon type={this.state.loading ? 'loading' : 'plus'} />
                          <div className="ant-upload-text">Upload</div>
                        </div>}
                      </Upload>
                    </Box>
                    <Box className="box-child">
                      <Typography variant="body2">Your role at company*</Typography>
                      <SelectField options={options} val={this.state.account_type} onChange={this.handleAccountType} />
                    </Box>
                    <Box className="box-child">
                      <Typography variant="body2">Coampany size*</Typography>
                      <SelectField required options={options1} val={this.state.total_employee_size} onChange={this.handleCompanySize} />
                    </Box>
                    <Box className="box-child">
                      <Typography variant="body2">Your department*</Typography>
                      <SelectField required options={options2} val={this.state.company_type} onChange={this.handleCompanyType} />
                    </Box>
                    <Box className="box-child">
                      <Typography variant="body2">Company contact number*</Typography>
                      <TextField required variant="outlined" onChange={this.handlePhone}
                        placeholder='Company contact number' />
                    </Box>
                  </Box>
                  <Box className="box-one">
                    <Box className="box-child">
                      <Typography variant="body2">Website url*</Typography>
                      <TextField required variant="outlined" onChange={this.handleWebsite}
                        placeholder='Website url' />
                    </Box>
                    <Box className="box-child">
                      <Typography variant="body2">LinkedIn profile url*</Typography>
                      <TextField required variant="outlined" onChange={this.handleLinkedIn}
                        placeholder='LinkedIn profile url' />
                    </Box>
                    <Box className="box-child">
                      <Typography variant="body2">Company address 1</Typography>
                      <TextField variant="outlined" onChange={this.handleAddress1}
                        placeholder='Company address 1' />
                    </Box>
                    <Box className="box-child">
                      <Typography variant="body2">Company address 2</Typography>
                      <TextField variant="outlined" onChange={this.handleAddress2}
                        placeholder='Company address 2' />
                    </Box>
                    <Box className="box-child">
                      <Typography variant="body2">City</Typography>
                      <TextField variant="outlined" onChange={this.handleCity}
                        placeholder='City' />
                    </Box>
                    <Box className="box-child">
                      <Typography variant="body2">State</Typography>
                      <TextField variant="outlined" onChange={this.handleState}
                        placeholder='State' />
                    </Box>
                    <Box className="box-child">
                      <Typography variant="body2">Zipcode</Typography>
                      <TextField variant="outlined" onChange={this.handleZipcode}
                        placeholder='ZipCode' />
                    </Box>
                  </Box>
                </Box>
                  <Box>
                  <IgButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ width: 223, height: 36, float: 'right', marginRight: 75 }}
                    label="Submit and next"
                    iconname={ArrowRightIcon}>
                  </IgButton>
                  <Box>
                    {this.props.createClientError || this.props.getClientError ? <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.open} autoHideDuration={3000} onClose={this.handleClose}>
                      <Alert onClose={this.handleClose} severity="error">
                        {this.state.createClientError || this.props.getClientError}
                      </Alert>
                    </Snackbar> : null}
                  </Box>
                </Box>
              </form>
            </Box>
        }
      </React.Fragment>
    )
  }
}
const mapStateToProps = state => {
  const { client, user } = state
  const { Clients, createClientError, createClientResponse, getClientError } = client;
  const { UsersProfile } = user;
  const { first_name } = UsersProfile || "";
  return { client, first_name, Clients, createClientError, getClientError, createClientResponse }
};
export default connect(mapStateToProps, { createClient, getClients, getJobs, getUsersProfiles })(withRouter(withStyles(styles)(CompanyProfileCompanyInformation)));