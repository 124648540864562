import React, { useState } from "react";
import ImageUploading from 'react-images-uploading';
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  AddIcon,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Select,
  MenuItem,
  CircularProgress,
  DialogContentText,
  makeStyles,
} from "@material-ui/core";
import { Upload, Icon, message } from "antd";
import DatePickerField from "../../../Components/Form/DatePickerField";
import { updateIdentity } from "../../../Actions/freelancerAction";
import { getBase64, beforeUpload } from "./base64";
import Error from "../ErrorComponent/ErrorComponent";

const styles = makeStyles(() => {
  return {
    root: { minWidth: 350 },
    loading: {
      margin: "auto",
      marginTop: 100,
      maxWidth: "max-content",
    },
    select: { width: "100%" },
    popup: {
      display: "flex",
      flexDirection: "column",
      padding: 10,
    },
    dialogActions: {
      display: "flex",
      justifyContent: "space-between",
    },
  };
});

const UpdateIdentity = (props) => {
  const dispatch = useDispatch();
  const updateStyles = styles();

  const { isPutIdentityLoading , Identity } = useSelector((state) => {
    const { freelancer } = state;
    const { isPutIdentityLoading , Identity } = freelancer;
    return { isPutIdentityLoading , Identity };
  });

  let Item = "";
  Identity.map((item) => {
    (props.data.id === item.id) && (Item = item);
  })

  const [state, setState] = useState({
    isUpdateUrl: true,
    open: true,
    loading: false,
    id: Item.id,
    name: Item.name,
    expiry: Item.expiry,
    imageUrl: "",
    pop_up : false,
    isImageUrl: Item.storage_url
  });

  const handleClose = () => {
    setState({
      ...state,
      open : true,
      pop_up : false
    });
  };

  const handleCancel = () => {
    props.handleEditDialog();
  }
  const handleName = (event) => {
    setState({
      ...state,
      name: event.target.value,
    });
  };
  const handleExpiry = (val) => {
    setState({
      ...state,
      expiry: val,
    });
  };

  const handleUpdateIdentity = () => {
  
    dispatch(
      updateIdentity({
        id: state.id,
        name: state.name,
        expiry: state.expiry,
        storage_url: state.imageUrl,
      },()=>{
        setState({...state,isUpdateUrl:true});
      })
    );
    props.handleEditDialog();
    

  };
  // const handleChange = (info) => {
  //   info.file.status === "uploading" && setState({ ...state, loading: true });

  //   info.file.status === "done" &&
  //     getBase64(info.file.originFileObj, (imageUrl) =>
  //       setState({
  //         ...state,
  //         imageUrl,
  //         loading: false,
  //       })
  //     );
  //     info.file.status === "error" &&
  //     setState({
  //       ...state,
  //       open : true,
  //       pop_up: true,
  //       loading: false,
  //     });
  // };
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  
  
  const onChange = (imageList, addUpdateIndex) => {
    setState({...state, imageUrl: imageList[0]['data_url'],isImageUrl:imageList[0]['data_url'],isUpdateUrl:false});
    setImages(imageList);
  };


  const uploadButton = (
    <div>
      <Icon type={state.loading ? "loading" : "plus"} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  const { isImageUrl} = state;

  return (
    <div className={updateStyles.root}>
      <Dialog fullWidth={true} open={state.open} onClose={handleClose}>
        {isPutIdentityLoading ? (
          <Box className={updateStyles.loading}>
            <CircularProgress />
          </Box>
        ) : (
          <React.Fragment>
            <Box>
              <DialogContent>
                <DialogContentText> ID Type</DialogContentText>
                <Select
                  value={state.name}
                  className={updateStyles.select}
                  onClick={handleName}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="Driving Licence">Driving Licence</MenuItem>
                  <MenuItem value="Passport">Passport</MenuItem>
                  <MenuItem value="Citizenship card">Citizenship card</MenuItem>
                </Select>
              </DialogContent>
              <DialogContent>
                <Box className={updateStyles.popup}>
                  <Typography variant="body2">Expiry date</Typography>
                  <DatePickerField
                    changeDate={handleExpiry}
                    selectedDate={state.expiry}
                    minDate={new Date()}
                  />
                </Box>
              </DialogContent>
              <DialogContent>
                <DialogContentText>Update Image</DialogContentText>
                
                  
                <ImageUploading
                  multiple
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper"
                    style={{textAlign: "center" }}>
                      {isImageUrl ? (
                            <div>
                              <img
                                src={isImageUrl}
                                className={updateStyles.select}
                              />  
                              {state.isUpdateUrl?
                              <>
                              <Button
                              style={isDragging ? { color: 'red' } : undefined}
                                style={{marginTop:10}}
                                color="primary"
                                variant="contained"
                                size="large"
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                Update Image
                              </Button>
                              </>:""
                              }

                                {imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                <div className="image-item__btn-wrapper">
                                {/* <img style={{textAlign: "center", marginTop:10 }}src={image['data_url']} alt="" width="120" /> */}
                                <div className="image-item__btn-wrapper">
                                  {/* <Button
                                    style={{textAlign: "center",marginTop:10}}
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    onClick={() => onImageUpdate(index)}
                                  >
                                    Update
                                  </Button> */}
                                </div>
                          </div>
                        </div>
                      ))}
                            </div>
                            ) : (
                              <div>
                              <button
                                style={isDragging ? { color: 'red' } : undefined}
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                Upload Image
                              </button>
                                {imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                <img src={image['data_url']} alt="" width="100" />
                                <div className="image-item__btn-wrapper">
                                <button onClick={() => onImageUpdate(index)}>Update</button>
                          </div>
                        </div>
                      ))}
                              </div>
                            )}
                      &nbsp;
                    </div>
                  )}
                </ImageUploading>
              </DialogContent>
              {state.pop_up &&
                    <Error
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      open={state.pop_up}
                      autoHideDuration={2000}
                      onClose={handleClose}
                      label="Image type is not supported"
                      severity="error"
                    />
            }
            </Box>
            <DialogActions className={updateStyles.dialogActions}>
              <Button onClick={handleCancel} color="primary">
                Cancel
              </Button>
              <Button color="primary" onClick={handleUpdateIdentity}>
                Update Identity
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      </Dialog>
    </div>
  );
};

export default UpdateIdentity;
