import API from "../Utils/API";
import freelancersConstants from "../constants/freelancers";
import { getQuestions } from "./clientAction.js";

export const createAttachment = (userData, history) => (dispatch) => {
	dispatch({ type: freelancersConstants.POST_ATTACHMENT_REQUEST });
	API.post("/api/attachments", userData)
		.then((result) => {
			dispatch({ type: freelancersConstants.POST_ATTACHMENT_SUCCESS });
			history.push("");
			getAttachments();
		})
		.catch((err) =>
			dispatch({ type: freelancersConstants.POST_ATTACHMENT_FAILURE })
		);
};

export const getAttachments = (attachable_id) => (dispatch) => {
	let url = `/api/attachments?attachable_id=${attachable_id}`;
	dispatch({ type: freelancersConstants.GET_ALL_ATTACHMENTS_REQUEST });
	API.get(url)
		.then((res) => {
			return dispatch({
				type: freelancersConstants.GET_ALL_ATTACHMENTS_SUCCESS,
				payload: res.data,
			});
		})
		.catch((err) =>
			dispatch({
				type: freelancersConstants.GET_ALL_ATTACHMENTS_FAILURE,
				payload: {},
			})
		);
};

export const updateAttachment = (attachment_id) => (dispatch) => {
	let url = "/api/attachments/" + attachment_id;
	dispatch({ type: freelancersConstants.PUT_ATTACHMENT_REQUEST });
	API.put(url)
		.then((result) => {
			return dispatch({ type: freelancersConstants.PUT_ATTACHMENT_SUCCESS });
		})
		.catch((err) =>
			dispatch({ type: freelancersConstants.PUT_ATTACHMENT_FAILURE })
		);
};

export const deleteAttachment = (attachment_id) => (dispatch) => {
	let url = "/api/attachments/" + attachment_id;
	dispatch({ type: freelancersConstants.DELETE_ATTACHMENT_REQUEST });
	API.delete(url)
		.then((result) => {
			return dispatch({ type: freelancersConstants.DELETE_ATTACHMENT_SUCCESS });
		})
		.catch((err) =>
			dispatch({ type: freelancersConstants.DELETE_ATTACHMENT_FAILURE })
		);
};

export const createExperience = (userData) => (dispatch) => {
	dispatch({
		type: freelancersConstants.POST_EXPERIENCE_REQUEST,
		payload: userData,
	});
	API.post("/api/experiences", userData)
		.then((result) => {
			dispatch({
				type: freelancersConstants.POST_EXPERIENCE_SUCCESS,
				payload: result.data,
			});
			dispatch(getExperiences());
		})
		.catch((err) => {
			dispatch({
				type: freelancersConstants.POST_EXPERIENCE_FAILURE,
				payload: err.response.data,
			});
			setTimeout(() => dispatch({ type: "CLEAR MY STATE" }), 3000);
		});
};

export const getExperiences = (current_user_id) => (dispatch) => {
	let url = `/api/experiences?current_user=${current_user_id}`;
	dispatch({ type: freelancersConstants.GET_ALL_EXPERIENCES_REQUEST });
	API.get(url)
		.then((res) => {
			dispatch({
				type: freelancersConstants.GET_ALL_EXPERIENCES_SUCCESS,
				payload: res.data,
			});
		})
		.catch((err) =>
			dispatch({
				type: freelancersConstants.GET_ALL_EXPERIENCES_FAILURE,
				payload: err.response.data,
			})
		);
};

export const updateExperience = (data) => (dispatch) => {
	let url = "/api/experiences/" + data.id;
	dispatch({
		type: freelancersConstants.PUT_EXPERIENCE_REQUEST,
		payload: data,
	});
	API.put(url, data)
		.then((result) => {
			dispatch({
				type: freelancersConstants.PUT_EXPERIENCE_SUCCESS,
				payload: result.data,
			});
			dispatch(getExperiences());
		})
		.catch((err) => {
			dispatch({
				type: freelancersConstants.PUT_EXPERIENCE_FAILURE,
				payload: err.response.data,
			});
			setTimeout(() => dispatch({ type: "CLEAR MY STATE" }), 3000);
		});
};

export const deleteExperience = (experience_id) => (dispatch) => {
	let url = "/api/experiences/" + experience_id;
	dispatch({ type: freelancersConstants.DELETE_EXPERIENCE_REQUEST });
	API.delete(url)
		.then((result) => {
			dispatch({ type: freelancersConstants.DELETE_EXPERIENCE_SUCCESS });
			dispatch(getExperiences());
		})
		.catch((err) =>
			dispatch({
				type: freelancersConstants.DELETE_EXPERIENCE_FAILURE,
				payload: err.response,
			})
		);
};

export const createIdentity = (userData, callback) => (dispatch) => {
	dispatch({
		type: freelancersConstants.POST_IDENTITY_REQUEST,
		payload: userData,
	});
	API.post("/api/identities", userData)
		.then((result) => {
			dispatch({
				type: freelancersConstants.POST_IDENTITY_SUCCESS,
				payload: result.data,
			});
			{
				callback && callback();
			}
			dispatch(getIdentities());
		})
		.catch((err) => {
			dispatch({
				type: freelancersConstants.POST_IDENTITY_FAILURE,
				payload: err.response.data,
			});
		});
};

export const getIdentities = (current_user_id, callback) => (dispatch) => {
	let url = `/api/identities?current_user=${current_user_id}`;
	dispatch({ type: freelancersConstants.GET_ALL_IDENTITIES_REQUEST });
	API.get(url)
		.then((res) => {
			dispatch({
				type: freelancersConstants.GET_ALL_IDENTITIES_SUCCESS,
				payload: res.data,
			});
			{
				callback && callback();
			}
		})
		.catch((err) => {
			dispatch({
				type: freelancersConstants.GET_ALL_IDENTITIES_FAILURE,
				payload: err.response,
			});
			{
				callback && callback();
			}
		});
};

export const updateIdentity = (data, callback) => (dispatch) => {
	let url = "/api/identities/" + data.id;
	dispatch({ type: freelancersConstants.PUT_IDENTITY_REQUEST, payload: data });
	API.put(url, data)
		.then((result) => {
			dispatch({
				type: freelancersConstants.PUT_IDENTITY_SUCCESS,
				payload: result.data,
			});
			{
				callback && callback();
			}
			dispatch(getIdentities());
		})
		.catch((err) =>
			dispatch({
				type: freelancersConstants.PUT_IDENTITY_FAILURE,
				payload: err.response,
			})
		);
};

export const deleteIdentity = (identity_id) => (dispatch) => {
	let url = "/api/identities/" + identity_id;
	dispatch({ type: freelancersConstants.DELETE_IDENTITY_REQUEST });
	API.delete(url)
		.then((result) => {
			dispatch(
				getIdentities(identity_id, () => {
					dispatch({ type: freelancersConstants.DELETE_IDENTITY_SUCCESS });
				})
			);
		})
		.catch((err) =>
			dispatch({
				type: freelancersConstants.DELETE_IDENTITY_FAILURE,
				payload: err.response,
			})
		);
};

export const createJobsAnswer =
	(data, job_id, jobApplication_Id) => (dispatch) => {
		dispatch({
			type: freelancersConstants.POST_JOBS_ANSWER_REQUEST,
			payload: data,
		});
		API.post("/api/jobs_answers", data)
			.then((result) => {
				dispatch(
					getQuestions(job_id, () => {
						dispatch({
							type: freelancersConstants.POST_JOBS_ANSWER_SUCCESS,
							payload: result.data,
						});
					})
				);
				dispatch({
					type: freelancersConstants.POST_JOBS_ANSWER_SUCCESS,
					payload: result.data,
				});
				dispatch(getJobsAnswers(jobApplication_Id));
			})
			.catch((err) =>
				dispatch({
					type: freelancersConstants.POST_JOBS_ANSWER_FAILURE,
					payload: err.response.data,
				})
			);
	};

export const getJobsAnswers = (jobs_application_id, callback) => (dispatch) => {
	let url = `/api/jobs_answers?jobs_application_id=${jobs_application_id}`;
	dispatch({
		type: freelancersConstants.GET_ALL_JOBS_ANSWERS_REQUEST,
		payload: jobs_application_id,
	});
	API.get(url)
		.then((res) => {
			dispatch({
				type: freelancersConstants.GET_ALL_JOBS_ANSWERS_SUCCESS,
				payload: res.data,
			});
			{
				callback && callback();
			}
		})
		.catch((err) => {
			dispatch({
				type: freelancersConstants.GET_ALL_JOBS_ANSWERS_FAILURE,
				payload: err.response.data,
			});
			{
				callback && callback();
			}
		});
};

export const updateJobsAnswer = (data, jobs_application_id) => (dispatch) => {
	let url = "/api/jobs_answers/" + data.id;
	dispatch({
		type: freelancersConstants.PUT_JOBS_ANSWER_REQUEST,
		payload: data,
	});
	API.put(url, data)
		.then((result) => {
			dispatch({
				type: freelancersConstants.PUT_JOBS_ANSWER_SUCCESS,
				payload: result.data,
			});
			dispatch(getJobsAnswers(jobs_application_id));
		})
		.catch((err) =>
			dispatch({
				type: freelancersConstants.PUT_JOBS_ANSWER_FAILURE,
				payload: err.response.data,
			})
		);
};

export const deleteJobsAnswer =
	(jobs_answer_id, jobs_application_id) => (dispatch) => {
		let url = "/api/jobs_answers/" + jobs_answer_id;
		dispatch({ type: freelancersConstants.DELETE_JOBS_ANSWER_REQUEST });
		API.delete(url)
			.then((result) => {
				dispatch({ type: freelancersConstants.DELETE_JOBS_ANSWER_SUCCESS });
				// dispatch(getQuestions(jobs_application_id))
				dispatch(getJobsAnswers(jobs_application_id));
			})
			.catch((err) =>
				dispatch({ type: freelancersConstants.DELETE_JOBS_ANSWER_FAILURE })
			);
	};

export const clearQuestionState = () => (dispatch) => {
	dispatch({ type: "CLEARING QUESTION ANSWER STATE" });
};

export const createJobsApplication = (userData) => (dispatch) => {
	dispatch({ type: freelancersConstants.POST_JOBS_APPLICATION_REQUEST });
	API.post("/api/jobs_applications", userData)
		.then((result) => {
			dispatch({
				type: freelancersConstants.POST_JOBS_APPLICATION_SUCCESS,
				payload: result.data,
			});
		})
		.catch((err) => {
			dispatch({
				type: freelancersConstants.POST_JOBS_APPLICATION_FAILURE,
				payload: err.response.data,
			});
		});
};

export const getJobsApplications = (job_id, callback) => (dispatch) => {
	let url = `api/jobs_applications?job_id=${job_id}`;
	dispatch({
		type: freelancersConstants.GET_ALL_JOBS_APPLICATIONS_REQUEST,
		payload: job_id,
	});
	API.get(url)
		.then((result) => {
			dispatch({
				type: freelancersConstants.GET_ALL_JOBS_APPLICATIONS_SUCCESS,
				payload: result.data,
			});
			{
				callback && callback();
			}
		})
		.catch((err) => {
			dispatch({
				type: freelancersConstants.GET_ALL_JOBS_APPLICATIONS_FAILURE,
				payload: err.data,
			});
			{
				callback && callback();
			}
		});
};

export const updateJobsApplication = (data, callback) => (dispatch) => {
	let url = "/api/jobs_applications/" + data.id;
	dispatch({
		type: freelancersConstants.PUT_JOBS_APPLICATION_REQUEST,
		payload: data,
	});
	API.put(url, data)
		.then((result) => {
			dispatch({
				type: freelancersConstants.PUT_JOBS_APPLICATION_SUCCESS,
				payload: result.data,
			});
			{
				callback && callback();
			}
		})
		.catch((err) =>
			dispatch(getJobsApplications, () => {
				dispatch({
					type: freelancersConstants.PUT_JOBS_APPLICATION_FAILURE,
					payload: err.response.data,
				});
			})
		);
};

export const deleteJobsApplication = (jobs_application_id) => (dispatch) => {
	let url = "/api/jobs_applications/" + jobs_application_id;
	dispatch({ type: freelancersConstants.DELETE_JOBS_APPLICATION_REQUEST });
	API.delete(url)
		.then((result) => {
			return dispatch({
				type: freelancersConstants.DELETE_JOBS_APPLICATION_SUCCESS,
			});
		})
		.catch((err) =>
			dispatch({ type: freelancersConstants.DELETE_JOBS_APPLICATION_FAILURE })
		);
};

export const createUsersInterest =
	(userData, successCallback) => (dispatch) => {
		dispatch({ type: freelancersConstants.POST_USERS_INTEREST_REQUEST });
		API.post("/api/users_interests", userData)
			.then((result) => {
				dispatch({
					type: freelancersConstants.POST_USERS_INTEREST_SUCCESS,
					payload: result.data,
				});
				const successCallbackMethod = successCallback
					? successCallback()
					: null;
				dispatch(getUsersInterests(successCallbackMethod));
			})
			.catch((err) => {
				dispatch({
					type: freelancersConstants.POST_USERS_INTEREST_FAILURE,
					payload: err.response.data,
				});
				setTimeout(() => dispatch({ type: "CLEAR MY STATE" }), 3000);
			});
	};

export const getUsersInterests = (current_user_id) => (dispatch) => {
	let url = `/api/users_interests?current_user_id=${current_user_id}`;
	dispatch({ type: freelancersConstants.GET_ALL_USERS_INTERESTS_REQUEST });
	API.get(url)
		.then((res) => {
			return dispatch({
				type: freelancersConstants.GET_ALL_USERS_INTERESTS_SUCCESS,
				payload: res.data,
			});
		})
		.catch((err) =>
			dispatch({
				type: freelancersConstants.GET_ALL_USERS_INTERESTS_FAILURE,
				payload: err.response.data,
			})
		);
};
export const updateUsersInterest = (data) => (dispatch) => {
	let url = "/api/users_interests/" + data.id;
	dispatch({
		type: freelancersConstants.PUT_USERS_INTEREST_REQUEST,
		payload: data,
	});
	API.put(url, data)
		.then((result) => {
			dispatch({
				type: freelancersConstants.PUT_USERS_INTEREST_SUCCESS,
				payload: result.data,
			});
			dispatch(getUsersInterests());
		})
		.catch((err) =>
			dispatch({
				type: freelancersConstants.PUT_USERS_INTEREST_FAILURE,
				payload: err.response,
			})
		);
};

export const deleteUsersInterest = (users_interest_id) => (dispatch) => {
	let url = "/api/users_interests/" + users_interest_id;
	dispatch({ type: freelancersConstants.DELETE_USERS_INTEREST_REQUEST });
	API.delete(url)
		.then((result) => {
			dispatch({ type: freelancersConstants.DELETE_USERS_INTEREST_SUCCESS });
			dispatch(getUsersInterests());
		})
		.catch((err) =>
			dispatch({ type: freelancersConstants.DELETE_USERS_INTEREST_FAILURE })
		);
};

export const createUsersLanguage =
	(userData, successCallback) => (dispatch) => {
		dispatch({ type: freelancersConstants.POST_USERS_LANGUAGE_REQUEST });
		API.post("/api/users_languages", userData)
			.then((result) => {
				dispatch({
					type: freelancersConstants.POST_USERS_LANGUAGE_SUCCESS,
					payload: result.data,
				});
				const successCallbackMethod = successCallback
					? successCallback()
					: null;
				dispatch(getUsersLanguages(successCallbackMethod));
			})
			.catch((err) => {
				dispatch({
					type: freelancersConstants.POST_USERS_LANGUAGE_FAILURE,
					payload: err.response.data,
				});
				setTimeout(() => dispatch({ type: "CLEAR MY STATE" }), 3000);
			});
	};

export const getUsersLanguages = (current_user_id) => (dispatch) => {
	let url = `/api/users_languages?current_user_id=${current_user_id}`;
	dispatch({ type: freelancersConstants.GET_ALL_USERS_LANGUAGES_REQUEST });
	API.get(url)
		.then((res) => {
			dispatch({
				type: freelancersConstants.GET_ALL_USERS_LANGUAGES_SUCCESS,
				payload: res.data,
			});
		})
		.catch((err) =>
			dispatch({
				type: freelancersConstants.GET_ALL_USERS_LANGUAGES_FAILURE,
				payload: err.response.data,
			})
		);
};

export const updateUsersLanguage = (data) => (dispatch) => {
	let url = "/api/users_languages/" + data.id;
	dispatch({
		type: freelancersConstants.PUT_USERS_LANGUAGE_REQUEST,
		payload: data,
	});
	API.put(url, data)
		.then((result) => {
			dispatch({
				type: freelancersConstants.PUT_USERS_LANGUAGE_SUCCESS,
				payload: result.data,
			});
			dispatch(getUsersLanguages());
		})
		.catch((err) =>
			dispatch({
				type: freelancersConstants.PUT_USERS_LANGUAGE_FAILURE,
				payload: err.response,
			})
		);
};

export const deleteUsersLanguage = (users_language_id) => (dispatch) => {
	let url = "/api/users_languages/" + users_language_id;
	dispatch({ type: freelancersConstants.DELETE_USERS_LANGUAGE_REQUEST });
	API.delete(url)
		.then((result) => {
			dispatch({ type: freelancersConstants.DELETE_USERS_LANGUAGE_SUCCESS });
			dispatch(getUsersLanguages());
		})
		.catch((err) =>
			dispatch({ type: freelancersConstants.DELETE_USERS_LANGUAGE_FAILURE })
		);
};

export const createUsersSkill = (userData, successCallback) => (dispatch) => {
	dispatch({
		type: freelancersConstants.POST_USERS_SKILL_REQUEST,
		payload: userData,
	});
	API.post("/api/users_skills", userData)
		.then((result) => {
			dispatch({
				type: freelancersConstants.POST_USERS_SKILL_SUCCESS,
				payload: result.data,
			});
			const successCallbackMethod = successCallback ? successCallback() : null;
			dispatch(getUsersSkills(successCallbackMethod));
		})
		.catch((err) => {
			dispatch({
				type: freelancersConstants.POST_USERS_SKILL_FAILURE,
				payload: err.response.data,
			});
			setTimeout(() => dispatch({ type: "CLEAR MY STATE" }), 3000);
		});
};

export const getUsersSkills = (current_user_id) => (dispatch) => {
	let url = `/api/users_skills?current_user_id=${current_user_id}`;
	dispatch({ type: freelancersConstants.GET_ALL_USERS_SKILLS_REQUEST });
	API.get(url)
		.then((res) => {
			dispatch({
				type: freelancersConstants.GET_ALL_USERS_SKILLS_SUCCESS,
				payload: res.data,
			});
		})
		.catch((err) =>
			dispatch({
				type: freelancersConstants.GET_ALL_USERS_SKILLS_FAILURE,
				payload: err.response,
			})
		);
};

export const updateUsersSkill = (data) => (dispatch) => {
	let url = "/api/users_skills/" + data.id;
	dispatch({
		type: freelancersConstants.PUT_USERS_SKILL_REQUEST,
		payload: data,
	});
	API.put(url, data)
		.then((result) => {
			dispatch({
				type: freelancersConstants.PUT_USERS_SKILL_SUCCESS,
				payload: result.data,
			});
			dispatch(getUsersSkills());
		})
		.catch((err) =>
			dispatch({
				type: freelancersConstants.PUT_USERS_SKILL_FAILURE,
				payload: err.response,
			})
		);
};

export const deleteUsersSkill = (users_skill_id) => (dispatch) => {
	let url = "/api/users_skills/" + users_skill_id;
	dispatch({ type: freelancersConstants.DELETE_USERS_SKILL_REQUEST });
	API.delete(url)
		.then((result) => {
			dispatch({ type: freelancersConstants.DELETE_USERS_SKILL_SUCCESS });
			dispatch(getUsersSkills());
		})
		.catch((err) =>
			dispatch({
				type: freelancersConstants.DELETE_USERS_SKILL_FAILURE,
				payload: err.response,
			})
		);
};

export const getDashboardfreelancer = () => (dispatch) => {
	let url = "api/dashboard_freelancer";
	dispatch({ type: freelancersConstants.GET_ALL_DASHBOARD_FREELANCER_REQUEST });
	API.get(url)
		.then((result) => {
			dispatch({
				type: freelancersConstants.GET_ALL_DASHBOARD_FREELANCER_SUCCESS,
				payload: result.data,
			});
		})
		.catch((err) =>
			dispatch({
				type: freelancersConstants.GET_ALL_DASHBOARD_FREELANCER_FAILURE,
				payload: err.data,
			})
		);
};
export const clearJobApplicationId = () => (dispatch) => {
	dispatch({ type: "CLEAR_JOB_APPLICATION_ID", payload: "" });
};

  export const clearApplied = () => dispatch =>{
    dispatch({ type:"CLEAR_APPLIED", payload:""})
  }
