import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { updateUserInfo } from "../../../../Actions/authAction";
import { PROVINCES, STATES } from "../../../../Utils/Provinces&States";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";

const AccountDetails = () => {
  const dispatch = useDispatch();

  const { usersProfile } = useSelector((state) => {
    const { user } = state;
    const { usersProfile } = user;
    return { usersProfile };
  });

  const [state , setState] = useState({
    country: usersProfile.country
  });

  const { register , handleSubmit, reset } = useForm({
    defaultValues : usersProfile,
  });

  const handleUpdateValues = (data) => {
    data.state = state.state;
    dispatch(
      updateUserInfo(data)
    );
    reset(data);
  };

  const handleOption = (event) => {
    setState({
      ...state,
      state : event.target.value
    })
  }

  return (
    <Card>
      <form onSubmit={handleSubmit(handleUpdateValues)} autoComplete="off">
        <CardHeader subheader="The information can be edited" title="Profile" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}> 
              <TextField
                fullWidth
                name="first_name"
                inputRef={register}   
                label="First name"
                margin="dense"
                required    
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                name="last_name"
                inputRef={register}
                label="Last name"
                margin="dense"
                required
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                inputRef={register}
                label="Phone Number"
                margin="dense"
                name="phone_number"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+1</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                select
                defaultValue={usersProfile.state}    
                inputRef={register}
                onChange={handleOption}
                variant="outlined"
                margin="dense"
                label="State/Provinces"
                name="state"
                required
                fullWidth  
                autoComplete="state"
                autoFocus
              >
                {state.country === "Canada"
                  ? PROVINCES.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>     
                  ))
                  : STATES.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
              </TextField>
              
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button 
          type="submit" 
          color="primary" variant="contained">
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default withRouter(AccountDetails);
