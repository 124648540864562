import React from 'react';
import './ShowExperience.css';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { getExperiences, updateExperience, deleteExperience, createExperience } from "../../Actions/freelancerAction";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import Button from "@material-ui/core/Button";
import { Dialog, Select, MenuItem, TextField, Container, DialogActions, DialogContent, CircularProgress, Checkbox, FormControlLabel } from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import DatePickerField from "../../Components/Form/DatePickerField";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class ShowExperiences extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Experience: this.props.Experience,
            Message: false,
            experience_type: 1,
            name: "",
            description: "",
            still_cont: false,
            sub_desc_type: "",
            sub_desc_name: "",
            ex_from: new Date(),
            ex_to: new Date(),
            dialog: false,
            open: false,
            editExperience: false,
            dialog_id: '',
            snack_open: false,
            experience_error: ""
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.Experience !== state.Experience) {
            return { Experience: props.Experience }
        }
        else {
            if (props.experience_error !== state.experience_error) {
                return { experience_error: props.experience_error, snack_open: true }
            }
            else {
                return null;
            }
        }
    }
    componentDidMount() {
        this.props.getExperiences()
    }
    handleExperienceType = (event) => {
        this.setState({
            experience_type: parseInt(event.target.value)
        })
    }
    handleStart = (val) => {
        this.setState({
            ex_from: val
        })
    }
    handleEnd = (val) => {
        this.setState({
            ex_to: val
        })
    }
    handleName = (event) => {
        this.setState({
            name: event.target.value
        })
    }
    handleDescription = (event) => {
        this.setState({
            description: event.target.value
        })
    }
    handleDescriptionName = (event) => {
        this.setState({
            sub_desc_name: event.target.value
        })
    }
    handleDescriptionType = (event) => {
        this.setState({
            sub_desc_type: event.target.value
        })
    }
    handleContinueChange = (event) => {
        this.setState({
            still_cont: event.target.checked
        })
    }
    handleDelete = (id) => {
        this.props.deleteExperience(id)
    }
    handleAddExperience = () => {
        this.props.createExperience({
            experience_type: parseInt(this.state.experience_type),
            ex_from: this.state.ex_from,
            ex_to: this.state.ex_to,
            name: this.state.name,
            description: this.state.description,
            sub_desc_type: this.state.sub_desc_type,
            sub_desc_name: this.state.sub_desc_name,
            still_cont: this.state.still_cont,
        })
        this.handleClose()
        this.setState({
            experience_type: 0,
            name: "",
            description: "",
            still_cont: false,
            sub_desc_type: "",
            sub_desc_name: "",
            ex_from: new Date(),
            ex_to: new Date(),
        })
    }
    handleDialog = () => {
        this.setState({
            dialog: true
        })
    }
    handleClickOpen = () => {
        this.setState({
            open: true
        })
    }
    handleClose = () => {
        this.setState({
            open: false
        })
    };
    handleEditExperience = () => {
        this.setState({ editExperience: false })
    }
    render() {
        const { experience_error, isPutExperienceLoading, isPostExperienceLoading } = this.props;
        const { experience_type } = this.state;
        if (isPutExperienceLoading || isPostExperienceLoading)
            return (
                <Box style={{ display: 'flex', justifyContent: 'center', paddingTop: '25%' }}>
                    <CircularProgress size={70} />
                </Box>
            )

        else
            return (
                <Container maxWidth="xs" style={{alignSelf: 'center'}}>
                <Box component="div" style={{ backgroundColor: 'white', height: 'fit-content', padding: 24, boxShadow: "0 0 0 1px rgba(93, 93, 99, 0.05), 0 1px 3px 0 rgba(63,63,68,0.15)" }} >
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="h3" style={{ textAlign: 'center' }}>Experience</Typography>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={this.handleClickOpen}>Add experience
                        </Button>
                        <Dialog
                        fullWidth={true}
                            open={this.state.open}
                            onClose={this.handleClose}>
                            <form onSubmit={this.handleAddExperience}>
                                <Box>
                                    <DialogContent>
                                        <DialogContentText >Experience type</DialogContentText>
                                        <Select className="field-input"
                                        style={{width: '100%'}}
                                            value={this.state.experience_type}
                                            onChange={this.handleExperienceType}>
                                                <MenuItem value={0}>Education</MenuItem>
                                                <MenuItem value={1}>Job</MenuItem>
                                        </Select>
                                    </DialogContent>
                                    {
                                        (experience_type === 1) ? <React.Fragment>
                                            <DialogContent>
                                                <DialogContentText >Job Title</DialogContentText>
                                                <TextField type="text" style={{ width: '100%' }} defaultValue={this.state.name} onChange={this.handleName} />
                                            </DialogContent>
                                            <DialogContent>
                                                <DialogContentText >Job Description</DialogContentText>
                                                <TextField type="text" style={{ width: '100%' }} defaultValue={this.state.description} onChange={this.handleDescription} />
                                            </DialogContent>
                                        </React.Fragment> : <React.Fragment>
                                                <DialogContent>
                                                    <DialogContentText style={{ width: '100%' }}>School/Institute/University</DialogContentText>
                                                    <Select className="field-input"
                                                        style={{width: '100%'}}
                                                        value={this.state.sub_desc_type}
                                                        onChange={this.handleDescriptionType}>
                                                        <MenuItem value="High School">High School</MenuItem>
                                                        <MenuItem value="Trade School">Trade School</MenuItem>
                                                        <MenuItem value="Associate Degree">Associate Degree</MenuItem>
                                                        <MenuItem value="Certifications">Certifications</MenuItem>
                                                        <MenuItem value="Undergraduate Degree">Undergraduate Degree</MenuItem>
                                                        <MenuItem value="Gradudate Studies">Gradudate Studies</MenuItem>
                                                        <MenuItem value="Doctor of Philosophy (PhD)">Doctor of Philosophy (PhD)</MenuItem>
                                                        <MenuItem value="Bachelor's Degree">Bachelor's Degree</MenuItem>
                                                        <MenuItem value="Master's Degree">Master's Degree</MenuItem>
                                                        <MenuItem value="Doctoral Degree">Doctoral Degree</MenuItem>
                                                    </Select>
                                                </DialogContent>
                                                <DialogContent>
                                                    <DialogContentText>Course/Subject Details</DialogContentText>
                                                    <TextField type="text" style={{ width: '100%' }} defaultValue={this.state.sub_desc_name} onChange={this.handleDescriptionName} />
                                                </DialogContent>
                                            </React.Fragment>
                                    }
                                    <DialogContent>
                                        <Box style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
                                            <Typography variant="body2">Start date</Typography>
                                            <DatePickerField changeDate={this.handleStart} selectedDate={this.state.ex_from} />
                                        </Box>
                                    </DialogContent>
                                    <DialogContent style={{ display: (this.state.still_cont === true) ? 'none' : 'block' }}>
                                        <Box style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
                                            <Typography variant="body2">End date</Typography>
                                            <DatePickerField changeDate={this.handleEnd} selectedDate={this.state.ex_to} />
                                        </Box>
                                    </DialogContent>
                                    <DialogContent style={{ display: 'flex', alignItems: 'flex-end'}}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.still_cont}
                                                    onChange={this.handleContinueChange}
                                                    color="primary"
                                                />
                                            }
                                            label="Still Continuing"
                                        />
                                    </DialogContent>
                                </Box>
                                <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Button onClick={this.handleClose} color="primary">Cancel</Button>
                                    <Button color="primary" type="submit">Add Experience</Button>
                                </DialogActions>
                            </form>
                        </Dialog>
                    </Box>
                    {this.state.Experience ? this.state.Experience.map(index =>
                        <Box className="exp-item" key={index.id}>
                            <Typography style={{ fontWeight: 'bold', backgroundColor: '#000000a6', color: '#f5f5f5', width: 'fit-content', padding: '3px 5px', margin: 5, borderRadius: 5 }}>{(index.experience_type === 0) ? "Education" : "Job"}</Typography>
                            {(index.experience_type === 1) ? <React.Fragment>
                                <Box className="field">
                                    <Box className="field-item" style={{ backgroundColor: '#dbebf9', width: '50%' }}><Typography variant="body1" style={{ fontWeight: 'bold' }}>Job Title</Typography></Box>
                                    <Box className="field-item" style={{ backgroundColor: '#dbebf9', width: '50%' }}><Typography variant="body1">{index.name}</Typography></Box>
                                </Box>
                                <Box className="field">
                                    <Box className="field-item" style={{ backgroundColor: '#f5f5f5', width: '50%' }}><Typography variant="body1" style={{ fontWeight: 'bold' }}>Job Description</Typography></Box>
                                    <Box className="field-item" style={{ backgroundColor: '#f5f5f5', width: '50%' }}><Typography variant="body1">{index.description}</Typography></Box>
                                </Box>
                            </React.Fragment> :
                                <React.Fragment>
                                    <Box className="field">
                                        <Box className="field-item" style={{ backgroundColor: '#dbebf9', width: '50%' }}><Typography variant="body1" style={{ fontWeight: 'bold' }}>Subject/Course</Typography></Box>
                                        <Box className="field-item" style={{ backgroundColor: '#dbebf9', width: '50%' }}><Typography variant="body1">{index.sub_desc_name}</Typography></Box>
                                    </Box>
                                    <Box className="field">
                                        <Box className="field-item" style={{ backgroundColor: '#f5f5f5', width: '50%' }}><Typography variant="body1" style={{ fontWeight: 'bold' }}>Education Type</Typography></Box>
                                        <Box className="field-item" style={{ backgroundColor: '#f5f5f5', width: '50%' }}><Typography variant="body1">{index.sub_desc_type}</Typography></Box>
                                    </Box>
                                </React.Fragment>}

                            <Box className="field">
                                <Box className="field-item" style={{ backgroundColor: '#dbebf9', width: '50%' }}><Typography variant="body1" style={{ fontWeight: 'bold' }}>Starts from</Typography></Box>
                                <Box className="field-item" style={{ backgroundColor: '#dbebf9', width: '50%' }}><Typography variant="body1">{new Intl.DateTimeFormat("en-GB", { year: "numeric", month: "long", day: "2-digit" }).format(Date.parse(index.ex_from))}</Typography></Box>
                            </Box>
                            {index.still_cont ? null : <Box className="field">
                                <Box className="field-item" style={{ backgroundColor: '#f5f5f5', width: '50%' }}><Typography variant="body1" style={{ fontWeight: 'bold' }}>End at</Typography></Box>
                                <Box className="field-item" style={{ backgroundColor: '#f5f5f5', width: '50%' }}><Typography variant="body1">{new Intl.DateTimeFormat("en-GB", { year: "numeric", month: "long", day: "2-digit" }).format(Date.parse(index.ex_to))}</Typography></Box>
                            </Box>}
                            {index.still_cont ? <Box className="field">
                                <Box className="field-item" style={{ backgroundColor: index.still_cont ? '#f5f5f5' : '#dbebf9', width: '50%' }}><Typography variant="body1" style={{ fontWeight: 'bold' }}>Still Continuing</Typography></Box>
                                <Box className="field-item" style={{ backgroundColor: index.still_cont ? '#f5f5f5' : '#dbebf9', width: '50%' }}><Typography variant="body1">{index.still_cont ? "YES" : "NO"}</Typography></Box>
                            </Box> : null}
                            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Tooltip title="Edit experience" aria-label="edit" arrow>
                                    <IconButton aria-label="edit" onClick={() => this.setState({ editExperience: true, dialog_id: index.id })} style={{ float: 'right' }}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                {
                                    (this.state.editExperience && (index.id === this.state.dialog_id)) && <UpdateExperience
                                        //eslint-disable-next-line
                                        handleEditExperience={this.handleEditExperience} isPutExperienceLoading={isPutExperienceLoading} getExperiences={this.props.getExperiences} dialogState={this.state} data={index} updateExperience={this.props.updateExperience} />
                                }
                                <Tooltip title="Delete Experience" aria-label="delete" arrow>
                                    <IconButton aria-label="delete" onClick={() => this.handleDelete(index.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    ) : null}
                    {experience_error ? <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.snack_open} autoHideDuration={3000}>
                        <Alert severity="error">
                            {experience_error}
                        </Alert>
                    </Snackbar> : null}
                </Box>
                </Container>
            );
    }
}
class UpdateExperience extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            id: this.props.data.id,
            experience_type: this.props.data.experience_type,
            name: this.props.data.name,
            description: this.props.data.description,
            still_cont: this.props.data.still_cont ? true : false,
            sub_desc_type: this.props.data.sub_desc_type,
            sub_desc_name: this.props.data.sub_desc_name,
            ex_from: this.props.data.ex_from,
            ex_to: this.props.data.ex_to,
        }
    }
    handleClose = () => {
        this.setState({
            open: false
        })
        this.props.handleEditExperience();
    };
    handleClickOpen = () => {
        this.setState({
            open: true
        })
    }
    handleExperienceType = (event) => {
        // eslint-disable-next-line
        let x = (event.target.value == 0) ? 0 : 1
        this.setState({
            experience_type: x
        })
    }
    handleStart = (val) => {
        this.setState({
            ex_from: val
        })
    }
    handleEnd = (val) => {
        this.setState({
            ex_to: val
        })
    }
    handleName = (event) => {
        this.setState({
            name: event.target.value
        })
    }
    handleDescription = (event) => {
        this.setState({
            description: event.target.value
        })
    }
    handleDescriptionName = (event) => {
        this.setState({
            sub_desc_name: event.target.value
        })
    }
    handleDescriptionType = (event) => {
        this.setState({
            sub_desc_type: event.target.value
        })
    }
    handleContinueChange = (event) => {
        this.setState({
            still_cont: event.target.checked
        })
    }
    handleSubDescName = (event) => {
        this.setState({
            sub_desc_name: event.target.value
        })
    }
    handleSubDescType = (event) => {
        this.setState({
            sub_desc_type: event.target.value
        })
    }
    handleUpdateExperience = () => {
        this.props.updateExperience({
            id: this.state.id,
            experience_type: this.state.experience_type,
            name: this.state.name,
            description: this.state.description,
            still_cont: this.state.still_cont,
            sub_desc_type: this.state.sub_desc_type,
            sub_desc_name: this.state.sub_desc_name,
            ex_from: this.state.ex_from,
            ex_to: this.state.ex_to,
        })
        this.props.handleEditExperience();
    }
    render() {
        const { experience_type } = this.state;
        return (
            <div style={{ minWidth: 350 }}>
                <Dialog
                    fullWidth={true}
                    open={this.state.open}
                    onClose={this.handleClose}>
                    <Box>
                        <DialogContent>
                            <DialogContentText>Experience type</DialogContentText>
                            <Select className="field-input"
                                style={{width: '100%'}}
                                value={experience_type}
                                onChange={this.handleExperienceType}>
                                <MenuItem value={0}>Education</MenuItem>
                                <MenuItem value={1}>Job</MenuItem>
                            </Select>
                        </DialogContent>
                        {
                            (experience_type === 1) ?
                                <React.Fragment>
                                    <DialogContent>
                                        <DialogContentText >Job Title</DialogContentText>
                                        <TextField type="text" style={{ width: '100%' }} defaultValue={this.state.name} onChange={this.handleName} />
                                    </DialogContent>
                                    <DialogContent>
                                        <DialogContentText >Job Description</DialogContentText>
                                        <TextField type="text" style={{ width: '100%' }} defaultValue={this.state.description} onChange={this.handleDescription} />
                                    </DialogContent>
                                </React.Fragment> :
                                <React.Fragment>
                                    <DialogContent>
                                        <DialogContentText style={{ width: '100%' }}>School/Institute/University</DialogContentText>
                                        <Select className="field-input"
                                            style={{width: '100%'}}
                                            value={this.state.sub_desc_type}
                                            onChange={this.handleDescriptionType}>
                                            <MenuItem value="High School">High School</MenuItem>
                                            <MenuItem value="Trade School">Trade School</MenuItem>
                                            <MenuItem value="Associate Degree">Associate Degree</MenuItem>
                                            <MenuItem value="Certifications">Certifications</MenuItem>
                                            <MenuItem value="Undergraduate Degree">Undergraduate Degree</MenuItem>
                                            <MenuItem value="Gradudate Studies">Gradudate Studies</MenuItem>
                                            <MenuItem value="Doctor of Philosophy (PhD)">Doctor of Philosophy (PhD)</MenuItem>
                                            <MenuItem value="Bachelor's Degree">Bachelor's Degree</MenuItem>
                                            <MenuItem value="Master's Degree">Master's Degree</MenuItem>
                                            <MenuItem value="Doctoral Degree">Doctoral Degree</MenuItem>
                                        </Select>
                                    </DialogContent>
                                    <DialogContent>
                                        <DialogContentText>Course/Subject Details</DialogContentText>
                                        <TextField type="text" style={{ width: '100%' }} defaultValue={this.state.sub_desc_name} onChange={this.handleDescriptionName} />
                                    </DialogContent>
                                </React.Fragment>
                        }
                        <DialogContent>
                            <Box style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
                                <Typography variant="body2">Start date</Typography>
                                <DatePickerField changeDate={this.handleStart} selectedDate={this.state.ex_from} />
                            </Box>
                        </DialogContent>
                        <DialogContent style={{ display: (this.state.still_cont === true) ? 'none' : 'block' }}>
                            <Box style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
                                <Typography variant="body2">End date</Typography>
                                <DatePickerField changeDate={this.handleEnd} selectedDate={this.state.ex_to} />
                            </Box>
                        </DialogContent>
                        <DialogContent style={{ display: 'flex', alignItems: 'flex-end'}}>
                            <DialogContentText>Still Continuing</DialogContentText>
                            <Checkbox
                                checked={this.state.still_cont}
                                onChange={this.handleContinueChange} />
                        </DialogContent>

                    </Box>
                    <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            onClick={this.handleUpdateExperience}>Update Experience
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>)
    }
}
const mapStateToProps = state => {
    const { freelancer } = state
    const { experience_error, Experience, Message, isPutExperienceLoading, isPostExperienceLoading } = freelancer;
    return ({ freelancer, experience_error, Experience, Message, isPutExperienceLoading, isPostExperienceLoading })
};
export default connect(mapStateToProps, { getExperiences, createExperience, updateExperience, deleteExperience })(withRouter(ShowExperiences));