import React, { useState,useEffect } from "react";
import { updateUserInfo } from "../../../../Actions/authAction";
import ImageUploading from 'react-images-uploading';
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import AddIcon from "@material-ui/icons/Add";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles
} from "@material-ui/core";
import { Upload, Icon, message } from "antd";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

// const beforeUpload = (file) => {
//   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
//   (!isJpgOrPng)&& message.error('You can only upload JPG/PNG file!');
  
//   const isLt2M = file.size / 1024 / 1024 < 2;
//   (!isLt2M)&& message.error('Image must smaller than 2MB!');
  
//   return isJpgOrPng && isLt2M;
// }
const styles = makeStyles((theme) => {
return {
  root: {},
  details: {
    display: "flex",
  },

updateImage: {
  cursor: "pointer",
},
  avatar: {
    marginLeft: "auto",
    height: 110,
    width: 110,
    flexShrink: 0,
    flexGrow: 0,
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    marginRight: theme.spacing(2),
  },
  image : { width: "100%" }
}
});

const AccountProfile = () => {
  const dispatch = useDispatch();
  const profileStyles = styles();

  const { usersProfile } = useSelector((state) => {
    const { user } = state;
    const { usersProfile } = user;
    return { usersProfile };
  });
  //console.log(usersProfile);

  const [state, setState] = useState({
    isImageUrl:true,
    loading: false,
    imageUrl: "",
    open: false,
  });
  const handleClickOpen = () => {
    setState({ 
      ...state,
      open: true 
    });
  };
  const handleClose = () => {
    setImages([]);
    setState({ 
      ...state,
      open: false,
      isImageUrl:true 
    });
  };
  const updateavatar = (imageUrl) => {
    setState({...state, open:false})
    dispatch(
      updateUserInfo({
        image_url: state.imageUrl,
      })
    );
    setImages([]);
  };

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    setState({...state, imageUrl: imageList[0]['data_url'],isImageUrl:false});
    setImages(imageList);
  };
  const defaultProps = {
    bgcolor: 'background.paper',
    borderColor: 'grey.500',
    m: 1,
    border: 1,
    style: { width: '5rem', height: '5rem' },
  };

  const { imageUrl } = state;

  const uploadButton = (
    <div>
      <Icon type={state.loading ? "loading" : "plus"} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  return (
    <Card>
      <CardContent>
        <div className={profileStyles.details}>
          <div>
            <Typography gutterBottom variant="h2">
              {usersProfile.first_name} {usersProfile.last_name}
            </Typography>
            <Typography
              className={profileStyles.locationText}
              color="textSecondary"
              variant="body1"
            >
              {usersProfile.email}
            </Typography>
            <Typography
              className={profileStyles.locationText}
              color="textSecondary"
              variant="body1"
            >
              Province/State: {usersProfile.state}
            </Typography>
          </div>
          <Avatar className={profileStyles.avatar} src={usersProfile.image_url && usersProfile.image_url.url} />
        </div>
      </CardContent>
      <Divider />
      <CardActions>
      {/* {alert(usersProfile.image_url)} */}
      {(usersProfile.image_url && usersProfile.image_url.url)?
          <Button
          className={profileStyles.uploadButton}
          color="primary"
          variant="text"
          onClick={handleClickOpen}
        >
          Update picture
        </Button>
        :""}
        {!(usersProfile.image_url && usersProfile.image_url.url)?
          <Button
          className={profileStyles.uploadButton}
          color="primary"
          variant="text"
          onClick={handleClickOpen}
        >
          Upload picture
        </Button>
        :""}
        
        <Dialog
          open={state.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="form-dialog-title" style={{textAlign: 'center'}}>Update Avatar</DialogTitle>

<ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div 
            style={isDragging ? { color: 'red' } : undefined} 
            style={{textAlign: 'center'}}
            
            >
             {state.isImageUrl?
              <>
              <Box display="flex" justifyContent="center">
                <Typography component="div">
                  <Box 
                    className={profileStyles.updateImage}
                    borderRadius="borderRadius"
                    {...defaultProps} 
                    fontWeight="fontWeightLight"
                    onClick={onImageUpload} 
                    {...dragProps}
                  >
                    <div >
                      <AddIcon
                        style={{fontSize:30,textAlign: 'center',marginTop:23}}
                      />
                    </div>
                  </Box>
                 </Typography>
              </Box>
              </>              
             :""}
            &nbsp;
            {images.map((image, index) => (
              <div key={index} className="image-item">
                <img  style={{marginBottom:5}} src={image['data_url']} alt="" width="120" />
                <div className="image-item__btn-wrapper">
                <Button
                style={{marginTop:10}}
              color="primary"
              variant="contained"
              size="small"
              
              onClick={() => onImageUpdate(index)}
            >
              Update Image
            </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>

            
          <DialogActions >
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              name="sub_image"
              type="submit"
              variant="text"
              color="primary"
              label="Update Identity"
              onClick={() => updateavatar(imageUrl)}
            >
              {" "}
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </CardActions>
    </Card>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string,
};

export default withRouter(AccountProfile);
