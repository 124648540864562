import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Typography from "@material-ui/core/Typography";
import { Box, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  Select,
  MenuItem,
  TextField,
  DialogActions,
  DialogContent,
  Checkbox,
} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import DatePickerField from "../../../Components/Form/DatePickerField";

import { updateExperience } from "../../../Actions/freelancerAction";

const useStyles = makeStyles(() => {
  return {
    fullWidth: {
      width: "100%",
    },
    boxStyle: {
      flexDirection: "column",
      padding: 10,
    },
    displayFlex: {
      display: "flex",
    },
    justifySpaceBetween: {
      justifyContent: "space-between",
    },
    minWidth: {
      minWidth: 350,
    },
  };
});

const UpdateExperience = (props) => {
  const updateExperienceStyle = useStyles();

  const [state, setState] = useState({
    open: true,
    id: props.data.id,
    experience_type: props.data.experience_type,
    name: props.data.name,
    description: props.data.description,
    still_cont: props.data.still_cont ? true : false,
    sub_desc_type: props.data.sub_desc_type,
    sub_desc_name: props.data.sub_desc_name,
    ex_from: props.data.ex_from,
    ex_to: props.data.ex_to,
  });

  const dispatch = useDispatch();

  const handleClose = () => {
    setState({ ...state, open: false });
    props.handleEditExperience();
  };

  const handleExperienceType = (event) => {
    setState({ ...state, experience_type: event.target.value });
  };

  const handleStart = (val) => {
    setState({ ...state, ex_from: val });
  };

  const handleEnd = (val) => {
    setState({ ...state, ex_to: val });
  };

  const handleName = (event) => {
    setState({ ...state, name: event.target.value });
  };

  const handleDescription = (event) => {
    setState({ ...state, description: event.target.value });
  };

  const handleDescriptionName = (event) => {
    setState({ ...state, sub_desc_name: event.target.value });
  };

  const handleDescriptionType = (event) => {
    setState({ ...state, sub_desc_type: event.target.value });
  };

  const handleContinueChange = (event) => {
    setState({ ...state, still_cont: event.target.checked });
  };

  const handleUpdateExperience = () => {
    dispatch(
      updateExperience({
        id: state.id,
        experience_type: state.experience_type,
        name: state.name,
        description: state.description,
        still_cont: state.still_cont,
        sub_desc_type: state.sub_desc_type,
        sub_desc_name: state.sub_desc_name,
        ex_from: state.ex_from,
        ex_to: state.ex_to,
      })
    );
    props.handleEditExperience();
  };

  const { experience_type } = state;
  return (
    <div className={updateExperienceStyle.minWidth}>
      <Dialog fullWidth={true} open={state.open} onClose={handleClose}>
        <Box>
          <DialogContent>
            <DialogContentText>Experience type</DialogContentText>
            <Select
              className={`field-input ${updateExperienceStyle.fullWidth}`}
              value={experience_type}
              onChange={handleExperienceType}
            >
              <MenuItem value={0}>Education</MenuItem>
              <MenuItem value={1}>Job</MenuItem>
            </Select>
          </DialogContent>
          {experience_type === 1 ? (
            <>
              <DialogContent>
                <DialogContentText>Job Title</DialogContentText>
                <TextField
                  type="text"
                  className={`${updateExperienceStyle.fullWidth}`}
                  defaultValue={state.name}
                  onChange={handleName}
                />
              </DialogContent>
              <DialogContent>
                <DialogContentText>Job Description</DialogContentText>
                <TextField
                  type="text"
                  className={`${updateExperienceStyle.fullWidth}`}
                  defaultValue={state.description}
                  onChange={handleDescription}
                />
              </DialogContent>
            </>
          ) : (
            <>
              <DialogContent>
                <DialogContentText
                  className={`${updateExperienceStyle.fullWidth}`}
                >
                  School/Institute/University
                </DialogContentText>
                <Select
                  className="field-input"
                  className={`${updateExperienceStyle.fullWidth}`}
                  value={state.sub_desc_type}
                  onChange={handleDescriptionType}
                >
                  <MenuItem value="High School">High School</MenuItem>
                  <MenuItem value="Trade School">Trade School</MenuItem>
                  <MenuItem value="Associate Degree">Associate Degree</MenuItem>
                  <MenuItem value="Certifications">Certifications</MenuItem>
                  <MenuItem value="Undergraduate Degree">
                    Undergraduate Degree
                  </MenuItem>
                  <MenuItem value="Graduate Studies">Graduate Studies</MenuItem>
                  <MenuItem value="Doctor of Philosophy (PhD)">
                    Doctor of Philosophy (PhD)
                  </MenuItem>
                  <MenuItem value="Bachelor's Degree">
                    Bachelor's Degree
                  </MenuItem>
                  <MenuItem value="Master's Degree">Master's Degree</MenuItem>
                  <MenuItem value="Doctoral Degree">Doctoral Degree</MenuItem>
                </Select>
              </DialogContent>
              <DialogContent>
                <DialogContentText>Course/Subject Details</DialogContentText>
                <TextField
                  type="text"
                  className={`${updateExperienceStyle.fullWidth}`}
                  defaultValue={state.sub_desc_name}
                  onChange={handleDescriptionName}
                />
              </DialogContent>
            </>
          )}
          <DialogContent>
            <Box
              className={`${updateExperienceStyle.displayFlex} ${updateExperienceStyle.boxStyle}`}
            >
              <Typography variant="body2">Start date</Typography>
              <DatePickerField
                changeDate={handleStart}
                selectedDate={state.ex_from}
              />
            </Box>
          </DialogContent>
          <DialogContent
            style={{
              display: state.still_cont === true ? "none" : "block",
            }}
          >
            <Box
              className={`${updateExperienceStyle.displayFlex} ${updateExperienceStyle.boxStyle}`}
            >
              <Typography variant="body2">End date</Typography>
              <DatePickerField
                changeDate={handleEnd}
                selectedDate={state.ex_to}
              />
            </Box>
          </DialogContent>
          <DialogContent style={{ display: "flex", alignItems: "flex-end" }}>
            <DialogContentText>Still Continuing</DialogContentText>
            <Checkbox
              checked={state.still_cont}
              onChange={handleContinueChange}
            />
          </DialogContent>
        </Box>
        <DialogActions
          className={`${updateExperienceStyle.displayFlex} ${updateExperienceStyle.justifySpaceBetween}`}
        >
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" onClick={handleUpdateExperience}>
            Update Experience
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UpdateExperience;
