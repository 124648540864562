import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./App.css";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { store, persistor } from "./store";
import setAuthToken from "./Utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { setCurrentUser, logoutuser } from "./Actions/authAction";
import { clearCurrentProfile } from "./Actions/profileAction";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import { Router, Route, Switch } from "react-router";
import Login from "./v2/Pages/Login/Login";
import Landing from "./v2/Pages/Landing/Landing";
import ForgotPassword from "./v2/Pages/ForgotPassword/ForgotPassword.js";
import ResendConfirmation from "./v2/Pages/ResendConfirmation/ResendConfirmation.js";
import ResetPassword from "./v2/Pages/ResetPassword/ResetPassword";
import IdentityForm from "./v2/Components/IdentityForm/IdentityForm";
import ExperienceForm from "./Screens/ExperienceForm";
import { history } from "./Utils/history";
import ConfirmLogin from "./Screens/ConfirmLogin";
import EmptyDashboardCompany from "./Screens/EmptyDashboardCompany";
import FreelancerProfilePersonalInformation from "./Screens/FreelancerProfilePersonalInformation";
import FreelancerProfileProfessionalInformation from "./Screens/FreelancerProfileProfessionalInformation";
import CompanyProfileCompanyInformation from "./Screens/CompanyProfileCompanyInformation";
import CompanyProfilePaymentInformation from "./Screens/CompanyProfilePaymentInformation";
import Feedback from "./Screens/Feedback";
import VerifiedMail from "./Screens/VerifiedMail";
import Register from "./v2/Pages/Register/Register";
import RegisterOrganisation from "./v2/Pages/Register/RegisterOrganisation";
import Faq from "./Screens/Faq/Faq";
import PrivacyPolicy from "./Screens/PrivacyPolicy/PrivacyPolicy";
import ResetLink from "./Screens/ResetLik/ResetLink";
import Messages from "./Screens/Messages/Messages";
import theme from "../src/Utils/theme";
import FindJob from "./v2/Pages/FindJob/FindJob";
// import CompanyPostAJob from "./Screens/CompanyPostAJob";
// import ApplicationPage from "./Screens/ApplicationPage";
// import Application from "./Screens/Application";
// import JobInfo from "../../v2/Components/BrowseJobs/JobInfo";
// import ApplicantDetails from "./Screens/ApplicantDetails/ApplicantDetails";
// import JobDetails from "./Screens/JobDetails/JobDetails";
// import EditJob from "./Screens/EditJob/EditJob";
// import ClientAddQuestion from "./Screens/ClientAddQuestion/ClientAddQuestion";
// import JobQuestions from "./Screens/JobQuestions/JobQuestions";
// import FreelancerDashboard from "./Screens/FreelancerDashboard/FreelancerDashboard";
// import BrowseJobs from "../../v2/Components/BrowseJobs/BrowseJobs";
// import EmptyDashboardFreelancer from "./Screens/EmptyDashboardFreelancer";
// import JobList from "./Screens/JobList/JobList";
// import CompanyDetail from "./Screens/CompanyDetail/CompanyDetail";
// import ClientDashboard from "./Screens/ClientDashboard/ClientDashboard";
import MyAccount from "./v2/Components/MyAccount/App";
import InternalServerError from "./Components/InternalServerError";
// const theme = createMuiTheme({
//   palette: {
//     secondary: {
//       main: "#FFBE00",
//     },
//     primary: {
//       main: "#06006A",
//     },
//   },
//   typography: {
//     // Use the system font instead of the default Roboto font.
//     // fontFamily: ['"Lato"', "sans-serif"].join(","),
//   },
//   shadows: Array(25).fill("0"),
// });
if (localStorage.jwtToken) {
	setAuthToken(localStorage.jwtToken);
	const decoded = jwt_decode(localStorage.jwtToken);
	store.dispatch(setCurrentUser(decoded));
	const currentTime = Date.now() / 1000;
	if (decoded.exp < currentTime) {
		store.dispatch(logoutuser());
		store.dispatch(clearCurrentProfile());
		window.location.href = "/login";
	}
}
function App() {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<Router history={history}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<MuiThemeProvider theme={theme}>
							<Switch>
								<Route exact path="/" component={Landing} />
								<Route exact path="/register" component={Register} />
								<Route exact path="/registerorganisation" component={RegisterOrganisation}/>
								<Route exact path="/feedback" component={Feedback} />
								<Route exact path="/verified-mail" component={VerifiedMail} />
								<Route
									exact
									path="/internal-server-error"
									component={InternalServerError}
								/>
								<Route
									exact
									path="/forgot-password"
									component={ForgotPassword}
								/>
								<Route
									exact
									path="/resend-confirmation"
									component={ResendConfirmation}
								/>
								<Route exact path="/find-job" component={FindJob}/>
								<Route exact path="/reset-password" component={ResetPassword} />
								<Route exact path="/login" component={Login} />
								{<Route exact path="/confirm-login" component={ConfirmLogin} />}
								{<Route exact path="/reset-link" component={ResetLink} />}
								<Route exact path="/messages" component={Messages} />
								<Route exact path="/faq" component={Faq} />
								<Route exact path="/privacy-policy" component={PrivacyPolicy} />
								<Route exact path="/identity" component={IdentityForm} />
								<Route exact path="/experience" component={ExperienceForm} />
								<Route exact path="/my-experience" component={MyAccount} />
								<Route exact path="/my-identity" component={MyAccount} />
								<Route exact path="/skills" component={MyAccount} />
								<Route exact path="/languages" component={MyAccount} />
								<Route exact path="/interests" component={MyAccount} />
								<Route exact path="/wizard" component={MyAccount} />
								<Route exact path="/account" component={MyAccount} />
								<Route exact path="/settings" component={MyAccount} />
								<Route exact path="/dashboard" component={MyAccount} />
								<Route exact path="/company-detail" component={MyAccount} />
								<Route exact path="/dashboard-client" component={MyAccount} />
								<Route exact path="/browse-jobs" component={MyAccount} />
								<Route exact path="/applied-jobs" component={MyAccount} />
								<Route exact path="/client-job-list" component={MyAccount} />
								<Route exact path="/profile" component={MyAccount} />
								<Route
									exact
									path="/empty-dashboard-company"
									component={EmptyDashboardCompany}
								/>
								<Route
									exact
									path="/freelancer"
									component={FreelancerProfilePersonalInformation}
								/>
								<Route
									exact
									path="/freelancer-info"
									component={FreelancerProfileProfessionalInformation}
								/>
								<Route
									exact
									path="/company"
									component={CompanyProfileCompanyInformation}
								/>
								<Route
									exact
									path="/company-info"
									component={CompanyProfilePaymentInformation}
								/>
								{/* <Route exact path="/create-job" component={CompanyPostAJob}/>
                <Route exact path="/application-page" component={ApplicationPage}/>
                <Route exact path="/application" component={Application}/>
                <Route exact path="/job-info" component={JobInfo}/>
                <Route exact path="/applicant-details" component={ApplicantDetails}/>
                <Route exact path="/client-job-details" component={JobDetails}/>
                <Route exact path="/client-edit-job" component={EditJob}/>
                <Route exact path="/client-add-question" component={ClientAddQuestion}/>
                <Route exact path="/job-questions" component={JobQuestions}/> */}
							</Switch>
						</MuiThemeProvider>
					</MuiPickersUtilsProvider>
				</Router>
			</PersistGate>
		</Provider>
	);
}
export default App;
