import React, { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles, Grid, Divider } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Link from "@material-ui/core/Link";
import {
  getJobsAnswers,
  createJobsAnswer,
  updateJobsAnswer,
  deleteJobsAnswer,
} from "../../../Actions/freelancerAction";
import { Empty } from "antd";

const styles = makeStyles(() => {
  return {
    updateJob: {
      backgroundColor: "#ffffff",
      paddingTop: "5px",
      paddingBottom: "10px",
    },
    mainContainer: {
      margin: "10px 5px",
      padding: "5px 8px",
      position: "relative",
    },
    done: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      padding: "8px 15px",
      backgroundColor: "#36A720",
      height: 36,
      borderRadius: 5,
      color: "#ffffff",
      float:"right"
    },
    updatejob: { textAlign: "center", marginTop: 5, fontSize: "22px" },
    boxContainer: {
      display: "flex",
      justifyContent: "space-between",
      padding: "14px 10px",
      marginTop: 5,
    },
    mandatoryQuestion: { fontFamily: "monospace" },
    subtitles: { fontWeight: "bold" },
    questionBox: {
      display: "flex",
      flexDirection: "column",
    },
    nodata: {
      textAlign: "center",
    },
    create: {
      color: "#0B196A",
      fontSize: "1.3rem",
    },
    delete: {
      color: "#EB5757",
      fontSize: "1.3rem",
    },
    divider: {
      margin: "5px",
      position: "relative",
    },
  };
});

const UpdateJobAnswers = (props) => {
  const answersStyles = styles();
  const { jobApplicationId } = props;

  const { jobsAnswers } = useSelector((state) => {
    const { freelancer } = state;
    const { jobsAnswers } = freelancer;
    return {
      jobsAnswers,
    };
  });

  const [state, setState] = useState({
    list: [],
  });

  useEffect(() => {
    setState({
      ...state,
      list: jobsAnswers,
    });
  }, [jobsAnswers]);

  const handleAppliedJobRedirection = (event) => {
    event.preventDefault();
    props.handlenavigateBack();
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container className={answersStyles.updateJob}>
          <Grid container className={answersStyles.mainContainer}>
            <Typography
              variant="h5"
              gutterBottom
              className={answersStyles.updatejob}
            >
              Update Job questions
            </Typography>
          </Grid>
          <Divider className={answersStyles.divider} />
          {jobsAnswers.length > 0 ? (
            <QuestionList
              items={state.list}
              jobApplicationId={jobApplicationId}
            />
          ) : (
            <Typography variant="h6" className={answersStyles.nodata}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Typography>
          )}
          <br/>
          {/* <Grid container className={answersStyles.mainContainer}> */}
            <Link
              color="inherit"
              onClick={handleAppliedJobRedirection}
              className={answersStyles.done}
            >
              Done
            </Link>
          {/* </Grid> */}
        <br />
      </Container>
    </React.Fragment>
  );
};

const QuestionList = (props) => {
  const dispatch = useDispatch();
  const { jobApplicationId } = props;

  const { jobsAnswers } = useSelector((state) => {
    const { jobsAnswers } = state.freelancer;
    return { jobsAnswers };
  });

  return jobsAnswers.map((index) => (
    <QuestionListItem
      item={index}
      key={index.id}
      jobApplicationId={jobApplicationId}
      getJobsAnswers={() => dispatch(getJobsAnswers)}
      createJobsAnswer={() => dispatch(createJobsAnswer)}
      updateJobsAnswer={() => dispatch(updateJobsAnswer)}
      deleteJobsAnswer={() => dispatch(deleteJobsAnswer())}
    />
  ));
};

const QuestionListItem = (props) => {
  const dispatch = useDispatch();
  const listStyles = styles();
  const { jobApplicationId, item } = props;
  const { id } = props.item;

  const { jobsAnswers } = useSelector((state) => {
    const { jobsAnswers } = state.freelancer;
    return { jobsAnswers };
  });

  useEffect(() => {
    dispatch(getJobsAnswers(jobApplicationId));
  }, []);

  let Ans = "";
  jobsAnswers &&
    jobsAnswers.map((Answers) => {
      id === Answers.id && (Ans = Answers);
    });

  const [state, setState] = useState({
    required: Ans.question.required,
    answerChange: "",
    answer: Ans.answer,
    id: id,
    open: false,
    item: item,
  });

  const handleClickOpen = () => {
    setState({
      ...state,
      open: true,
    });
  };
  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };
  const handleSubmitAnswer = () => {
    setState({
      ...state,
      open: false,
    });

    dispatch(
      createJobsAnswer({
        answer: state.answerChange,
        jobApplicationId: jobApplicationId,
        question_id: state.id,
      })
    );
  };
  const handleUpdateAnswer = () => {
    setState({
      ...state,
      open: false,
    });
    dispatch(
      updateJobsAnswer(
        {
          id: state.id,
          answer: state.answerChange,
        },
        jobApplicationId
      )
    );
  };
  const handleAnswerChange = (event) => {
    setState({
      ...state,
      answerChange: event.target.value,
    });
  };

  const handleDelete = (id) => {
    dispatch(deleteJobsAnswer(id, jobApplicationId));
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container fixed>
        <Typography component="div" className={listStyles.boxContainer}>
          <Typography variant="body2">
            {Ans.required ? (
              <span className={listStyles.mandatoryQuestion}>
                This question is mandatory
              </span>
            ) : null}
            <br />
            <span className={listStyles.subtitles}>Question Type: </span>{" "}
            {Ans.question.question_type}
            <br />
            <span className={listStyles.subtitles}>Question: </span>{" "}
            {Ans.question.question_text}
            <br />
            <span className={listStyles.subtitles}>Answer: </span> {Ans.answer}
            <br />
          </Typography>
          {Ans.answer ? (
            <div className={listStyles.questionBox}>
              <div>
                <IconButton aria-label="delete" onClick={handleClickOpen}>
                  <CreateIcon className={listStyles.create} />
                </IconButton>
                <Dialog
                  open={state.open}
                  onClose={handleClose}
                  aria-labelledby="form-dialog-title"
                  maxWidth="md"
                >
                  <DialogTitle id="form-dialog-title">
                    {Ans.question.question_type}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {Ans.question.question_text}
                    </DialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Answer"
                      type="text"
                      defaultValue={state.answer}
                      onChange={handleAnswerChange}
                      fullWidth
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={handleUpdateAnswer} color="primary">
                      Update Answer
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
              <IconButton
                aria-label="delete"
                onClick={() => handleDelete(Ans.id, jobApplicationId)}
              >
                <DeleteIcon className={listStyles.delete} />
              </IconButton>
            </div>
          ) : (
            <div>
              <IconButton aria-label="delete" onClick={handleClickOpen}>
                <CreateIcon className={listStyles.create} />
              </IconButton>
              <Dialog
                open={state.open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
              >
                <DialogTitle id="form-dialog-title">
                  {state.question_type}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>{state.question_text}</DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Answer"
                    type="text"
                    onChange={handleAnswerChange}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleSubmitAnswer} color="primary">
                    Submit Answer
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          )}
        </Typography>
        <Divider className={listStyles.divider} />
      </Container>
    </React.Fragment>
  );
};

export default withRouter(UpdateJobAnswers);
