import usersConstants from '../constants/users'

const initialState = {
  usersProfile: [], Interest: null, Interests: null,
  Comment: null, Comments: null, Rates: [],
  isPostRateLoading: false,
  allSkills:[],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case usersConstants.GET_ALL_USERS_PROFILES_REQUEST:
      return {
        ...state, isGetAllUsersProfilesLoading: true
      }
    case usersConstants.GET_ALL_USERS_PROFILES_SUCCESS:
      return {
        ...state, usersProfile: action.payload, isGetAllUsersProfilesLoading: false
      }
    case usersConstants.GET_ALL_USERS_PROFILES_FAILURE:
      return {
        ...state, isGetAllUsersProfilesLoading: false
      }


    case usersConstants.GET_ALL_INTERESTS_REQUEST:
      return {
        ...state, isGetAllInterestsLoading: true
      }
    case usersConstants.GET_ALL_INTERESTS_SUCCESS:
      return {
        ...state, allInterests: action.payload, isGetAllInterestsLoading: false
      }
    case usersConstants.GET_ALL_INTERESTS_FAILURE:
      return {
        ...state, isGetAllInterestsLoading: false
      }

    case usersConstants.GET_FIND_INTERESTS_REQUEST:
      return {
        ...state, isGetFindInterestsLoading: true
      }
    case usersConstants.GET_FIND_INTERESTS_SUCCESS:
      return {
        ...state, required_interest: action.payload, isGetFindInterestsLoading: false
      }
    case usersConstants.GET_FIND_INTERESTS_FAILURE:
      return {
        ...state, isGetFindInterestsLoading: false
      }


    case usersConstants.GET_ALL_LANGUAGES_REQUEST:
      return {
        ...state, isGetAllLanguagesLoading: true
      }
    case usersConstants.GET_ALL_LANGUAGES_SUCCESS:
      return {
        ...state, allLanguages: action.payload, isGetAllLanguagesLoading: false
      }
    case usersConstants.GET_ALL_LANGUAGES_FAILURE:
      return {
        ...state, isGetAllLanguagesLoading: false
      }

    case usersConstants.GET_FIND_LANGUAGES_REQUEST:
      return {
        ...state, isGetFindLanguagesLoading: true
      }
    case usersConstants.GET_FIND_LANGUAGES_SUCCESS:
      return {
        ...state, required_language: action.payload, isGetFindLanguagesLoading: false
      }
    case usersConstants.GET_FIND_LANGUAGES_FAILURE:
      return {
        ...state, isGetFindLanguagesLoading: false
      }


    case usersConstants.GET_ALL_SKILLS_REQUEST:
      return {
        ...state, isGetAllSkillsLoading: true
      }
    case usersConstants.GET_ALL_SKILLS_SUCCESS:
      return {
        ...state, allSkills: action.payload, isGetAllSkillsLoading: false
      }
    case usersConstants.GET_ALL_SKILLS_FAILURE:
      return {
        ...state, isGetAllSkillsLoading: false
      }

    case usersConstants.GET_FIND_SKILLS_REQUEST:
      return {
        ...state, isGetFindSkillsLoading: true
      }
    case usersConstants.GET_FIND_SKILLS_SUCCESS:
      return {
        ...state, required_skill: action.payload, isGetFindSkillsLoading: false
      }
    case usersConstants.GET_FIND_SKILLS_FAILURE:
      return {
        ...state, isGetFindSkillsLoading: false
      }



    case usersConstants.GET_ALL_COMMENTS_REQUEST:
      return {
        ...state, isGetAllCommentsLoading: true
      }
    case usersConstants.GET_ALL_COMMENTS_SUCCESS:

      const Comment = action.payload
      const Comments = action.payload[0];
      return {
        ...state, Comment, Comments, isGetAllCommentsLoading: false
      }
    case usersConstants.GET_ALL_COMMENTS_FAILURE:
      return {
        ...state, isGetAllCommentsLoading: false
      }


    case usersConstants.POST_COMMENT_REQUEST:
      return {
        ...state, isPostCommentLoading: true
      }
    case usersConstants.POST_COMMENT_SUCCESS:
      // const comments = action.payload
      return {
        ...state, isPostCommentLoading: false
      }
    case usersConstants.POST_COMMENT_FAILURE:
      return {
        ...state, isPostCommentLoading: false
      }


    case usersConstants.PUT_COMMENT_REQUEST:
      return {
        ...state, isPutCommentLoading: true
      }
    case usersConstants.PUT_COMMENT_SUCCESS:
      // const comments = action.payload
      return {
        ...state, isPutCommentLoading: false
      }
    case usersConstants.PUT_COMMENT_FAILURE:
      return {
        ...state, isPutCommentLoading: false
      }


    case usersConstants.DELETE_COMMENT_REQUEST:
      return {
        ...state, isDeleteCommentLoading: true
      }
    case usersConstants.DELETE_COMMENT_SUCCESS:
      // const comments = action.payload
      return {
        ...state, isDeleteCommentLoading: false
      }
    case usersConstants.DELETE_COMMENT_FAILURE:
      return {
        ...state, isDeleteCommentLoading: false
      }


    case usersConstants.GET_ALL_RATES_REQUEST:
      return {
        ...state, isGetAllRatesLoading: true
      }
    case usersConstants.GET_ALL_RATES_SUCCESS:

      const Rates = action.payload
      return {
        ...state, Rates, isGetAllRatesLoading: false
      }
    case usersConstants.GET_ALL_RATES_FAILURE:
      return {
        ...state, isGetAllRatesLoading: false
      }


    case usersConstants.POST_RATE_REQUEST:
      return {
        ...state, isPostRateLoading: true
      }
    case usersConstants.POST_RATE_SUCCESS:
      // const rates = action.payload
      return {
        ...state, isPostRateLoading: false
      }
    case usersConstants.POST_RATE_FAILURE:
      {
        let { error } = action.payload

        return {
          ...state, isPostRateLoading: false, error
        }
      }

    case usersConstants.PUT_RATE_REQUEST:
      return {
        ...state, isPutRateLoading: true
      }
    case usersConstants.PUT_RATE_SUCCESS:
      // const rates = action.payload
      return {
        ...state, isPutRateLoading: false
      }
    case usersConstants.PUT_RATE_FAILURE:
      return {
        ...state, isPutRateLoading: false
      }


    case usersConstants.DELETE_RATE_REQUEST:
      return {
        ...state, isDeleteRateLoading: true
      }
    case usersConstants.DELETE_RATE_SUCCESS:
      // const rates = action.payload
      return {
        ...state, isDeleteRateLoading: false
      }
    case usersConstants.DELETE_RATE_FAILURE:
      return {
        ...state, isDeleteRateLoading: false
      }
    case 'RESET_APP':
      return { ...initialState }
    default:
      return state;
  }
}

export default userReducer;