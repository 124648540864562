import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { connect } from "react-redux";
import logo from "./../Assets/logo@2x.png";
import Hidden from "@material-ui/core/Hidden";
import { history } from "./../Utils/history";

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#F6F8FB"
  },
  grid: {
    width: 1200,
    margin: `0 ${theme.spacing(2)}px`,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)"
    }
  },
  loadingState: {
    opacity: 0.05
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing(3)
  },
  rangeLabel: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(2)
  },
  topBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  outlinedButtom: {
    textTransform: "uppercase",
    margin: theme.spacing(2)
  },
  actionButtom: {
    textTransform: "uppercase",
    margin: theme.spacing(2),
    width: 152,
    height: 36
  },
  blockCenter: {
    padding: theme.spacing(2),
    textAlign: "center"
  },
  block: {
    padding: theme.spacing(4)
  },
  loanAvatar: {
    display: "inline-block",
    verticalAlign: "center",
    width: 16,
    height: 16,
    marginRight: 10,
    marginBottom: -2,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  interestAvatar: {
    display: "inline-block",
    verticalAlign: "center",
    width: 16,
    height: 16,
    marginRight: 10,
    marginBottom: -2,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light
  },
  inlining: {
    display: "inline-block",
    marginRight: 10
  },
  buttonBar: {
    display: "flex"
  },
  noBorder: {
    borderBottomStyle: "hidden"
  },
  mainBadge: {
    textAlign: "center",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: "none"
  },
  footerlisttext: {
    marginBottom: -5,
    cursor: "pointer",
    "&:hover": {
      marginLeft: 5,
      transition: ".5s ease-in-out"
    },
    MuiListItemText: {
      fontWeight: 700,
    }
  },

});

class BeforeLoginFooter extends Component {
  state = {
    loading: true
  };
  handleJobs =() => {
    history.push("/")
    setTimeout(()=>{
      window.scrollTo(0, 1800);
    })
  }
  handlescrollWinClick = () => {
    history.push("/")
    setTimeout(()=>{
      window.scrollTo(0, 700);
    })
    
  };
  handleContact=()=>{
    history.push("/")
    setTimeout(()=>{
      window.scrollTo(0, 2500);
    })
  }

  handleFaq = () => {
    history.push('/faq')
    setTimeout(()=>{
      window.scrollTo(0, 0);
    })
  }
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <Grid container style={{ backgroundColor: "white", padding: "50px 0px" }}>
          <Grid item xs={12} sm={3} lg={6} md={6}>
            <Hidden only={["xs", "sm", "md"]}>
              <img
                src={logo}
                alt="InsureGig Logo"
                className={classes.logo}
                style={{
                  padding: 10,
                  height: 62,
                  width: 193,
                  marginLeft: 400,
                  marginTop: 50,
                }}
              />
            </Hidden>
            {
              <Hidden only={["lg", "xl"]}>
                <img
                  src={logo}
                  alt="InsureGig Logo"
                  className={classes.logo}
                  style={{
                    padding: 10,
                    height: 62,
                    width: 193,
                    marginTop: 50,
                  }}
                />
              </Hidden>
            }
          </Grid>


          <Grid item sm={3} xs={12} lg={2} md={2}>
            <List>
              <ListItem>
                <ListItemText primary="How it works?" className={classes.footerlisttext} onClick={() => this.handlescrollWinClick()} />
              </ListItem>

              <ListItem>
                <ListItemText primary="Find Freelancers" className={classes.footerlisttext} onClick={() => history.push({ pathname: "/registerorganisation"})}/>
              </ListItem>

              {/* <ListItem>
                <ListItemText primary="Find Jobs" className={classes.footerlisttext} onClick={() => history.push({ pathname: "/register"})} />
                <ListItemText primary="Find Freelancers" className={classes.footerlisttext} onClick={()=>this.handleJobs()}/>
              </ListItem> */}

              <ListItem>
                <ListItemText primary="Find Jobs" className={classes.footerlisttext} onClick={()=> history.push('/find-job')}/>
              </ListItem>



              {/* <ListItem>
                <ListItemText primary="Blog" style={{cursor: 'pointer'}} />
              </ListItem> */}
            </List>
          </Grid>

          <Grid item sm={3} xs={12} lg={2} md={2}>


            <List>
              {/* <ListItem xs={3}>
                <ListItemText primary="FAQs" className={classes.footerlisttext} onClick={() => this.handleFaq()} />
              </ListItem> */}

              <ListItem>
                <ListItemText primary="Contact Us" className={classes.footerlisttext} onClick={() => this.handleContact()} />
              </ListItem>

              <ListItem>
                <ListItemText primary="About Us" className={classes.footerlisttext}  onClick={() => this.handleFaq()} />
              </ListItem>

              {/* <ListItem>
                <ListItemText primary="Press" className={classes.footerlisttext}  onClick={() => this.handleFaq()} />
              </ListItem> */}
            </List>
          </Grid>

          <Grid item sm={2} xs={12} lg={2} md={2}>
            <List>
              {/* <ListItem xs={3}>
                <ListItemText primary="Trust & Safety" className={classes.footerlisttext} />
              </ListItem> */}

              {/* <ListItem>
                <ListItemText primary="Terms of Use" className={classes.footerlisttext} />
              </ListItem> */}

              {/* <ListItem>
                <ListItemText primary="Careers" style={{cursor: 'pointer'}} />
              </ListItem> */}

              <ListItem>
                <ListItemText primary="Privacy Policy" className={classes.footerlisttext} onClick={() => history.push('/privacy-policy')} style={{ cursor: 'pointer' }} />
              </ListItem>
            </List>
          </Grid>

        </Grid>



        {/* <Divider />
        <Grid justify="flex-end" container>
          <Grid item xs={12} md={3}>
            <ListItem >
              <ListItemText secondary="Trust & Safety"></ListItemText>
              <ListItemText secondary="Terms of Use"></ListItemText>
              <ListItemText secondary="Privacy Policy" onClick={() => history.push('/privacy-policy')} style={{ cursor: 'pointer' }} />
            </ListItem>
          </Grid>
        </Grid> */}
      </React.Fragment>
    );
  }
}

export default connect()(withRouter(withStyles(styles)(BeforeLoginFooter)));
