import React from 'react';
import { Grid, Box, CircularProgress } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getUsersProfiles } from "../../../../Actions/userAction";
import { AccountProfile, AccountDetails } from './components';
// const useStyles = makeStyles(theme => ({
//   root: {
//     padding: theme.spacing(4)
//   }
// }));

class Account extends React.PureComponent{
  constructor(props){
    super(props);
    this.state={
      UsersProfile: this.props.UsersProfile
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.UsersProfile !== state.UsersProfile) {
        return { UsersProfile: props.UsersProfile }
    }
    else {
        return null
    }
}
  componentDidMount(){
    this.props.getUsersProfiles()
  }
  render(){
  // const { state, first_name, last_name, email, phone_number } = this.state.UsersProfile || ""
  if(this.props.isUserProfileUpdateLoading)
  return(
    <Box style={{display:'flex', justifyContent:'center', paddingTop:'25%'}}>
      <CircularProgress size={70}/>
    </Box>
  )
  else
  return (
    <div style={{padding:32}}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          {
            this.state.UsersProfile ? <AccountProfile /> : null
          }
        </Grid>
        <Grid
          item
          lg={8}
          md={6}
          xl={8}
          xs={12}
        >
          {
            this.state.UsersProfile ? <AccountDetails/> : null
          }
        </Grid>
      </Grid>
    </div>
  )
  }
}
const mapStateToProps = state => {
  const { user, auth } = state
  const { UsersProfile } = user
  const { isUserProfileUpdateLoading } = auth
  return ({ UsersProfile, isUserProfileUpdateLoading })
};
export default connect(mapStateToProps,{getUsersProfiles})(withRouter(Account));