import React from 'react';
import Chip from '@material-ui/core/Chip';

  export default function Chips(props) {
    const { name } = props
    return (
          <Chip variant="default" label={name} {...props} />
    )   
  }
  
