/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  List,
  ListItem,
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { logoutuser } from "../../../../../../../../Actions/authAction";
import { withRouter } from "react-router-dom";
import { history } from "../../../../../../../../Utils/history";

const styles = makeStyles(() => {
  return {
    root: {
      position: "relative",
    },
    item: {
      "&:hover": {
        background: "rgba(0,0,0,0.025)",
      },
    },
    listicon: {
      color: "#0C0033",
      minWidth: 40,
    },
    listtext: {
      color: "#0C0033",
    },
    flex: {
      display: "flex",
      justifyContent: "center",
    },
  };
});

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = (props) => {
  const dispatch = useDispatch();
  const sideStyles = styles();

  const {} = useSelector((state) => {
    const { auth } = state;
    return { auth };
  });

  const handleLogout = () => {
    dispatch(logoutuser());
    history.push("/");
  };

  const { pages, onClose, className, staticContext, ...rest } = props;
  const pathname = window.location.pathname;

  return (
    <React.Fragment>
      <List className={clsx(sideStyles.root, className)}>
        {pages.map((page) => (
          <ListItem
            className={sideStyles.item}
            disableGutters
            activeClassName={sideStyles.active}
            component={CustomRouterLink}
            to={page.href}
            onClick={onClose}
            key={page.title}
            selected={pathname === page.href}
          >
            <ListItemIcon> {page.icon}</ListItemIcon>
            <ListItemText primary={page.title} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <ListItem className={sideStyles.flex}>
        <Button onClick={handleLogout}>Logout</Button>
      </ListItem>
    </React.Fragment>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};

export default withRouter(SidebarNav);
