import React from 'react';
import Topbar from "../../Components/Topbar";
import { history } from "./../../Utils/history";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Box, TextField, Checkbox } from '@material-ui/core';
import { Button } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import AddBoxIcon from '@material-ui/icons/AddBox';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import DatePickerField from "./../../Components/Form/DatePickerField";
import CircularProgress from '@material-ui/core/CircularProgress';
import { createExperience } from "../../Actions/freelancerAction";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [day, mnth, date.getFullYear()].join("-");
}
class CreateExperience extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      experience_type: "Education",
      ex_from: new Date(),
      ex_to: new Date(),
      name: "",
      description: "",
      sub_desc_type: "",
      sub_desc_name: "",
      still_cont: false,
      open: false,
      experience_error: ""
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.experience_error !== state.experience_error) {
      return { experience_error: props.experience_error, open: true }
    }
    else {
      return null;
    }
  }
  handleStart = (val) => {

    this.setState({
      ex_from: val
    })
  }
  handleEnd = (val) => {
    this.setState({
      ex_to: val
    })
  }
  handleExpName = (event) => {
    this.setState({
      name: event.target.value
    })
  }
  handleExpDesc = (event) => {
    this.setState({
      description: event.target.value
    })
  }
  handleSubDescType = (event) => {
    this.setState({
      sub_desc_type: event.target.value
    })
  }
  handleSubDescName = (event) => {
    this.setState({
      sub_desc_name: event.target.value
    })
  }

  handleExperienceType = (event) => {
    this.setState({
      experience_type: event.target.value
    })
  }
  handleContinueChange = (event) => {
    this.setState({
      still_cont: event.target.checked
    })
  }
  handleAddExperience = () => {
    this.props.createExperience({
      experience_type: this.state.experience_type,
      ex_from: convert(this.state.ex_from),
      ex_to: convert(this.state.ex_to),
      name: this.state.name,
      description: this.state.description,
      sub_desc_type: this.state.sub_desc_type,
      sub_desc_name: this.state.sub_desc_name,
      still_cont: this.state.still_cont,
    })
  }
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      open: false
    })
  };
  submit = event => {
    event.preventDefault();
    this.handleAddExperience()
    window.location.href = '/my-experience';
  }
  render() {
    const currentPath = this.props.location.pathname;
    const { experience_error, isPostExperienceLoading } = this.props;
    if (isPostExperienceLoading)
      return (<React.Fragment>
        <Topbar currentPath={currentPath} />
        <Container maxWidth="sm">
          <Box component="div" style={{ backgroundColor: '#ffffff', height: '100%', marginTop: 30, padding: 24, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress disableShrink color='primary' />
          </Box>
        </Container>
      </React.Fragment>)
    else
      return (
        <React.Fragment>
          <Topbar currentPath={currentPath} />
          <Container maxWidth="sm">
            <Box component="div" style={{ backgroundColor: '#ffffff', height: '100%', marginTop: 30, padding: 24 }}>
              <form onSubmit={this.submit}>
                <Typography variant="h4" style={{ textAlign: 'center', marginBottom: 20 }}>Add Experience</Typography>
                <Box className="input-cover-sel">
                  <Typography variant="body2">Experience type</Typography>
                  <Select
                    variant="outlined" native
                    value={this.state.experience_type}
                    onChange={this.handleExperienceType}
                    style={{marginBottom: 25, marginLeft: 5 }}>
                    <option value={"Education"}>Education</option>
                    <option value={"Job"}>Job</option>
                  </Select>
                </Box>
                <Box className="input-cover">
                  <Typography variant="body2">Experience name</Typography>
                  <TextField required variant="outlined" style={{ width: '100%', marginBottom: 25 }} onChange={this.handleExpName} />
                </Box>
                <Box className="input-cover">
                  <Typography variant="body2">Experience description</Typography>
                  <TextField required variant="outlined" style={{ width: '100%', marginBottom: 25 }} onChange={this.handleExpDesc} />
                </Box>
                <Box style={{ marginBottom: 25 }}>
                  <Typography variant="body2">Start date</Typography>
                  <DatePickerField changeDate={this.handleStart} selectedDate={this.state.ex_from} />
                </Box>
                <Box style={{ marginBottom: 25, display: (this.state.still_cont === true) ? 'none' : 'block' }}>
                  <Typography variant="body2">End date</Typography>
                  <DatePickerField changeDate={this.handleEnd} selectedDate={this.state.ex_to} />
                </Box>
                <Box style={{ marginBottom: 25 }}>
                  <Typography variant="body1">Still continuing?</Typography>
                  <Checkbox checked={this.state.still_cont} onChange={this.handleContinueChange} />
                </Box>
                <Box className="input-cover">
                  <Typography variant="body2">Experience subject</Typography>
                  <TextField required variant="outlined" style={{ width: '100%', marginBottom: 25 }} onChange={this.handleSubDescType} />
                </Box>
                <Box className="input-cover">
                  <Typography variant="body2">Experience subject description</Typography>
                  <TextField required variant="outlined" style={{ width: '100%', marginBottom: 25 }} onChange={this.handleSubDescName} />
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }} >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<AddBoxIcon />}
                  >
                    Add
                  </Button>
                  <Button
                    onClick={() => history.push("/my-experience")}
                    variant="contained"
                    color="primary"
                    size="large"
                    endIcon={<SkipNextIcon />}>
                    skip
                  </Button>
                </Box>
                {experience_error ? <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.open} autoHideDuration={3000} onClose={this.handleClose}>
                  <Alert onClose={this.handleClose} severity="error">
                    {experience_error}
                </Alert>
                </Snackbar> : null}
              </form>
            </Box>
          </Container>
        </React.Fragment>
      );
  }
}

const mapStateToProps = state => {
  const { freelancer } = state
  const { experience_error, isPostExperienceLoading } = freelancer;
  return ({ freelancer, experience_error, isPostExperienceLoading })
};
export default connect(mapStateToProps, { createExperience })(withRouter(CreateExperience));