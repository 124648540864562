import { SET_CURRENT_USER } from "./types";
import { authConstants } from "./../constants/auth";
import setAuthToken from "./../Utils/setAuthToken";
import jwt_decode from "jwt-decode";
import API from "./../Utils/API";
import { getUsersProfiles } from "./userAction";
import { history } from "../Utils/history";

export const registerUser = (userData) => (dispatch) => {
  dispatch({ type: authConstants.USER_SIGN_UP_REQUEST, payload: userData });
  API.post("users", userData)
    .then((result) => {
      let data = result.data;
      dispatch({ type: authConstants.USER_SIGN_UP_SUCCESS, payload: data });
      history.push("/confirm-login");
    })
    .catch((error) => {
      if(error.message === "Network Error"){
        history.push("/internal-server-error");
        return
      }
      dispatch({
        type: authConstants.USER_SIGN_UP_FAILURE,
        payload: error.response.data,
        form_data: userData
      });
      setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
    });
};

export const loginUser = (userData) => (dispatch) => {
  dispatch({ type: authConstants.USER_LOGIN_REQUEST });
  API.post("api/login", userData)
    .then((result) => {
      const token = result.data.token;
      const data = result.data;
      localStorage.setItem("jwtToken", token);
      localStorage.setItem("name", data.displayName);
      localStorage.setItem("avatar", data.image);
      localStorage.setItem("email", data.email);
      localStorage.setItem("user_type", data.user_type);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));
      dispatch({ type: authConstants.USER_LOGIN_SUCCESS, payload: data });
      if (data.user_type === "Freelancer") {
        history.push("/dashboard");
      } else if (data.user_type === "Client") {
        history.push("/dashboard-client");
      }
    })
    .catch((error) => {
      try {
        dispatch({
          type: authConstants.USER_LOGIN_FAILURE,
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 5000);
      } catch {
        // console.log(error);
      }
    });
};

export const forgotPassword = (userData) => (dispatch) => {
  dispatch({ type: authConstants.USER_FORGOT_PASSWORD_REQUEST });
  API.post("/api/forgot-password", userData)
    .then((result) => {
      dispatch({ type: authConstants.USER_FORGOT_PASSWORD_SUCCESS });
      history.push("/reset-link");
    })
    .catch((err) => {
      dispatch({
        type: authConstants.USER_FORGOT_PASSWORD_FAILURE,
        payload: err.response.data,
      });
      setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
    });
};

export const resetPassword = (userData) => (dispatch) => {
  dispatch({
    type: authConstants.USER_RESET_PASSWORD_REQUEST,
    payload: userData,
  });
  API.post("/api/reset-password", userData)
    .then((result) => {
      dispatch({
        type: authConstants.USER_RESET_PASSWORD_SUCCESS,
        payload: result.data,
      });
      history.push("/login");
    })
    .catch((err) => {
      dispatch({
        type: authConstants.USER_RESET_PASSWORD_FAILURE,
        payload: err.response.data,
      });
      setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
    });
};

export const confirmEmail = (userData) => (dispatch) => {
  dispatch({
    type: authConstants.USER_CONFIRM_EMAIL_REQUEST,
    payload: userData,
  });
  API.post("/api/confirm-email", userData)
    .then((result) => {
      dispatch({
        type: authConstants.USER_CONFIRM_EMAIL_SUCCESS,
        payload: result.data,
      });
      // history.push('/login')
    })
    .catch((err) => {
      dispatch({
        type: authConstants.USER_CONFIRM_EMAIL_FAILURE,
        payload: err.response.data,
      });
      setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
    });
};

export const resendConfirmation = (userData) => (dispatch) => {
  dispatch({ type: authConstants.USER_RESEND_CONFIRMATION_REQUEST });
  API.post("/api/resend-confirmation", userData)
    .then((result) => {
      dispatch({ type: authConstants.USER_RESEND_CONFIRMATION_SUCCESS });
      history.push("/confirm-login");
    })
    .catch((err) => {
      dispatch({
        type: authConstants.USER_RESEND_CONFIRMATION_FAILURE,
        payload: err.response.data,
      });
      setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
    });
};

export const setCurrentUser = (decoded) => {
  localStorage.setItem("user_id", decoded.user_id);
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const updateUser = (userData) => (dispatch) => {
  dispatch({ type: authConstants.USER_UPDATE_REQUEST, payload: userData });
  API.put("/api/users/" + userData.user_id, userData)
    .then((result) => {
      let data = result.data;
      dispatch({ type: authConstants.USER_UPDATE_SUCCESS, data });
      history.push("/identity");
    })
    .catch((err) => {
      dispatch({
        type: authConstants.USER_UPDATE_FAILURE,
        payload: err.response.data,
      });
      setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
    });
};
export const updateUserInfo = (userData) => (dispatch) => {
  dispatch({ type: authConstants.USER_UPDATE_REQUEST, payload: userData });
  API.put("/api/users/" + localStorage.user_id, userData)
    .then((result) => {
      let data = result.data;
      dispatch(getUsersProfiles(localStorage.user_id , () => {
        dispatch({ type: authConstants.USER_UPDATE_SUCCESS, data })
      }));
    })
    .catch((err) => {
      dispatch({
        type: authConstants.USER_UPDATE_FAILURE,
        payload: err.response.data,
      });
      setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
    });
};

export const logoutuser = () => (dispatch) => {
  history.push("/login");
  localStorage.removeItem("jwtToken");
  localStorage.clear();
  window.history.replaceState(null, null, "/");
  setAuthToken(false);
  dispatch({ type: "RESET_APP" });
  dispatch(setCurrentUser({}));
};

// export const registerUser = (userData) => dispatch => {
//   dispatch({ type: authConstants.USER_SIGN_UP_REQUEST, payload: userData })
//   API.post("/users", userData)
//     .then(result => {
//       const token = result.data.token;
//       const data = result.data
//       localStorage.setItem("jwtToken", token);
//       localStorage.setItem("name", `${data.first_name} ${data.last_name}`);
//       localStorage.setItem("user_type", data.user_type);
//       setAuthToken(token);
//       const decoded = jwt_decode(token);
//       dispatch(setCurrentUser(decoded));
//       history.push('/confirm-login')
//       dispatch({ type: authConstants.USER_SIGN_UP_SUCCESS, payload: data })
//     })
//     .catch(error => {
//       try {
//         dispatch({ type: authConstants.USER_SIGN_UP_FAILURE, payload: error.response.data })
//       } catch{
//         console.log(error)
//       }
//     })
// };
