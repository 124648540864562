import React ,{useState , useEffect} from 'react';
import { useForm } from "react-hook-form";
import { Grid, Paper } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers";
import { Button, CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { useDispatch , useSelector} from "react-redux";
import PropTypes from "prop-types";
import { forgotPassword } from "../../../Actions/authAction";
import { history } from "../../../Utils/history";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Topbar from "../../../Components/Topbar";
import Snackbar from "@material-ui/core/Snackbar";
import myStyle from "../../../Utils/style";
import AlertComponent from "../../Components/AlertComponent/index";
import {ForgotPasswordSchema} from "../../../Utils/Schema";

const ForgotPassword = (props) => {
    const dispatch = useDispatch();

    const { auth , isUserForgotPasswordLoading, forgotPasswordError } = useSelector((state) => {
        const { auth } = state;
        const {isUserForgotPasswordLoading , forgotPasswordError}  = auth;
        return ({ auth, isUserForgotPasswordLoading, forgotPasswordError });
    });

    const classes = myStyle();
    const handleRegister = () => {
        history.push("/register");
    };

const [open, setOpen] = useState(false);
const handleClick = () => {
        setOpen(true);
    };
    useEffect(() => {
        handleClick();
    }, [forgotPasswordError]);
    
    const handleClose = (event, reason) => {
    if (reason === "clickaway") {
        return;
    }

    setOpen(false);
    };
    const handleUserForgotPassword = (data) => dispatch(forgotPassword(data));
    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(ForgotPasswordSchema),
    });

    const currentPath = props.location.pathname;
    

    return (
    <React.Fragment>
        <Topbar currentPath={currentPath} />
        <div className={classes.root}>
        <Grid container>
            <Grid item xs={12} md={3}></Grid>
            <Grid item xs={12} md={6}>
                <form noValidate onSubmit={handleSubmit(handleUserForgotPassword)}>
                {isUserForgotPasswordLoading ? (
                    <CircularProgress className={classes.loading} size={20} />
                ) : (
                    <Paper className={classes.LoginPaper}>
                    <Typography variant="h4" gutterBottom color="secondary">Forgot Password</Typography>
                        <Grid container>
                        <Typography gutterBottom variant="body2">
                            Don't have account yet?
                        </Typography>
                        <Typography onClick={() => handleRegister()} className={classes.link}>
                            Sign Up
                        </Typography>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <TextField
                            inputRef={register}
                            required
                            fullWidth={true}
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            className={classes.inputFormControl}
                        />
                        {errors.email && (
                            <p className={classes.error}>{errors.email.message}</p>
                        )}
                        </Grid>
                        
                        <Grid item xs={12} sm={6}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.cButton}
                        >
                            Send Email
                        </Button>
                        </Grid>
                        </Grid>
                        {forgotPasswordError && (

                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={open}
                            autoHideDuration={3000}
                            onClose={handleClose}
                        >
                        <AlertComponent onClose={handleClose} severity="error">
                            {forgotPasswordError.error}
                        </AlertComponent>
                        </Snackbar>

                    )}
                    </Paper>
                )}
            </form>
            </Grid>
            <Grid item xs={12} md={3}></Grid>
        </Grid>
        </div>
    </React.Fragment>
    );
};

ForgotPassword.propType = {
    forgotPassword: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    forgotPasswordError: PropTypes.object.isRequired,
    };

export default (withRouter(ForgotPassword));

