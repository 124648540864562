import React, { Fragment } from "react";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
function KeyboardDatePickerExample(props) {
const date = props.selectedDate
  return (
    <Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        minDate={props.minDate}
        value={date}
        label={props.label}
        onChange={date => props.changeDate(date)}
        format="MM/dd/yyyy"
      />
      </MuiPickersUtilsProvider>
    </Fragment>
  );
}

export default KeyboardDatePickerExample;