import React from "react";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
class UploadField extends React.Component {
  constructor(props){
    super(props);
    this.state={
            loading: false,
            imageUrl:{}
    }
  }
  handleChange = value => {
    this.props.onChange("preview", value.currentTarget.files[0]);
    var bodyFormData = new FormData();
    bodyFormData.append(this.props.name, value.currentTarget.files[0]);
    this.props.onChange(this.props.name, "http://www.google.com");

  };
  sendToProps=(url)=>{
    this.props.imageURL(url)
  }
  handleImageChange = value => {
    const makeCall = this.sendToProps;
    getBase64(value.currentTarget.files[0], imageUrl =>{
      this.setState({
        imageUrl,
        loading: false
      })
      makeCall(imageUrl)
    }
    );

  };
  render() {
    // const {imageUrl} = this.state;
    return (
      <div className="form-group">
        <label htmlFor={this.props.id}>{this.props.label}</label>
        <input
          id={this.props.id}
          name={this.props.name}
          type="file" accept="image/*,.pdf"
          onChange={this.handleImageChange}
          className="form-control"
          style={{ display: "block", marginTop: "10px", marginBottom: "10px" }}
        />
        {/* {imageUrl ? <img src={imageUrl} alt="image preview" style={{ width: '100%' }} /> : null} */}
      </div>
    );
  }
}

export default UploadField;
