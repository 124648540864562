import React,{useState} from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
// import IconButton from '@material-ui/core/IconButton';
import {Typography, Grid } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import Chip from "@material-ui/core/Chip";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";
import renderHTML from "react-render-html";
import _ from "lodash";

import LinearProgress from "@material-ui/core/LinearProgress";

let projectColor = "";
const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: lighten(projectColor ? projectColor : "#d3cfd4", 0.5),
  },
  bar: {
    borderRadius: 5,
    backgroundColor: projectColor,
  },
})(LinearProgress);
const useStyles = makeStyles((theme) => ({
  root: {
    width: "1000px",
    // height: "306px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    margin: 24,
  },
  margin: {
    margin: theme.spacing(3),
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  less : {
    position : "absolute",
    top:"15px",
    right:"8px"
  }
}));

export default function RecipeReviewCard(props) {
  const [more , setMore] = useState({
    isMore: false,
    text : "",
    size:false
  });

  let boxSize = (more.size) ? "310px" : "250px";

  const truncate = (text , max) => {
    const Text = text.trim().split(' ');
    const more = Text.length > max ? '...' : '';
    return Text.slice(0 , max).join(' ') + more;
  }

  projectColor = props.projectColor;
  const { projectName, projectText, status } = props;
  let max = 30;
  let truncuatedText = truncate(projectText , max);

  const handleMore = () =>{
    setMore({
      ...more,
      isMore:true,
      size: true
    })
  }

  const handleLess = () =>{
    setMore({
      ...more,
      isMore:false,
      size: false
    })
  }
  const classes = useStyles();
  // const [anchorEl, setAnchorEl] = React.useS tate(null);
  // const handleClick = event => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  return (
    <Card className={classes.root}  style={{ height : boxSize , transition : "0.2s" , position:"relative"}}>
      <CardHeader
        avatar={
          <Chip
            size="small"
            label={props.projectStatus}
            style={{ backgroundColor: projectColor }}
          />
        }
        // action={
        //   <React.Fragment>
        //     <IconButton aria-label="settings" aria-haspopup="true" onClick={handleClick}>
        //     <MoreVertIcon />
        //   </IconButton>
        //   <Menu
        //   id="simple-menu"
        //   anchorEl={anchorEl}
        //   keepMounted
        //   open={Boolean(anchorEl)}
        //   onClose={handleClose}>
        //   <MenuItem onClick={handleClose}>Details</MenuItem>
        //   <MenuItem onClick={handleClose}>Settings</MenuItem>
        // </Menu>
        //   </React.Fragment>
        // }
      />

      <CardContent>
        <Grid container style={{ marginBottom: 10 }}>
          <Grid item lg={12} sm={12} md={12}>
        <Typography
          variant="h5"
          component="div"
          style={{
            wordBreak: "break-word",
            overflow:"hidden",
          }}
        >
          {projectName}
        </Typography>
        </Grid>
        <Grid item lg={12} sm={12} md={12}>
          {status}
        </Grid>
        </Grid>
        <hr style={{border: "1px dashed #BDBDBD", margin:"5px 0px" }}/>
        <Typography
          color="textSecondary"
          component="div"
          style={{ overflowY: "scroll", height: 97 , marginTop:10 , fontSize:"14px" }}
        >
          {(more.isMore) ? 
          <> 
           <a className={classes.less} onClick={handleLess}>Read less</a>
          {renderHTML(projectText)}
          </>
           : 
        <>
  {projectText.length < max ? renderHTML(projectText) :
  <>
{renderHTML(truncuatedText)} 
<a onClick={handleMore}>Read more</a>
  </>
  }
        </> 
          }
        </Typography>
      </CardContent>
      <BorderLinearProgress
        className={classes.margin}
        variant="determinate"
        value={50}
      />
    </Card>
  );
}
