import React from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import {
  Button,
  InputAdornment,
  TextField,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { createContactUs } from "../../../Actions/contactUsAction";
import { Spin } from "antd";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  helpcard: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: 50,
    // marginBottom: "auto ",
    borderRadius: 10,
    backgroundColor: "#FFFFFF",
    boxShadow: "0 8px 20px 0 rgba(224,224,224,0.5)",
  },
  heading: {
    color: "#0C0033",
    fontWeight: 500,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  helpBlock: {
    background: "#fff",
  },
  helpheading: {
    marginTop: theme.spacing(2),
    color: "#454F5B",
    textAlign: "left",
    fontWeight: 500,
  },
}));
const phoneRegExp = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
const QuerySchema = yup.object().shape({
  first_name: yup.string().required("First name is a required field"),
  last_name: yup.string().required("Last name is a required field"),
  contact_number: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is a required field"),
  subject: yup.string().required("Subject is a required field"),
  message: yup.string().required("Message is a required field")
});
export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let {
    isPostContactUsLoading,
    querySubmitError,
  } = useSelector((state) => state.contactUs);
  const [issue, setIssue] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [submissionResponse, setSubmissionResponse] = React.useState(false);
  // const handleOnChangeMessage = (text) => {
  //   // setMessage(text);
  // };
  const handleClick = () => {
    setOpen(true);
  };
  React.useEffect(() => {
    handleClick();
  }, [querySubmitError]);
  const handleClose = ( reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setIssue(false);
  };
	const handleSuccessCallback = (e) => {
		reset()
	}
  const handleQuery = (data, event) => {
    setSubmissionResponse(true);
    event.preventDefault();
    setTimeout(()=>{
      window.scrollTo(0, 2700);
    },2000)
    dispatch(createContactUs(data, handleSuccessCallback));
  };
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(QuerySchema),
  });
  return (
    <Spin size="large" spinning={isPostContactUsLoading}>
      <Grid
        container
        spacing={3}
        className={classes.helpBlock}
        justify="space-evenly"
      >
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.helpheading}>
            Contact Us
          </Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(handleQuery)}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  inputRef={register}
                  required
                  fullWidth
                  id="first_name"
                  label="First Name"
                  name="first_name"
                  autoComplete="first_name"
                  autoFocus
                />
                {errors.first_name && (
                  <p style={{ color: "#f44336" }}>
                    {errors.first_name.message}
                  </p>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  inputRef={register}
                  required
                  fullWidth
                  id="last_name"
                  label="Last Name"
                  name="last_name"
                  autoComplete="first_name"
                  autoFocus
                />
                {errors.last_name && (
                  <p style={{ color: "#f44336" }}>{errors.last_name.message}</p>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  inputRef={register}
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                {errors.email && (
                  <p style={{ color: "#f44336" }}>{errors.email.message}</p>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  inputRef={register}
                  required
                  fullWidth
                  id="contact_number"
                  label="Contact Number"
                  name="contact_number"
                  autoComplete="contact_number"
                  autoFocus
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+1</InputAdornment>
                    ),
                  }}
                />
                {errors.contact_number && (
                  <p style={{ color: "#f44336" }}>
                    {errors.contact_number.message}
                  </p>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputRef={register}
                  required
                  fullWidth
                  id="subject"
                  label="Subject"
                  name="subject"
                  autoComplete="subject"
                  autoFocus
                />
                {errors.subject && (
                  <p style={{ color: "#f44336" }}>{errors.subject.message}</p>
                )}
              </Grid>
              <Grid item xs={12}>
               
                <TextField
                  inputRef={register}
									required
                  name="message"
									fullWidth
                  label="Message"
                  multiline
                  rows={4}
                />
								{errors.message && (
                  <p style={{ color: "#f44336" }}>{errors.message.message}</p>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Submit Query
                </Button>
              </Grid>
              {submissionResponse && (
                <Grid style={{ display: "flex", justifyContent: "center" }}>
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                  >
                    <Alert onClose={handleClose} severity="success">
                      Query submitted successfully
                    </Alert>
                  </Snackbar>
                </Grid>
              )}
              {querySubmitError && (
                <Grid style={{ display: "flex", justifyContent: "center" }}>
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                  >
                    <Alert onClose={handleClose} severity="error">
                      {querySubmitError.error}
                    </Alert>
                  </Snackbar>
                </Grid>
              )}
              {issue && (
                <Grid style={{ display: "flex", justifyContent: "center" }}>
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                  >
                    <Alert onClose={handleClose} severity="error">
                      please provide query description
                    </Alert>
                  </Snackbar>
                </Grid>
              )}
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Spin>
  );
};
