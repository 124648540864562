const clientsConstants = {
    GET_ALL_CLIENTS_REQUEST: 'GET_ALL_CLIENTS_REQUEST',
    GET_ALL_CLIENTS_SUCCESS:'GET_ALL_CLIENTS_SUCCESS',
    GET_ALL_CLIENTS_FAILURE:'GET_ALL_CLIENTS_FAILURE',

    POST_CLIENT_REQUEST: 'POST_CLIENT_REQUEST',
    POST_CLIENT_SUCCESS:'POST_CLIENT_SUCCESS',
    POST_CLIENT_FAILURE:'POST_CLIENT_FAILURE',

    PUT_CLIENT_REQUEST: 'PUT_CLIENT_REQUEST',
    PUT_CLIENT_SUCCESS:'PUT_CLIENT_SUCCESS',
    PUT_CLIENT_FAILURE:'PUT_CLIENT_FAILURE',

    DELETE_CLIENT_REQUEST: 'DELETE_CLIENT_REQUEST',
    DELETE_CLIENT_SUCCESS:'DELETE_CLIENT_SUCCESS',
    DELETE_CLIENT_FAILURE:'DELETE_CLIENT_FAILURE',

    GET_ALL_JOBS_REQUEST: 'GET_ALL_JOBS_REQUEST',
    GET_ALL_JOBS_SUCCESS:'GET_ALL_JOBS_SUCCESS',
    GET_ALL_JOBS_FAILURE:'GET_ALL_JOBS_FAILURE',

    GET_HOME_JOBS_REQUEST: 'GET_HOME_JOBS_REQUEST',
    GET_HOME_JOBS_SUCCESS:'GET_HOME_JOBS_SUCCESS',
    GET_HOME_JOBS_FAILURE:'GET_HOME_JOBS_FAILURE',

    POST_JOB_REQUEST: 'POST_JOB_REQUEST',
    POST_JOB_SUCCESS:'POST_JOB_SUCCESS',
    POST_JOB_FAILURE:'POST_JOB_FAILURE',

    PUT_JOB_REQUEST: 'PUT_JOB_REQUEST',
    PUT_JOB_SUCCESS:'PUT_JOB_SUCCESS',
    PUT_JOB_FAILURE:'PUT_JOB_FAILURE',

    DELETE_JOB_REQUEST: 'DELETE_JOB_REQUEST',
    DELETE_JOB_SUCCESS:'DELETE_JOB_SUCCESS',
    DELETE_JOB_FAILURE:'DELETE_JOB_FAILURE',

    GET_ALL_JOBS_SKILLS_REQUEST: 'GET_ALL_JOBS_SKILLS_REQUEST',
    GET_ALL_JOBS_SKILLS_SUCCESS:'GET_ALL_JOBS_SKILLS_SUCCESS',
    GET_ALL_JOBS_SKILLS_FAILURE:'GET_ALL_JOBS_SKILLS_FAILURE',

    POST_JOBS_SKILL_REQUEST: 'POST_JOBS_SKILL_REQUEST',
    POST_JOBS_SKILL_SUCCESS:'POST_JOBS_SKILL_SUCCESS',
    POST_JOBS_SKILL_FAILURE:'POST_JOBS_SKILL_FAILURE',

    PUT_JOBS_SKILL_REQUEST: 'PUT_JOBS_SKILL_REQUEST',
    PUT_JOBS_SKILL_SUCCESS:'PUT_JOBS_SKILL_SUCCESS',
    PUT_JOBS_SKILL_FAILURE:'PUT_JOBS_SKILL_FAILURE',

    DELETE_JOBS_SKILL_REQUEST: 'DELETE_JOBS_SKILL_REQUEST',
    DELETE_JOBS_SKILL_SUCCESS:'DELETE_JOBS_SKILL_SUCCESS',
    DELETE_JOBS_SKILL_FAILURE:'DELETE_JOBS_SKILL_FAILURE',

    GET_ALL_JOBS_EDUCATIONS_REQUEST: 'GET_ALL_JOBS_EDUCATIONS_REQUEST',
    GET_ALL_JOBS_EDUCATIONS_SUCCESS:'GET_ALL_JOBS_EDUCATIONS_SUCCESS',
    GET_ALL_JOBS_EDUCATIONS_FAILURE:'GET_ALL_JOBS_EDUCATIONS_FAILURE',

    POST_JOBS_EDUCATION_REQUEST: 'POST_JOBS_EDUCATION_REQUEST',
    POST_JOBS_EDUCATION_SUCCESS:'POST_JOBS_EDUCATION_SUCCESS',
    POST_JOBS_EDUCATION_FAILURE:'POST_JOBS_EDUCATION_FAILURE',

    PUT_JOBS_EDUCATION_REQUEST: 'PUT_JOBS_EDUCATION_REQUEST',
    PUT_JOBS_EDUCATION_SUCCESS:'PUT_JOBS_EDUCATION_SUCCESS',
    PUT_JOBS_EDUCATION_FAILURE:'PUT_JOBS_EDUCATION_FAILURE',

    DELETE_JOBS_EDUCATION_REQUEST: 'DELETE_JOBS_EDUCATION_REQUEST',
    DELETE_JOBS_EDUCATION_SUCCESS:'DELETE_JOBS_EDUCATION_SUCCESS',
    DELETE_JOBS_EDUCATION_FAILURE:'DELETE_JOBS_EDUCATION_FAILURE',

    GET_ALL_QUESTIONS_REQUEST: 'GET_ALL_QUESTIONS_REQUEST',
    GET_ALL_QUESTIONS_SUCCESS:'GET_ALL_QUESTIONS_SUCCESS',
    GET_ALL_QUESTIONS_FAILURE:'GET_ALL_QUESTIONS_FAILURE',

    POST_QUESTION_REQUEST: 'POST_QUESTION_REQUEST',
    POST_QUESTION_SUCCESS:'POST_QUESTION_SUCCESS',
    POST_QUESTION_FAILURE:'POST_QUESTION_FAILURE',

    PUT_QUESTION_REQUEST: 'PUT_QUESTION_REQUEST',
    PUT_QUESTION_SUCCESS:'PUT_QUESTION_SUCCESS',
    PUT_QUESTION_FAILURE:'PUT_QUESTION_FAILURE',

    DELETE_QUESTION_REQUEST: 'DELETE_QUESTION_REQUEST',
    DELETE_QUESTION_SUCCESS:'DELETE_QUESTION_SUCCESS',
    DELETE_QUESTION_FAILURE:'DELETE_QUESTION_FAILURE',

    GET_ALL_DASHBOARD_CLIENT_REQUEST: 'GET_ALL_DASHBOARD_CLIENT_REQUEST',
    GET_ALL_DASHBOARD_CLIENT_SUCCESS:'GET_ALL_DASHBOARD_CLIENT_SUCCESS',
    GET_ALL_DASHBOARD_CLIENT_FAILURE:'GET_ALL_DASHBOARD_CLIENT_FAILURE',
}
export default clientsConstants;