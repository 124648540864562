import API from "../Utils/API";
import contactUsConstants from '../constants/contactUs';

export const createContactUs = (userData,successCallback) => dispatch => {
    dispatch({type: contactUsConstants.POST_CONTACT_US_REQUEST, payload: userData})
    API.post("/api/contacts", userData)
      .then(result => {
        dispatch({type: contactUsConstants.POST_CONTACT_US_SUCCESS, payload: result})
        {successCallback&&successCallback()}
      })
      .catch(err =>{
        dispatch({ type: contactUsConstants.POST_CONTACT_US_FAILURE, payload: err.response.data })
        setTimeout(() => dispatch({ type: "RESET_APP"}), 3000)
      });
  };

  export const getContactUs = () => dispatch =>{
    let url= "/api/contacts"
    dispatch({type: contactUsConstants.GET_ALL_CONTACT_US_REQUEST})
    API.get(url).then(res =>{
      return dispatch({
      type: contactUsConstants.GET_ALL_CONTACT_US_SUCCESS,
      payload: res.data
    });
  })
  .catch(err =>
    dispatch({
      type: contactUsConstants.GET_ALL_CONTACT_US_FAILURE,
      payload: {}
    })
  );
  };

  export const updateContactUs = (contact_id) => dispatch => {
    let url= "/api/contacts" + contact_id
    dispatch({ type: contactUsConstants.PUT_CONTACT_US_REQUEST})
    API.put(url).then(result => {
        return dispatch({type: contactUsConstants.PUT_CONTACT_US_SUCCESS})
      })
      .catch((err) =>
        dispatch({ type: contactUsConstants.PUT_CONTACT_US_FAILURE })
    );
  };

  export const deleteContactUs = (contact_id) => dispatch => {
    let url= "/api/contacts" + contact_id
    dispatch({ type: contactUsConstants.DELETE_CONTACT_US_REQUEST})
    API.delete(url).then(result => {
        return dispatch({type: contactUsConstants.DELETE_CONTACT_US_SUCCESS})
      })
      .catch((err) =>
        dispatch({ type: contactUsConstants.DELETE_CONTACT_US_FAILURE })
    );
  };