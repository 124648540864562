import React , {useState , useEffect} from "react";
import "./UsersLanguage.css";
import IgButton from "../../../Components/Form/IgButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Box,
  Tooltip,
  Button,
  IconButton,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  DialogContentText,
  CircularProgress,
  makeStyles
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getLanguages } from "../../../Actions/userAction";
import { getUsersProfiles } from "../../../Actions/userAction";
import {
  createUsersLanguage,
  getUsersLanguages,
  updateUsersLanguage,
  deleteUsersLanguage,
} from "../../../Actions/freelancerAction";
import { withRouter } from "react-router-dom";
import { useDispatch , useSelector } from "react-redux";
import ErrorComponent from "../../Components/Error/ErrorComponent";


const styles = makeStyles((theme) => {
return {
  root: {
    flexGrow: 1,
    backgroundColor: "#F6F8FB",
    paddingLeft: 10,
    marginTop: -10,
  },
  paper: {
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
  pageTitle: {
    paddingBottom: 10,
    paddingTop: 10,
  },
  LanguageBox: {
    minHeight: 60,
  },
  Textlanguages : {
    width : '100%'
  },
  radioColor : {
    color: "#1a237e"
  },
  editIcon : {
    fontSize: 18
  },
  updateLanguageRoot : {
    minWidth: "max-content"
  },
  radioGroup : {
    display: "flex", 
    flexDirection: "row"
  },
  dialogAction : {
    display: "flex"
  },
  deleteIcon : {
    fontSize: 18
  },
  loadingbox : { 
    display: "flex", 
    justifyContent: "center", 
    paddingTop: "22%",
    paddingLeft: "47%" 
  },
  addLanguage : { padding : "5px 10px" }
}
});

const UsersLanguages = (props) => {
const dispatch = useDispatch();
const UserlanguagesStyles = styles();

let {freelancer,
  allLanguages,
  userLanguages,
  usersProfile,
  isPutUsersLanguageLoading,
  isPostUsersLanguageLoading,
  isGetAllUsersLanguagesLoading,
  languageCreateError
} = useSelector((state) => {
  const { freelancer, user } = state;
  const { allLanguages , usersProfile } = user;
  const {
    userLanguages,
    isPutUsersLanguageLoading,
    isPostUsersLanguageLoading,
    isGetAllUsersLanguagesLoading,
    languageCreateError,
  } = freelancer;
  return {
    freelancer,
    allLanguages,
    usersProfile,
    userLanguages,
    isPutUsersLanguageLoading,
    isPostUsersLanguageLoading,
    isGetAllUsersLanguagesLoading,
    languageCreateError
  };
});

const [state, setState] = useState({
  language_id: "",
  level: "Beginner",
  dialog: false,
  open: false,
  error : true,
  editDialog: "",
  dialog_id: "",
  change: "new",
});

useEffect(() => {
  dispatch(getUsersLanguages());
  dispatch(getLanguages());
  dispatch(getUsersProfiles());
},[]);

  const handleLevelChange = (event) => {
    setState({ 
      ...state,
      level: event.target.value 
    });
  };
  const handleLanguage = (event, params) => {
    if (params) {
      const { id } = params;
      let a = allLanguages.filter((index) => {
        return index.id === id;
      });
      (a.length > 0) && setState({ 
        ...state,
        language_id: a[0].id 
      });  
    }
  };
  
  const handleDelete = (id) => {
    dispatch(deleteUsersLanguage(id));
  };

  const handleResetForm = () => {
    setState({
      ...state,
      language_id: "",
      change: Math.random(),
      level: "Beginner"
    });
  };

  const handlecreateUsersLanguage = (event) => {
    event.preventDefault();
    const { language_id, level } = state;
    const data = {
     language_id,
     level
    };
    dispatch(createUsersLanguage(data, handleResetForm()));
    
    // setTimeout(() => {
    //   (languageCreateError) && setState({
    //     ...state,
    //     error : true
    //   });
    // },1000)
  };

  const handleEditDialog = () => setState({ 
    ...state,
    editDialog: false 
  });

    const handleErrorClose = () => {
    setState({ 
      ...state,
      error: false 
    });
  };

    const { change, dialog_id } = state;

    (userLanguages) && userLanguages.forEach((element) => {
        (element.level === "Expert")? element.tagcol = "col-blue"
        : 
        (element.level === "Intermediate")? element.tagcol = "col-green"
        :
          element.tagcol = "col-yellow";
      });
    
    return (
      <div className={UserlanguagesStyles.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" className={UserlanguagesStyles.pageTitle}>
              {" "}
              Add Languages
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <form onSubmit={handlecreateUsersLanguage}>
              <Paper className={UserlanguagesStyles.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={10} sm={10} md={4} lg={5}>
                    {allLanguages ? (
                      <Autocomplete
                        id="clear-on-escape"
                        disableClearable
                        clearOnEscape
                        key={change}
                        options={allLanguages}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) =>
                          handleLanguage(event, value)
                        }
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            className ={UserlanguagesStyles.Textlanguages}
                            label="Languages"
                          />
                        )}
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={10} lg={5} sm={10} md={6}>
                    <RadioGroup
                      row
                      aria-label="level"
                      name="level"
                      onChange={handleLevelChange}
                    >
                      <FormControlLabel
                        value="Beginner"
                        checked={state.level === "Beginner"}
                        control={<Radio className={UserlanguagesStyles.radioColor} />}
                        label="Beginner"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="Intermediate"
                        checked={state.level === "Intermediate"}
                        control={<Radio className={UserlanguagesStyles.radioColor} />}
                        label="Intermediate"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="Expert"
                        checked={state.level === "Expert"}
                        control={<Radio className={UserlanguagesStyles.radioColor} />}
                        label="Expert"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={10} sm={10} md={2} lg={2}>
                    <IgButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={UserlanguagesStyles.addLanguage}
                      label="Add language"
                      loading={+isPostUsersLanguageLoading}
                    ></IgButton>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" className={UserlanguagesStyles.pageTitle}>
              Languages({userLanguages && userLanguages.length})
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} wrap="wrap">
          {userLanguages
            ? userLanguages.map((index , i) => (
                <Grid key={i} item xs={12} sm={6} md={4} lg={4} xl={3}>
                  <Paper className={UserlanguagesStyles.paper}>
                    <Grid container>
                      <Grid item xs={6} sm={6} md={6} lg={8} xl={9}>
                        <div className={UserlanguagesStyles.LanguageBox}>
                          <Typography variant="h5">
                            {" "}
                            {index.language.name}
                          </Typography>
                          <Typography variant="subtitle2">
                            {index.level}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={4} xl={3}>
                        {isPutUsersLanguageLoading && dialog_id === index.id ? (
                          <CircularProgress size={25} />
                        ) : (
                          <Tooltip
                            title="Edit Language"
                            aria-label="edit"
                            arrow
                          >
                            <IconButton
                              aria-label="edit"
                              onClick={() =>
                                setState({
                                  ...state,
                                  editDialog: true,
                                  dialog_id: index.id,
                                })
                              }
                            >
                              <EditIcon className={UserlanguagesStyles.editIcon} />
                            </IconButton>
                          </Tooltip>
                        )}
                        {state.editDialog &&
                          state.dialog_id === index.id && (
                            <UpdateUsersLanguage
                              handleEditDialog={handleEditDialog}
                              isPutUsersLanguageLoading={
                                isPutUsersLanguageLoading
                              }
                              getUsersLanguages={dispatch(getUsersLanguages)}
                              ExperienceUpdateUsersLanguagedialogState={
                                {...state}
                              }
                              data={index}
                              updateUsersLanguage={
                                props.updateUsersLanguage
                              }
                            />
                          )}
                        <Tooltip
                          title="Delete Language"
                          aria-label="delete"
                          arrow
                        >
                          <IconButton
                            onClick={() => handleDelete(index.id)}
                          >
                            <DeleteIcon className={UserlanguagesStyles.deleteIcon} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ))
            : null}
        </Grid>
          <ErrorComponent
        error={languageCreateError}
        open={true}
        handleClose={handleErrorClose}
      />
      </div>
    );
}
const UpdateUsersLanguage = (props) => {
const dispatch = useDispatch();
const UserlanguagesStyles = styles();

const [state , setState] = useState({
  open: true,
  id: props.data.id,
  language_id: "",
  level: props.data.level
});
  
  const handleClose = () => {
    setState({ 
      ...state,
      open: false });
    props.handleEditDialog();
  };

  const handleLevelChange = (event) => {
    setState({ 
      ...state,
      level: event.target.value });
  };
  const handleUpdateUsersLanguage = () => {
    dispatch(updateUsersLanguage({
      id: state.id,
      level: state.level,
    }));
    props.handleEditDialog();
  };

    return (
      <div className={UserlanguagesStyles.updateLanguageRoot}>
        <Dialog open={state.open} onClose={handleClose}>
          <DialogTitle id="form-dialog-title">Language Level</DialogTitle>
          <DialogContent>
            <DialogContentText component="div">
              <RadioGroup
                aria-label="level"
                name="level"
                onChange={handleLevelChange}
                className ={UserlanguagesStyles.radioGroup}
              >
                <FormControlLabel
                  value="Beginner"
                  checked={state.level === "Beginner"}
                  control={<Radio className={UserlanguagesStyles.radioColor} />}
                  label="Beginner"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="Intermediate"
                  checked={state.level === "Intermediate"}
                  control={<Radio className={UserlanguagesStyles.radioColor} />}
                  label="Intermediate"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="Expert"
                  checked={state.level === "Expert"}
                  control={<Radio className={UserlanguagesStyles.radioColor} />}
                  label="Expert"
                  labelPlacement="end"
                />
              </RadioGroup>
            </DialogContentText>
          </DialogContent>

          <DialogActions className={UserlanguagesStyles.dialogAction}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateUsersLanguage}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}

export default (withRouter(UsersLanguages));
