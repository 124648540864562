import clientsConstants from '../constants/clients'
const initialState = {
  clients: [],
  clientDetails: {},
  isGetAllJobsLoading: false,
  isGetHomeJobsLoading:false,
  isGetAllClientsLoading: false,
  isPostClientLoading: false,
  isPutClientLoading: false,
  isPutJobLoading: false,
  isPostJobLoading: false,
  Job: [],
  Jobs: [],
  jobs : null,

  JobSkill: [],
  JobsSkills: [],
  job_skills : [],
  job_educations : [],
  new_application : "New Job Created Successfully.",
  draft_job : null,
  latestJob : null,
  isDeleted : false,
  homeJobs:[],
  JobsEducation: [],
  JobsEducations: [],

  Question: [],
  Questions: [],
  jobId : null
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case clientsConstants.GET_ALL_CLIENTS_REQUEST:
      return {
        ...state, isGetAllClientsLoading: true
      }
    case clientsConstants.GET_ALL_CLIENTS_SUCCESS:
      const clients = action.payload
      let { error } = action.payload
      return {
        ...state, clients, isGetAllClientsLoading: false, getClientError: error
      }
    case clientsConstants.GET_ALL_CLIENTS_FAILURE:
      return {
        ...state, isGetAllClientsLoading: false
      }


    case clientsConstants.POST_CLIENT_REQUEST:
      return {
        ...state, isPostClientLoading: true
      }
    case clientsConstants.POST_CLIENT_SUCCESS:

      return {
        ...state, isPostClientLoading: false, createClientResponse: action.payload
      }
    case clientsConstants.POST_CLIENT_FAILURE:
      {
        let { error } = action.payload
        return {
          ...state, isPostClientLoading: false, createClientError: error
        }
      }


    case clientsConstants.PUT_CLIENT_REQUEST:
      return {
        ...state, isPutClientLoading: true
      }
    case clientsConstants.PUT_CLIENT_SUCCESS:

      return {
        ...state, isPutClientLoading: false
      }
    case clientsConstants.PUT_CLIENT_FAILURE:
      {
        let { error } = action.payload
        return {
          ...state, isPutClientLoading: false, error
        }
      }


    case clientsConstants.DELETE_CLIENT_REQUEST:
      return {
        ...state, isDeleteClientLoading: true
      }
    case clientsConstants.DELETE_CLIENT_SUCCESS:

      return {
        ...state, isDeleteClientLoading: false
      }
    case clientsConstants.DELETE_CLIENT_FAILURE:
      return {
        ...state, isDeleteClientLoading: false
      }


    case clientsConstants.GET_ALL_JOBS_REQUEST:
      return {
        ...state, isGetAllJobsLoading: true
      }
    case clientsConstants.GET_ALL_JOBS_SUCCESS:
      return {
        ...state, jobs : action.payload, isGetAllJobsLoading: false
      }
    case clientsConstants.GET_ALL_JOBS_FAILURE:
      return {
        ...state, isGetAllJobsLoading: false
      }

      case clientsConstants.GET_HOME_JOBS_REQUEST:
        return {
          ...state, isGetHomeJobsLoading: true
        }
      case clientsConstants.GET_HOME_JOBS_SUCCESS:
        return {
          ...state, homeJobs : action.payload, isGetHomeJobsLoading: false
        }
      case clientsConstants.GET_HOME_JOBS_FAILURE:
        return {
          ...state, isGetHomeJobsLoading: false
        }

    case clientsConstants.POST_JOB_REQUEST:
      return {
        ...state, isPostJobLoading: true
      }
    case clientsConstants.POST_JOB_SUCCESS:
      return {
        ...state, recent_created_job: action.payload, isPostJobLoading: false,draft_job : "Job added to draft"
      }
    case clientsConstants.POST_JOB_FAILURE:
      {
        let { error } = action.payload
        return {
          ...state, isPostJobLoading: false, createJobFailure: error
        }
      }
    case clientsConstants.PUT_JOB_REQUEST:
      return {
        ...state, isPutJobLoading: true
      }
    case clientsConstants.PUT_JOB_SUCCESS:
      return {
        ...state, isPutJobLoading: false, latestJob:action.payload, updateJobResponse: action.payload ,isDeleted:false
      }
    case clientsConstants.PUT_JOB_FAILURE:
      return {
        ...state, isPutJobLoading: false, updateJobFailure: action.payload
      }
    case clientsConstants.DELETE_JOB_REQUEST:
      return {
        ...state, isDeleteJobLoading: true,isDeleted:false
      }
    case clientsConstants.DELETE_JOB_SUCCESS:
      return {
        ...state, isDeleteJobLoading: false , isDeleted : true
      }
    case clientsConstants.DELETE_JOB_FAILURE:
      return {
        ...state, isDeleteJobLoading: false
      }
    case "GET_APPLIED_JOBS_REQUEST":
      return {
        ...state, isGetAppliedJobsLoading: true
      }
    case "GET_APPLIED_JOBS_SUCCESS":
      let applied_jobs = [];
      if (undefined !== action.payload.length) {
        action.payload.forEach(job => {
          job.jobs_applications.forEach(jobApplication => {
            if (jobApplication.user_id === localStorage.user_id) {
              job.my_job_application = jobApplication
              applied_jobs.push(job)
            }
          })
        })
      }
      return {
        ...state, isGetAppliedJobsLoading: false, applied_jobs: applied_jobs
      }
    case "GET_APPLIED_JOBS_FAILURE":
      return {
        ...state, isGetAppliedJobsLoading: false, applied_jobs_error: action.payload
      }

    case clientsConstants.GET_ALL_JOBS_SKILLS_REQUEST:
      return {
        ...state, isGetAllJobsSkillsLoading: true
      }
    case clientsConstants.GET_ALL_JOBS_SKILLS_SUCCESS:
      return {
        ...state, isGetAllJobsSkillsLoading: false, job_skills: action.payload
      }
    case clientsConstants.GET_ALL_JOBS_SKILLS_FAILURE:
      return {
        ...state, isGetAllJobsSkillsLoading: false
      }
    case clientsConstants.POST_JOBS_SKILL_REQUEST:
      return {
        ...state, isPostJobsSkillLoading: true
      }
    case clientsConstants.POST_JOBS_SKILL_SUCCESS:
      return {
        ...state, isPostJobsSkillLoading: false
      }
    case clientsConstants.POST_JOBS_SKILL_FAILURE:
      {
        let { error } = action.payload
        return {
          ...state, isPostJobsSkillLoading: false, error
        }
      }
    case clientsConstants.DELETE_JOBS_SKILL_REQUEST:
      return {
        ...state, isDeleteJobsSkillLoading: true
      }
    case clientsConstants.DELETE_JOBS_SKILL_SUCCESS:
      return {
        ...state, isDeleteJobsSkillLoading: false
      }
    case clientsConstants.DELETE_JOBS_SKILL_FAILURE:
      return {
        ...state, isDeleteJobsSkillLoading: false
      }
    case clientsConstants.GET_ALL_JOBS_EDUCATIONS_REQUEST:
      return {
        ...state, isGetAllJobsEducationsLoading: true
      }
    case clientsConstants.GET_ALL_JOBS_EDUCATIONS_SUCCESS:
      return {
        ...state, isGetAllJobsEducationsLoading: false, job_educations: action.payload
      }
    case clientsConstants.GET_ALL_JOBS_EDUCATIONS_FAILURE:
      return {
        ...state, isGetAllJobsEducationsLoading: false
      }
    case clientsConstants.POST_JOBS_EDUCATION_REQUEST:
      return {
        ...state, isPostJobsEducationLoading: true
      }
    case clientsConstants.POST_JOBS_EDUCATION_SUCCESS:
      return {
        ...state, isPostJobsEducationLoading: false
      }
    case clientsConstants.POST_JOBS_EDUCATION_FAILURE:
      {
        let { error } = action.payload
        return {
          ...state, isPostJobsEducationLoading: false, error
        }
      }
    case clientsConstants.DELETE_JOBS_EDUCATION_REQUEST:
      return {
        ...state, isDeleteJobsEducationLoading: true
      }
    case clientsConstants.DELETE_JOBS_EDUCATION_SUCCESS:
      return {
        ...state, isDeleteJobsEducationLoading: false
      }
    case clientsConstants.DELETE_JOBS_EDUCATION_FAILURE:
      return {
        ...state, isDeleteJobsEducationLoading: false
      }
    case clientsConstants.GET_ALL_QUESTIONS_REQUEST:
      return {
        ...state, isGetAllQuestionsLoading: true
      }
    case clientsConstants.GET_ALL_QUESTIONS_SUCCESS:
      let jobId = "";
      (action.payload) && action.payload.map((data) => {
        jobId = (data) && data.job.id;
      })
      return {
        ...state, questions: action.payload, isGetAllQuestionsLoading: false , jobId : jobId 
      }
    case clientsConstants.GET_ALL_QUESTIONS_FAILURE:
      return {
        ...state, isGetAllQuestionsLoading: false
      }
    case clientsConstants.POST_QUESTION_REQUEST:
      return {
        ...state, isPostQuestionLoading: true
      }
    case clientsConstants.POST_QUESTION_SUCCESS:
      return {
        ...state, isPostQuestionLoading: false, 
      }
    case clientsConstants.POST_QUESTION_FAILURE:
      return {
        ...state, isPostQuestionLoading: false
      }
    case clientsConstants.DELETE_QUESTION_REQUEST:
      return {
        ...state, isDeleteQuestionLoading: true
      }
    case clientsConstants.DELETE_QUESTION_SUCCESS:
      return {
        ...state, deleteQuestion: "success", isDeleteQuestionLoading: false
      }
    case clientsConstants.DELETE_QUESTION_FAILURE:
      return {
        ...state, delteQuestion: "failure", isDeleteQuestionLoading: false
      }

    case clientsConstants.GET_ALL_DASHBOARD_CLIENT_REQUEST:
      return {
        ...state, isGetAllDashboardClientLoading: true
      }
    case clientsConstants.GET_ALL_DASHBOARD_CLIENT_SUCCESS:
      return {
        ...state, isGetAllDashboardClientLoading: false, client_dashboard: action.payload
      }
    case clientsConstants.GET_ALL_DASHBOARD_CLIENT_FAILURE:
      return {
        ...state, isGetAllDashboardClientLoading: false, client_dashboard_error: action.payload
      }

    case "CLEAR ERROR STATE":
      return {
        ...state, getClientError: " ", createClientError: " "
      }
      case "CLEARING NEW APPLICATION STATE" : 
      return {
        ...state,new_application : "",jobId : null
      }
      case "CLEARING LATEST JOB STATE":
        return {
          ...state,latestJob : null , isDeleted:false,draft_job:""
        }
        case "CLEARING DRAFT JOB":
          return {
            ...state , draft_job : null
          }
    case 'RESET_APP':
      return { ...initialState }
    default:
      return state;
  }
}

export default clientReducer;