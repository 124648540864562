import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter} from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IgButton from "../../Components/Form/IgButton"
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Topbar from "./../../Components/Topbar";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import AddIcon from '@material-ui/icons/Add';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import InsureTable from '../../Components/InsureTable';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { history } from "./../../Utils/history";
import { createUsersInterest } from "./../../Actions/freelancerAction";


const numeral = require("numeral");
numeral.defaultFormat("0,000");

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#F6F8FB'

  },
  grid: {
    width: 1200,
    margin: `0 ${theme.spacing(2)}px`,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)"
    }
  },
  loadingState: {
    opacity: 0.05
  },

  paper1: {
    marginTop: theme.spacing(0.5),
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(3)
  },
  text: {
    marginTop: theme.spacing(2)
  },
  tab: {
    marginTop: 40,
  },

  tab1: {
    background: "#FFFFFF",
    color: "#212B36",
  },

  tab2: {
    background: "#637381",
    color: "#FFFFFF",

  },

  paper2: {
    maxWidth: 865,
    marginTop: theme.spacing(5),
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    alignItems: "center",
    padding: theme.spacing(3)
  },

  heading: {
    marginTop: 10,
    color: "#0C0033",
    textAlign: "center"
  },

  theading: {
    color: "#0C0033",
  },
  valuebox: {
    boxSizing: "border-box",
    border: "1px solid #E6E6E6",
    height: 34,
    width: 145,

  },

  value: {
    padding: theme.spacing(0.4),
    paddingLeft: theme.spacing(8),
    color: "#0C0033",
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'none'
  }
});

class FreelancerProfileProfessionalInformation extends Component {
  state = {
    loading: true,
    level:"",
    interest_id: "",
  };

  handleLevel = (event) =>{
    this.setState({
      level: event.target.value
    })
  }

  handleFreelancerProfilePersonalInformation = () => {
    history.push('/freelancer')
  }
  handleSubmission=()=>{
    this.props.createUsersInterest(this.state.data)
  }
render() {
  const { classes } = this.props;
  const currentPath = this.props.location.pathname;
  const percentage = 50;
return (
<React.Fragment>
  <Topbar currentPath={currentPath} />
    <div className={classes.root}>
      <Paper className={classes.paper1}>
        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CircularProgressbar styles={{ root: { height: 105, width: 105 }, path: { stroke: '#23C0F5' } }} value={percentage} text={`${percentage}%`} />
            </Grid>
              <Grid item className={classes.text}>
                <Typography variant="h4" gutterBottom style={{ paddingLeft: 15, color: "#212B36"}}>
                  Your Profile is Complete
                </Typography>
                <Typography variant="body1" gutterBottom style={{ paddingLeft: 15, color: "#637381"}}>
                  Great Start {this.props.userName}! Please complete remaining steps!
                </Typography>
              </Grid>
      </Paper>

  <Grid container justify="center">
    <Grid
      spacing={3}
      alignItems="center"
      justify="center"
      container
      className={classes.grid}
    >
    <Grid container justify="center">
      <Paper className={classes.paper2}>
        <Typography variant="h4" gutterBottom className={classes.heading}>
          Professional Information
        </Typography>

        <Typography className={classes.theading}>
          Interest Areas
        </Typography>
        <InsureTable columnNames={["","Beginner", "Intermediate" , "Expert", "Action"]}
                    data={[["Claims",
                    <div className={classes.valuebox}>
                          <RadioGroup aria-label="level" name="level" onChange={this.handleLevel.bind(this)}>
														<FormControlLabel
															value="Beginner"
															checked={this.state.level === "Beginner"}
															control={<Radio  className={classes.value} />}
														/></RadioGroup></div>,
                      <div className={classes.valuebox}>
                          <RadioGroup aria-label="level" name="level" onChange={this.handleLevel.bind(this)}>
                            <FormControlLabel
															value="Intermediate"
															checked={this.state.level === "Intermediate"}
															control={<Radio  className={classes.value} />}
														/></RadioGroup></div>,
                      <div className={classes.valuebox}>
                      <RadioGroup aria-label="level" name="level" onChange={this.handleLevel.bind(this)}>
                      <FormControlLabel
															value="Expert"
															checked={this.state.level === "Expert"}
															control={<Radio  className={classes.value} />}
														/></RadioGroup></div>,
                             <RemoveCircleIcon style={{color: "#D61600"}}/>],
                     ["Human Resources",
                     <div className={classes.valuebox}>
                       <RadioGroup aria-label="level" name="level" onChange={this.handleLevel.bind(this)}>
														<FormControlLabel
															value="Beginner"
															checked={this.state.level === "Beginner"}
															control={<Radio  className={classes.value} />}
														/></RadioGroup></div>,
                     <div className={classes.valuebox}>
                       <RadioGroup aria-label="level" name="level" onChange={this.handleLevel.bind(this)}>
                            <FormControlLabel
															value="Intermediate"
															checked={this.state.level === "Intermediate"}
															control={<Radio  className={classes.value} />}
														/></RadioGroup></div>,
                     <div className={classes.valuebox}>
                       <RadioGroup aria-label="level" name="level" onChange={this.handleLevel.bind(this)}>
                      <FormControlLabel
															value="Expert"
															checked={this.state.level === "Expert"}
															control={<Radio  className={classes.value} />}
														/></RadioGroup></div>,
                     <RemoveCircleIcon style={{color: "#D61600"}}/>]]}
        >
        </InsureTable>


        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <IgButton
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            label="Add New Interest"
            labelplacement="end"
            className={classes.submit}
          >
          </IgButton>
        </div>

      <Typography className={classes.theading}>
        Languages
      </Typography>
      <InsureTable columnNames={["English","Limited", "Professional" , "Fluent", "Action"]}
                  rowsData={[{name:'Reading', Beginner: true, Intermediate:false, Expert: true}
                            ,{name:'Writting', Beginner: true, Intermediate:false, Expert: true}]}
                  data={[["Reading",
                  <div className={classes.valuebox}>
                  <Radio checked={this.state.englishReadingLevel === "a3"} onChange={this.handleEnglishReadingLevel} value="a3" className={classes.value} /></div>,
                  <div className={classes.valuebox}>
                  <Radio checked={this.state.englishReadingLevel === "b3"} onChange={this.handleEnglishReadingLevel} value="b3" className={classes.value} /></div>,
                  <div className={classes.valuebox}>
                  <Radio checked={this.state.englishReadingLevel === "c3"} onChange={this.handleEnglishReadingLevel} value="c3" className={classes.value} /></div>,
                  <RemoveCircleIcon style={{color: "#D61600"}}/>],
                        ["Writting",
                  <div className={classes.valuebox}>
                  <Radio checked={this.state.englishWritingLevel === "a4"} onChange={this.handleEnglishWritingLevel} value="a4" className={classes.value} /></div>,
                  <div className={classes.valuebox}>
                  <Radio checked={this.state.englishWritingLevel === "b4"} onChange={this.handleEnglishWritingLevel} value="b4" className={classes.value} /></div>,
                  <div className={classes.valuebox}>
                  <Radio checked={this.state.englishWritingLevel === "c4"} onChange={this.handleEnglishWritingLevel} value="c4" className={classes.value} /></div>,
                  <RemoveCircleIcon style={{color: "#D61600"}}/>]]}
      >
      </InsureTable>

      <InsureTable columnNames={["French","Limited", "Professional" , "Fluent", "Action"]}
                  rowsData={[{name:'Reading', Beginner: true, Intermediate:false, Expert: true}
                            ,{name:'Writting', Beginner: true, Intermediate:false, Expert: true}]}
                  data={[["Reading",
                  <div className={classes.valuebox}>
                  <Radio checked={this.state.frenchReadingLevel === "a5"} onChange={this.handleFrenchReadingLevel} value="a5" className={classes.value} /></div>,
                  <div className={classes.valuebox}>
                  <Radio checked={this.state.frenchReadingLevel === "b5"} onChange={this.handleFrenchReadingLevel} value="b5" className={classes.value} /></div>,
                  <div className={classes.valuebox}>
                  <Radio checked={this.state.frenchReadingLevel === "c5"} onChange={this.handleFrenchReadingLevel} value="c5" className={classes.value} /></div>,
                  <RemoveCircleIcon style={{color: "#D61600"}}/>],
                          ["Writting",
                  <div className={classes.valuebox}>
                  <Radio checked={this.state.frenchWritingLevel === 'a6'} onChange={this.handleFrenchWritingLevel} value="a6" className={classes.value} /></div>,
                  <div className={classes.valuebox}>
                  <Radio checked={this.state.frenchWritingLevel === 'b6'} onChange={this.handleFrenchWritingLevel} value="b6" className={classes.value} /></div>,
                  <div className={classes.valuebox}>
                  <Radio checked={this.state.frenchWritingLevel === 'c6'} onChange={this.handleFrenchWritingLevel} value="c6" className={classes.value} /></div>,
                  <RemoveCircleIcon style={{color: "#D61600"}}/>]]}
      >
      </InsureTable>
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <IgButton
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          label=" Add New Language"
          className={classes.submit}
        >
        </IgButton>
      </div>

    <Grid container spacing={2}>
	    <Grid item xs={12} sm={6}>
        <IgButton
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<ArrowLeftIcon />}
          label=" Personal Information"
          className={classes.submit}
          onClick={() => this.handleFreelancerProfilePersonalInformation()}
        >
        </IgButton>
      </Grid>

      <Grid item xs={12} sm={6} style={{ display: "flex", justifyContent: "flex-end"}}>
        <IgButton
          type="submit"
          variant="contained"
          color="primary"
          label="Finish"
          iconname= {ArrowRightIcon}
          className={classes.submit}
          onClick={this.handleSubmission}
        >
        </IgButton>
        &nbsp;
        <IgButton
          type="submit"
          variant="contained"
          color="primary"
          label="Skip for now"
          iconname= {ArrowRightIcon}
          className={classes.submit}
          href='/browse-jobs'
        >
        </IgButton>
      </Grid>
  </Grid>

</Paper>
</Grid>

      </Grid>
    </Grid>
  </div>
</React.Fragment>
    );
  }
}

FreelancerProfileProfessionalInformation.propType = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => {
	const { freelancer } = state
	const { error } = freelancer;
	const {userName} = state.auth;
	return{
	  freelancer,error,userName
	}
  };

export default connect(
  mapStateToProps,
  { createUsersInterest }

)(withRouter(withStyles(styles)(FreelancerProfileProfessionalInformation)));
