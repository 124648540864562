import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { Grid, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { Card, CardContent } from "@material-ui/core"
import logo from "./../../Assets/logo.png";
import { confirmEmail } from "../../Actions/authAction";
import ForgotPasswordMailFooter from '../../Components/ForgotPasswordMailFooter'
import IgButton from '../../Components/Form/IgButton';
import { history } from '../../Utils/history';


const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    backgroundColor: '#F7F9FB'
  },
  grid: {
    width: 1200,
    margin: `0 ${theme.spacing(2)}px`,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)"
    }
  },
  loadingState: {
    marginTop: theme.spacing(25),
  },
  card:{
    marginTop: theme.spacing(15),
    borderRadius: 10,
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)',

  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'none'
  }
});

class VerifiedMail extends Component {
  state = {
    loading: true,
    confirmation_token: '',
  };

  componentDidMount() {
    const url = window.location;
    const confirmation_token = new URLSearchParams(url.search).get('confirmation');
    this.setState({
      confirmation_token: confirmation_token,
    })
  }
componentWillMount() {
  const url = window.location;
  const confirmation_token = new URLSearchParams(url.search).get('confirmation');
  this.handleVerifiedMail(confirmation_token)
}
  handleVerifiedMail = (token) => {
    this.props.confirmEmail({
      token: token,
    });
  }

  render() {
    const { classes, auth, status, confirmEmailError } = this.props;
    const { isConfirmEmailLoading } = auth;

    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <Grid
            spacing={3}
            alignItems="center"
            justify="center"
            container
            alignContent='center'
          >
             {isConfirmEmailLoading ?
                        <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                          <CircularProgress size={70} className={classes.loadingState} />
                        </Grid> :
            <Grid item xs={12} md={6} style={{ margin: 30 }}>
              <Card className={classes.card}>
                <CardContent>
                  <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center', margin: 30 }}>
                    <img src={logo} alt="InsureGig Logo" className={classes.logo} />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography align='center' variant="h6" component="h6" gutterBottom style={{ paddingBlockStart: 15, fontWeight: 600, color: '#0C0033' }}>
                    Hey! Your email is verified.
                        </Typography>
                    <Typography align='center' variant="body1"  gutterBottom style={{ color: '#0C0033' }}>
                    You have successfully verified your email.<br />
                    Click on the button below and proceed.
                        </Typography>
                  </Grid>
                  <Grid container justify='center' alignContent='center' alignItems='center'>
                    <IgButton
                      style={{ fontWeight: 600 }}
                      type="submit"
                      color="primary"
                      variant="contained"
                      className={classes.submit}
                      onClick={() => history.push('/login')}
                      label='Login'
                    >
                        </IgButton>
                  </Grid>
                  {status && (<Grid style={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography style={{ color: 'green' }}>{status.status}</Typography>
                          </Grid>)}
                  {confirmEmailError && (<Grid style={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography style={{ color: 'red' }}>{confirmEmailError.error}</Typography>
                          </Grid>)}
                </CardContent>
              </Card>
              <ForgotPasswordMailFooter></ForgotPasswordMailFooter>
            </Grid>}
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { auth } = state;
  const { status, confirmEmailError } = auth;

  return ({
    auth: state.auth,
    confirmEmailError, status
  })

};

export default connect(
  mapStateToProps,
  { confirmEmail }
)(withRouter(withStyles(styles)(VerifiedMail)));
