import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Rating from '@material-ui/lab/Rating';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
let data =[]
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

export default function FeedbackList(props) {
  const classes = useStyles();
  data = props.data;
  return (
   <div>
     <h2 style={{textAlign:'center'}}>All Feedbacks</h2>
     <List className={classes.root}>
      {data.map(index=>{
        return (
          <ListItem alignItems="flex-start" key={index.id}>
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={index.user.image_url.url} />
        </ListItemAvatar>
        <ListItemText
          primary={(<Rating name="read-only" value={index.rating} readOnly />)}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                {index.user.first_name+" "+index.user.last_name+" -  "}
              </Typography>
              {index.feedback}
            </React.Fragment>
          }
        />
      </ListItem>
        )
      })}
    </List>
   </div>
  );
}
