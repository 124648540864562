import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Grid,
  Paper,
  Tooltip,
  IconButton,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Snackbar from "@material-ui/core/Snackbar";

import IgButton from "../../../Components/Form/IgButton";
import { getInterests } from "../../../Actions/userAction";
import {
  createUsersInterest,
  getUsersInterests,
  deleteUsersInterest,
} from "../../../Actions/freelancerAction";
import AlertComponent from "../../Components/AlertComponent/index";
import UpdateUsersInterest from "./UpdateUsersInterest";

import "./UsersInterest.css";
import Error from "../../Components/ErrorComponent/ErrorComponent";
import ErrorComponent from "../../Components/Error/ErrorComponent";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
      backgroundColor: "#F6F8FB",
      paddingLeft: 10,
      marginTop: -10,
    },
    paper: {
      padding: theme.spacing(3),
      color: theme.palette.text.secondary,
    },
    pageTitle: {
      paddingBottom: 10,
      paddingTop: 10,
    },
    InterestBox: {
      minHeight: 60,
    },
    radioColor: {
      color: "#1a237e",
    },
    iconSize: {
      fontSize: 18,
    },
    fullWidth: {
      width: "100%",
    },
  };
});

const UsersInterest = () => {
  const UsersInterestStyle = useStyles();

  const {
    allInterests,
    UserInterests,
    isPostUsersInterestLoading,
    isPutUsersInterestLoading,
    interestCreateError,
  } = useSelector((state) => {
    const { freelancer, user } = state;
    const { allInterests } = user;
    const {
      UserInterests,
      isPostUsersInterestLoading,
      isPutUsersInterestLoading,
      interestCreateError,
    } = freelancer;
    return {
      allInterests,
      UserInterests,
      isPostUsersInterestLoading,
      isPutUsersInterestLoading,
      interestCreateError,
    };
  });

  const [state, setState] = useState({
    UserInterests: UserInterests,
    interest_id: "",
    level: "Beginner",
    open: true,
    editDialog: "",
    dialog_id: "",
    interestCreateError: interestCreateError,
    change: "new",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInterests());
    dispatch(getUsersInterests());
  }, []);

  const handleInterest = (event, params) => {
    if (params) {
      const interest = allInterests.filter((index) => {
        return index.id === params.id;
      });
      if (interest.length > 0) {
        setState({ ...state, interest_id: interest[0].id });
      }
    }
  };

  const handleLevelChange = (event) => {
    setState({ ...state, level: event.target.value });
  };

  const handleCreateUsersInterest = (event) => {
    event.preventDefault();
    const { interest_id, level } = state;
    const data = {
      interest_id,
      level,
    };
    dispatch(createUsersInterest(data, handleResetForm));
  };

  const handleResetForm = () => {
    setState({
      ...state,
      interest_id: "",
      change: Math.random(),
      level: "Beginner",
    });
  };

  const handleOpenEditDialog = (id) => {
    setState({ ...state, editDialog: true, dialog_id: id });
  };

  const handleDelete = (id) => {
    dispatch(deleteUsersInterest(id));
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleEditDialog = () => {
    setState({ ...state, editDialog: false });
  };

  return (
    <div className={UsersInterestStyle.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" className={UsersInterestStyle.pageTitle}>
            Add Interests
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <form onSubmit={handleCreateUsersInterest}>
            <Paper className={UsersInterestStyle.paper}>
              <Grid container spacing={3}>
                <Grid item xs={10} lg={5} sm={10} md={4}>
                  {allInterests ? (
                    <Autocomplete
                      id="clear-on-escape"
                      disableClearable
                      clearOnEscape
                      key={state.change}
                      options={allInterests}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => handleInterest(event, value)}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          className={UsersInterestStyle.fullWidth}
                          label="Interests"
                        />
                      )}
                    />
                  ) : null}
                </Grid>

                <Grid item xs={10} lg={5} sm={10} md={6}>
                  <RadioGroup
                    row
                    aria-label="level"
                    name="level"
                    onChange={handleLevelChange}
                  >
                    <FormControlLabel
                      value="Beginner"
                      checked={state.level === "Beginner"}
                      control={
                        <Radio
                          className={UsersInterestStyle.radioColor}
                          color="primary"
                        />
                      }
                      label="Beginner"
                    />
                    <FormControlLabel
                      value="Intermediate"
                      checked={state.level === "Intermediate"}
                      control={
                        <Radio
                          className={UsersInterestStyle.radioColor}
                          color="primary"
                        />
                      }
                      label="Intermediate"
                    />
                    <FormControlLabel
                      value="Expert"
                      checked={state.level === "Expert"}
                      control={
                        <Radio
                          className={UsersInterestStyle.radioColor}
                          color="primary"
                        />
                      }
                      label="Expert"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={10} lg={2} sm={10} md={2}>
                  <IgButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    label="Add Interest"
                    loading={+isPostUsersInterestLoading}
                  />
                </Grid>
              </Grid>
            </Paper>
          </form>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" className={UsersInterestStyle.pageTitle}>
            Interests({UserInterests && UserInterests.length})
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} wrap="wrap">
        {UserInterests
          ? UserInterests.map((index) => (
              <Grid item xs={12} lg={4} xl={3} sm={6} md={4} key={index.id}>
                <Paper className={UsersInterestStyle.paper}>
                  <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={8} xl={9}>
                      <div className={UsersInterestStyle.InterestBox}>
                        <Typography variant="h5">
                          {index.interest.name}
                        </Typography>
                        <Typography variant="subtitle2">
                          {index.level}
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={4} xl={3}>
                      {isPutUsersInterestLoading &&
                      state.dialog_id === index.id ? (
                        <CircularProgress size={25} />
                      ) : (
                        <Tooltip title="Edit Interest" aria-label="edit" arrow>
                          <IconButton
                            aria-label="edit"
                            onClick={() => handleOpenEditDialog(index.id)}
                          >
                            <EditIcon className={UsersInterestStyle.iconSize} />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip
                        title="Delete Interest"
                        aria-label="delete"
                        arrow
                      >
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDelete(index.id)}
                        >
                          <DeleteIcon className={UsersInterestStyle.iconSize} />
                        </IconButton>
                      </Tooltip>
                      {state.editDialog && state.dialog_id === index.id && (
                        <UpdateUsersInterest
                          handleEditDialog={handleEditDialog}
                          data={index}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))
          : null}
      </Grid>

      <ErrorComponent
        error={interestCreateError}
        open={true}
        handleClose={handleClose}
      />
      {/* {interestCreateError ? 
      <Error
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={true}
            label={interestCreateError}
            autoHideDuration={2000}
            onClose={handleClose}
            severity="error"
          />
          : null} */}
    </div>
  );
};

export default UsersInterest;
