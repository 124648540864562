import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import IgButton from "../../../Components/Form/IgButton";

const people_search = require("../../../Assets/images/undraw_people_search.svg");
const wall = require("../../../Assets/images/undraw_wall.svg");

const styles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
      backgroundColor: "#F7F9FB",
    },
    grid: {
      width: 1200,
      [theme.breakpoints.down("sm")]: {
        width: "calc(100% - 20px)",
      },
    },
    gridTop: {
      width: 1200,
      [theme.breakpoints.down("sm")]: {
        width: "calc(100% - 20px)",
      },
    },
    paper: {
      padding: theme.spacing(3),
      textAlign: "left",
      backgroundColor: "#FFFFFF",
      color: theme.palette.text.secondary,
      borderRadius: 3,
      boxShadow:
        "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
    },
    loadingState: {
      opacity: 0.05,
    },
    loadingMessage: {
      position: "absolute",
      top: "40%",
      left: "40%",
    },
    mediaImage: {
      width: "238px",
      margin: "0 auto",
      marginBottom: "20px",
      display: "flex",
      justifyContent: "center",
    },

    submit: {
      margin: theme.spacing(3, 0, 2),
      textTransform: "none",
    },
    titleGrid: {
      marginTop: 90,
    },
    titleTypography: {
      color: "#000000",
      textAlign: "center",
    },
    wallImage: {
      height: "200px",
      width: "300px",
    },
    searchImage: {
      height: "220px",
      width: "300px",
    },
    postTitleTypography: {
      color: "#0C0033",
      textAlign: "center",
    },
    postJob: {
      display: "flex",
      justifyContent: "center",
    },
  };
});

const EmptyDashboardCompany = () => {
  const EmptyStyles = styles();
  const [state, setState] = useState({
    loading: true,
    amount: 15000,
    period: 3,
    start: 0,
    monthlyInterest: 0,
    totalInterest: 0,
    monthlyPayment: 0,
    totalPayment: 0,
    data: [],
  });

  const handleBrowsJob = () => {
    window.location.href = "/client-job-list";
  };

  const handleCreateJob = () => {
    window.location.href = "/client-job-list";
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={EmptyStyles.root}>
        <Grid container justify="center">
          <Grid
            spacing={3}
            alignItems="center"
            justify="center"
            container
            className={EmptyStyles.grid}
          >
            <Grid item xs={12} className={EmptyStyles.titleGrid}>
              <Typography
                variant="h5"
                gutterBottom
                className={EmptyStyles.titleTypography}
              >
                Looking to hire a freelancer? Start with posting a new job or
                explore talent.
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper className={EmptyStyles.paper}>
                <div>
                  <div className={EmptyStyles.mediaImage}>
                    <img
                      src={wall}
                      alt="InsureGig"
                      className={EmptyStyles.wallImage}
                    />
                  </div>
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={EmptyStyles.postTitleTypography}
                  >
                    Post a job in InsureGig website and find suitable candidate
                    for your requirements.
                  </Typography>

                  <div className={EmptyStyles.postJob}>
                    <IgButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      className={EmptyStyles.submit}
                      label="Post a job"
                      onClick={() => handleCreateJob()}
                    ></IgButton>
                  </div>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper className={EmptyStyles.paper}>
                <div>
                  <div className={EmptyStyles.mediaImage}>
                    <img
                      src={people_search}
                      alt="InsureGig"
                      className={EmptyStyles.searchImage}
                    />
                  </div>

                  <Typography
                    align="center"
                    variant="body1"
                    gutterBottom
                    className={EmptyStyles.postTitleTypography}
                  >
                    Browse through different talents in InsureGig website.
                  </Typography>
                  <div className={EmptyStyles.postJob}>
                    <IgButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      className={EmptyStyles.submit}
                      label="Browse Talent"
                      onClick={() => handleBrowsJob()}
                    ></IgButton>
                  </div>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EmptyDashboardCompany);
