import React from 'react';
import './UsersInterest.css';
import withStyles from "@material-ui/core/styles/withStyles";
import IgButton from "../../Components/Form/IgButton";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Tooltip, Button, IconButton, FormControlLabel, Radio, RadioGroup, Typography, DialogContentText, CircularProgress } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getInterests } from "../../Actions/userAction";
import { createUsersInterest, getUsersInterests, updateUsersInterest, deleteUsersInterest } from "../../Actions/freelancerAction";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#F6F8FB",
    paddingLeft: 10,
    marginTop: -10,
  },
  paper: {
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
  pageTitle: {
    paddingBottom: 10,
    paddingTop: 10,
  },
  InterestBox: {
    minHeight: 60,
  },

  });
function Alert(props) { return <MuiAlert elevation={6} variant="filled" {...props} /> }
class UsersInterests extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            UserInterests: this.props.UserInterests,
            Message: false,
            interest_id: "",
            level: "Beginner",
            dialog: false,
            open: false,
            editDialog: '',
            dialog_id: '',
            interestCreateError: "",
            change: "new",
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.UserInterests !== state.UserInterests) {
            return { UserInterests: props.UserInterests }
        }
        else {
            if (props.interestCreateError !== state.interestCreateError) {
                return { interestCreateError: props.interestCreateError, open: true }
            }
            else {
                return null;
            }
        }
    }
    handleClose = () => {
        this.setState({
            open: false
        })
    }
    componentDidMount() {
        this.props.getInterests();
        this.props.getUsersInterests()
    }

    handleLevelChange = (event) => {
        this.setState({
          level: event.target.value,
        });
      };
      handleInterest = (event, params) => {
        if(params){
          const { id } = params;
      const { allInterests } = this.props;
      let a = allInterests.filter((index) => {
        return index.id === id;
      });
      if (a.length > 0) {
        this.setState({ interest_id: a[0].id });
      }
        }
    };
      handleResetForm = () => {
        this.setState({
        interest_id: "",
        change: Math.random(),
        level: "Beginner",
        });
      };
    handleDelete = (id) => {
        this.props.deleteUsersInterest(id)
    }
    handlecreateUsersInterest = (event) => {
        event.preventDefault();
        const { interest_id, level } = this.state;
        const data = {
          interest_id,
          level,
        };
        // console.log("Before Submit", this.state);
        this.props.createUsersInterest(data, this.handleResetForm);
      };
      handleEditDialog = () => this.setState({ editDialog: false });
    render() {
        const { classes } = this.props;
        const { change, dialog_id } = this.state;
        let UserInterests = this.state.UserInterests;
        if (UserInterests) {
            UserInterests.forEach(element => {
                if (element.level === "Expert") {
                    element.tagcol = "col-blue"
                }
                else if (element.level === "Intermediate") {
                    element.tagcol = "col-green"
                }
                else {
                    element.tagcol = "col-yellow"
                }
            })
        }
        const { allInterests, interestCreateError , isPutUsersInterestLoading, isPostUsersInterestLoading } = this.props;
            return (
                <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                className={classes.pageTitle}
              >
                {" "}
                Add Interests
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12} sm={12} md={12}>
              <form onSubmit={this.handlecreateUsersInterest}>
                <Paper className={classes.paper}>
                  <Grid container spacing={3}>
                    <Grid item xs={10} lg={5} sm={10} md={4}>
                      {allInterests ? (
                        <Autocomplete
                          id="clear-on-escape"
                          disableClearable
                          clearOnEscape
                          key={change}
                          options={allInterests}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) =>
                            this.handleInterest(event, value)
                          }
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              style={{ width: "100%" }}
                              label="Interests"
                            />
                          )}
                        />
                      ) : null}
                    </Grid>
                    <Grid item xs={10} lg={5} sm={10} md={6}>
                      <RadioGroup
                        row
                        aria-label="level"
                        name="level"
                        onChange={this.handleLevelChange.bind(this)}
                      >
                        <FormControlLabel
                          value="Beginner"
                          checked={this.state.level === "Beginner"}
                          control={<Radio style={{ color: "#1a237e" }} />}
                          label="Beginner"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="Intermediate"
                          checked={this.state.level === "Intermediate"}
                          control={<Radio style={{ color: "#1a237e" }} />}
                          label="Intermediate"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="Expert"
                          checked={this.state.level === "Expert"}
                          control={<Radio style={{ color: "#1a237e" }} />}
                          label="Expert"
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={10} lg={2} sm={10} md={2}>
                      <IgButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        label="Add Interest"
                        loading={isPostUsersInterestLoading}
                        // iconname={AddIcon}
                      ></IgButton>
                    </Grid>
                  </Grid>
                </Paper>
              </form>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                className={classes.pageTitle}
              >
                Interests({UserInterests && UserInterests.length})
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3} wrap={true}>
            {UserInterests
              ? UserInterests.map((index) => (
                  <Grid item xs={12} lg={4} xl={3} sm={6} md={4}>
                    <Paper className={classes.paper}>
                      <Grid container>
                        <Grid xs={6} sm={6} md={6} lg={8} xl={9}>
                        <div className={classes.InterestBox}>
                          <Typography variant="h5">
                            {" "}
                            {index.interest.name}
                          </Typography>
                          <Typography variant="subtitle2">{index.level}</Typography>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={4} xl={3}>
                        {isPutUsersInterestLoading && dialog_id === index.id ? (
                      <CircularProgress size={25} />
                    ) : (
                          <Tooltip title="Edit Interest" aria-label="edit" arrow>
                            <IconButton
                              aria-label="edit"
                              onClick={() =>
                                this.setState({
                                  editDialog: true,
                                  dialog_id: index.id,
                                })
                              }
                            >
                              <EditIcon style={{ fontSize: 18 }} />
                            </IconButton>
                          </Tooltip>
                    )}
                          {this.state.editDialog &&
                            this.state.dialog_id === index.id && (
                              <UpdateUsersInterest
                                // eslint-disable-next-line
                                handleEditDialog={this.handleEditDialog}
                                isPutUsersInterestLoading={isPutUsersInterestLoading}
                                getUsersInterests={this.props.getUsersInterests}
                                ExperienceUpdateUsersInterestdialogState={
                                  this.state
                                }
                                data={index}
                                updateUsersInterest={this.props.updateUsersInterest}
                              />
                            )}
                          <Tooltip
                            title="Delete Interest"
                            aria-label="delete"
                            arrow
                          >
                            <IconButton
                              aria-label="delete"
                              onClick={() => this.handleDelete(index.id)}
                            >
                              <DeleteIcon style={{ fontSize: 18 }} />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                ))
              : null}
          </Grid>
          {interestCreateError ? <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.open} autoHideDuration={3000} onClose={this.handleClose}>
    <Alert severity="error">
        {interestCreateError}
    </Alert>
</Snackbar> : null}
        </div>
            );
    }
}
class UpdateUsersInterest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            id: this.props.data.id,
            interest_id: "",
            level: this.props.data.level
        }
    }
    handleClose = () => {
        this.setState({
            open: false
        })
        this.props.handleEditDialog();
    };
    handleClickOpen = () => {
        this.setState({
            open: true
        })
    }
    handleLevelChange = (event) => {
        this.setState({
            level: event.target.value
        })
    }
    handleUpdateUsersInterest = () => {
        this.props.updateUsersInterest({
            id: this.state.id,
            level: this.state.level,
        })
        this.props.handleEditDialog();
    }
    render() {
        return (
            <div style={{ minWidth: 'max-content' }}>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}>
          <DialogTitle id="form-dialog-title">Interest Level</DialogTitle>
                        <DialogContent>
                        <DialogContentText>
                            <RadioGroup aria-label="level" name="level" onChange={this.handleLevelChange.bind(this)} style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel value="Beginner" checked={this.state.level === "Beginner"} control={<Radio style={{ color: "#1a237e" }} />} label="Beginner" labelPlacement="end" />
                                <FormControlLabel value="Intermediate" checked={this.state.level === "Intermediate"} control={<Radio style={{ color: "#1a237e" }} />} label="Intermediate" labelPlacement="end" />
                                <FormControlLabel value="Expert" checked={this.state.level === "Expert"} control={<Radio style={{ color: "#1a237e" }} />} label="Expert" labelPlacement="end" />
                            </RadioGroup>
                            </DialogContentText>
                        </DialogContent>

                    <DialogActions style={{ display: 'flex'}}>
                        <Button onClick={this.handleClose}>Cancel</Button>
                        <Button variant="contained" color="primary" onClick={this.handleUpdateUsersInterest}>Update</Button>
                    </DialogActions>
                </Dialog>
            </div>
            )
    }
}
const mapStateToProps = (state) => {
    const { freelancer, user } = state
    const { allInterests } = user;
    const { error, UserInterests, Message, isPostUsersInterestLoading, isPutUsersInterestLoading, interestCreateError } = freelancer;
    return ({ freelancer, allInterests, error, UserInterests, Message, isPostUsersInterestLoading, isPutUsersInterestLoading, interestCreateError })
};
export default connect(mapStateToProps, { createUsersInterest, getInterests, getUsersInterests, updateUsersInterest, deleteUsersInterest })(withRouter(withStyles(styles)(UsersInterests)));