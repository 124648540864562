/* eslint-disable */
import React, { Component } from "react";
import './index.css';
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Grid, CircularProgress } from "@material-ui/core";
import IgButton from "../../Components/Form/IgButton";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import Topbar from "./../../Components/Topbar";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Upload, Icon, message } from 'antd';
import 'antd/dist/antd.css';
import { history } from "./../../Utils/history";
import { updateUser } from "./../../Actions/authAction";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) { return <MuiAlert elevation={6} variant="filled" {...props} /> }
const numeral = require("numeral");
numeral.defaultFormat("0,000");
const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#F7F9FB'
  },
  grid: {
    width: 1200,
    margin: `0 ${theme.spacing(2)}px`,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)"
    }
  },
  loadingState: {
    opacity: 0.05
  },
  paper1: {
    marginTop: theme.spacing(0.5),
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(3)
  },
  text: {
    marginTop: theme.spacing(2)
  },
  tab: {
    marginTop: 40,

  },
  tab1: {
    background: "#637381",
    color: "#FFFFFF",
    fontSize: 13,
    fontWeight: 600
  },
  tab2: {
    background: "#FFFFFF",
    color: "#212B36",
    fontSize: 13,
    fontWeight: 600
  },
  paper2: {
    maxWidth: 865,
    marginTop: theme.spacing(5),
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    alignItems: "center",
    padding: theme.spacing(3)
  },
  formheading: {
    marginTop: 10,
    color: "#0C0033",
    textAlign: "center"
  },
  form: {
    display: "flex",
    flexDirection: "row",
  },
  // formlabel: {
  //   color: "#0C0033",
  //   opacity: 0.5,
  //   lineheight: "16px"
  // },
  // frominput: {
  //   boxSizing: "border-box",
  // },
  cardlabel: {
    color: "#0C0033",
    fontSize: 18,
    textAlign: 'center'
  },
  card: {
    maxWidth: 330,
    boxSizing: "border-box",
    border: "2px dashed #C4CDD5",
    borderRadius: 3,
    backgroundColor: "#F9FAFB",
    marginTop: "10px"
  },
  mediaimage: {
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
  },
  carddescription: {
    marginTop: theme.spacing(0),
    color: "#212B36",

    textAlign: "center",
    padding: theme.spacing(4)
  },
  cardfoot: {
    marginTop: theme.spacing(0),
    color: "#919EAB",
    fontSize: 14,
    textAlign: "center",
    padding: theme.spacing(0)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'none',
  },
  profilePic:{
    float: 'center'
  }
});
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}
class FreelancerProfilePersonalInformation extends Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      imgLoading: false,
      image_url: "",
      id: "",
      open: false
    };
  }
  static getDerivedStateFromProps(props,state){
    if(props.error !== state.error){
        return { error: props.error, open: true}
    }
    else{
        return null;
    }
}
  componentDidMount() {
    const { auth } = this.props;
    const { user } = auth;
    const { user_id } = user;
    this.setState({
      id: user_id
    })
  }
  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ imgLoading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          imgLoading: false,
        }),
      );
    }
  };
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false })
  };
  render() {
    const { classes } = this.props;
    const {auth}=this.props
    const {isUpdateingLoading,error}= auth
    const currentPath = this.props.location.pathname;
    const percentage = 50;
    const uploadButton = (
      <div>
        <Icon type={this.state.imgLoading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const { imageUrl } = this.state;
    return (
      <React.Fragment>
        <Topbar currentPath={currentPath} />
        <div className={classes.root}>
          {/* <Paper className={classes.paper1}>
            <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }} xs={3} >
              <CircularProgressbar styles={{ root: { height: 105, width: 105 }, path: { stroke: '#23C0F5' } }} value={percentage} text={`${percentage}%`} />
            </Grid>
            <Grid item className={classes.text}>
              <Typography variant="h4" gutterBottom style={{ paddingLeft: 15, color: "#212B36" }}>
                Your Profile is Complete
              </Typography>
              <Typography variant="body1" gutterBottom style={{ paddingLeft: 15, color: "#637381"}}>
                Great Start {this.props.userName}! Please complete remaining steps!
              </Typography>
            </Grid>
          </Paper> */}
          <Grid container justify="center">
            <Grid
              spacing={3}
              alignItems="center"
              justify="center"
              container
              className={classes.grid}>
              <Grid container justify="center">
                <Paper className={classes.paper2}>
                  <Typography variant="h4" gutterBottom className={classes.formheading}>
                    Personal Information
                  </Typography>
                  <div>
                    <Formik
                      initialValues={{
                        image_url: ""
                      }}
                      enableReinitialize={true}
                      // validationSchema={userSchema}
                      onSubmit={(values,{resetForm}) => {
                        values.image_url = this.state.imageUrl;
                        values.user_id = this.state.id
                        this.props.updateUser(values);
                        resetForm()
                        history.push('/identity')
                      }}>
                      {() => (
                        <Form
                          className={classes.form}
                          noValidate
                          autoComplete="off">
                          <Grid container spacing={2} style={{display: 'flex', justifyContent:'center'}}>
                            <Grid item style={{display: 'flex',  flexDirection: 'column', justifyContent: 'center'}}>
                              <Typography variant="caption" gutterBottom className={classes.cardlabel}>
                                Upload Profile Photo
                              </Typography>
                             <div className="freelancer-profile">
                              <Upload
                                  name="avatar"
                                  listType="picture-card"
                                  className="avatar-uploader"
                                  showUploadList={false}
                                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                  beforeUpload={beforeUpload}
                                  onChange={this.handleChange}>
                                  {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                             </div>
                              <div style={{display: 'flex', flexDirection: 'column'}}>
                                <IgButton
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  className={classes.submit}
                                  label="Next"
                                  iconname={ArrowRightIcon}>
                                  {/* onClick={(event) => this.handleFreelancerProfileProfessionalInformation(event)}>  */}
                                </IgButton>
                                <IgButton
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  className={classes.submit}
                                  label="Skip for now"
                                  iconname={ArrowRightIcon}
                                  onClick={()=>history.push("/identity")}>
                                </IgButton>
                              </div>
                            { error &&(<Grid style={{ display:'flex', justifyContent:'center'}}>
                                                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.open} autoHideDuration={3000} onClose={this.handleClose}>
                                                  <Alert onClose={this.handleClose} severity="error">
                                                    {error.error}
                                                  </Alert>
                                                </Snackbar>
                                              </Grid>)}
                              <Grid style={{ display:'flex', justifyContent:'center', paddingBottom:10}}>
                            { isUpdateingLoading  &&
                            <CircularProgress circularprogress="true">
                            { !isUpdateingLoading &&
                            <CircularProgress circularprogress="false" />
                            }
                            </CircularProgress>}</Grid>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
FreelancerProfilePersonalInformation.propType = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  const { auth } = state
  const { error } = auth;
  const { userName } = state.auth;
  return { auth, error, userName }};
export default connect(mapStateToProps,{ updateUser })(withRouter(withStyles(styles)(FreelancerProfilePersonalInformation)));