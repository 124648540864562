import React, { useState, useEffect } from "react";
import JobInfo from "./JobInfo";
import { withRouter } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Grid, CircularProgress, Box, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import "react-circular-progressbar/dist/styles.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import DatePickerField from "../../../Components/Form/DatePickerField";
import UploadField from "../../../Components/Form/UploadField";
import IgButton from "../../../Components/Form/IgButton";
import moment from "moment";
import {
  createJobsApplication,
  clearQuestionState,
} from "../../../Actions/freelancerAction";
import { TextField, Divider } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import JobQuestions from "./JobQuestions";
import BrowseJobs from "./BrowseJobs";
import Error from "../ErrorComponent/ErrorComponent";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { history } from "../../../Utils/history";

const styles = makeStyles((theme) => {
  return {
    root: {
      margin: "10px",
    },
    paper2: {
      backgroundColor: "#ffffff",
      borderRadius: 5,
      alignItems: "center",
      padding: theme.spacing(2),
      width: "100%",
      marginTop: "-50px",
      marginRight: "-50px",
    },
    title: {
      paddingLeft: 15,
      color: "#212B36",
    },

    subtitle: {
      paddingLeft: 15,
      color: "#212B36",
    },

    chips: {
      borderRadius: 3,
      color: "#FFFFFF",
      marginLeft: 18,
      backgroundColor: "#0C0033",
      opacity: 0.5,
    },

    circularprogress: {
      marginTop: theme.spacing(15),
      padding: theme.spacing(3),
    },

    formheading: {
      marginTop: 10,
      color: "#0C0033",
      textAlign: "center",
    },
    heading: {
      marginTop: theme.spacing(2),
    },

    buttonBar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },

    submit: {
      margin: theme.spacing(3, 0, 2),
      textTransform: "none",
      variant: "contained",
      color: "primary",
    },
    boxLoading: {
      display: "flex",
      justifyContent: "center",
      marginTop: 200,
    },
    rootGrid: {
      display: "flex",
      alignItems: "center",
    },
    subGrid: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: 27,
    },
    typography: {
      color: "#0C0033",
      fontsize: "12px",
      fontweight: "600",
      marginTop: "5px",
    },
    radioGroup: {
      display: "flex",
      flexDirection: "row",
    },
    radioButton: {
      color: "#4DA9FF",
    },
    link: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      marginRight: 10,
      padding: "8px 15px",
      backgroundColor: "#bfbfbf",
      height: 36,
      borderRadius: 5,
      color: "white",
    },
    errorGrid: {
      display: "flex",
      justifyContent: "center",
    },
    rateGrid: {
      marginTop: 20,
      marginLeft: 0,
    },
    hex: {
      color: "#546E7A",
    },
    headContainer: {
      margin: "10px 5px",
      padding: "5px 6px",
    },
    divider: { margin: "5px 0px" },
  };
});

const ApplicationPage = (props) => {
  const dispatch = useDispatch();
  const ApplicationPageStyle = styles();
  let appliedJob = "";

  const {
    error,
    freelancer,
    jobs,
    jobApplication_Id,
    isPostJobsApplicationLoading,
    questions,
    jobId,
  } = useSelector((state) => {
    const { jobs, questions, jobId } = state.client;
    const { freelancer } = state;
    const { error, jobApplication_Id, isPostJobsApplicationLoading } =
      freelancer;
    return {
      error,
      freelancer,
      jobs,
      jobApplication_Id,
      isPostJobsApplicationLoading,
      questions,
      jobId,
    };
  });

  const [state, setState] = useState({
    loading: true,
    payment_type: "hourly",
    proposed_rate: "",
    D1: "",
    D2: "",
    D3: "",
    job_id: jobId ? jobId : props.jobId,
    expected_start_date: new Date(),
    company: "",
    title: "",
    job_type: "",
    attachment_url: "",
    open: false,
    error: "",
    jobApplicationId: "",
    jobData: "",
    browseJobRedirection: false,
    applied: "",
  });

  useEffect(() => {
    const { data } = props;
    setState({
      ...state,
      job_id: jobId,
    });
    let client = data.client;
    let { title } = data;
    let { job_type } = data;
    setState({
      ...state,
      company: client.name,
      title: title,
      job_type: job_type,
    });
  }, []);

  const handleOnChangeD = (event) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handlePaymentType = (event) => {
    setState({
      ...state,
      payment_type: event.target.value,
    });
  };
  const handleRate = (event) => {
    setState({
      ...state,
      proposed_rate: event.target.value,
    });
  };
  const handleDate = (val) => {
    setState({
      ...state,
      expected_start_date: val,
    });
  };
  const handleUrl = (val) => {
    setState({
      ...state,
      attachment_url: val,
    });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState({
      ...state,
      open: false,
    });
  };
  const handleJobInfoRedirection = (event) => {
    event && event.preventDefault();
    setState({
      ...state,
      browseJobRedirection: true,
      jobData: [props.data],
    });
  };

  const handleBrowseJobRedirection = (event) => {
    event.preventDefault();
    setState({
      ...state,
      jobData: [props.data],
    });
  };

  const handleJobApplication = () => {
    setState({
      ...state,
      jobApplicationId: jobApplication_Id,
      browseJobRedirection: false,
    });
  };

  const handleJobRedirection = (event) => {
    props.makeRedirection();
  };

  const clearMyState = () => {
    setState({
      ...state,
      job_id: null,
      jobApplicationId: null,
      browseJobRedirection: true,
    });
  };

  const { jobData, browseJobRedirection } = state;
  const { jobQuestions } = props;
  let url = state.attachment_url;

  return jobData && jobData ? (
    <>
      <BrowseJobs redirected={true} />
      <JobInfo Id={jobData.id} jobData={jobData} />
    </>
  ) : browseJobRedirection && browseJobRedirection ? (
    <JobInfo Id={jobData.id} jobData={jobData} />
  ) : jobApplication_Id && jobQuestions && jobQuestions.length > 0 ? (
    <JobQuestions
      jobId={jobId}
      jobApplicationId={jobApplication_Id}
      clearMyState={clearMyState}
      jobQuestions={jobQuestions}
      jobRedirection={handleBrowseJobRedirection}
    />
  ) : (
    <div className={ApplicationPageStyle.root}>
      <Formik
        initialValues={{
          expected_start_date: moment().toDate(),
          description: state.D1,
          description_1: state.D2,
          description_2: state.D3,
          payment_type: state.payment_type,
          proposed_rate: state.proposed_rate,
          attachment_url: "",
        }}
        enableReinitialize={true}
        onSubmit={(values, { resetForm }) => {
          const newValues = values;
          newValues.description = state.D1;
          newValues.description_1 = state.D2;
          newValues.description_2 = state.D3;
          newValues.job_id = state.job_id;
          newValues.payment_type = state.payment_type;
          newValues.proposed_rate = state.proposed_rate;
          newValues.attachment_url = url;
          newValues.expected_start_date = state.expected_start_date;
          dispatch(createJobsApplication(newValues));
          setTimeout(() =>{
            (jobQuestions && undefined === jobQuestions) ||
          jobQuestions.length === 0
            ? history.push("/applied-jobs")
            : handleJobApplication();
          })
        }}
      >
        {({ values, setFieldTouched, setFieldValue }) => (
          <Form className={ApplicationPageStyle.container} autoComplete="off">
            {isPostJobsApplicationLoading ? (
              <Box className={ApplicationPageStyle.boxLoading}>
                <CircularProgress size={70} />
              </Box>
            ) : (
              <Paper className={ApplicationPageStyle.paper2}>
                <Grid container className={ApplicationPageStyle.headContainer}>
                  <Link color="inherit" onClick={handleJobRedirection}>
                    {/* {state.title} */}
                    <ArrowBackIcon />
                  </Link>
                </Grid>
                <Divider className={ApplicationPageStyle.divider} />
                <Typography
                  variant="h4"
                  gutterBottom
                  className={
                    (ApplicationPageStyle.formheading,
                    ApplicationPageStyle.headContainer)
                  }
                >
                  Applying for the position of {state.title}
                </Typography>
                <Grid container className={ApplicationPageStyle.headContainer}>
                  <Typography
                    component="span"
                    variant="body2"
                    className={ApplicationPageStyle.hex}
                  >
                    @ {state.company}
                  </Typography>
                  <br />
                  <Typography
                    component="span"
                    variant="body2"
                    className={ApplicationPageStyle.hex}
                  >
                    Job Type: {state.job_type}
                  </Typography>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  className={ApplicationPageStyle.headContainer}
                >
                  <Grid item className={ApplicationPageStyle.heading}>
                    <DatePickerField
                      changeDate={handleDate}
                      selectedDate={state.expected_start_date}
                      label="Expected Starting Date"
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={ApplicationPageStyle.headContainer}
                >
                  <TextField
                    name="D1"
                    fullWidth
                    onChange={(e) => handleOnChangeD(e)}
                    label="Do you have relevent education for this job? Please explain"
                    multiline
                    rows={4}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={ApplicationPageStyle.headContainer}
                >
                  <TextField
                    name="D2"
                    fullWidth
                    onChange={(e) => handleOnChangeD(e)}
                    label="Do you have any experience for this job? Please explain"
                    multiline
                    rows={4}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={ApplicationPageStyle.headContainer}
                >
                  <TextField
                    name="D3"
                    fullWidth
                    onChange={(e) => handleOnChangeD(e)}
                    label="Any Comments"
                    multiline
                    rows={4}
                  />
                </Grid>

                <Grid
                  container
                  className={
                    (ApplicationPageStyle.rootGrid,
                    ApplicationPageStyle.headContainer)
                  }
                >
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography className={ApplicationPageStyle.typography}>
                      Payment Type
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <RadioGroup
                      aria-label="payment_type"
                      name="payment_type"
                      onChange={handlePaymentType}
                      className={ApplicationPageStyle.radioGroup}
                    >
                      <FormControlLabel
                        value="hourly"
                        checked={state.payment_type === "hourly"}
                        control={
                          <Radio className={ApplicationPageStyle.radioButton} />
                        }
                        label="Hourly"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="fixed"
                        checked={state.payment_type === "fixed"}
                        control={
                          <Radio className={ApplicationPageStyle.radioButton} />
                        }
                        label="Fixed"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className={
                    (ApplicationPageStyle.rootGrid,
                    ApplicationPageStyle.headContainer)
                  }
                >
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    className={ApplicationPageStyle.rateGrid}
                  >
                    <TextField
                      id="proposed_rate"
                      label="Rate"
                      variant="outlined"
                      placeholder="$500"
                      onChange={handleRate}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={
                    (ApplicationPageStyle.heading,
                    ApplicationPageStyle.headContainer)
                  }
                >
                  <Typography className={ApplicationPageStyle.cardlabel}>
                    Upload Your Resume (image or pdf only)
                  </Typography>
                  <UploadField
                    name="attachment_url"
                    id="attachment_url"
                    imageURL={handleUrl}
                  />
                </Grid>
                <div className={ApplicationPageStyle.buttonBar}>
                  <Link
                    color="inherit"
                    href="/browse-jobs"
                    className={ApplicationPageStyle.link}
                  >
                    Cancel
                  </Link>
                  <IgButton
                    type="submit"
                    className={ApplicationPageStyle.submit}
                    label="Apply"
                  ></IgButton>
                </div>
                {error && (
                  <Grid className={ApplicationPageStyle.errorGrid}>
                    <Error
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      open={state.open}
                      autoHideDuration={3000}
                      onClose={handleClose}
                      label={error}
                      severity="error"
                    />
                  </Grid>
                )}
              </Paper>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};
ApplicationPage.propType = {
  createJobsApplication: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default withRouter(ApplicationPage);
