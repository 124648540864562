/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { CardMedia, Card, Grid, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import IgButton from "../../../Components/Form/IgButton";
import Avatar from "@material-ui/core/Avatar";
import { history } from "../../../Utils/history";
import {
  getQuestions,
  getJobsSkills,
  getJobsEducations,
} from "../../../Actions/clientAction";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import BusinessIcon from "@material-ui/icons/Business";
import ApplicationPage from "./ApplicationPage";
import BrowseJobs from "./BrowseJobs";
import {
  getJobsApplications,
  clearQuestionState,
} from "../../../Actions/freelancerAction";
import { Empty } from "antd";
import renderHTML from "react-render-html";
import _ from "lodash";
import './JobInfo.css';

const styles = makeStyles((theme) => {
  return {
    jobTitlebox: {
      backgroundColor: "#FFFFFF",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      padding: 24,
      borderRadius: 5,
      height: "fit-content",
    },
    topContainer: { marginLeft: "35px", marginTop: "0px" },
    letter_img: {
      fontSize: "50px",
      color: "#ffffff",
      fontWeight: 600,
      textTransform: "capitalize",
    },
    job_info_text: {
      marginTop: 10,
    },
    name: {
      paddingLeft: theme.spacing(2),
      color: "#546E7A",
    },
    jobquestions: {
      backgroundColor: "#FFFFFF",
      boxShadow:
        "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
      padding: 15,
      borderRadius: 5,
      margin: "20px 0px",
      width: "100%",
      height: "fit-content",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      textTransform: "none",
      variant: "contained",
      color: "primary",
    },
    apply: {
      marginLeft: 15,
      backgroundColor: "#00D3A7",
      float: "right",
    },
    applied: {
      marginLeft: 15,
      float: "right",
    },
    title: {
      fontSize: 28,
      lineHeight: 1,
    },
    contact: {
      color: "#546E7A",
    },
    posted: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: "-5px",
    },
    avatar: {
      width: "25px",
      height: "25px",
      marginTop: 0,
    },
    skillsroot: {
      display: "flex",
      flexDirection: "row",
    },
    skillGrid: {
      width: "100%",
    },
    skillList: {
      backgroundColor: "#f5f5f5",
      borderRadius: 25,
      width: "fit-content",
      padding: "4px 8px",
      margin: "8px 5px",
    },
    urlTypography: {
      fontWeight: "bold",
      width: "90%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    buttonRoot: {
      display: "flex",
      justifyContent: "flex-end",
    },
    Divider: {
      marginLeft: 0,
      width: "100%",
    },
    questionsTypography: {
      textAlign: "center",
    },
    jobDescription: {
      fontWeight: "bold",
    },
    boxContainer: {
      width: "100%",
      margin: "8px 5px",
      padding: "5px 10px",
      position: "relative",
    },
    questionType: {
      color: "#546E7A",
    },
    questionText: {
      fontSize: "14px",
      fontWeight: "500",
      color: "#000000",
    },
    divider: { margin: "5px 0px" },
    less: {
      position: "absolute",
      top: "0px",
      right: "17px",
    },
    readmore: {
      overflowY: "scroll",
      height: "100px",
      marginTop: 5,
      fontSize: "14px",
    },
    readless: {
      height: "50px",
    },
    nodata: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    fitImage: {
      objectFit: "contain",
      height: "auto",
    },
    large: {
      border: "2px whitesmoke",
      height: "120px",
      width: "120px"
    }
  };
});

let data = {};

const JobInfo = (props) => {
  const dispatch = useDispatch();
  const JobInfoStyles = styles();

  const { Id } = props;

  const { questions, job_skills, job_educations, jobId, currentApplied_job } =
    useSelector((state) => {
      const { user, client, freelancer } = state;
      const { Rates } = user;
      const { questions, job_skills, job_educations, jobId } = client;
      const { error } = user;
      const { currentApplied_job } = freelancer;
      return {
        Rates,
        questions,
        error,
        job_skills,
        job_educations,
        jobId,
        currentApplied_job,
      };
    });

  const [state, setState] = useState({
    loading: true,
    rateLoaded: false,
    jobid: "",
    browseJobRedirection: false,
    applied: currentApplied_job,
  });

  useEffect(() => {
    dispatch(getQuestions(Id));
    dispatch(getJobsSkills(Id));
    dispatch(getJobsEducations(Id));
    dispatch(getJobsApplications());
    dispatch(clearQuestionState());
    setState({
      ...state,
      jobid: "",
    });
  }, [Id]);

  const handleApply = (job_id) => {
    setState({
      ...state,
      jobid: job_id,
    });
  };

  const makeRedirection = (event) => {
    setState({
      ...state,
      jobid: "",
    });
  };

  data = props.jobData[0];

  const { client, user, jobs_applications } = data;

  let candidature = jobs_applications.filter(
    (index) => index.user_id === localStorage.user_id
  );

  let buttonCondition = null,
    candidature_status = null;
  if (candidature[0]) {
    buttonCondition = candidature[0].user_id === localStorage.user_id;
    candidature_status = candidature[0].application_status;
  }

  const truncate = (text, max) => {
    const Text = text.trim().split(" ");
    const more = Text.length > max ? "..." : "";
    return Text.slice(0, max).join(" ") + more;
  };

  let max = 20;

  const [more, setMore] = useState({
    isMore: false,
    Id: "",
  });

  const handleMore = (id) => {
    setMore({
      ...more,
      isMore: true,
      Id: id,
    });
  };

  const handleLess = () => {
    setMore({
      ...more,
      isMore: false,
      size: false,
    });
  };

  const { jobid, browseJobRedirection } = state;

  return browseJobRedirection && browseJobRedirection ? (
    <BrowseJobs />
  ) : jobid ? (
    <ApplicationPage
      makeRedirection={makeRedirection}
      jobId={jobid}
      data={data}
      jobQuestions={questions}
    />
  ) : (
    <React.Fragment>
        <Grid className="job_details">
          <Grid container spacing={1} className={JobInfoStyles.jobTitlebox}>
            <Grid item 
            xs={2}
            sm={2}
            md={2}
            lg={2}
            >
              {/* {client.company_logo.url ? (
                <CardMedia
                  image={client.company_logo.url}
                  alt={user.first_name + " " + user.last_name}
                  
                  className="client_image"
                >
                </CardMedia>
              ) : (
                  <Typography className="letter_image">
                    {data.title[0]}
                  </Typography>
              )} */}
              {client.company_logo.url ? (
              <Avatar
                alt={user.first_name + " " + user.last_name}
                src={client.company_logo.url}
                className={JobInfoStyles.large}
                classes={{ 
                  img: JobInfoStyles.fitImage,
                }}
              >
                <BusinessIcon className={JobInfoStyles.businessicon} />
              </Avatar>
            ) : (
              <Avatar className={JobInfoStyles.large}>
                <Typography className={JobInfoStyles.letter_img}>
                  {data.title[0]}
                </Typography>
              </Avatar>
            )}
            </Grid>
            <Grid item xs={9} sm={9} md={9} lg={9} className={JobInfoStyles.topContainer}>
              <Typography
                variant="h5"
                gutterBottom
                className={JobInfoStyles.title}
              >
                {data.title}
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                className={JobInfoStyles.contact}
              >
                {client.name}
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                className={(JobInfoStyles.contact, JobInfoStyles.posted)}
              >
                Posted by: &nbsp;&nbsp;
                <Avatar
                  className={JobInfoStyles.avatar}
                  alt={user.first_name}
                  src={user.image_url.url}
                />
                &nbsp;&nbsp;{user.first_name} {user.last_name}
              </Typography>
            </Grid>
          </Grid>
          <Divider className={JobInfoStyles.divider} />
          <Grid container>
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={3}
              className={JobInfoStyles.boxContainer}
            >
              <Typography
                variant="body1"
                className={JobInfoStyles.jobDescription}
              >
                Job Posting Date
              </Typography>
              <Typography variant="body1">
                {new Intl.DateTimeFormat("en-GB", {
                  year: "numeric",
                  month: "long",
                  day: "2-digit",
                }).format(Date.parse(data.created_at))}
              </Typography>
            </Grid>

            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={4}
              className={JobInfoStyles.boxContainer}
            >
              <Typography
                variant="body1"
                className={JobInfoStyles.jobDescription}
              >
                Job Status
              </Typography>
              <Typography variant="body1">{data.job_status}</Typography>
            </Grid>

            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={4}
              className={JobInfoStyles.boxContainer}
            >
              <Typography
                variant="body1"
                className={JobInfoStyles.jobDescription}
              >
                Job Type
              </Typography>
              <Typography variant="body1">{data.job_type}</Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={JobInfoStyles.boxContainer}
          >
            <Typography
              variant="body1"
              className={JobInfoStyles.jobDescription}
            >
              Job Description
            </Typography>
            <Typography
              component="div"
              variant="body1"
              className={
                data.description.length > max
                  ? JobInfoStyles.readmore
                  : JobInfoStyles.readless
              }
            >
              {data.description ? (
                more.isMore &&
                more.Id === data.id &&
                data.description.length > max ? (
                  <>
                    <a className={JobInfoStyles.less} onClick={handleLess}>
                      Read Less
                    </a>
                    {renderHTML(data.description)}
                  </>
                ) : (
                  <>
                    {data.description.length < max ? (
                      renderHTML(data.description)
                    ) : (
                      <>
                        {renderHTML(truncate(data.description, max))}
                        <a onClick={() => handleMore(data.id)}>Read more</a>
                      </>
                    )}
                  </>
                )
              ) : (
                <Grid container className={JobInfoStyles.nodata}>
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 60,
                    }}
                    description={"No description"}
                  />
                </Grid>
              )}
              {/* <p dangerouslySetInnerHTML={{ __html: data.description }} /> */}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={JobInfoStyles.boxContainer}
          >
            {undefined !== job_skills && job_skills.length ? (
              <Grid container className={JobInfoStyles.skillsroot}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    variant="body1"
                    className={JobInfoStyles.jobDescription}
                  >
                    Skills required
                  </Typography>
                </Grid>
                <Grid
                  container
                  justify="space-between"
                  direction="row"
                  wrap="wrap"
                  className={JobInfoStyles.skillGrid}
                >
                  {job_skills.map((index, i) => {
                    return (
                      <Typography
                        variant="body1"
                        key={i}
                        className={JobInfoStyles.skillList}
                      >
                        {index.skill.name}
                      </Typography>
                    );
                  })}
                </Grid>
              </Grid>
            ) : null}
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={JobInfoStyles.boxContainer}
          >
            {undefined !== job_educations && job_educations.length ? (
              <Grid container className={JobInfoStyles.skillsroot}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    variant="body1"
                    className={JobInfoStyles.jobDescription}
                  >
                    Job Education required
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid
                    container
                    justify="space-between"
                    direction="row"
                    wrap="wrap"
                    className={JobInfoStyles.skillGrid}
                  >
                        <Typography
                          variant="body1"
                          className={JobInfoStyles.skillList}
                        >
                          {job_educations[0].education_type}
                        </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>

          <Grid container className={JobInfoStyles.boxContainer}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography
                variant="body1"
                className={JobInfoStyles.jobDescription}
              >
                Offered Rate
              </Typography>
              <Typography variant="body1">{data.rate_amount}</Typography>
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography
                variant="body1"
                className={JobInfoStyles.jobDescription}
              >
                Phone Number
              </Typography>
              <Typography variant="body1">{client.phone_number}</Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={JobInfoStyles.boxContainer}
            >
              <Typography
                variant="body1"
                className={JobInfoStyles.jobDescription}
              >
                Company Website
              </Typography>
              <Typography
                variant="body1"
                className={JobInfoStyles.urlTypography}
              >
                <a onClick={() => window.open(client.website_url, "_blank")}>
                  {client.website_url}
                </a>
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={JobInfoStyles.boxContainer}
            >
              <Typography
                variant="body1"
                className={JobInfoStyles.jobDescription}
              >
                Company LinkedIn Address
              </Typography>
              <Typography
                variant="body1"
                className={JobInfoStyles.urlTypography}
              >
                <a onClick={() => window.open(client.linked_url, "_blank")}>
                  {client.linked_url}
                </a>
              </Typography>
            </Grid>
          </Grid>
          <Divider className={JobInfoStyles.divider} />
          <Grid className={JobInfoStyles.buttonRoot}>
            {candidature_status === "completed" ? (
              <IgButton
                type="submit"
                variant="contained"
                color="primary"
                className={JobInfoStyles.submit}
                label="Feedback"
                onClick={() => {
                  history.push({
                    pathname: "./feedback",
                    state: { ratable_id: data.id, ratable_type: "Job" },
                  });
                }}
              ></IgButton>
            ) : null}
            <Grid className={JobInfoStyles.boxContainer}>
              {buttonCondition ? (
                <IgButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.push({ pathname: "./applied-jobs" });
                  }}
                  className={(JobInfoStyles.submit, JobInfoStyles.applied)}
                  label="Already Applied"
                  iconname={CheckCircleOutlineIcon}
                ></IgButton>
              ) : (
                <IgButton
                  variant="contained"
                  color="primary"
                  className={(JobInfoStyles.submit, JobInfoStyles.apply)}
                  label="Do you want to Apply?"
                  onClick={() => handleApply(data.id)}
                ></IgButton>
              )}
            </Grid>
          </Grid>
        </Grid>

      <Paper className={JobInfoStyles.jobquestions}>
        <Typography
          variant="h5"
          gutterBottom
          className={JobInfoStyles.questionsTypography}
        >
          Job questions to answer after applying the job.
        </Typography>
        {questions && questions.length > 0 ? (
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <List>
                {questions.map((index) => {
                  return (
                    <React.Fragment key={index.id}>
                      <ListItem alignItems="center">
                        <ListItemText
                          primary={
                            <Typography className={JobInfoStyles.questionText}>
                              Question: {index.question_text}
                            </Typography>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                className={JobInfoStyles.questionType}
                              >
                                {"Type: " + index.question_type}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider className={JobInfoStyles.Divider} />
                    </React.Fragment>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        ) : (
          <Grid container className={JobInfoStyles.nodata}>
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 60,
              }}
              description={"No Questions"}
            />
          </Grid>
        )}
      </Paper>
    </React.Fragment>
  );
};

JobInfo.propType = {
  getJobsApplication: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default withRouter(JobInfo);
