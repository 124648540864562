import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import IgButton from '../../Components/Form/IgButton'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Topbar from "./../../Components/Topbar";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { history } from "./../../Utils/history";


const numeral = require("numeral");
numeral.defaultFormat("0,000");

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#F7F9FB'

  },
  grid: {
    width: 1200,
    margin: `0 ${theme.spacing(2)}px`,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)"
    }
  },
  loadingState: {
    opacity: 0.05
  },
  paper1: {
    marginTop: theme.spacing(0.5),
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(3)
  },
  text: {
    marginTop: theme.spacing(2)
  },
  tab: {
    marginTop: 40,

  },

  tab1: {
    background: "#637381",
    color: "#FFFFFF",
    fontSize: 13,
    fontWeight: 600
  },

  tab2: {
    background: "#FFFFFF",
    color: "#212B36",
    fontSize: 13,
    fontWeight: 600
  },

  paper2: {
    marginTop: theme.spacing(2),
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    padding: theme.spacing(3)
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'none',
  }
});

class CompanyProfilePaymentInformation extends Component {
  state = {
    loading: false,

  };

	handleCompanyProfileCompanyInformation = () => {
    history.push('/company')
	}

	handleFinish = () => {
    history.push('./dashboard-client')
  }
  render() {
    const { classes } = this.props;
    const currentPath = this.props.location.pathname;
    const percentage = 50;
    return (
      <React.Fragment>
        <Topbar currentPath={currentPath} />
        <div className={classes.root}>
          <Paper className={classes.paper1}>
            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CircularProgressbar styles={{ root: { height: 105, width: 105 }, path: { stroke: '#23C0F5' } }} value={percentage} text={`${percentage}%`} />
            </Grid>
            <Grid item className={classes.text}>
              <Typography variant="h4" gutterBottom style={{ paddingLeft: 15, color: "#212B36" }}>
                Your Profile is Complete
                </Typography>
              <Typography variant="body1" gutterBottom style={{ paddingLeft: 15, color: "#637381" }}>
                Great Start {this.props.userName}! Please complete remaining steps!
                </Typography>
            </Grid>
          </Paper>
          <Grid container justify="center" style={{height:'66vh'}}>
            <Grid item md={6}
              spacing={3}
              alignItems="center"
              justify="center"
              container
              className={classes.grid}
            >

              <Paper className={classes.paper2} elevation={10}>
                <Typography variant="h4" gutterBottom className={classes.formheading}>
								Payment Information
								</Typography>

								<Grid style={{display: "flex", justifyContent: "space-between"}}>
								<IgButton
									type="submit"
									variant="contained"
									color="primary"
									className={classes.submit}
									onClick={() => this.handleCompanyProfileCompanyInformation()}
									startIcon={<ArrowLeftIcon />}
									label="Company Information"
								>
								</IgButton>

								<IgButton
										type="submit"
										variant="contained"
										color="primary"
										className={classes.submit}
										onClick={() => this.handleFinish()}
										iconname={ArrowRightIcon}
										label="Finish"
									>
									</IgButton>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

CompanyProfilePaymentInformation.propType = {
	loginUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired
};


const mapStateToProps = state => {
	const { client } = state
	const { error } = client;
	const {userName} = state.auth;
	return{
	  client,error,userName
	}
  };

export default connect(
	mapStateToProps,

)(withRouter(withStyles(styles)(CompanyProfilePaymentInformation)));
