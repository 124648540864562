import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import { updateUsersInterest } from "../../../Actions/freelancerAction";

const useStyles = makeStyles(() => {
  return {
    maxContent: {
      minWidth: "max-content",
    },
    displayFlex: {
      display: "flex",
    },
    flexDirectionRow: {
      flexDirection: "row",
    },
    radioColor: {
      color: "#1a237e",
    },
  };
});

const UpdateUsersInterest = (props) => {
  const UpdateUsersInterestStyle = useStyles();

  const [state, setState] = useState({
    open: true,
    id: props.data.id,
    level: props.data.level,
  });

  const dispatch = useDispatch();

  const handleClose = () => {
    setState({ ...state, open: false });
    props.handleEditDialog();
  };

  const handleLevelChange = (event) => {
    setState({ ...state, level: event.target.value });
  };

  const handleUpdateUsersInterest = () => {
    dispatch(
      updateUsersInterest({
        id: state.id,
        level: state.level,
      })
    );
    props.handleEditDialog();
  };

  return (
    <div className={UpdateUsersInterestStyle.maxContent}>
      <Dialog open={state.open} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">Interest Level</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <RadioGroup
              aria-label="level"
              name="level"
              onChange={handleLevelChange}
              className={`${UpdateUsersInterestStyle.displayFlex} ${UpdateUsersInterestStyle.flexDirectionRow}`}
            >
              <FormControlLabel
                value="Beginner"
                checked={state.level === "Beginner"}
                control={
                  <Radio
                    className={UpdateUsersInterestStyle.radioColor}
                    color="primary"
                  />
                }
                label="Beginner"
              />
              <FormControlLabel
                value="Intermediate"
                checked={state.level === "Intermediate"}
                control={
                  <Radio
                    className={UpdateUsersInterestStyle.radioColor}
                    color="primary"
                  />
                }
                label="Intermediate"
              />
              <FormControlLabel
                value="Expert"
                checked={state.level === "Expert"}
                control={
                  <Radio
                    className={UpdateUsersInterestStyle.radioColor}
                    color="primary"
                  />
                }
                label="Expert"
              />
            </RadioGroup>
          </DialogContentText>
        </DialogContent>

        <DialogActions className={UpdateUsersInterestStyle.displayFlex}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateUsersInterest}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UpdateUsersInterest;
