import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Topbar from "./../../Components/Topbar";
// import IgButton from "../../Components/Form/IgButton";
// import AfterLoginFooter from "../../Components/Form/AfterLoginFooter";
// import Avatar from '@material-ui/core/Avatar';

const numeral = require("numeral");
numeral.defaultFormat("0,000");

const styles = theme => ({
	root: {
		flexGrow: 1,
		backgroundColor: '#F6F8FB'

	},
	grid: {
		width: 1200,
		margin: `0 ${theme.spacing(2)}px`,
		[theme.breakpoints.down("sm")]: {
			width: "calc(100% - 20px)"
		}
	},

	paper1: {
		marginTop: theme.spacing(0.5),
		backgroundColor: "#FFFFFF",
		boxShadow: "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
		display: "flex",
		flexDirection: "row",
		padding: theme.spacing(3)
    },

    paper2: {
        marginTop: theme.spacing(10),
        background: "linear-gradient(135deg, #FFFFFF 0%, #F8F8F8 65.34%, #FFFFFF 100%)",	
        boxShadow: "16px 0 30px 0 rgba(0,0,0,0.02)",
		borderRadius: 5,
		alignItems: "center",
		padding: theme.spacing(2)
	},

    buttonBar: {
		display: "flex",
		justifyContent: "flex-end"
	},

	submit: {
		margin: theme.spacing(3, 0, 2),
		textTransform: 'none',
		variant: "contained",
		color: "primary",
	}
});

class Messages extends Component {
	state = {
		loading: true,
    
    };

    render() {
		const { classes } = this.props;
		const currentPath = this.props.location.pathname;

        return (
            <React.Fragment>
				<Topbar currentPath={currentPath} />
                <div className={classes.root}>
                <Paper className={classes.paper1}>
                <Grid item xs={10} style  ={{display: "flex", justifyContent: "center"}}>
                        <Typography variant="h5" style={{color: "#0C0033"}}>Messages</Typography>
                </Grid>
                </Paper>

                <Paper className={classes.paper2}>

                </Paper>
                </div>
                </React.Fragment>

                 );
            }
        }

        Messages.propType = {
            getMessages: PropTypes.func.isRequired,
            auth: PropTypes.object.isRequired,
            errors: PropTypes.object.isRequired
        };
        
        const mapStateToProps = state => {
        //     const { Jobs } = state.client
        //   const { freelancer } = state
        //   const { error } = freelancer;
        //  return ({
        //     freelancer,Jobs,
        //   error})
        };
        
        export default connect(
            mapStateToProps,
            // { createJobsApplication }
        )(withRouter(withStyles(styles)(Messages)));