import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import { TableBody, Table, Paper, TableCell, TableHead, TableRow } from "@material-ui/core";
import { connect } from "react-redux";
import { history } from "./../Utils/history";
function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    //eslint-disable-next-line
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

const styles = (theme => ({
  
  paper: {
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    borderRadius: 5,
    backgroundColor: "#FFFFFF",

  },
  table: {
    boxSizing: "border-box",
    border: "1px solid #E6E6E6",
  },
}));

class InsureTable extends Component {

  state = {
    value: 0,
    menuDrawer: false
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  mobileMenuOpen = event => {
    this.setState({ menuDrawer: true });
  };

  mobileMenuClose = event => {
    this.setState({ menuDrawer: false });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }


  handlePostJobClick = () => { };
  handleLoginClick = () => {
    history.push("/login");
  };
  handleRegisterClick = () => { };
  render() {
    const { classes ,data, columnNames } = this.props

    return (
      <div className={classes.root}>
        <Paper className={classes.paper} >
          <Table className={classes.table}>
            <TableHead>
              <TableRow style={{	backgroundColor: "#F7F9FB"}}>
                { columnNames && (columnNames.map((col,index) => {
                   return <TableCell key={uuidv4()} align="center">{col}</TableCell>
                }))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.map(row => (
                <TableRow key={uuidv4()}>
                  {row.map((dataCell, index) => {
                    return (<TableCell align={dataCell === 0 ? 'left' : 'center'} component="th" scope="row" key={uuidv4()}>
                    {dataCell}
                  </TableCell>)
                  })}

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}



const mapStateToProp = state => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProp)(withRouter(withStyles(styles)(InsureTable)));
