import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { Grid, Card } from "@material-ui/core";
import FeatureCard from "../../../Components/FeatureCard";
import Topbar from "../../../v2/Pages/Topbar/Topbar";
import { history } from "../../../Utils/history";
import BeforeLoginFooter from "../../../Components/BeforeLoginFooter";
import IgButton from "../../../Components/Form/IgButton";
import ReactPlayer from "react-player";
import { createContactUs } from "../../../Actions/contactUsAction";
import ContactUsForm from "../ContactUsForm/ContactUsForm";


const people_search = require("../../../Assets/images/undraw_people_search.svg");

// Assets
const Home = require("../../../Assets/Landing/home.svg");
const Feature1 = require("../../../Assets/Landing/icon-home-1.svg");
const Feature2 = require("../../../Assets/Landing/icon-home-2.svg");
const Feature3 = require("../../../Assets/Landing/icon-home-3.svg");
const startFreelancer = require("../../../Assets/Landing/freelancers.svg");
const startCompany = require("../../../Assets/Landing/companies.svg");

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: "#F6F8FB",
	},

	topBanner: {
		minHeight: "600px",
		overflow: "hidden",
		background: `url(${Home}) no-repeat`,
		backgroundSize: "contain",
		backgroundPosition: "center bottom",
		backgroundColor: "#C4DCFC",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",

	},

	bannercontent: {
		marginTop: theme.spacing(25),
		padding: theme.spacing(0, 3),

	},

	bannervideo: {
		marginTop: theme.spacing(10),
		display: "flex",
		justifyContent: "center",
		padding: 20,
	},

	howBlockheading: {
		marginTop: theme.spacing(10),
		color: "#0C0033",
		textAlign: "center",
	},

	howBlock: {
		marginTop: theme.spacing(5),
		minHeight: "450px",
		backgroundColor: "#F7F9FB",
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "center",

	},
	quote: {
		marginTop: theme.spacing(2),
		textAlign: "center",
	},

	quoteAuthor: {
		marginTop: theme.spacing(3),
		textAlign: "center",
		color: "#4AD5F6",
	},

	quoteJob: {
		height: 42,
		textAlign: "center",
	},

	jobBlock: {
		marginTop: theme.spacing(3),
		backgroundColor: "white",
		padding: "100px 0px",
		textAlign: "center",
	},

	jobcontent: {

	},

	jobheading: {
		color: "#1C1C1C",
		textTransform: "uppercase",
	},

	jobtext: {
		color: "#545454",
		letterpacing: 0.75,
	},
	jobimage: {
		"& img": {
			width: 300,
			height: "auto",
		},
	},

	getStarted: {
		marginTop: theme.spacing(10),
		backgroundColor: "#F7F9FB",
	},

	card: {
		padding: 50,
		// marginBottom: "auto ",
		borderRadius: 10,
		backgroundColor: '#FFFFFF',
		maxHeight: 600,
		boxShadow: '0 8px 20px 0 rgba(224,224,224,0.5)',
	},

	cardheading: {
		color: "#333333",
		letterSpacing: 1.05,
		textAlign: "center",
		fontWeight: 500,
	},

	getStartedimage: {
		display: "flex",
		justifyContent: "center",
		padding: 25,
		margin: 20,
	},

	cardlist: {
		display: "flex",
		justifyContent: "center",
		padding: 20,
		"& ul": {
			listStyleType: "none",
			textAlign: "center",
			" & li": {
				fontSize: 16,
				lineHeight: 1.5,
			},
		},
	},

	helpBlock: {
		marginTop: theme.spacing(2),
	},

	helpcard: {
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		alignItems: "center",
		padding: 50,
		// marginBottom: "auto ",
		borderRadius: 10,
		backgroundColor: '#FFFFFF',
		boxShadow: '0 8px 20px 0 rgba(224,224,224,0.5)',
	},
	helpheading: {
		marginTop: theme.spacing(2),
		color: "#454F5B",
		textAlign: "center",
		fontWeight: 500,
	},

	helptext: {
		marginTop: theme.spacing(2),
		color: "#454F5B",
		textAlign: "center",
	},

	helplink: {
		marginTop: theme.spacing(2),
		color: "#007ACE",
		fontSize: 18,
		textAlign: "center",
		display: "flex",
		justifyContent: "center",
	},
	needHelp: {
		padding: 100,
	},



	submit: {
		margin: theme.spacing(3, 0, 2),
		textTransform: "none",
	},
});

const Landing=(props) => {
    // const [state,setState]=useState({
	// 	learnMoreDialog: false,
	// 	getStartedDialog: false,
	// 	message: ""
	// })


	//  const handleChange = (event, value) => {
	// 	setState({ value });
	// };

	//  const mobileMenuOpen = (event) => {
	// 	setState({ menuDrawer: true });
	// };

	//  const mobileMenuClose = (event) => {
	// 	setState({ menuDrawer: false });
	// };


     useEffect(() => {
        window.scrollTo(0, 0);
    })

	//  const openDialog = (event) => {
	// 	setState({ learnMoredialog: true });
	// };

	//  const dialogClose = (event) => {
	// 	setState({ learnMoredialog: false });
	// };

	//  const openGetStartedDialog = (event) => {
	// 	setState({ getStartedDialog: true });
	// };

	//  const closeGetStartedDialog = (event) => {
	// 	setState({ getStartedDialog: false });
	// };

	 const handleRegisterASAgencyClick = () => {
		history.push({ pathname: "/registerorganisation", state: { value: 1 } });
	};

	 const handleRegisterAsFreelancerClick = () => {
		history.push({ pathname: "/register", state: { value: 0 } });
	};
	//  const handleOnChangeMessage = (text) => {
	// 	setState({ message: text });
	// };
	
		const { classes } = props;
		// console.log("Application Env is", process.env);
		return (
			<React.Fragment>
				<CssBaseline />
				<Topbar />
				<div className={classes.root}>
					<div className={classes.topBanner}>
						<Grid container spacing={3} justify='center'>
							<Grid item xs={8} lg={4} sm={8} md={8} className={classes.bannercontent}>
								<Typography variant="h3" component="h1" gutterBottom>
									Share The Talent
								</Typography>
								<Typography variant="body1" gutterBottom>
									InsureGig provides insurance companies with a pool of
									<br />
									experienced insurance professionals who can be hired on a
									<br />
									freelance and contract basis.
								</Typography>
								<IgButton
									type="submit"
									color="primary"
									variant="contained"
									className={classes.submit}
									onClick={() =>handleRegisterAsFreelancerClick()}
									label="Create Account"
								></IgButton>
							</Grid>
							<Grid item xs={10} lg={4} sm={8} md={8} className={classes.bannervideo}>
								<ReactPlayer url="https://www.youtube.com/watch?v=1byvLVkKw2A"/>
							</Grid>
						</Grid>
					</div>
					<Grid container>
					<Grid item xs={10} lg={12} sm={10} md={10}>
					<Typography
						variant="h3"
						gutterBottom
						className={classes.howBlockheading}
					>
						How It Works
					</Typography>
					</Grid>
					</Grid>

					<Grid container spacing={3} className={classes.howBlock}>
						<Grid item xs={10} lg={3} sm={8} md={8}>
							<FeatureCard
								name="Create Profiles"
								image={Feature1}
								description="Both Companies and Professionals create their profiles with our easy to use onboarding process."
							/>
						</Grid>
						<Grid item xs={10} lg={3} sm={8} md={8}>
							<FeatureCard
								name="Post Jobs & Apply to Jobs"
								image={Feature2}
								description="Companies get to post jobs and select freelancers who apply to the particular job."
							/>
						</Grid>
						<Grid item xs={10} lg={3} sm={8} md={8}>
							<FeatureCard
								name="Complete Job"
								image={Feature3}
								description="When job is complete as agreed upon payments are processed on the platform."
							/>
						</Grid>
					</Grid>

					{/* <Grid container spacing={3} justify="center">
]						<Grid item xs={12} md={6}>
							<Card className={classes.card}>
								<Typography variant="h5" className={classes.quote}>
									InsureGig has created an avenue for Insurance companies to
									have access to experienced professionals through
									thisnon-traditional ever growing labour market.
								</Typography>
								<Typography className={classes.quoteAuthor}>
									Valeria Boltneva
								</Typography>
								<Typography className={classes.quoteJob}>
									Director of Adrian Stefan.Inc
								</Typography>
							</Card>
						</Grid>
					</Grid> */}
					{/* <Card className={classes.jobBlock}>
						<Grid container spacing={3} justify="space-evenly">
							<Grid item>
								<div className={classes.jobimage}>
									<img src={FreelancerFeature} alt="Freelancer Feature" />
								</div>
							</Grid>
							<Grid item className={classes.jobcontent}>
								<Typography
									variant="h4"
									gutterBottom
									className={classes.jobheading}
								>
									Be the Talent
								</Typography>
								<Typography
									variant="body1"
									gutterBottom
									className={classes.jobtext}
								>
									Build your profile to create endless opportunities while
									<br />
									having access to available projects that fit your
									<br />
									expertise.
								</Typography>

								<IgButton
									type="submit"
									color="primary"
									variant="contained"
									className={classes.submit}
									onClick={() => this.handleRegisterAsFreelancerClick()}
									label="Join as Professional"
								></IgButton>
							</Grid>
						</Grid>
					</Card> */}

					<Card className={classes.jobBlock}>
						<Grid container spacing={2} justify="space-evenly">
							<Grid item xs={10} lg={5} sm={10} md={10}>
								<Typography
									variant="h4"
									gutterBottom
									className={classes.jobheading}
								>
									HIRE the Talent
								</Typography>
								<Typography
									variant="body1"
									gutterBottom
								>
									Choose the best from the pool of freelancers
									<br />
									based on the requied expertise that matches your
									<br />
									project.
								</Typography>

								<IgButton
									type="submit"
									color="primary"
									variant="contained"
									className={classes.submit}
									onClick={() =>handleRegisterASAgencyClick()}
									label="Join as an Organization"
								></IgButton>
							</Grid>
							<Grid item xs={10} lg={5} sm={10} md={10}>
								<div className={classes.jobimage}>
									<img
										src={people_search}
										alt="Hire Freelancers"
									/>
								</div>
							</Grid>
						</Grid>
					</Card>

					<Typography
						variant="h3"
						gutterBottom
						className={classes.howBlockheading}
					>
						{" "}
						Sign Up For Our Beta
					</Typography>

					<Grid
						container
						spacing={3}
						className={classes.getStarted}
						justify="center"
					>
						<Grid item xs={10} lg={4} sm={5} md={5}>
							<Card className={classes.card}>
								<Typography
									variant="h5"
									gutterBottom
									className={classes.cardheading}
								>
									{/* <img src={logo} alt="InsureGig Logo" className={classes.logo} />  */}
									For Companies
								</Typography>
								<div className={classes.getStartedimage}>
									<img src={startCompany} alt="InsureGig" />
								</div>

								<div className={classes.cardlist}>
									<ul>
										<li>Access to experienced professional</li>
										<li>Access to a pool of global professionals</li>
										<li>Affordable option with limited overhead costs</li>
									</ul>
								</div>
								<div style={{ display: "flex", justifyContent: "center" }}>
									<IgButton
										type="submit"
										color="primary"
										variant="contained"
										className={classes.submit}
										onClick={() =>handleRegisterASAgencyClick()}
										label="Sign Up As Company"
									/>
								</div>
							</Card>
						</Grid>

						<Grid item xs={10} lg={4} sm={5} md={5}>
							<Card className={classes.card}>
								<Typography
									variant="h5"
									gutterBottom
									className={classes.cardheading}
								>
									{/* <img src={logo} alt="InsureGig Logo" className={classes.logo} />  */}
									For Freelancers
								</Typography>
								<div className={classes.getStartedimage}>
									<img src={startFreelancer} alt="InsureGig" />
								</div>

								<div className={classes.cardlist}>
									<ul>
										<li>Work remotely</li>
										<li>Opportunity to earn desired income</li>
										<li>Flexibility and freedom to work from any location</li>
									</ul>
								</div>
								<div style={{ display: "flex", justifyContent: "center" }}>
									<IgButton
										type="submit"
										color="primary"
										variant="contained"
										className={classes.submit}
										onClick={() =>handleRegisterAsFreelancerClick()}
										label="Sign Up as Professional"
									/>
								</div>
							</Card>
						</Grid>
					</Grid>

					<Grid
						container
						spacing={3}
						className={classes.getStarted}
						justify="center"
					>

						<Card className={classes.card}>
							<Grid
								container
								spacing={3}
								justify="center"
							>
								<Grid item xs={11} lg={4} sm={12} md={5} >
									<div 
									// className={classes.needHelp}
									>
										<Typography
											variant="h5"
											gutterBottom
											className={classes.helpheading}
										>
											Need Help?
								</Typography>
										<Typography
											variant="body1"
											gutterBottom
											className={classes.helptext}
										>
											Contact our Customer Support that is always ready to help you
											with any possible questions, problems or information.
								</Typography>

										<a href="https://insuregig.com/" className={classes.helplink}>
											support@insuregig.com
								</a>
									</div>
								</Grid>
								<Grid item xs={11} lg={4} sm={5} md={5}>
									<ContactUsForm />
								</Grid>
							</Grid>

						</Card>
					</Grid>
					<div style={{ marginTop: 100 }}>
						<BeforeLoginFooter />
					</div>
				</div>
			</React.Fragment>
		);
	}

const mapStateToProps = state => {
	const { contactUs } = state;
	const { isPostContactUsLoading } = contactUs;
	return {
		contactUs,
		isPostContactUsLoading,
		error: state.errors,
	};
};

export default connect(mapStateToProps, { createContactUs })(
	withRouter(withStyles(styles)(Landing))
);
