import { Snackbar } from "@material-ui/core";
import React from "react";
import AlertComponent from "../AlertComponent";

const ErrorComponent = (props) => {
  const { error, open, handleClose } = props;
  return (
    <>
      {error ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <AlertComponent severity="error">{error}</AlertComponent>
        </Snackbar>
      ) : null}
    </>
  );
};

export default ErrorComponent;
