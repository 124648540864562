/*eslint-disable*/
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Container from "@material-ui/core/Container";
import { getDashboardClient } from "../../../Actions/clientAction";
import { getUsersProfiles } from "../../../Actions/userAction";
import DashboardWidget from "../../../Components/Form/DashboardWidget";
import { Grid, Typography, Box, makeStyles, CircularProgress } from "@material-ui/core";
import { CircularProgressbar } from "react-circular-progressbar";
import EmptyDashboardCompany from "./EmptyDashboardCompany";
import { history } from "../../../Utils/history";

const styles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
      backgroundColor: "#F7F9FB",
    },
    grid: {
      width: 1200,
      margin: `0 ${theme.spacing(2)}px`,
      [theme.breakpoints.down("sm")]: {
        width: "calc(100% - 20px)",
      },
    },
    loadingState: {
      opacity: 0.05,
    },
    heading: {
      color: "#0C0033",
    },
    rootbox: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    innerbox: {
      display: "flex",
      flexDirection: "row",
      margin: 20,
      padding: 10,
      backgroundColor: "rgb(232, 243, 252)",
    },
    dashboardtitle: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: 15,
      justifyContent: "center",
      position:"relative"
    },
    widgetGrid: {
      margin: "18px 0",
    },
    emptydashboard: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: 20,
    },
    loading : {
      marginLeft : "5px",
    }
  };
});

const ClientDashboard = () => {
  const dispatch = useDispatch();
  const clientStyles = styles();

  const {
    draft_jobs,
    open_jobs,
    client_dashboard,
    percentage,
    first_name,
    usersProfile,
    isGetAllUsersProfilesLoading
  } = useSelector((state) => {
    const { auth, user } = state;
    const { usersProfile,isGetAllUsersProfilesLoading} = user;
    const { first_name } = auth;
    const { percentage } = usersProfile || "";
    const { client_dashboard, client_dashboard_error } = state.client;
    let { draft_jobs, jobs_applications, open_jobs } = client_dashboard || {};
    undefined !== open_jobs &&
      open_jobs.length &&
      open_jobs.forEach((job) => {
        let allApplications = jobs_applications.filter((index) => {
          return index.job_id === job.id;
        });
        let application_count = allApplications.length;
        job.application_count = application_count;
      });

    return {
      draft_jobs,
      jobs_applications,
      open_jobs,
      client_dashboard_error,
      percentage,
      first_name,
      usersProfile,
      client_dashboard,
      isGetAllUsersProfilesLoading
    };
  });

  let userName = usersProfile.first_name;

  useEffect(() => {
    dispatch(getUsersProfiles());
    dispatch(getDashboardClient());
  },[]);

  const handleApplications = () => {
    history.push("./client-job-list");
  };

  return undefined !== open_jobs && open_jobs.length ? (
    <Box className={clientStyles.rootbox}>
      {percentage && percentage !== 100 && undefined !== percentage ? (
        <Box className={clientStyles.innerbox}>
          <CircularProgressbar
            styles={{
              root: { height: 105, width: 105 },
              path: { stroke: "#23C0F5" },
            }}
            value={percentage}
            text={`${percentage}%`}
          />
          <Box className={clientStyles.dashboardtitle}>
            <Grid container spacing={1}>
              <Grid item>
              Great Start —
              </Grid>
              <Grid item>
              {(isGetAllUsersProfilesLoading) ?
                <CircularProgress className={clientStyles.loading} size={20} />
              :
              <strong>{(first_name)? first_name : userName}!</strong>
              } 
              </Grid>
            </Grid>
          </Box>      
        </Box>
      ) : null}
      <Grid
        container
        spacing={1}
        justify="space-evenly"
        direction="row"
        wrap="wrap"
        className={clientStyles.widgetGrid}
      >
        {undefined !== draft_jobs && draft_jobs.length
          ? draft_jobs.map((draft_job) => {
              return (
                <DashboardWidget
                  key={draft_job.id}
                  statusLineText="Draft Job"
                  hourlyPrice={draft_job.rate_amount}
                  jobTitle={draft_job.title}
                  date={draft_job.created_at}
                />
              );
            })
          : null}
        {undefined !== open_jobs && open_jobs.length
          ? open_jobs.map((open_job) => {
              return (
                <DashboardWidget
                  key={open_job.id}
                  statusLineText="Open Job"
                  hourlyPrice={open_job.rate_amount}
                  payment={open_job.job_type}
                  jobTitle={open_job.title}
                  date={open_job.created_at}
                  numberOfBids={open_job.application_count}
                  text="Applications"
                  handleOnclickmethod={handleApplications}
                />
              );
            })
          : null}
      </Grid>
    </Box>
  ) : (
    <React.Fragment>
      <Container style={{ height: "83vh" }}>
        <Box className={clientStyles.rootbox}>
          {percentage !== 100 && undefined !== percentage ?  (
            <Box className={clientStyles.innerbox}>
              <CircularProgressbar
                styles={{
                  root: { height: 105, width: 105 },
                  path: { stroke: "#23C0F5" },
                }}
                value={percentage}
                text={`${percentage}%`}
              />
              <Box className={clientStyles.dashboardtitle}>
                <Typography>
                  Great Start — <strong>{first_name}!</strong>{" "} 
                </Typography>
              </Box>
            </Box>
          ) : null}
        </Box>
        <Box className={clientStyles.emptydashboard}>
          <EmptyDashboardCompany />
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(ClientDashboard);
