import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IgButton from "../../Components/Form/IgButton";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Topbar from "./../../Components/Topbar";
import { ListItemText, CircularProgress, TextField } from "@material-ui/core";
import "react-circular-progressbar/dist/styles.css";

// import Rating from '@material-ui/lab/Rating';
import IgChips from "../../Components/IgChips";
import { createRate } from "../../Actions/userAction";

// const freelancerId = "3c49cfd4-e606-4f1e-9843-e563853616ca";
// const jobId = "3a41d820-63be-49e8-a013-7dfaf59d87d7";
// let auth = {};
const numeral = require("numeral");
numeral.defaultFormat("0,000");

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#F7F9FB",
  },
  grid: {
    width: 1200,
    margin: `0 ${theme.spacing(2)}px`,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)",
    },
  },

  paper: {
    maxWidth: 865,
    alignItems: "center",
    marginTop: theme.spacing(4),
    borderRadius: 5,
    backgroundColor: "#FFFFFF",
    padding: theme.spacing(3),
  },

  chips: {
    borderRadius: "0",
    color: "#FFFFFF",
    margin: 2,
    backgroundColor: "#0C0033",
    opacity: 0.5,
  },

  label: {
    textAlign: "center",
    color: "#0C0033",
  },

  form: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },

  buttonBar: {
    display: "flex",
    justifyContent: "flex-end",
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: "none",
    type: "submit",
    variant: "contained",
    color: "primary",
  },
});

// const userSchema = Yup.object().shape({
//   email: Yup.string()
//     .email("Invalid email address")
//     .required("Required"),
//   password: Yup.string().required("Required")
// });

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      feedback: "",
      rating: "",
      ratable_id: this.props.location.state.ratable_id,
      ratable_type: this.props.location.state.ratable_type,
      user_id: "",
    };

    this.handleFeedback = this.handleFeedback.bind(this);
  }
  handleFeedback = (event) => {
    const value = event.target.value;
    this.setState({
      text: value,
    });
  };
  handleRating = (e) => {
    this.setState({
      rating: e.target.value,
    });
  };
  handleEditorChange = (text) => {
    this.setState({ feedback: text });
  };
  handleSubmit = () => {
    let userData = {
      feedback: this.state.feedback,
      rating: this.state.rating,
      ratable_id: this.state.ratable_id,
      ratable_type: this.state.ratable_type,
      user_id: this.state.user_id,
    };
    this.props.createRate(userData);
  };
  componentDidMount() {
    const { user } = this.props;
    this.setState({
      user_id: user.user_id,
    });
  }
  render() {
    const { classes } = this.props;
    const { isPostRateLoading, error } = this.props;
    const currentPath = this.props.location.pathname;
    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar currentPath={currentPath} />
        <div className={classes.root}>
          <Grid container justify="center">
            <Grid
              spacing={3}
              alignItems="center"
              justify="center"
              container
              className={classes.grid}
            >
              <Paper className={classes.paper}>
                <Typography variant="h4" gutterBottom className={classes.label}>
                  {this.props.location.state.ratable_type} Feedback
                </Typography>

                <Grid
                  container
                  spacing={2}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ color: "#0C0033" }}
                    >
                      Job Title: {this.props.location.state.job_title}
                    </Typography>
                    {/* <Typography variant="body2" gutterBottom style={{ color: "#212B36"}}>
                      Company Name
                  </Typography> */}
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <IgChips name="category1" className={classes.chips} />
                      <IgChips name="category2" className={classes.chips} />
                    </div>
                    {/* <Typography variant="body2" gutterBottom>Completed Date: </Typography> */}
                  </Grid>

                  <Grid
                    item
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Avatar
                      alt={this.props.location.state.name}
                      src={this.props.location.state.avatar}
                      style={{ marginBottom: 15, marginRight: 10 }}
                    />

                    <ListItemText
                      style={{ color: "#0C0033", overflow: "hidden" }}
                      primary={this.props.location.state.name}
                    ></ListItemText>
                  </Grid>
                </Grid>

          

                <Grid item xs={12}>
                  <TextField
                    name="feedback"
                    fullWidth
                    onChange={(e) => this.handleEditorChange(e)}
                    label="Feedback"
                    multiline
                    rows={4}
                  />
                </Grid>

                <div className={classes.buttonBar}>
                  {" "}
                  <IgButton
                    onClick={this.handleSubmit}
                    className={classes.submit}
                    label="Submit"
                  />{" "}
                </div>
                {error && (
                  <Grid style={{ display: "flex", justifyContent: "center" }}>
                    <Typography style={{ color: "red" }}>{error}</Typography>
                  </Grid>
                )}
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: 10,
                  }}
                >
                  {isPostRateLoading && (
                    <CircularProgress circularprogress="true">
                      {!isPostRateLoading && (
                        <CircularProgress circularprogress="false" />
                      )}
                    </CircularProgress>
                  )}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

Feedback.propType = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    error: state.user.error,
    isPostRateLoading: state.user.isPostRateLoading,
  };
};
export default connect(mapStateToProps, { createRate })(
  withRouter(withStyles(styles)(Feedback))
);
