// import { CREATE_EXPERIENCE } from "../Actions/types";
// import isEmpty from "../Utils/isEmpty";

const initialState = {
  experience: null,
  experiences: null,

  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'RESET_APP':
          return { ...initialState}
    default:
      return state;
  }
}
