import React,  { useState , useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { PROVINCES, STATES } from "./../../../Utils/Provinces&States";
import { withRouter } from "react-router-dom";
import {
    Grid,
    CircularProgress,
    TextField,
    InputAdornment,
    MenuItem,
    Button,
    Paper,
    Typography,
    Snackbar,
} from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { registerUser } from "./../../../Actions/authAction";
import { useDispatch , useSelector } from "react-redux";
import PropTypes from "prop-types";
import { history } from "../../../Utils/history";
import myStyle from "../../../Utils/style";
import AlertComponent from "../../../v2/Components/AlertComponent/index";
import {AgencySchema} from "./../../../Utils/Schema";
import {numeral} from "../../../Utils/Numeral";

const department = [
    { value: 0, label: "Actuarial" },
    { value: 1, label: "Office and Business Administration" },
    { value: 2, label: "Claims" },
    { value: 3, label: "Finance" },
    { value: 4, label: "Human Resources" },
    { value: 5, label: "Information Technology" },
    { value: 6, label: "Legal" },
    { value: 7, label: "Marketing/Communications" },
    { value: 8, label: "Project Management" },
    { value: 9, label: "Sales" },
    { value: 10, label: "Students" },
    { value: 11, label: "Underwriting" },
    { value: 12, label: "Consulting" },
    { value: 13, label: "Client and Customer Service" },
    { value: 14, label: "Strategy and Corporate Development" },
    { value: 15, label: "General Management" },
];

const countries = [
    { value: "Canada", label: "Canada" },
    { value: "United States", label: "United States" },
];

const SignupAgency = (props) => {
    const dispatch = useDispatch();

    const {auth , signUpError, form_data, isSigningUpLoading} = useSelector((state) => {
        const { auth } = state;
        const { signUpError, form_data, isSigningUpLoading } = auth;
        return { signUpError, form_data, isSigningUpLoading };
    });
    const classes = myStyle();
    const [accountType, setaccountType] = useState(0);
    const [country, setCountry] = useState("Canada");
    const [province, setProvince] = useState("Ontario");
    const [values, setValues] = useState({showPassword: false});
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
      };
    const handleCompanyType = (event) => {
        setaccountType(event.target.value);
    };
    const handleCountry = (event) => {
        if (event.target.value === "United States") {
            setCountry("United States");
            setProvince("Washington");
        } else {
            setCountry("Canada");
            setProvince("Ontario");
        }
    };  
    const handleProvince = (event) => {
        setProvince(event.target.value);
    };
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
    };
    useEffect(() => {
        handleClick();
    }, [signUpError]);
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        };
    const handleUserSignUp = (data, event) => {
        event.preventDefault();
        delete data.confirmPassword;
        data.country = country;
        data.state = province;
        data.organization_industries = accountType;
        data.user_type = "Client";
        dispatch(registerUser(data));
    };
    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(AgencySchema),
    });

    return (
    <React.Fragment>
        <div className={classes.root}>
            <Grid container justify="center">
                <Grid
                    item
                    xs={12}
                    md={6}
                >
            <form
                className={classes.form}
                noValidate
                onSubmit={handleSubmit(handleUserSignUp)}
            >
                {isSigningUpLoading ? (
                    <CircularProgress className={classes.loading} size={20} />
                ) : (
                    <Paper className={classes.LoginPaper}>
                        <Grid container alignContent="flex-start">
                            <Typography variant="h4" gutterBottom color="secondary">Register</Typography>
                        </Grid>
                        <Grid container>
                        <Typography gutterBottom variant="body2">
                            already have an account?
                        </Typography>
                        <Typography
                            onClick={() => history.push("/login")}
                            className={classes.link}
                        >
                            Login
                        </Typography>
                        </Grid>
                        <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                defaultValue={form_data ? form_data.first_name : ''}
                                inputRef={register}
                                required
                                fullWidth={true}
                                label="First Name"
                                name="first_name"
                                autoComplete="first_name"
                                autoFocus
                            />
                            {errors.first_name && (
                                <p className={classes.error}>
                                {errors.first_name.message}
                                </p>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                defaultValue={form_data ? form_data.last_name : ''}
                                inputRef={register}
                                required
                                fullWidth={true}
                                label="Last Name"
                                name="last_name"
                                autoComplete="last_name"
                                autoFocus
                            />
                            {errors.last_name && (
                                <p className={classes.error}>
                                {errors.last_name.message}
                                </p>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                defaultValue={form_data ? form_data.email : ''}
                                inputRef={register}
                                required
                                fullWidth={true}
                                label="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            {errors.email && (
                                <p className={classes.error}>
                                {errors.email.message}
                                </p>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                        <FormControl fullWidth>
                                <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                    <Input
                                        defaultValue={form_data ? form_data.password : ""}
                                        inputRef={register}
                                        required
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        type={values.showPassword ? 'text' : 'password'}
                                        value={values.password}
                                        endAdornment={
                                            <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                            </FormControl>
                        {errors.password && (
                            <p className={classes.error}>
                            {errors.password.message}
                            </p>
                        )}
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
                                    <Input
                                        defaultValue={form_data ? form_data.password : ""}
                                        inputRef={register}
                                        required
                                        name="confirmPassword"
                                        type="password"
                                        type={values.showPassword ? 'text' : 'password'}
                                        value={values.password}
                                        endAdornment={
                                            <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                            </FormControl>
                        {errors.confirmPassword && (
                            <p className={classes.error}>
                            {errors.confirmPassword.message}
                            </p>
                        )}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type="number"
                                defaultValue={form_data ? form_data.phone_number : ''}
                                inputRef={register}
                                required
                                fullWidth={true}
                                label="Phone Number"
                                name="phone_number"
                                autoComplete="phone_number"
                                autoFocus
                                InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">+1</InputAdornment>
                                ),
                                }}
                            />
                            {errors.phone_number && (
                                <p className={classes.error}>
                                {errors.phone_number.message}
                            </p>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                inputRef={register}
                                required
                                fullWidth={true}
                                select
                                value={accountType}
                                onChange={handleCompanyType}
                                label="Organization Industries"
                                name="organization_industries"
                                autoComplete="organization_industries"
                                autoFocus
                            >
                                {department.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                defaultValue={form_data ? form_data.organization_name : ''}
                                inputRef={register}
                                required
                                fullWidth={true}
                                label="Organization Name"
                                name="organization_name"
                                autoFocus
                            />
                            {errors.organization_name && (
                                <p className={classes.error}>
                                {errors.organization_name.message}
                                </p>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                inputRef={register}
                                required
                                fullWidth={true}
                                select
                                value={country}
                                onChange={handleCountry}
                                label="Country"
                                name="country"
                                autoComplete="country"
                                autoFocus
                            >
                                {countries.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                inputRef={register}
                                required
                                fullWidth={true}
                                select
                                value={province}
                                onChange={handleProvince}
                                label="State/Provinces"
                                name="province"
                                autoComplete="province"
                                autoFocus
                            >
                                {country === "Canada"
                                ? PROVINCES.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                    </MenuItem>
                                ))
                                : STATES.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.cButton}
                            >
                            Register as an Organization
                        </Button>
                        </Grid>
                        </Grid>
                        {signUpError && (
                            <Grid>
                            <Snackbar
                                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                open={open}
                                autoHideDuration={3000}
                                onClose={handleClose}
                            >
                                <AlertComponent onClose={handleClose} severity="error">
                                    {signUpError === "has already been taken"
                                        ? "Email already in use"
                                        : signUpError}
                                </AlertComponent>
                            </Snackbar>
                        </Grid>
                        )}
                    </Paper>
                    )}
                </form>
            </Grid>
            </Grid>
        </div>
        </React.Fragment>
    );
};
SignupAgency.propType = {
    registerUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export default (withRouter(SignupAgency));

