import freelancersConstants from '../constants/freelancers'

const initialState = {
  // isGetAllJobsApplicationsLoading: false,

    Attachment: null,
    Attachments: null,
    jobApplication_Id : null,
    currentApplied_job:null,
    jobId : null ,

    Experience: null,
    Experiences: null,

    Identities: null,

    JobsApplication: [],
    JobsApplications: [],

    jobsAnswers : [],

    UsersInterest: null,
    UsersInterests: null,

    UsersLanguage: null,
    UsersLanguages: null,

    UsersSkill: null,
    UsersSkills: null,
    isPostIdentityLoading: false,
    isPutIdentityLoading: false,
    isPutUsersSkillLoading: false,
    isPutUsersLanguageLoading: false,
    isPutUsersInterestLoading: false,
    isPostUsersSkillLoading: false,
    isPostUsersLanguageLoading: false,
    isPostUsersInterestLoading: false,
    isPutExperienceLoading:false,
    isPostExperienceLoading:false,
    isDeleteJobsAnswerLoading: false,
    isPutJobsApplicationLoading: false,
    isMakeARoute: false,
    languageCreateError : "",
    interestCreateError:""
};

const  freelancerReducer = (state = initialState, action) => {
    switch (action.type) {
        case freelancersConstants.GET_ALL_ATTACHMENTS_REQUEST:
            return {
              ...state, isGetAllAttachmentsLoading: true
            }
          case freelancersConstants.GET_ALL_ATTACHMENTS_SUCCESS:

            const Attachment = action.payload
            return {
              ...state, Attachment, isGetAllAttachmentsLoading: false
            }
          case freelancersConstants.GET_ALL_ATTACHMENTS_FAILURE:
            return {
              ...state,isGetAllAttachmentsLoading: false
            }


            case freelancersConstants.POST_ATTACHMENT_REQUEST:
            return {
              ...state, isPostAttachmentLoading: true
            }
          case freelancersConstants.POST_ATTACHMENT_SUCCESS:
            return {
              ...state, isPostAttachmentLoading: false
            }
          case freelancersConstants.POST_ATTACHMENT_FAILURE:
            return {
              ...state,isPostAttachmentLoading: false
            }


            case freelancersConstants.PUT_ATTACHMENT_REQUEST:
            return {
              ...state, isPutAttachmentLoading: true
            }
          case freelancersConstants.PUT_ATTACHMENT_SUCCESS:
            return {
              ...state, isPutAttachmentLoading: false
            }
          case freelancersConstants.PUT_ATTACHMENT_FAILURE:
            return {
              ...state,isPutAttachmentLoading: false
            }


            case freelancersConstants.DELETE_ATTACHMENT_REQUEST:
            return {
              ...state, isDeleteAttachmentLoading: true
            }
          case freelancersConstants.DELETE_ATTACHMENT_SUCCESS:
            return {
              ...state, isDeleteAttachmentLoading: false
            }
          case freelancersConstants.DELETE_ATTACHMENT_FAILURE:
            return {
              ...state,isDeleteAttachmentLoading: false
            }


            case freelancersConstants.GET_ALL_EXPERIENCES_REQUEST:
            return {
              ...state, isGetAllExperiencesLoading: true
            }
          case freelancersConstants.GET_ALL_EXPERIENCES_SUCCESS:

            const Experience = action.payload
            const Experiences = action.payload[0];
            return {
              ...state, Experience, Experiences, isGetAllExperiencesLoading: false
            }
          case freelancersConstants.GET_ALL_EXPERIENCES_FAILURE:
            return {
              ...state,isGetAllExperiencesLoading: false
            }


            case freelancersConstants.POST_EXPERIENCE_REQUEST:
            return {
              ...state, isPostExperienceLoading: true
            }
          case freelancersConstants.POST_EXPERIENCE_SUCCESS:
            // const experiences = action.payload
            return {
              ...state, isPostExperienceLoading: false
            }
          case freelancersConstants.POST_EXPERIENCE_FAILURE:
            {
              const { error } = action.payload
              return { ...state,isPostExperienceLoading: false, experience_error: error }
            }

          case freelancersConstants.PUT_EXPERIENCE_REQUEST:
            return {
              ...state, isPutExperienceLoading: true
            }
          case freelancersConstants.PUT_EXPERIENCE_SUCCESS:
            // const experiences = action.payload
            return {
              ...state, Message: true, isPutExperienceLoading: false
            }
          case freelancersConstants.PUT_EXPERIENCE_FAILURE:
            const { error } = action.payload
            return {
              ...state,isPutExperienceLoading: false, experience_error: error
            }


            case freelancersConstants.DELETE_EXPERIENCE_REQUEST:
            return {
              ...state, isPutExperienceLoading: true
            }
          case freelancersConstants.DELETE_EXPERIENCE_SUCCESS:
            // const experiences = action.payload
            return {
              ...state, isPutExperienceLoading: false
            }
          case freelancersConstants.DELETE_EXPERIENCE_FAILURE:
            return {
              ...state,isPutExperienceLoading: false
            }


            case freelancersConstants.GET_ALL_IDENTITIES_REQUEST:
            return {
              ...state, isGetAllIdentitiesLoading: true
            }
          case freelancersConstants.GET_ALL_IDENTITIES_SUCCESS:

            const Identity = action.payload
            const Identities = action.payload[0];
            return {
              ...state, Identity, Identities, isGetAllIdentitiesLoading: false
            }
          case freelancersConstants.GET_ALL_IDENTITIES_FAILURE:
            return {
              ...state,isGetAllIdentitiesLoading: false
            }


            case freelancersConstants.POST_IDENTITY_REQUEST:
            return {
              ...state, isPostIdentityLoading: true
            }
          case freelancersConstants.POST_IDENTITY_SUCCESS:
            return {
              ...state, isPostIdentityLoading: false
            }
          case freelancersConstants.POST_IDENTITY_FAILURE:
            {
              let {error} = action.payload
            return {
              ...state,isPostIdentityLoading: false, postIdentityError: error}
            }



          case freelancersConstants.PUT_IDENTITY_REQUEST:
            return {
              ...state, isPutIdentityLoading: true
            }
          case freelancersConstants.PUT_IDENTITY_SUCCESS:
            return {
              ...state , Identities : action.payload , isPutIdentityLoading: false
            }
          case freelancersConstants.PUT_IDENTITY_FAILURE:
            return {
              ...state,isPutIdentityLoading: false
            }


            case freelancersConstants.DELETE_IDENTITY_REQUEST:
            return {
              ...state, isPutIdentityLoading: true
            }
          case freelancersConstants.DELETE_IDENTITY_SUCCESS:
            return {
              ...state, Identities : action.payload, isPutIdentityLoading: false
            }
          case freelancersConstants.DELETE_IDENTITY_FAILURE:
            return {
              ...state,isPutIdentityLoading: false
            }


            case freelancersConstants.GET_ALL_JOBS_ANSWERS_REQUEST:
            return {
              ...state, isGetAllJobsAnswersLoading: true
            }
          case freelancersConstants.GET_ALL_JOBS_ANSWERS_SUCCESS:
            return {
              ...state, jobsAnswers : action.payload , isGetAllJobsAnswersLoading: false
            }
          case freelancersConstants.GET_ALL_JOBS_ANSWERS_FAILURE:
            return {
              ...state,isGetAllJobsAnswersLoading: false
            }


            case freelancersConstants.POST_JOBS_ANSWER_REQUEST:
            return {
              ...state, isPostJobsAnswerLoading: true
            }
          case freelancersConstants.POST_JOBS_ANSWER_SUCCESS:
             return {
              ...state, isPostJobsAnswerLoading: false
            }
          case freelancersConstants.POST_JOBS_ANSWER_FAILURE:
            return {
              ...state,isPostJobsAnswerLoading: false
            }


            case freelancersConstants.PUT_JOBS_ANSWER_REQUEST:
            return {
              ...state, isPutJobsAnswerLoading: true
            }
          case freelancersConstants.PUT_JOBS_ANSWER_SUCCESS:
            return {
              ...state,  isPutJobsAnswerLoading: false
            }
          case freelancersConstants.PUT_JOBS_ANSWER_FAILURE:
            return {
              ...state,isPutJobsAnswerLoading: false
            }


            case freelancersConstants.DELETE_JOBS_ANSWER_REQUEST:
            return {
              ...state, isDeleteJobsAnswerLoading: true
            }
          case freelancersConstants.DELETE_JOBS_ANSWER_SUCCESS:
            return {
              ...state, isDeleteJobsAnswerLoading: false
            }
          case freelancersConstants.DELETE_JOBS_ANSWER_FAILURE:
            return {
              ...state,isDeleteJobsAnswerLoading: false
            }
          case "CLEARING QUESTION ANSWER STATE":
            return {
              ...state, jobApplication_Id: null 
            }
            case freelancersConstants.GET_ALL_JOBS_APPLICATIONS_REQUEST:
            return {
              ...state, isGetAllJobsApplicationsLoading: true
            }
          case freelancersConstants.GET_ALL_JOBS_APPLICATIONS_SUCCESS:

            const JobsApplication = action.payload
            const JobsApplications = action.payload[0];
            return {
              ...state, JobsApplication, JobsApplications, isGetAllJobsApplicationsLoading: false
            }
          case freelancersConstants.GET_ALL_JOBS_APPLICATIONS_FAILURE:
            return {
              ...state,isGetAllJobsApplicationsLoading: false
            }


            case freelancersConstants.POST_JOBS_APPLICATION_REQUEST:
            return {
              ...state, isPostJobsApplicationLoading: true
            }
          case freelancersConstants.POST_JOBS_APPLICATION_SUCCESS:
            return {
              ...state, isPostJobsApplicationLoading: false,currentApplied_job:action.payload, jobApplication_Id: action.payload.id, jobId: action.payload.job_id
            }
          case freelancersConstants.POST_JOBS_APPLICATION_FAILURE:
            {
              let {error} = action.payload

            return {
              ...state,isPostJobsApplicationLoading: false, error}
            }

            case freelancersConstants.PUT_JOBS_APPLICATION_REQUEST:
            return {
              ...state, isPutJobsApplicationLoading: true
            }
          case freelancersConstants.PUT_JOBS_APPLICATION_SUCCESS:
            // const JobsApplications = action.payload
            return {
              ...state, isPutJobsApplicationLoading: false
            }
          case freelancersConstants.PUT_JOBS_APPLICATION_FAILURE:
            return {
              ...state,isPutJobsApplicationLoading: false
            }


            case freelancersConstants.DELETE_JOBS_APPLICATION_REQUEST:
            return {
              ...state, isDeleteJobsApplicationLoading: true
            }
          case freelancersConstants.DELETE_JOBS_APPLICATION_SUCCESS:
            // const JobsApplications = action.payload
            return {
              ...state, isDeleteJobsApplicationLoading: false
            }
          case freelancersConstants.DELETE_JOBS_APPLICATION_FAILURE:
            return {
              ...state,isDeleteJobsApplicationLoading: false
            }


            case freelancersConstants.GET_ALL_USERS_INTERESTS_REQUEST:
            return {
              ...state, isGetAllUsersInterestsLoading: true
            }
          case freelancersConstants.GET_ALL_USERS_INTERESTS_SUCCESS:
            return {
              ...state, UserInterests: action.payload, isGetAllUsersInterestsLoading: false
            }
          case freelancersConstants.GET_ALL_USERS_INTERESTS_FAILURE:
            return {
              ...state,isGetAllUsersInterestsLoading: false
            }


            case freelancersConstants.POST_USERS_INTEREST_REQUEST:
            return {
              ...state, isPostUsersInterestLoading: true
            }
          case freelancersConstants.POST_USERS_INTEREST_SUCCESS:
            // const UsersInterests = action.payload
            return {
              ...state, isPostUsersInterestLoading: false, interestCreateError: ""
            }
          case freelancersConstants.POST_USERS_INTEREST_FAILURE:
            let { interest_id } = action.payload
            interest_id = interest_id[0]
            let interestCreateError=""
            if(interest_id === "has already been taken"){
              interestCreateError= "Interest already exists."
            }
            return {
              ...state,isPostUsersInterestLoading: false, interestCreateError
            }


            case freelancersConstants.PUT_USERS_INTEREST_REQUEST:
            return {
              ...state, isPutUsersInterestLoading: true
            }
          case freelancersConstants.PUT_USERS_INTEREST_SUCCESS:
            // const UsersInterests = action.payload
            return {
              ...state, isPutUsersInterestLoading: false
            }
          case freelancersConstants.PUT_USERS_INTEREST_FAILURE:
            return {
              ...state,isPutUsersInterestLoading: false
            }


            case freelancersConstants.DELETE_USERS_INTEREST_REQUEST:
            return {
              ...state, isDeleteUsersInterestLoading: true
            }
          case freelancersConstants.DELETE_USERS_INTEREST_SUCCESS:
            // const UsersInterests = action.payload
            return {
              ...state, isDeleteUsersInterestLoading: false
            }
          case freelancersConstants.DELETE_USERS_INTEREST_FAILURE:
            return {
              ...state,isDeleteUsersInterestLoading: false
            }


            case freelancersConstants.GET_ALL_USERS_LANGUAGES_REQUEST:
            return {
              ...state, isGetAllUsersLanguagesLoading: true
            }
          case freelancersConstants.GET_ALL_USERS_LANGUAGES_SUCCESS:

            return {
              ...state, userLanguages: action.payload, isGetAllUsersLanguagesLoading: false , languageCreateError : ""
            }
          case freelancersConstants.GET_ALL_USERS_LANGUAGES_FAILURE:
            return {
              ...state,isGetAllUsersLanguagesLoading: false
            }


            case freelancersConstants.POST_USERS_LANGUAGE_REQUEST:
            return {
              ...state, isPostUsersLanguageLoading: true 
            }
          case freelancersConstants.POST_USERS_LANGUAGE_SUCCESS:
            return {
              ...state, isPostUsersLanguageLoading: false, languageCreateError: ""
            }
          case freelancersConstants.POST_USERS_LANGUAGE_FAILURE:
            let { language_id } = action.payload
            language_id = language_id[0] || language_id
            let LanguageCreateError=""
            if(language_id === "has already been taken"){
              LanguageCreateError= "Language already exists."
            }
            return {
              ...state,isPostUsersLanguageLoading: false, languageCreateError : LanguageCreateError
            }
            case freelancersConstants.PUT_USERS_LANGUAGE_REQUEST:
            return {
              ...state, isPutUsersLanguageLoading: true
            }
          case freelancersConstants.PUT_USERS_LANGUAGE_SUCCESS:
            return {
              ...state, isPutUsersLanguageLoading: false
            }
          case freelancersConstants.PUT_USERS_LANGUAGE_FAILURE:
            return {
              ...state,isPutUsersLanguageLoading: false
            }


            case freelancersConstants.DELETE_USERS_LANGUAGE_REQUEST:
            return {
              ...state, isDeleteUsersLanguageLoading: true
            }
          case freelancersConstants.DELETE_USERS_LANGUAGE_SUCCESS:
            return {
              ...state, isDeleteUsersLanguageLoading: false
            }
          case freelancersConstants.DELETE_USERS_LANGUAGE_FAILURE:
            return {
              ...state,isDeleteUsersLanguageLoading: false
            }


            case freelancersConstants.GET_ALL_USERS_SKILLS_REQUEST:
            return {
              ...state, isGetAllUsersSkillsLoading: true
            }
          case freelancersConstants.GET_ALL_USERS_SKILLS_SUCCESS:

            return {
              ...state, UserSkills: action.payload, isGetAllUsersSkillsLoading: false
            }
          case freelancersConstants.GET_ALL_USERS_SKILLS_FAILURE:
            return {
              ...state,isGetAllUsersSkillsLoading: false
            }


            case freelancersConstants.POST_USERS_SKILL_REQUEST:
            return {
              ...state, isPostUsersSkillLoading: true
            }
          case freelancersConstants.POST_USERS_SKILL_SUCCESS:
            // const UsersSkills = action.payload
            return {
              ...state, isPostUsersSkillLoading: false, skillCreateError: ""
            }
          case freelancersConstants.POST_USERS_SKILL_FAILURE:
            let { skill_id } = action.payload
            const skill = skill_id[0]
            let skillCreateError=""
            if(skill === "has already been taken"){
              skillCreateError= "Skill already exists."
            }
            if(skill === "must exist"){
              skillCreateError= "Skill is mising."
            }
            return {
              ...state,isPostUsersSkillLoading: false, skillCreateError
            }
            case "CLEAR MY STATE":
              return {
                ...state, skillCreateError: "", experience_error: "",interestCreateError:"",languageCreateError:""
              }
            case freelancersConstants.PUT_USERS_SKILL_REQUEST:
            return {
              ...state, isPutUsersSkillLoading: true
            }
          case freelancersConstants.PUT_USERS_SKILL_SUCCESS:
            // const UsersSkills = action.payload
            return {
              ...state, isPutUsersSkillLoading: false
            }
          case freelancersConstants.PUT_USERS_SKILL_FAILURE:
            return {
              ...state,isPutUsersSkillLoading: false
            }


            case freelancersConstants.DELETE_USERS_SKILL_REQUEST:
            return {
              ...state, isPutUsersSkillLoading: true
            }
          case freelancersConstants.DELETE_USERS_SKILL_SUCCESS:
            // const UsersSkills = action.payload
            return {
              ...state, isPutUsersSkillLoading: false
            }
          case freelancersConstants.DELETE_USERS_SKILL_FAILURE:
            return {
              ...state,isPutUsersSkillLoading: false
            }

            case freelancersConstants.GET_ALL_DASHBOARD_FREELANCER_REQUEST:
            return {
              ...state, isGetAllDashboardFreelancerLoading: true
            }
          case freelancersConstants.GET_ALL_DASHBOARD_FREELANCER_SUCCESS:
            return {
              ...state, dashboard:action.payload, isGetAllDashboardFreelancerLoading: false
            }
          case freelancersConstants.GET_ALL_DASHBOARD_FREELANCER_FAILURE:
            return {
              ...state,isGetAllDashboardFreelancerLoading: false
            }
            case 'CLEAR_APPLIED':
              return {
                ...state,currentApplied_job:null
              }
            case 'RESET_APP':
              return { ...initialState}
        default:
            return state;
        }
      }
      export default freelancerReducer;