import React, { useState, useEffect } from "react";
import JobList from "../JobList/JobList";
import JobDetails from "../JobDetails/JobDetails";
import { useForm } from "react-hook-form";
import {
  Typography,
  TextField,
  CircularProgress,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@material-ui/core";
import { history } from "../../../Utils/history";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AutoComplete from "../../../Components/Form/Autocomplete";
import IgButton from "../../../Components/Form/IgButton";
import { getSkills } from "../../../Actions/userAction";
import {
  updateJob,
  createJobsSkill,
  createJobsEducation,
} from "../../../Actions/clientAction";
import { Grid, makeStyles, MenuItem } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { options, options2 } from "../../../Utils/clientData";
import Error from "../ErrorComponent/ErrorComponent";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "./EditJob.css";

let mySkillSet = [];
const skillHandler = (value) => {
  mySkillSet = value;
};

const styles = makeStyles(() => {
  return {
    loadingBox: {
      backgroundColor: "#ffffff",
      height: "100%",
      marginTop: 30,
      padding: 24,
      display: "flex",
      justifyContent: "center",
    },
    mainContainer : {
      paddingBottom:"5px"
    },
    titleContainer:{
      padding:"10px 8px"
    },
    main_title: { fontWeight: "500", marginBottom: "20px" },
    jobSideHeading: {
      width: "100%",
    },
    radioGroup: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
    radio: { color: "#4DA9FF" },
    buttonRoot: {
      display: "flex",
      flexDirection: "row",
      float: "right",
    },
    cancelButton: {
      backgroundColor: "black",
      opacity: 0.25,
      height: 36,
      width: 77,
    },
    updateButton: {
      marginLeft: 15,
      height: 36,
      width: 133,
    },
    descriptionBox: {
      width: "100%",
      border: "1px solid gray",
      borderRadius: "5px",
      padding: "10px",
    },
    font: { fontSize: "12px" },
    arrowFont: { fontSize: "24px" },
  };
});

let Job = "";
const EditJob = (props) => {
  const dispatch = useDispatch();
  const editStyles = styles();

  let Education = "";
  let Skill = [];
  const {
    clients,
    client,
    jobs,
    updateJobFailure,
    isPutJobLoading,
    allSkills,
    updateJobResponse,
    job_skills,
    job_educations
  } = useSelector((state) => {
    const { client, user } = state;
    const { clients, updateJobFailure, isPutJobLoading, updateJobResponse,job_skills,
      job_educations} =
      client;
    const { jobs } = clients;
    const { allSkills } = user;
    jobs.map((each) => {
      each.id === props.job.id && (Job = each);
    });
    job_skills.map((skill) => {
      Skill.push(skill);
    })
    job_educations.length > 0 && options2.map((option) =>{
        (option.label === job_educations[0].education_type.replaceAll("_"," ") && (Education = option.value))
      }) 
    return {
      clients,
      client,
      jobs,
      updateJobFailure,
      isPutJobLoading,
      allSkills,
      updateJobResponse,
      job_skills,
      job_educations
    };
  });

  const { register, handleSubmit } = useForm({
    defaultValues: Job,
  });

  const [state, setState] = useState({
    id: Job.id,
    job_status: Job.job_status,
    job_type: Job.job_type,
    client_id: Job.client_id,
    expertise : Job.expertise,
    open: false,
    createJobFailure: "",
    updateJobResponse: "",
    updateJobFailure: "",
    education_type: Education,
    skill: [],
    jobListRedirection: false,
    job: "",
  });

  useEffect(() => {
    dispatch(getSkills());
  },[props.Id]);

  const handleOptions = (event) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleCreateJob = (data) => {
    let skill_array = [];
    mySkillSet.forEach((skill) => {
      skill_array.push(skill.id);
    });
    dispatch(
      createJobsEducation({
        education_type: state.education_type,
        job_id: state.id,
      })
    );
    dispatch(
      createJobsSkill({
        job_id: state.id,
        skill_ids: skill_array,
      })
    );
    dispatch(
      updateJob({
        ...data,
        expertise:state.expertise,
        education_type:state.education_type,
        id: state.id,
        job_status: "Published",
        job_type: state.job_type,
      }, (data) => {
        setState({
          ...state,
          job:data,
          jobListRedirection : true
        })
      })
    );
  };

  const handleJobDetailsRedirection = (data) => {
    props.makeReverseRedirection(data);
  };

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  const { jobListRedirection, job } = state;

  return jobListRedirection && jobListRedirection ? (
    <>
    <JobList isRedirected="true"/>
    <JobDetails Id={job.id} job={job} />
    </>
  ) : job && job ? (
    <JobDetails Id={job.id} job={job} />
  ) : isPutJobLoading && isPutJobLoading ? (
    <Grid component="div" className={editStyles.loadingBox}>
      <CircularProgress disableShrink color="primary" />
    </Grid>
  ) : (
    <Grid container className="edit-root">
      <form onSubmit={handleSubmit(handleCreateJob)}>
          <Grid className={editStyles.mainContainer}>
            <Typography className={editStyles.main_title} variant="h4">
            <Link color="inherit" onClick={handleJobDetailsRedirection}>
              <ArrowBackIcon className={editStyles.arrowFont} />
            </Link>
              &nbsp; Edit Job
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className={editStyles.titleContainer}>
          <Typography variant="body1" paragraph>
            Job Name
          </Typography>
          <TextField
            name="title"
            className={editStyles.jobSideHeading}
            variant="outlined"
            inputRef={register}
            placeholder="Please enter job title"
            inputProps={{ style: { fontSize: "12px" } }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}  className={editStyles.titleContainer}>
          <Typography variant="body1" paragraph>
            General Description
          </Typography>
          <TextField
            name="description"
            className={editStyles.descriptionBox}
            fullWidth
            inputRef={register}
            multiline
            rows={4}
            inputProps={{ style: { fontSize: "12px" } }}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}  className={editStyles.titleContainer}>
          <Typography variant="body1" paragraph>
            Expertise
          </Typography>
          <RadioGroup
            aria-label="expertise"
            name="expertise"
            ref={register}
            onChange={handleOptions}
            defaultChecked={state.expertise}
            className={editStyles.radioGroup}
          >
            <FormControlLabel
              value="Beginner"
              checked={state.expertise === "Beginner"}
              control={<Radio className={editStyles.radio} />}
              label={<Typography variant="body1">Beginner</Typography>}
              labelPlacement="end"
            />
            <FormControlLabel
              value="Intermediate"
              checked={state.expertise === "Intermediate"}
              control={<Radio className={editStyles.radio} />}
              label={<Typography variant="body1">Intermediate</Typography>}
              labelPlacement="end"
            />
            <FormControlLabel
              value="Expert"
              checked={state.expertise === "Expert"}
              control={<Radio className={editStyles.radio} />}
              label={<Typography variant="body1">Expert</Typography>}
              labelPlacement="end"
            />
          </RadioGroup>
        </Grid>

        <Grid container spacing={1} className={editStyles.titleContainer}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography variant="body1" paragraph>
              Job Type
            </Typography>
            {/* <SelectField
              options={options}
              name="job_type"
              defaultValue={""}
              onChange={handleOptions}
            /> */}
            <TextField
              variant="outlined"
              margin="dense"
              required
              fullWidth
              rows={10}
              select
              onChange={handleOptions}
              value={state.job_type || ""}
              name="job_type"
              autoFocus
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography variant="body1" paragraph>
              Education
            </Typography>
            <TextField
              variant="outlined"
              margin="dense"
              fullWidth
              required
              name="education_type"
              size="medium"
              select
              onChange={handleOptions}
              value={state.education_type|| Education || ""}
              // placeholder={state.education_type}
              autoFocus
              inputProps={{ style: { fontSize: "12px" } }}
            >
              {options2.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container spacing={1} className={editStyles.titleContainer}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography variant="body1" paragraph>
              Experience Required
            </Typography>
            <TextField
              name="experience_years"
              inputRef={register}
              className={editStyles.jobSideHeading}
              variant="outlined"
              placeholder="3 Years"
              inputProps={{ style: { fontSize: "12px" } }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography variant="body1" paragraph className={editStyles.skillfield}>
              Skills
            </Typography>
            {allSkills ? (
              <AutoComplete
                display={Skill}
                data={allSkills}
                className={editStyles.jobSideHeading}
                jobSkills={(value) => skillHandler(value)}
              />
            ) : null}
          </Grid>
        </Grid>
        <Grid container spacing={1} className={editStyles.titleContainer}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography variant="body1" paragraph>
              Hourly Rate
            </Typography>
            <TextField
              name="rate_amount"
              className={editStyles.jobSideHeading}
              inputRef={register}
              variant="outlined"
              placeholder="Please enter Hourly Rate"
              inputProps={{ style: { fontSize: "12px" } }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography variant="body1" paragraph>
              Industry Designation
            </Typography>
            <TextField
              name="industry_designation"
              className={editStyles.jobSideHeading}
              variant="outlined"
              inputRef={register}
              placeholder="Please enter Industry Designation"
              inputProps={{ style: { fontSize: "12px" } }}
            />
          </Grid>
        </Grid>
        <Grid item  className={editStyles.titleContainer}>
          <Grid className={editStyles.buttonRoot}>
            <IgButton
              variant="contained"
              color="primary"
              className={editStyles.cancelButton}
              label="Cancel"
              onClick={handleJobDetailsRedirection}
            ></IgButton>
            <IgButton
              type="submit"
              className={editStyles.updateButton}
              variant="contained"
              color="primary"
              label="Update Job"
            ></IgButton>
          </Grid>
        </Grid>
        {updateJobFailure ? (
          <Error
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={state.open}
            autoHideDuration={3000}
            onClose={handleClose}
            label={updateJobFailure}
            severity="error"
          />
        ) : null}
      </form>
    </Grid>
  );
};

export default withRouter(EditJob);
