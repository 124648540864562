import React from "react";
import { Empty } from "antd";
import { Grid, makeStyles } from "@material-ui/core";

const styles = makeStyles(() => {
  return {
    nodata: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
});
const EmptyData = (props) => {
  const empty = styles();
  return (
    <Grid container className={empty.nodata}>
      <Empty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        imageStyle={{
          height: 60,
        }}
        description={<span>{props.description}</span>}
      />
    </Grid>
  );
};

export default EmptyData;
