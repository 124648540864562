import React, { useEffect } from "react";
import {Grid , Box, CircularProgress, makeStyles} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUsersProfiles } from "../../../../Actions/userAction";
import AccountProfile from "./AccountProfile";
import AccountDetails from "./AccountDetails";

const style= makeStyles(() => {
return {
  loadingbox : { 
    display: "flex", 
    justifyContent: "center", 
    paddingTop: "22%",
    paddingLeft: "47%" 
  },
  root : { padding: 32 }
}
});

const Account = () => {
  const dispatch = useDispatch();
  const accountStyles = style();

  const {User,usersProfile, isGetAllUsersProfilesLoading ,isUserProfileUpdateLoading } = useSelector((state) => {
    const { user, auth } = state;
    const { usersProfile , isGetAllUsersProfilesLoading } = user;
    const { isUserProfileUpdateLoading } = auth;
    const User = auth.user;
    return { User , usersProfile,isGetAllUsersProfilesLoading, isUserProfileUpdateLoading };
  });


  useEffect(() => {
    dispatch(getUsersProfiles(
      (User.user_id)
    ));
  },[]);


  return isGetAllUsersProfilesLoading ? (
    <Box className={accountStyles.loadingbox}>
      <CircularProgress size={70} />
    </Box>
  ) : (
    <div className={accountStyles.root}>
      <Grid container spacing={4}>
        <Grid item lg={4} md={6} xl={4} xs={12}>
          {usersProfile ? <AccountProfile /> : null}
        </Grid>
        <Grid item lg={8} md={6} xl={8} xs={12}>
          {usersProfile ? <AccountDetails /> : null}
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(Account);
