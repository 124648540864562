import { SET_CURRENT_USER } from "./../Actions/types";
import isEmpty from "./../Utils/isEmpty";
import { authConstants } from "./../constants/auth";

const initialState = {
  isUserProfileUpdateLoading: false,
  isAuthenticated: false,
  isUserLoginLoading: false,
  user: {},
  hasRole: "",
  isSigningUpLoading: false,
  isUpdateingLoading: false,
  percentage: 0,
  userName: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
        hasRole: action.payload.role,
      };

    case authConstants.USER_LOGIN_REQUEST:
      return {
        ...state,
        isUserLoginLoading: true,
      };
    case authConstants.USER_LOGIN_SUCCESS:
      const { first_name, displayName, user_type, percentage } = action.payload;
      return {
        ...state,
        first_name: first_name,
        userName: displayName,
        user_type: user_type,
        percentage: percentage,
        isUserLoginLoading: false,
      };
    case authConstants.USER_LOGIN_FAILURE:
      return {
        ...state,
        isUserLoginLoading: false,
        loginError: action.payload
      };
    case authConstants.USER_FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isUserForgotPasswordLoading: true,
      };
    case authConstants.USER_FORGOT_PASSWORD_SUCCESS:
      return { ...state, isUserForgotPasswordLoading: false };
    case authConstants.USER_FORGOT_PASSWORD_FAILURE: {
      return {
        ...state,
        isUserForgotPasswordLoading: false,
        forgotPasswordError: action.payload,
      };
    }
    case authConstants.USER_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isUserResetPasswordLoading: true,
      };
    case authConstants.USER_RESET_PASSWORD_SUCCESS:
      return { ...state, isUserResetPasswordLoading: false };
    case authConstants.USER_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isUserResetPasswordLoading: false,
        resetPasswordError: action.payload,
      };

    case authConstants.USER_CONFIRM_EMAIL_REQUEST:
      return {
        ...state,
        isConfirmEmailLoading: true,
      };
    case authConstants.USER_CONFIRM_EMAIL_SUCCESS:
      return { ...state, isConfirmEmailLoading: false, status: action.payload };
    case authConstants.USER_CONFIRM_EMAIL_FAILURE:
      return {
        ...state,
        isConfirmEmailLoading: false,
        confirmEmailError: action.payload,
      };

    case authConstants.USER_RESEND_CONFIRMATION_REQUEST:
      return {
        ...state,
        isUserResendConfirmationLoading: true,
      };
    case authConstants.USER_RESEND_CONFIRMATION_SUCCESS:
      return { ...state, isUserResendConfirmationLoading: false };
    case authConstants.USER_RESEND_CONFIRMATION_FAILURE: {
      return {
        ...state,
        isUserResendConfirmationLoading: false,
        resendConfirmationError: action.payload,
      };
    }

    case authConstants.USER_SIGN_UP_REQUEST:
      return {
        ...state,
        isSigningUpLoading: true,
      };
    case authConstants.USER_SIGN_UP_SUCCESS:
      return { ...state, isSigningUpLoading: false };
    case authConstants.USER_SIGN_UP_FAILURE:
      const { email } = action.payload;
      if (undefined !== email && email.length) {
        return { ...state, isSigningUpLoading: false, signUpError: email[0], form_data: action.form_data };
      } else {
        return {
          ...state,
          isSigningUpLoading: false,
          signUpError: action.payload, form_data: action.form_data
        };
      }
    case authConstants.USER_UPDATE_REQUEST:
      return {
        ...state,
        isUserProfileUpdateLoading: true,
      };
    case authConstants.USER_UPDATE_SUCCESS:
      return { ...state, isUserProfileUpdateLoading: false };
    case authConstants.USER_UPDATE_FAILURE: {
      let error = action.payload;
      return { ...state, isUserProfileUpdateLoading: false, error };
    }
    case "CLEAR ERROR STATE":
      return {
        ...state,
        loginError: "",
        forgotPasswordError: "",
        resetPasswordError: "",
        confirmEmailError: "",
        signUpError: "",
        error: "",
      };
    case "RESET_APP":
      return { ...initialState };
    default:
      return state;
  }
};
