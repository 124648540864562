import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { Paper, Grid } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import Topbar from "./../../Components/Topbar";
import Topbar from "../../v2/Pages/Topbar/Topbar";
import BeforeLoginFooter from "../../Components/BeforeLoginFooter";
import GroupIcon from '@material-ui/icons/Group';
import GolfCourseIcon from '@material-ui/icons/GolfCourse';
import CameraIcon from '@material-ui/icons/Camera';
import CardMembershipIcon from '@material-ui/icons/CardMembership';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#F6F8FB',
  },

  title: {
    marginTop: theme.spacing(10),
    textAlign: "center"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  ValList: {
    padding: theme.spacing(5),
  },
  paper: {
    marginTop: theme.spacing(5),
    backgroundColor: "#FFFFFF",
    color: theme.palette.text.secondary,
    borderRadius: 3,
    padding: 50,
    boxShadow: "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
  },
  aboutSectionTitle: {
    color: "#04004A",
    padding: "5px 0px 15px",
    color: "#04004A",
  },
  aboutSection: {
    marginBottom: 50,
  },


}));

export default function SimpleExpansionPanel() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Topbar />
        <Typography variant="h4" className={classes.title}>About Us</Typography>
        <Grid container justify="center">
          <Grid item xs={12} md={8}>

            <Paper className={classes.paper}>
              <div className={classes.aboutSection}>
                <GroupIcon style={{ color: "#04004A", fontSize: 30 }} />
                <Typography variant="h6" className={classes.aboutSectionTitle}>
                  The Team
              </Typography>
                <Typography variant="body2" className={classes.aboutSectionContent}>
                  With bringing experience from different backgrounds, our founders were able to act on an
                  opportunity which was seen in the Insurance industry. With each founder’s span of experience
                  in the Insurance, Finance, Human Resources and IT industry, we were able to use our experience
                  and network to help InsureGig bring this idea to reality.
                  (I am okay with putting a little Bio and picture of each individual if everyone is okay with it? I
                  believe people will trust you more if they have a face to the founders)
              </Typography>
              </div>

              <div className={classes.aboutSection}>
                <GolfCourseIcon style={{ color: "#04004A", fontSize: 30 }} />
                <Typography variant="h6" className={classes.aboutSectionTitle}>
                  Mission
              </Typography>
                <Typography variant="body2" className={classes.aboutSectionContent}>
                  To be the leader in providing experienced freelancing talent to the Insurance Industry.
              </Typography>
              </div>

              <div className={classes.aboutSection}>
                <CameraIcon style={{ color: "#04004A", fontSize: 30 }} />
                <Typography variant="h6" className={classes.aboutSectionTitle}>
                  Vision
              </Typography>
                <Typography variant="body2" className={classes.aboutSectionContent}>
                  Connecting Insurance Companies to Professionals to achieve their company goals and demands.
                  While allowing Professionals to have flexibility while earning income.
              </Typography>
              </div>

              <div className={classes.aboutSection}>
                <CardMembershipIcon style={{ color: "#04004A", fontSize: 30 }} />
                <Typography variant="h6" className={classes.aboutSectionTitle}>
                  Values
              </Typography>
                <Typography component="div" variant="body2" className={classes.aboutSectionContent}>
                  <ul>
                    <li>Trustworthy</li>
                    <li>Professional</li>
                    <li>Knowledgeable</li>
                    <li>Integrity</li>
                    <li>Innovative</li>
                    <li>Result Oriented</li>
                    <li>Freedom of Flexibility</li>
                  </ul>
                </Typography>
              </div>




            </Paper>


          </Grid>
        </Grid>

        <Grid container style={{ marginTop: 100 }}>
          <BeforeLoginFooter />
        </Grid>
      </div>
    </React.Fragment>
  );
}