import React , {useState} from 'react';
import { Box, Button, Divider } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
import BusinessIcon from '@material-ui/icons/Business';
import SignupFreelancer from '../../Components/SignupFreelancer/SignupFreelancer';
import SignupAgency from "../../Components/SignupAgency/SignupAgency";
// import Topbar from "../../../Components/Topbar";
import Topbar from "../Topbar/Topbar";
import { history } from '../../../Utils/history';

const Register = () => {
    const [state , setState] = useState({
            freelancer: false,
            organization: false,
            colorFreelancer: true,
            colorOrganization: false
    });

    const handleFreelancerSignUp = () => {
        setState({
            freelancer: true,
            organization: false,
            colorFreelancer: true,
            colorOrganization: false
        })
    }
    const handleOrganizationSignUp = () => {
        setState({
            organization: true,
            freelancer: false,
            colorOrganization: true,
            colorFreelancer: false
        })
    }
const { organization } = state;
return (
            <React.Fragment>
                <Topbar />
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 15, marginBottom: -55 }}>
                    <Box style={{ display: 'flex', flexDirection: 'row' }}>
                        <Button
                            style={{ color: state.colorFreelancer ? '#06006A' : '#00000073' }}
                            startIcon={<FaceIcon />}
                            onClick={handleFreelancerSignUp}>Register as Freelancer</Button>
                        <Divider orientation="vertical" flexItem />
                        <Button
                            style={{ color: state.colorOrganization ? '#06006A' : '#00000073' }}
                            startIcon={<BusinessIcon />}
                            onClick={handleOrganizationSignUp}>Register as an Organization</Button>
                    </Box>
                </Box>
                {organization ? <SignupAgency /> : <SignupFreelancer />}
            </React.Fragment>
        )
}

export default Register;

