import React from "react";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Divider, Grid } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import { deleteExperience } from "../../../Actions/freelancerAction";
import UpdateExperience from "../../Components/Experience/UpdateExperience";

const useStyles = makeStyles(() => ({
  delete: {
    color: "#f44336",
  },
}));

const ExperienceCard = (props) => {
  const { data, state, setState } = props;
  const experienceCardStyle = useStyles();
  const dispatch = useDispatch();

  const handleDelete = (id) => {
    dispatch(deleteExperience(id));
  };

  const isExperienceTypeEducation = (index) => {
    return index.experience_type === 0;
  };

  const handleEditExperience = () => {
    setState({ ...state, editExperience: false });
  };

  const handleOpenEditDialog = () => {
    setState({
      ...state,
      editExperience: true,
      dialog_id: data.id,
    });
  };

  return (
    <>
      <Card>
        <CardHeader
          action={
            <>
              <IconButton
                aria-label="settings"
                color="primary"
                onClick={handleOpenEditDialog}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label="settings"
                className={experienceCardStyle.delete}
                onClick={() => handleDelete(data.id)}
              >
                <DeleteIcon />
              </IconButton>
            </>
          }
          title={
            isExperienceTypeEducation(data) ? data.sub_desc_type : data.name
          }
          subheader={`From ${new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "long",
            day: "2-digit",
          }).format(Date.parse(data.ex_from))} To
          ${
            data.still_cont
              ? "Present"
              : `${new Intl.DateTimeFormat("en-GB", {
                  year: "numeric",
                  month: "long",
                  day: "2-digit",
                }).format(Date.parse(data.ex_to))}`
          }`}
        />
        <Divider variant="middle" />

        <CardContent>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="body1" color="textPrimary" component="p">
                {isExperienceTypeEducation(data)
                  ? data.sub_desc_name
                  : data.description}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {state.editExperience && data.id === state.dialog_id && (
        <UpdateExperience
          handleEditExperience={handleEditExperience}
          data={data}
        />
      )}
    </>
  );
};

export default ExperienceCard;
