import React from 'react';
import AlertComponent from "../AlertComponent/index";
import Snackbar from "@material-ui/core/Snackbar";

const Error = (props) =>{
  const {label} = props;
  return (
    <Snackbar {...props}>
        <AlertComponent onClose={props.onClose} severity={props.severity}>
      {label}
        </AlertComponent>
    </Snackbar>
  );
}

export default Error;
