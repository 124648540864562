import contactUsConstants from '../constants/contactUs';

const initialState = {
  isPostContactUsLoading: false
};

export const contactUsReducer = (state = initialState, action) => {
  switch (action.type) {
    case contactUsConstants.GET_ALL_CONTACT_US_REQUEST:
      return {
        ...state, isGetAllContactUsLoading: true
      }
    case contactUsConstants.GET_ALL_CONTACT_US_SUCCESS:
      return {
        ...state, result: action, isGetAllContactUsLoading: false
      }
    case contactUsConstants.GET_ALL_CONTACT_US_FAILURE:
      return {
        ...state, result: action, isGetAllContactUsLoading: false
      }


    case contactUsConstants.POST_CONTACT_US_REQUEST:
      return {
        ...state, isPostContactUsLoading: true
      }
    case contactUsConstants.POST_CONTACT_US_SUCCESS:
      return {
        ...state, result: action, isPostContactUsLoading: false, submissionResponse: true
      }
    case contactUsConstants.POST_CONTACT_US_FAILURE:
      return { ...state, result: action, isPostContactUsLoading: false, querySubmitError: action.payload };

    case contactUsConstants.PUT_CONTACT_US_REQUEST:
      return {
        ...state, result: action, isPutContactUsLoading: true
      }
    case contactUsConstants.PUT_CONTACT_US_SUCCESS:
      // const Matches = action.payload
      return {
        ...state, isPutContactUsLoading: false
      }
    case contactUsConstants.PUT_CONTACT_US_FAILURE:
      return {
        ...state, result: action, isPutContactUsLoading: false
      }


    case contactUsConstants.DELETE_CONTACT_US_REQUEST:
      return {
        ...state, isDeleteContactUsLoading: true
      }
    case contactUsConstants.DELETE_CONTACT_US_SUCCESS:
      // const Matches = action.payload
      return {
        ...state, isDeleteContactUsLoading: false
      }
    case contactUsConstants.DELETE_CONTACT_US_FAILURE:
      return {
        ...state, isDeleteContactUsLoading: false
      }
    case 'RESET_APP':
      return { ...initialState }
    default:
      return state;
  }
};
