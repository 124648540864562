/*eslint-disable*/
import React, { useEffect, useState } from "react";
import "./Profile.css";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useDispatch, useSelector } from "react-redux";
import UserStats from "./UserStats";
import UserProject from "../../../Components/UserProject";
import { getUsersProfiles } from "../../../Actions/userAction";
import Avatar from "@material-ui/core/Avatar";
import {
  getUsersLanguages,
  getUsersSkills,
  getUsersInterests,
} from "../../../Actions/freelancerAction";
import { getAppliedJobs } from "../../../Actions/clientAction";
import { getDashboardfreelancer } from "../../../Actions/freelancerAction";
import {
  Typography,
  Grid,
  Paper,
  makeStyles,
  Card,
  Divider,
  CircularProgress,
  Link,
} from "@material-ui/core";
import EmptyData from "./Empty";
import renderHTML from "react-render-html";
import _ from "lodash";

const styles = makeStyles(() => {
  return {
    topPaper: {
      marginTop: "100px",
      padding: "20px 15px",
      paddingBottom: "30px",
      position: "relative",
    },
    paper: {
      marginTop: "20px",
      padding: "20px 15px",
      position: "relative",
    },
    user_add: {
      position: "absolute",
      right: "20px",
      color: "#06006A",
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "Roboto",
      fontStyle: "normal",
      lineHeight: "24px",
    },
    name: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px",
      display: "flex",
      alignItems: "flex-end",
      color: "#000000",
    },
    title: {
      fontSize: "18px",
      lineHeight: "24px",
      marginLeft: "13px",
      letterSpacing: "0.1px",
      fontWeight: "500",
      fontFamily: "Roboto",
      fontStyle: "normal",
    },
    level: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      marginTop: "2px",
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      letterSpacing: "0.1px",
      color: "#9B9B9B",
    },
    card: {
      marginTop: "15px",
      marginLeft: "10px",
      padding: 10,
      width: "240px",
      background: "#F5F9FF",
    },
    experience_card: {
      marginTop: "15px",
      marginLeft: "10px",
      padding: 15,
      height: "270",
      background: "#F5F9FF",
      position: "relative",
    },
    experience_title: {
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
      fontWeight: "500",
      fontFamily: "Roboto",
      fontStyle: "normal",
      color: "#000000",
    },
    experience_level: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      marginBottom: "5px",
      marginTop: "3px",
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      letterSpacing: "0.1px",
      color: "#9B9B9B",
    },
    divider: {
      border: "none",
      borderBottom: "1px dashed #BDBDBD",
      margin: "10px 0px",
    },
    job_type: {
      padding: "10px 5px",
      width: "fit-content",
      height: "32px",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "whitesmoke",
      border: "1px solid rgba(0, 0, 0, 0.36)",
      margin: "10px 0px",
      boxSizing: "border-box",
      borderRadius: "70px",
    },
    jobStatus: {
      letterSpacing: "0.1px",
      color: "#9B9B9B",
      marginTop: "5px",
    },
    exp_desc: {
      padding: "10px 5px",
    },
    username: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      marginBottom: "0px",
      marginTop: "10px",
      marginLeft: "45px",
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      letterSpacing: "0.1px",
      color: "#000000",
      textTransform: "capitalize",
    },
    date: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      letterSpacing: "0.1px",
      color: " #9B9B9B",
      marginLeft: "45px",
      marginTop: "-3px",
    },
    firstLetter: {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      background: "#270D6F",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "20px",
      color: "#ffffff",
      position: "absolute",
      top: 6,
      left: 0,
      textTransform: "capitalize",
    },
    large: {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "20px",
      position: "absolute",
      top: 6,
      left: 0,
      textTransform: "capitalize",
      border: "1px solid whitesmoke",
    },
    loadMore: {
      overflowY: "scroll",
      marginTop: 10,
      fontSize: "14px",
    },
    company: {
      fontSize: "16px",
      lineHeight: "24px",
      color: "#828282",
      width: "100%",
    },
    clientName: {
      fontSize: "14px",
      color: "#06006A",
      fontWeight: "500",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    moreData: {
      overflowY: "scroll",
      height: "100px",
      display: "flex",
      flexWrap: "wrap",
      margin: "10px 5px",
    },
    lessData: {
      height: "100px",
      display: "flex",
      flexWrap: "wrap",
      margin: "10px 5px",
    },
    appliedBox: {
      position: "relative",
    },
  };
});

let active_projects = [],
  inProcess_projects = [],
  completed_projects = [],
  active = "",
  awaiting = "",
  completed = "";

const Profile = () => {
  const profileStyles = styles();
  const dispatch = useDispatch();
  const {
    dashboard,
    first_name,
    last_name,
    image_url,
    email,
    users_skills,
    users_interests,
    users_languages,
    jobs_applications,
    experiences,
    UserSkills,
    userLanguages,
    UserInterests,
    applied_jobs,
    created_at,
    isGetAllUsersProfilesLoading,
  } = useSelector((state) => {
    const { freelancer, user, client } = state;
    const { dashboard, UserSkills, userLanguages, UserInterests } = freelancer;
    const { usersProfile, isGetAllUsersProfilesLoading } = user;
    const { applied_jobs } = client;
    const {
      first_name,
      last_name,
      image_url,
      email,
      users_skills,
      users_interests,
      users_languages,
      jobs_applications,
      experiences,
      created_at,
    } = usersProfile;
    return {
      dashboard,
      first_name,
      last_name,
      image_url,
      email,
      users_skills,
      users_interests,
      users_languages,
      jobs_applications,
      experiences,
      UserSkills,
      userLanguages,
      UserInterests,
      applied_jobs,
      created_at,
      isGetAllUsersProfilesLoading,
    };
  });

  useEffect(() => {
    dispatch(getUsersProfiles());
    dispatch(getDashboardfreelancer());
    dispatch(getUsersSkills(localStorage.user_id));
    dispatch(getUsersLanguages());
    dispatch(getUsersInterests());
    dispatch(getAppliedJobs());
  }, []);

  let dashboard_length = null;

  if (dashboard) {
    dashboard_length = dashboard.length;
    active_projects = dashboard.filter(
      (index) => index.application_status === "accepted"
    );
    inProcess_projects = dashboard.filter(
      (index) => index.application_status === null
    );
    completed_projects = dashboard.filter(
      (index) => index.application_status === "completed"
    );
    active_projects.forEach((index) => {
      const { title, description, job_status } = index.job;
      index.job_name = title;
      index.description = description;
      index.job_status = job_status;
    });
    completed_projects.forEach((index) => {
      const { title, description, job_status } = index.job;
      index.job_name = title;
      index.description = description;
      index.job_status = job_status;
    });

    inProcess_projects.forEach((index) => {
      const { title, description, job_status } = index.job;
      index.job_name = title;
      index.description = description;
      index.job_status = job_status;
    });

    active = active_projects.length;
    awaiting = inProcess_projects.length;
    completed = completed_projects.length;
  }

  const [more, setMore] = useState({
    isMore: false,
    text: "",
    size: false,
    Id: "",
  });

  let boxSize = more.size ? "310px" : "250px";

  const truncate = (text, max) => {
    const Text = text.trim().split(" ");
    const more = Text.length > max ? "..." : "";
    return Text.slice(0, max).join(" ") + more;
  };

  let max = 30;

  const handleMore = (id) => {
    setMore({
      ...more,
      isMore: true,
      size: true,
      Id: id,
    });
  };

  const handleLess = () => {
    setMore({
      ...more,
      isMore: false,
      size: false,
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div className="grid-container">
        <Grid className="profile-background">
          <Grid container className="userInfo">
            <Grid item className="imageItem">
              {image_url && image_url.url ? (
                <img
                  src={image_url && image_url.url}
                  className="image"
                  alt="profile-image"
                />
              ) : (
                <Typography component="div" className="firstLetter">
                  {isGetAllUsersProfilesLoading && (
                    <CircularProgress size={30} />
                  )}
                  {first_name && first_name[0]}
                </Typography>
              )}
            </Grid>
            <Grid item className="profItem">
              <div className="userprofile">
                {first_name} {last_name && last_name}
              </div>
              <div className="info">{email && "@" + email}&nbsp;</div>
            </Grid>
          </Grid>
          <Grid container spacing={0} className="cards">
            <Grid item>
              <UserStats
                color={"#F2C94C"}
                sublabel={"Last active Date: "}
                value={active}
                label="Active Projects"
              />
            </Grid>
            <Grid item>
              <UserStats
                color={"#2F80ED"}
                sublabel={"Last Approval Date: "}
                value={awaiting}
                label="Awaiting Approval"
              />
            </Grid>
            <Grid item>
              <UserStats
                color={"#27AE60"}
                sublabel={"Last active Date: "}
                value={completed}
                label="Completed"
              />
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid className="row-2">
              {active_projects
                ? active_projects.map((index) => (
                    <UserProject
                      className="grid-item"
                      projectName={index.job_name}
                      projectText={index.description}
                      status={index.job_status}
                      projectStatus={"Active"}
                      projectColor={"#4DA9FF"}
                      key={index.id}
                    />
                  ))
                : null}
              {completed_projects
                ? completed_projects.map((index) => (
                    <UserProject
                      className="grid-item"
                      projectName={index.job_name}
                      projectText={index.description}
                      status={index.job_status}
                      projectStatus={"Complete"}
                      projectColor={"#78ff88"}
                      key={index.id}
                    />
                  ))
                : null}
              {inProcess_projects
                ? inProcess_projects.map((index) => (
                    <UserProject
                      className="grid-item"
                      projectName={index.job_name}
                      projectText={index.description}
                      status={index.job_status}
                      projectStatus={"In Process"}
                      projectColor={"#d3cfd4"}
                      key={index.id}
                    />
                  ))
                : null} */}
        {/* </Grid> */}
        <Paper className={profileStyles.topPaper} elevation={3}>
          <Grid container spacing={1}>
            <Grid item>
              <Typography className={profileStyles.title}>
                Skills ({users_skills ? users_skills.length : "0"})
              </Typography>
            </Grid>
            {/* <Grid item>
          <Typography variant="body1" className={profileStyles.user_add}>
           <Add style={{fontSize:"14px"}}/> Add Skill
          </Typography>
          </Grid> */}
          </Grid>
          <Grid container>
            {users_skills && users_skills.length > 0 ? (
              UserSkills &&
              UserSkills.map((userskill, i) => {
                return (
                  <Grid item sm={6} md={4} lg={3} xs={12} key={i}>
                    <Card className={profileStyles.card}>
                      <Typography className={profileStyles.name} variant="h5">
                        {userskill.skill.name}
                      </Typography>
                      <Typography
                        className={profileStyles.level}
                        variant="body1"
                      >
                        {userskill.level}
                      </Typography>
                    </Card>
                  </Grid>
                );
              })
            ) : (
              <EmptyData description={"No Skills added yet"} />
            )}
          </Grid>
        </Paper>
        <Paper className={profileStyles.paper} elevation={3}>
          <Typography className={profileStyles.title}>
            Languages ({users_languages ? users_languages.length : "0"})
          </Typography>
          <Grid container>
            {users_languages && users_languages.length > 0 ? (
              userLanguages &&
              userLanguages.map((userlang, i) => {
                return (
                  <Grid item sm={6} md={4} lg={3} xs={12} key={i}>
                    <Card className={profileStyles.card}>
                      <Typography className={profileStyles.name} variant="h5">
                        {userlang.language.name}
                      </Typography>
                      <Typography
                        className={profileStyles.level}
                        variant="body1"
                      >
                        {userlang.level}
                      </Typography>
                    </Card>
                  </Grid>
                );
              })
            ) : (
              <EmptyData description={"No Languages added yet"} />
            )}
          </Grid>
        </Paper>
        <Paper className={profileStyles.paper} elevation={3}>
          <Typography className={profileStyles.title}>
            Interests ({users_interests ? users_interests.length : "0"})
          </Typography>
          <Grid container>
            {users_interests && users_interests.length > 0 ? (
              UserInterests &&
              UserInterests.map((userInterest, i) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                    <Card className={profileStyles.card}>
                      <Typography className={profileStyles.name} variant="h5">
                        {userInterest.interest.name}
                      </Typography>
                      <Typography
                        className={profileStyles.level}
                        variant="body1"
                      >
                        {userInterest.level}
                      </Typography>
                    </Card>
                  </Grid>
                );
              })
            ) : (
              <EmptyData description={"No Interests added yet"} />
            )}
          </Grid>
        </Paper>
        <Paper className={profileStyles.paper} elevation={3}>
          <Typography className={profileStyles.title}>
            Experiences ({experiences ? experiences.length : "0"})
          </Typography>
          <Grid container>
            {experiences && experiences.length > 0 ? (
              experiences.map((experience, i) => {
                return (
                  <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
                    <Paper className={profileStyles.experience_card}>
                      {experience.experience_type === 0 ? (
                        <>
                          <Typography
                            className={profileStyles.experience_title}
                            variant="h5"
                          >
                            {experience.sub_desc_type}
                          </Typography>
                          <Typography
                            className={profileStyles.experience_level}
                            variant="body1"
                          >
                            Education
                          </Typography>
                          <Divider className={profileStyles.divider} />
                          <Grid className={profileStyles.appliedBox}>
                            <Typography
                              component="div"
                              className={
                                experience.sub_desc_name.length > max
                                  ? profileStyles.moreData
                                  : profileStyles.lessData
                              }
                            >
                              {experience.sub_desc_name ? (
                                more.isMore &&
                                more.Id === experience.id &&
                                experience.sub_desc_name.length > max ? 
                                  <Typography component="div">
                                    <Link
                                      className="exp-less"
                                      onClick={handleLess}
                                    >
                                      Read Less
                                    </Link>
                                    {renderHTML(experience.sub_desc_name)}
                                  </Typography>
                                 : 
                                    experience.sub_desc_name.length < max ? 
                                      renderHTML(experience.sub_desc_name)
                                     : 
                                      <Typography component="div">
                                        {renderHTML(
                                          truncate(
                                            experience.sub_desc_name,
                                            max
                                          )
                                        )}
                                        <Link
                                          onClick={() =>
                                            handleMore(experience.id)
                                          }
                                        >
                                          Read more
                                        </Link>
                                      </Typography>
                              ) : 
                                <EmptyData description={"No description"} />
                              }
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <Grid>
                          <Typography
                            className={profileStyles.experience_title}
                            variant="h5"
                          >
                            {experience.name}
                          </Typography>
                          <Typography
                            className={profileStyles.experience_level}
                            variant="body1"
                          >
                            Job
                          </Typography>
                          <Divider className={profileStyles.divider} />
                          <Grid className={profileStyles.appliedBox}>
                            <Typography
                              component="div"
                              className={
                                experience.description.length > max
                                  ? profileStyles.moreData
                                  : profileStyles.lessData
                              }
                            >
                              {experience.description ? (
                                more.isMore &&
                                more.Id === experience.id &&
                                experience.description.length > max ? 
                                  <Typography component="div">
                                    <Link
                                      className="exp-less"
                                      onClick={handleLess}
                                    >
                                      Read Less
                                    </Link>
                                    {renderHTML(experience.description)}
                                  </Typography>
                                 : experience.description.length < max ? 
                                  renderHTML(experience.description)
                                : 
                                  <Typography component="div">
                                    {renderHTML(
                                      truncate(experience.description, max)
                                    )}
                                    <Link
                                      onClick={() => handleMore(experience.id)}
                                    >
                                      Read more
                                    </Link>
                                  </Typography>
                              ) : 
                                <EmptyData description={"No Description"} />
                              }
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      <Divider className={profileStyles.divider} />
                      <Grid style={{ position: "relative" }}>
                        {image_url ? (
                          <Avatar
                            alt={first_name + " " + last_name}
                            src={image_url.url}
                            className={profileStyles.large}
                          />
                        ) : (
                          <Grid px={1} className={profileStyles.firstLetter}>
                            {first_name[0]}
                          </Grid>
                        )}
                        <Grid px={1} className={profileStyles.username}>
                          {first_name}
                        </Grid>
                        <Grid px={1} className={profileStyles.date}>
                          {new Intl.DateTimeFormat("en-GB", {
                            year: "numeric",
                            month: "long",
                            day: "2-digit",
                          }).format(Date.parse(experience.ex_from))}
                          {" - "}
                          {experience.still_cont === true
                            ? "Continue"
                            : new Intl.DateTimeFormat("en-GB", {
                                year: "numeric",
                                month: "long",
                                day: "2-digit",
                              }).format(Date.parse(experience.ex_to))}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                );
              })
            ) : (
              <EmptyData description={"No Experiences added yet"} />
            )}
          </Grid>
        </Paper>
        <Paper className={profileStyles.paper} elevation={3}>
          <Typography className={profileStyles.title}>
            Applied Jobs ({jobs_applications ? jobs_applications.length : "0"})
          </Typography>
          <Grid container>
            {jobs_applications && jobs_applications.length > 0 ? (
              applied_jobs &&
              applied_jobs.map((user_job, i) => {
                return (
                  <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
                    <Card className="job_card">
                      <Typography
                        className={profileStyles.experience_title}
                        variant="h5"
                      >
                        {user_job.title}
                      </Typography>
                      <Typography className={profileStyles.jobStatus}>
                        {user_job.job_status}
                      </Typography>
                      <Divider className={profileStyles.divider} />
                      <Typography className={profileStyles.job_type}>
                        {user_job.job_type}
                      </Typography>
                      <Grid
                        component="div"
                        className={profileStyles.appliedBox}
                      >
                        <Typography
                          component="span"
                          className={profileStyles.company}
                        >
                          Company :{" "}
                        </Typography>
                        <Typography
                          component="span"
                          className={profileStyles.clientName}
                        >
                          {user_job.client.name}
                        </Typography>
                        <Typography
                          component="div"
                          className={
                            user_job.description.length > max
                              ? profileStyles.moreData
                              : profileStyles.lessData
                          }
                        >
                          {user_job.description ? (
                            more.isMore &&
                            more.Id === user_job.id &&
                            user_job.description.length > max ? (
                              <Typography component="div">
                                <Link className="less" onClick={handleLess}>
                                  Read Less
                                </Link>
                                {renderHTML(user_job.description)}
                              </Typography>
                            ) : user_job.description.length < max ? (
                                renderHTML(user_job.description)
                            ) : (
                              <Typography component="div">
                                  {renderHTML(
                                    truncate(user_job.description, max)
                                  )}
                                <Link onClick={() => handleMore(user_job.id)}>
                                  Read more
                                </Link>
                              </Typography>
                            )
                          ) : (
                            <EmptyData description={"No Description"} />
                          )}
                        </Typography>
                      </Grid>
                      <Divider className={profileStyles.divider} />
                      <Grid px={1} style={{ position: "relative" }}>
                        {image_url ? (
                          <Avatar
                            alt={first_name + " " + last_name}
                            src={image_url.url}
                            className={profileStyles.large}
                          />
                        ) : (
                          <Grid className={profileStyles.firstLetter}>
                            {first_name[0]}
                          </Grid>
                        )}
                        <Grid px={1} className={profileStyles.username}>
                          {first_name}
                        </Grid>
                        <Grid px={1} className={profileStyles.date}>
                          {new Intl.DateTimeFormat("en-GB", {
                            year: "numeric",
                            month: "long",
                            day: "2-digit",
                          }).format(Date.parse(created_at))}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                );
              })
            ) : (
              <EmptyData description={"No Jobs applied yet"} />
            )}
          </Grid>
        </Paper>
        <br />
      </div>
    </React.Fragment>
  );
};

export default withRouter(Profile);
