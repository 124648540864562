const freelancersConstants = {
    // GET_ALL_FREELANCERS_REQUEST: 'GET_ALL_FREELANCERS_REQUEST',
    // GET_ALL_FREELANCERS_SUCCESS:'GET_ALL_FREELANCERS_SUCCESS',
    // GET_ALL_FREELANCERS_FAILURE:'GET_ALL_FREELANCERS_FAILURE',

    // POST_FREELANCER_REQUEST: 'POST_FREELANCER_REQUEST',
    // POST_FREELANCER_SUCCESS:'POST_FREELANCER_SUCCESS',
    // POST_FREELANCER_FAILURE:'POST_FREELANCER_FAILURE',

    // PUT_FREELANCER_REQUEST: 'PUT_FREELANCER_REQUEST',
    // PUT_FREELANCER_SUCCESS:'PUT_FREELANCER_SUCCESS',
    // PUT_FREELANCER_FAILURE:'PUT_FREELANCER_FAILURE',

    // DELETE_FREELANCER_REQUEST: 'DELETE_FREELANCER_REQUEST',
    // DELETE_FREELANCER_SUCCESS:'DELETE_FREELANCER_SUCCESS',
    // DELETE_FREELANCER_FAILURE:'DELETE_FREELANCER_FAILURE',

    GET_ALL_ATTACHMENTS_REQUEST: 'GET_ALL_ATTACHMENTS_REQUEST',
    GET_ALL_ATTACHMENTS_SUCCESS:'GET_ALL_ATTACHMENTS_SUCCESS',
    GET_ALL_ATTACHMENTS_FAILURE:'GET_ALL_ATTACHMENTS_FAILURE',

    POST_ATTACHMENT_REQUEST: 'POST_ATTACHMENT_REQUEST',
    POST_ATTACHMENT_SUCCESS:'POST_ATTACHMENT_SUCCESS',
    POST_ATTACHMENT_FAILURE:'POST_ATTACHMENT_FAILURE',

    PUT_ATTACHMENT_REQUEST: 'PUT_ATTACHMENT_REQUEST',
    PUT_ATTACHMENT_SUCCESS:'PUT_ATTACHMENT_SUCCESS',
    PUT_ATTACHMENT_FAILURE:'PUT_ATTACHMENT_FAILURE',

    DELETE_ATTACHMENT_REQUEST: 'DELETE_ATTACHMENT_REQUEST',
    DELETE_ATTACHMENT_SUCCESS:'DELETE_ATTACHMENT_SUCCESS',
    DELETE_ATTACHMENT_FAILURE:'DELETE_ATTACHMENT_FAILURE',

    GET_ALL_EXPERIENCES_REQUEST: 'GET_ALL_EXPERIENCES_REQUEST',
    GET_ALL_EXPERIENCES_SUCCESS:'GET_ALL_EXPERIENCES_SUCCESS',
    GET_ALL_EXPERIENCES_FAILURE:'GET_ALL_EXPERIENCES_FAILURE',

    POST_EXPERIENCE_REQUEST: 'POST_EXPERIENCE_REQUEST',
    POST_EXPERIENCE_SUCCESS:'POST_EXPERIENCE_SUCCESS',
    POST_EXPERIENCE_FAILURE:'POST_EXPERIENCE_FAILURE',

    PUT_EXPERIENCE_REQUEST: 'PUT_EXPERIENCE_REQUEST',
    PUT_EXPERIENCE_SUCCESS:'PUT_EXPERIENCE_SUCCESS',
    PUT_EXPERIENCE_FAILURE:'PUT_EXPERIENCE_FAILURE',

    DELETE_EXPERIENCE_REQUEST: 'DELETE_EXPERIENCE_REQUEST',
    DELETE_EXPERIENCE_SUCCESS:'DELETE_EXPERIENCE_SUCCESS',
    DELETE_EXPERIENCE_FAILURE:'DELETE_EXPERIENCE_FAILURE',

    GET_ALL_IDENTITIES_REQUEST: 'GET_ALL_IDENTITIES_REQUEST',
    GET_ALL_IDENTITIES_SUCCESS:'GET_ALL_IDENTITIES_SUCCESS',
    GET_ALL_IDENTITIES_FAILURE:'GET_ALL_IDENTITIES_FAILURE',

    POST_IDENTITY_REQUEST: 'POST_IDENTITY_REQUEST',
    POST_IDENTITY_SUCCESS:'POST_IDENTITY_SUCCESS',
    POST_IDENTITY_FAILURE:'POST_IDENTITY_FAILURE',

    PUT_IDENTITY_REQUEST: 'PUT_IDENTITY_REQUEST',
    PUT_IDENTITY_SUCCESS:'PUT_IDENTITY_SUCCESS',
    PUT_IDENTITY_FAILURE:'PUT_IDENTITY_FAILURE',

    DELETE_IDENTITY_REQUEST: 'DELETE_IDENTITY_REQUEST',
    DELETE_IDENTITY_SUCCESS:'DELETE_IDENTITY_SUCCESS',
    DELETE_IDENTITY_FAILURE:'DELETE_IDENTITY_FAILURE',

    GET_ALL_JOBS_ANSWERS_REQUEST: 'GET_ALL_JOBS_ANSWERS_REQUEST',
    GET_ALL_JOBS_ANSWERS_SUCCESS:'GET_ALL_JOBS_ANSWERS_SUCCESS',
    GET_ALL_JOBS_ANSWERS_FAILURE:'GET_ALL_JOBS_ANSWERS_FAILURE',

    POST_JOBS_ANSWER_REQUEST: 'POST_JOBS_ANSWER_REQUEST',
    POST_JOBS_ANSWER_SUCCESS:'POST_JOBS_ANSWER_SUCCESS',
    POST_JOBS_ANSWER_FAILURE:'POST_JOBS_ANSWER_FAILURE',

    PUT_JOBS_ANSWER_REQUEST: 'PUT_JOBS_ANSWER_REQUEST',
    PUT_JOBS_ANSWER_SUCCESS:'PUT_JOBS_ANSWER_SUCCESS',
    PUT_JOBS_ANSWER_FAILURE:'PUT_JOBS_ANSWER_FAILURE',

    DELETE_JOBS_ANSWER_REQUEST: 'DELETE_JOBS_ANSWER_REQUEST',
    DELETE_JOBS_ANSWER_SUCCESS:'DELETE_JOBS_ANSWER_SUCCESS',
    DELETE_JOBS_ANSWER_FAILURE:'DELETE_JOBS_ANSWER_FAILURE',

    GET_ALL_JOBS_APPLICATIONS_REQUEST: 'GET_ALL_JOBS_APPLICATIONS_REQUEST',
    GET_ALL_JOBS_APPLICATIONS_SUCCESS:'GET_ALL_JOBS_APPLICATIONS_SUCCESS',
    GET_ALL_JOBS_APPLICATIONS_FAILURE:'GET_ALL_JOBS_APPLICATIONS_FAILURE',

    POST_JOBS_APPLICATION_REQUEST: 'POST_JOBS_APPLICATION_REQUEST',
    POST_JOBS_APPLICATION_SUCCESS:'POST_JOBS_APPLICATION_SUCCESS',
    POST_JOBS_APPLICATION_FAILURE:'POST_JOBS_APPLICATION_FAILURE',

    PUT_JOBS_APPLICATION_REQUEST: 'PUT_JOBS_APPLICATION_REQUEST',
    PUT_JOBS_APPLICATION_SUCCESS:'PUT_JOBS_APPLICATION_SUCCESS',
    PUT_JOBS_APPLICATION_FAILURE:'PUT_JOBS_APPLICATION_FAILURE',

    DELETE_JOBS_APPLICATION_REQUEST: 'DELETE_JOBS_APPLICATION_REQUEST',
    DELETE_JOBS_APPLICATION_SUCCESS:'DELETE_JOBS_APPLICATION_SUCCESS',
    DELETE_JOBS_APPLICATION_FAILURE:'DELETE_JOBS_APPLICATION_FAILURE',


    GET_ALL_USERS_INTERESTS_REQUEST: 'GET_ALL_USERS_INTERESTS_REQUEST',
    GET_ALL_USERS_INTERESTS_SUCCESS:'GET_ALL_USERS_INTERESTS_SUCCESS',
    GET_ALL_USERS_INTERESTS_FAILURE:'GET_ALL_USERS_INTERESTS_FAILURE',

    POST_USERS_INTEREST_REQUEST: 'POST_USERS_INTEREST_REQUEST',
    POST_USERS_INTEREST_SUCCESS:'POST_USERS_INTEREST_SUCCESS',
    POST_USERS_INTEREST_FAILURE:'POST_USERS_INTEREST_FAILURE',

    PUT_USERS_INTEREST_REQUEST: 'PUT_USERS_INTEREST_REQUEST',
    PUT_USERS_INTEREST_SUCCESS:'PUT_USERS_INTEREST_SUCCESS',
    PUT_USERS_INTEREST_FAILURE:'PUT_USERS_INTEREST_FAILURE',

    DELETE_USERS_INTEREST_REQUEST: 'DELETE_USERS_INTEREST_REQUEST',
    DELETE_USERS_INTEREST_SUCCESS:'DELETE_USERS_INTEREST_SUCCESS',
    DELETE_USERS_INTEREST_FAILURE:'DELETE_USERS_INTEREST_FAILURE',


    GET_ALL_USERS_LANGUAGES_REQUEST: 'GET_ALL_USERS_LANGUAGES_REQUEST',
    GET_ALL_USERS_LANGUAGES_SUCCESS:'GET_ALL_USERS_LANGUAGES_SUCCESS',
    GET_ALL_USERS_LANGUAGES_FAILURE:'GET_ALL_USERS_LANGUAGES_FAILURE',

    POST_USERS_LANGUAGE_REQUEST: 'POST_USERS_LANGUAGE_REQUEST',
    POST_USERS_LANGUAGE_SUCCESS:'POST_USERS_LANGUAGE_SUCCESS',
    POST_USERS_LANGUAGE_FAILURE:'POST_USERS_LANGUAGE_FAILURE',

    PUT_USERS_LANGUAGE_REQUEST: 'PUT_USERS_LANGUAGE_REQUEST',
    PUT_USERS_LANGUAGE_SUCCESS:'PUT_USERS_LANGUAGE_SUCCESS',
    PUT_USERS_LANGUAGE_FAILURE:'PUT_USERS_LANGUAGE_FAILURE',

    DELETE_USERS_LANGUAGE_REQUEST: 'DELETE_USERS_LANGUAGE_REQUEST',
    DELETE_USERS_LANGUAGE_SUCCESS:'DELETE_USERS_LANGUAGE_SUCCESS',
    DELETE_USERS_LANGUAGE_FAILURE:'DELETE_USERS_LANGUAGE_FAILURE',

    GET_ALL_USERS_SKILLS_REQUEST: 'GET_ALL_USERS_SKILLS_REQUEST',
    GET_ALL_USERS_SKILLS_SUCCESS:'GET_ALL_USERS_SKILLS_SUCCESS',
    GET_ALL_USERS_SKILLS_FAILURE:'GET_ALL_USERS_SKILLS_FAILURE',

    POST_USERS_SKILL_REQUEST: 'POST_USERS_SKILL_REQUEST',
    POST_USERS_SKILL_SUCCESS:'POST_USERS_SKILL_SUCCESS',
    POST_USERS_SKILL_FAILURE:'POST_USERS_SKILL_FAILURE',

    PUT_USERS_SKILL_REQUEST: 'PUT_USERS_SKILL_REQUEST',
    PUT_USERS_SKILL_SUCCESS:'PUT_USERS_SKILL_SUCCESS',
    PUT_USERS_SKILL_FAILURE:'PUT_USERS_SKILL_FAILURE',

    DELETE_USERS_SKILL_REQUEST: 'DELETE_USERS_SKILL_REQUEST',
    DELETE_USERS_SKILL_SUCCESS:'DELETE_USERS_SKILL_SUCCESS',
    DELETE_USERS_SKILL_FAILURE:'DELETE_USERS_SKILL_FAILURE',

    GET_ALL_DASHBOARD_FREELANCER_REQUEST: 'GET_ALL_DASHBOARD_FREELANCER_REQUEST',
    GET_ALL_DASHBOARD_FREELANCER_SUCCESS:'GET_ALL_DASHBOARD_FREELANCER_SUCCESS',
    GET_ALL_DASHBOARD_FREELANCER_FAILURE:'GET_ALL_DASHBOARD_FREELANCER_FAILURE',

}
export default freelancersConstants;