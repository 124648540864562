import React , {useState , useEffect} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { Button, CircularProgress } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Grid, Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import { useDispatch , useSelector } from "react-redux";
import PropTypes from "prop-types";
import { resendConfirmation } from "../../../Actions/authAction";
import { history } from "../../../Utils/history";
import Topbar from "../../../Components/Topbar";
import Snackbar from "@material-ui/core/Snackbar";
import myStyle from "../../../Utils/style";
import AlertComponent from "../../Components/AlertComponent/index";
import {ResendConfirmationSchema} from "../../../Utils/Schema";

const ResendConfirmation = (props) => {
    const dispatch = useDispatch();
    const {auth , isUserResendConfirmationLoading, resendConfirmationError} = useSelector((state) => {
        const { auth } = state;
        const { isUserResendConfirmationLoading, resendConfirmationError } = auth;
        return { auth, isUserResendConfirmationLoading, resendConfirmationError };
    });

    const classes = myStyle();
    const handleLogin = () => {
        history.push("/login");
    };


const [open, setOpen] = useState(false);
const handleClick = () => {
        setOpen(true);
    };

    useEffect(() => {
        handleClick();
    }, [resendConfirmationError]);
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
    const handleUserForgotPassword = (data) => dispatch(resendConfirmation(data));
    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(ResendConfirmationSchema),
    });

    const currentPath = props.location.pathname;

    return (
    <React.Fragment>
        <Topbar currentPath={currentPath} />
        <div className={classes.root}>
        <Grid container>
            <Grid item xs={12} md={3}></Grid>
            <Grid item xs={12} md={6}>
                <form noValidate onSubmit={handleSubmit(handleUserForgotPassword)}>
                    {isUserResendConfirmationLoading ? (
                    <CircularProgress className={classes.loading} size={20} />
                    ) : (
                    <Paper className={classes.LoginPaper}>
                        <Typography variant="h4" gutterBottom color="secondary"> Resend Confirmation Email</Typography>
                        <Grid container>
                            <Typography gutterBottom variant="body2">
                                Do you want to go back to login?
                            </Typography>
                        <Typography onClick={() => handleLogin()} className={classes.link}>
                            Log in
                        </Typography>
                        </Grid>
                        <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                inputRef={register}
                                required
                                fullWidth={true}
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                className={classes.inputFormControl}
                            />
                            {errors.email && (
                                <p className={classes.error}>{errors.email.message}</p>
                            )}
                        </Grid>   
                        <Grid item xs={12} sm={6}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.cButton}
                            >
                            Send Email
                            </Button>
                            </Grid>
                    </Grid>
                        {resendConfirmationError && (
                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={open}
                            autoHideDuration={3000}
                            onClose={handleClose}
                        >
                            <AlertComponent onClose={handleClose} severity="error">
                                {resendConfirmationError.error}
                            </AlertComponent>
                        </Snackbar>
                        )}
                    </Paper>
                )}
                </form>
            </Grid>
            <Grid item xs={12} md={3}></Grid>
        </Grid>
        </div>
    </React.Fragment>
    );
};
    ResendConfirmation.propType = {
        forgotPassword: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired,
        resendConfirmationError: PropTypes.object.isRequired,
    };

export default (withRouter(ResendConfirmation));

