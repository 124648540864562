import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {CompanyDetailsSchema } from "../../../Utils/Schema";
import { yupResolver } from "@hookform/resolvers";
import "./index.css";
import { getClients, updateClient } from "../../../Actions/clientAction";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { history } from "../../../Utils/history";
import { department, companySize } from "../../../Utils/clientData";
import ImageUploading from 'react-images-uploading';
import AddIcon from "@material-ui/icons/Add";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Avatar,
  Grid,
  Button,
  TextField,
  CircularProgress,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";
import { Upload, Icon, message } from "antd";
import { getBase64, beforeUpload } from "../IdentityForm/base64";

const styles = makeStyles((theme) => {
  return {
    root: {
      width: "80%",
      height: "fit-content",
      marginTop: 24,
    },
    image:{
      //display:'flex',
      //marginLeft:200,
      //justifyContent:'center',
      height: 100,
      width: 100,
      flexShrink: 0,
      flexGrow: 0,
    },
    loading: {
      margin: "auto",
      marginTop: "22%"
    },
    error: {
			color: "#f44336",
			position: "absolute",
			fontSize: 13,
		},
    save: {
      justifyContent: "flex-end",
    }
    
  };
});

const CompanyDetail = (props) => {
  const dispatch = useDispatch();
  const detailStyles = styles();

  const {
    clients,
    isGetAllClientsLoading,
    id,
    name,
    phone_number,
    company_type,
    total_employee_size,
    c_address_1,
    c_address_2,
    c_city,
    c_state,
    c_zip,
    website_url,
    linked_url,
  } = useSelector((state) => {
    const { client } = state;
    const { clients, isGetAllClientsLoading } = client;
    const {
      id,
      name,
      phone_number,
      company_type,
      total_employee_size,
      c_address_1,
      c_address_2,
      c_city,
      c_state,
      c_zip,
      website_url,
      linked_url,
    } = clients;
    return {
      clients,
      isGetAllClientsLoading,
      id,
      name,
      phone_number,
      company_type,
      total_employee_size,
      c_address_1,
      c_address_2,
      c_city,
      c_state,
      c_zip,
      website_url,
      linked_url,
    };
  });
  const { register, handleSubmit, errors ,reset} = useForm({
    resolver: yupResolver(CompanyDetailsSchema),
    defaultValues: clients,
  });

  const [state, setState] = useState({
    isImageUrl: true,
    company_type: "",
    loading: false,
    id: id,
  });

  useEffect(() => {
    setTimeout(() => {
      clients.error === "User does not have any client" &&
        history.push("/company");
    }, dispatch(getClients()));
  }, []);

  const handleChange = (info) => {
    info.file.status === "uploading" &&
      setState({
        ...state,
        loading: true,
      });

    info.file.status === "done" &&
      getBase64(info.file.originFileObj, (imageUrl) =>
        setState({
          ...state,
          imageUrl,
          loading: false,
        })
      );
  };

  const handleUpdateValues = (data) => {
    dispatch(
      updateClient({
        ...data,
        id: state.id,
        company_type: state.company_type,
        total_employee_size: state.total_employee_size,
      })
    );
    reset(data);
  };
  

  const handleOption = (event) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };


  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  
  const onChange = (imageList, addUpdateIndex) => {
    dispatch(
      updateClient({
        id: state.id,
        company_logo: imageList[0]['data_url'],
      },()=>{setImages([]);setState({...state,isImageUrl:true})})
    )
    // setState({...state, imageUrl: imageList[0]['data_url'],isImageUrl:false});
    // setImages(imageList);
  };
  const defaultProps = {
    bgcolor: 'background.paper',
    borderColor: 'grey.500',
    m: 1,
    border: 1,
    style: { width: '5rem', height: '5rem' },
  };

  const { imageUrl, loading } = state;

  const uploadButton = (
    <div>
      <Icon type={loading ? "loading" : "plus"} />
      <div className="ant-upload-text">Company Logo</div>
    </div>
  );

  return isGetAllClientsLoading ? (
    <CircularProgress size={70} className={detailStyles.loading} />
  ) : (
    <Card className={detailStyles.root}>
      <form
        onSubmit={handleSubmit(handleUpdateValues)}
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader="The information can be edited"
          title="Company Details"
        />
        <Divider />
        {/* <Avatar className={detailStyles.image} src={clients.company_logo} /> */}
        {/* /className={profileStyles.avatar} */}
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
<ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <div 
            style={isDragging ? { color: 'red' } : undefined} 
            style={{textAlign: 'center'}}
            >
            
            <div style={{display:'flex',justifyContent:'center',marginBottom:2}}>
             <Avatar className={detailStyles.image} src={clients.company_logo} />
            </div>
            

             {(state.isImageUrl && clients.company_logo)&&
              <>
              <Button
                onClick={onImageUpload} 
                color="primary"
                variant="text"
                {...dragProps}
              >
                Update Logo
              </Button>
              </>              
              }
              {(state.isImageUrl && !clients.company_logo)&&
              <>
              <Button
                onClick={onImageUpload} 
                color="primary"
                variant="text"
                {...dragProps}
              >
                Add Logo
              </Button>
              </>              
              }
              <Button
                  className={detailStyles.logobutton}
                  color="primary"
                  variant="text"
                  onClick={() =>
                    dispatch(
                      updateClient({
                        id: state.id,
                        company_logo: "hii",
                      },()=>{setImages([]);setState({...state,isImageUrl:true})})
                    )
                  }
                >
                  REMOVE LOGO
                </Button>
             
             
            &nbsp;
            {images.map((image, index) => (
              <div key={index} className="image-item">
                <img  style={{marginTop:-10}} src={image['data_url']} alt="" width="120" />
                <div className="image-item__btn-wrapper">
                <Button
                style={{marginTop:10}}
              color="primary"
              variant="contained"
              size="small"
              
              onClick={() => onImageUpdate(index)}
            >
              Update Image
            </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
      
              <div style={{textAlign: 'center'}}>
              </div>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Company name"
                margin="dense"
                name="name"
                defaultValue={clients.name}
                inputRef={register}
                required
                variant="outlined"
              />
              {errors.name && (
                                <p className={detailStyles.error}>
                                {errors.name.message}
                                </p>
                            )}
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                select
                defaultValue={company_type || ""}
                inputRef={register}
                onChange={handleOption}
                id="company_type"
                label="Department"
                name="company_type"
                autoComplete="company_type"
                autoFocus
              >
                {department.map((department) => (
                  <MenuItem key={department.value} value={department.value}>
                    {department.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                margin="dense"
                name="phone_number"
                defaultValue={phone_number}
                inputRef={register}
                type="text"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+1</InputAdornment>
                  ),
                }}
              />
              {errors.phone_number && (
                                <p className={detailStyles.error}>
                                {errors.phone_number.message}
                                </p>
                            )}
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                select
                inputRef={register}
                onChange={handleOption}
                defaultValue={total_employee_size || ""}
                id="total_employee_size"
                label="Company Size"
                name="total_employee_size"
                autoComplete="total_employee_size"
                autoFocus
              >
                {companySize.map((companySize) => (
                  <MenuItem key={companySize.value} value={companySize.value}>
                    {companySize.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Address 1"
                margin="dense"
                name="c_address_1"
                defaultValue={c_address_1}
                inputRef={register}
                required
                variant="outlined"
              />
              {errors.c_address_1 && (
                                <p className={detailStyles.error}>
                                {errors.c_address_1.message}
                                </p>
                            )}
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Address 2"
                margin="dense"
                name="c_address_2"
                defaultValue={c_address_2}
                inputRef={register}
                required
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="City"
                margin="dense"
                name="c_city"
                defaultValue={c_city}
                inputRef={register}
                required
                variant="outlined"
              />
              {errors.c_city && (
                                <p className={detailStyles.error}>
                                {errors.c_city.message}
                                </p>
                            )}
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="State/Province"
                margin="dense"
                name="c_state"
                defaultValue={c_state}
                inputRef={register}
                required
                variant="outlined"
              />
              {errors.c_state && (
                                <p className={detailStyles.error}>
                                {errors.c_state.message}
                                </p>
                            )}
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Zip code"
                margin="dense"
                name="c_zip"
                defaultValue={c_zip}
                inputRef={register}
                required
                variant="outlined"
              />
              {errors.c_zip && (
                                <p className={detailStyles.error}>
                                {errors.c_zip.message}
                                </p>
                            )}
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Website url"
                margin="dense"
                name="website_url"
                defaultValue={website_url}
                inputRef={register}
                required
                variant="outlined"
              />
              {errors.website_url && (
                                <p className={detailStyles.error}>
                                {errors.website_url.message}
                                </p>
                            )}
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="LinkedIn url"
                margin="dense"
                name="linked_url"
                defaultValue={linked_url}
                inputRef={register}
                required
                variant="outlined"
              />
              {errors.linked_url && (
                                <p className={detailStyles.error}>
                                {errors.linked_url.message}
                                </p>
                            )}
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions className={detailStyles.save}>
          <Button type="submit" color="primary" variant="contained">
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default withRouter(CompanyDetail);
