import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import UpdateJobAnswers from "../UpdateJobAnswers/UpdateJobAnswers";
import Typography from "@material-ui/core/Typography";
import { Grid, CircularProgress, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import "react-circular-progressbar/dist/styles.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import DatePickerField from "../../../Components/Form/DatePickerField";
import UploadField from "../../../Components/Form/UploadField";
import IgButton from "../../../Components/Form/IgButton";
import { useForm } from "react-hook-form";

import {
  updateJobsApplication,
  getJobsAnswers,
} from "../../../Actions/freelancerAction";
import Snackbar from "@material-ui/core/Snackbar";
import { Divider, TextField } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import AlertComponent from "../AlertComponent/index";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "./UpdateApplication.css";

const styles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: "#FFFFFF",
      borderRadius: 5,
      width: "fit-content",
      height: "fit-content",
      alignItems: "center",
      flexWrap: "wrap",
      paddingBottom: "10px",
      marginBottom: "10px",
    },
    mainContainer: {
      overflowWrap: "break-word",
      padding: "15px 20px",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    titleContainer: {
      margin: "10px 15px",
      position: "relative",
      padding: "10px 15px",
    },
    paymentContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    divider: {
      margin: "5px 0px",
    },
    title: {
      paddingLeft: 15,
      color: "#212B36",
    },
    subtitle: {
      paddingLeft: 15,
      color: "#212B36",
    },
    buttonBar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    submit: {
      textTransform: "none",
      variant: "contained",
      color: "primary",
      position: "absolute",
      right: 30,
    },
    radioColor: {
      color: "#4DA9FF",
    },
    flexRow: {
      display: "flex",
      flexDirection: "row",
    },
    cancelButton: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      marginLeft: 0,
      padding: "8px 15px",
      backgroundColor: "#bfbfbf",
      height: 36,
      borderRadius: 5,
      color: "white",
    },
    errorGrid: {
      display: "flex",
      justifyContent: "center",
    },
    circularBox: {
      display: "flex",
      justifyContent: "center",
      marginTop: 200,
    },
    hex: {
      color: "#546E7A",
    },
    arrow: {
      fontSize: "24px",
      marginLeft: "-10px",
    },
    text: {
      fontSize: "14px",
      marginBottom: "5px",
      lineHeight: "24px",
    },
    textLabel: {
      fontSize: "16px",
      marginBottom: "5px",
      lineHeight: "24px",
    },
  };
});

const UpdateApplication = (props) => {
  const dispatch = useDispatch();
  const UpdateApplicationStyles = styles();

  let { NewApplication, myNewApplication } = "";
  const { error, isPutJobsApplicationLoading, jobsAnswers } = useSelector(
    (state) => {
      const { freelancer } = state;
      const application = state.client.applied_jobs;
      application.map((job) => {
        if (job.my_job_application.id === props.application.id) {
          myNewApplication = job;
          NewApplication = job.my_job_application;
        }
      });
      const { error, isPutJobsApplicationLoading, jobsAnswers } = freelancer;
      return { error, freelancer, isPutJobsApplicationLoading, jobsAnswers };
    }
  );

  useEffect(() => {
    dispatch(getJobsAnswers(NewApplication.id));
  }, []);

  const { register, handleSubmit } = useForm({
    defaultValues: NewApplication,
  });

  const [state, setState] = useState({
    loading: true,
    payment_type: NewApplication.payment_type,
    proposed_rate: NewApplication.proposed_rate,
    D1: NewApplication.description,
    D2: NewApplication.description_1,
    D3: NewApplication.description_2,
    job_id: NewApplication.id,
    expected_start_date: NewApplication.expected_start_date,
    company: myNewApplication.client.name,
    title: myNewApplication.title,
    job_type: myNewApplication.job_type,
    attachment_url: NewApplication.attachment_url.url,
    open: false,
    error: "",
    updateJobAnswersScreen: false,
  });

  const handlePaymentType = (event) => {
    setState({
      ...state,
      payment_type: event.target.value,
    });
  };

  const handleDate = (val) => {
    setState({
      ...state,
      expected_start_date: val,
    });
  };
  const handleUrl = (val) => {
    setState({
      ...state,
      attachment_url: val,
    });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState({
      ...state,
      open: true,
    });
  };

  const handlenavigateBack = () => {
    setState({
      ...state,
      updateJobAnswersScreen: !updateJobAnswersScreen,
    });
    props.handleUpdateApplicationScreen();
  };

  const handleJobApplicationUpdate = (data) => {
    dispatch(
      updateJobsApplication({
        ...data,
        id: state.job_id,
        attachment_url: state.attachment_url,
        expected_start_date: state.expected_start_date,
        payment_type: state.payment_type,
      })
    );
    jobsAnswers && jobsAnswers.length > 0
      ? setState({
          ...state,
          loading: true,
          updateJobAnswersScreen: true,
        })
      : handlenavigateBack();
  };

  const { updateJobAnswersScreen } = state;

  return updateJobAnswersScreen ? (
    <UpdateJobAnswers
      jobApplicationId={NewApplication.id}
      handlenavigateBack={handlenavigateBack}
    />
  ) : (
    <Grid alignItems="center" justify="center" container>
      <form onSubmit={handleSubmit(handleJobApplicationUpdate)}>
        {isPutJobsApplicationLoading ? (
          <Grid className={UpdateApplicationStyles.circularBox}>
            <CircularProgress size={70} />
          </Grid>
        ) : (
          <Grid className={UpdateApplicationStyles.root}>
            <Grid className={UpdateApplicationStyles.titleContainer}>
              <Link
                color="inherit"
                onClick={() => props.handleUpdateApplicationScreen()}
              >
                <ArrowBackIcon className={UpdateApplicationStyles.arrow} />
              </Link>
            </Grid>
            <Divider className={UpdateApplicationStyles.divider} />
            <Grid className={UpdateApplicationStyles.mainContainer}>
              <Typography variant="h3" gutterBottom className="formheading">
                Updating application for the position of {state.title}
              </Typography>
            </Grid>
            <Grid className={UpdateApplicationStyles.titleContainer}>
              <Typography
                component="span"
                variant="body2"
                className={UpdateApplicationStyles.hex}
              >
                @ {state.company}
              </Typography>
              <br />
              <Typography
                component="span"
                variant="body2"
                className={UpdateApplicationStyles.hex}
              >
                Job Type: {state.job_type}
              </Typography>
            </Grid>
            <Grid container className={UpdateApplicationStyles.titleContainer}>
              <Grid item sm={12} md={12} lg={12}>
                <Typography className={UpdateApplicationStyles.text}>
                  Expected Starting Date
                </Typography>
                <DatePickerField
                  changeDate={handleDate}
                  selectedDate={state.expected_start_date}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className={UpdateApplicationStyles.titleContainer}
            >
              <Typography
                component="div"
                className={UpdateApplicationStyles.textLabel}
              >
                Do you have relevent education for this job? Please explain
              </Typography>
              <TextField
                name="description"
                inputRef={register}
                fullWidth
                multiline
                rows={4}
              />
            </Grid>

            <Grid
              item
              xs={12}
              className={UpdateApplicationStyles.titleContainer}
            >
              <Typography
                component="div"
                className={UpdateApplicationStyles.textLabel}
              >
                Do you have any experience for this job? Please explain
              </Typography>
              <TextField
                name="description_1"
                inputRef={register}
                fullWidth
                multiline
                rows={4}
              />
            </Grid>

            <Grid
              item
              xs={12}
              className={UpdateApplicationStyles.titleContainer}
            >
              <Typography
                component="div"
                className={UpdateApplicationStyles.textLabel}
              >
                Any Comments
              </Typography>
              <TextField
                name="description_2"
                inputRef={register}
                fullWidth
                multiline
                rows={4}
              />
            </Grid>
            <Grid
              container
              className={UpdateApplicationStyles.paymentContainer}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={UpdateApplicationStyles.titleContainer}
              >
                <Typography component="span">Payment Type</Typography>
                <Grid component="span">
                  <RadioGroup
                    aria-label="payment_type"
                    name="payment_type"
                    onChange={handlePaymentType}
                    className={UpdateApplicationStyles.flexRow}
                  >
                    Do you have any experience for this job? Please explain
                    <TextField
                      name="description_1"
                      inputRef={register}
                      fullWidth
                      multiline
                      rows={4}
                    />
                  </RadioGroup>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={UpdateApplicationStyles.titleContainer}
                >
                  <Typography
                    component="div"
                    className={UpdateApplicationStyles.text}
                  >
                    Any Comments
                    <TextField
                      name="description_2"
                      inputRef={register}
                      fullWidth
                      multiline
                      rows={4}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={UpdateApplicationStyles.titleContainer}
              >
                <TextField
                  required
                  defaultValue={state.proposed_rate}
                  id="proposed_rate"
                  label={
                    <Typography className={UpdateApplicationStyles.text}>
                      Rate
                    </Typography>
                  }
                  variant="outlined"
                  placeholder="$500"
                  name="proposed_rate"
                  inputRef={register}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              className={
                (UpdateApplicationStyles.heading,
                UpdateApplicationStyles.titleContainer)
              }
            >
              <Typography className={UpdateApplicationStyles.cardlabel}>
                Upload Your Resume
              </Typography>
              <UploadField
                name="attachment_url"
                id="attachment_url"
                imageURL={handleUrl}
                value={state.attachment_url}
              />
            </Grid>
            <Grid container className={UpdateApplicationStyles.titleContainer}>
              <div className={UpdateApplicationStyles.buttonBar}>
                <Link
                  color="inherit"
                  onClick={props.handleUpdateApplicationScreen}
                  className={UpdateApplicationStyles.cancelButton}
                >
                  Cancel
                </Link>
                <IgButton
                  type="submit"
                  className={UpdateApplicationStyles.submit}
                  label="Update Application"
                ></IgButton>
              </div>
            </Grid>
            {error && (
              <Grid className={UpdateApplicationStyles.errorGrid}>
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={state.open}
                  autoHideDuration={3000}
                  onClose={handleClose}
                >
                  <AlertComponent onClose={handleClose} severity="error">
                    {error}
                  </AlertComponent>
                </Snackbar>
              </Grid>
            )}
          </Grid>
        )}
      </form>
    </Grid>
  );
};

UpdateApplication.propType = {
  createJobsApplication: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default withRouter(UpdateApplication);
