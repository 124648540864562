import React, { useState, useEffect, Fragment } from "react";
import "./CompanyPostAJob.css";
import JobList from "../JobList/JobList";
import { makeStyles } from "@material-ui/core";
import { useForm } from "react-hook-form";
import {
  Typography,
  TextField,
  CircularProgress,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@material-ui/core";
import { history } from "../../../Utils/history";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AutoComplete from "../../../Components/Form/Autocomplete";
import IgButton from "../../../Components/Form/IgButton";
import SelectField from "../../../Components/Form/SelectField";
import {
  getClients,
  updateJob,
  createJob,
  createJobsSkill,
  createJobsEducation,
} from "../../../Actions/clientAction";
import { getSkills } from "../../../Actions/userAction";
import ClientAddQuestion from "../ClientAddQuestion/ClientAddQuestion";
import { Grid, MenuItem, Container } from "@material-ui/core";
import Error from "../ErrorComponent/ErrorComponent";
import { options, options2 } from "../../../Utils/clientData";
import Success from "../ErrorComponent/ErrorComponent";
// import Edit from "./TextEditor/TextEditor";
// import  "./TextEditor/TextEditor.css";

const styles = makeStyles(() => {
  return {
    circular: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "25%",
    },
    titleContainer: {
      margin: "15px 0px",
    },
    main_title: { fontWeight: "500", marginBottom: "20px" },
    jobTitle: { width: "100%" },
    radioGroup: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
    radio: { color: "#4DA9FF" },
    radioLabel: { fontSize: "14px" },
    input: { width: "100%" },
    descriptionBox: {
      border: "1px solid gray",
      borderRadius: "5px",
    },
  };
});

let mySkillSet = [];
const skillHandler = (value) => {
  mySkillSet = value;
};

const CompanyPostAJob = (props) => {
  const dispatch = useDispatch();
  const postStyles = styles();

  const {
    clients,
    client,
    jobs,
    isPostJobLoading,
    isPutJobLoading,
    allSkills,
    draft_job,
  } = useSelector((state) => {
    const { client, user } = state;
    const { clients, isPostJobLoading, isPutJobLoading, draft_job } = client;
    const { jobs } = clients;
    const { allSkills } = user;

    return {
      clients,
      client,
      jobs,
      isPostJobLoading,
      isPutJobLoading,
      allSkills,
      draft_job,
    };
  });

  setTimeout(() => {
    clients.error === "User does not have any client" &&
      history.push("/company");
  });

  const { AllSkills } = props;
  let Skills = AllSkills ? AllSkills : allSkills;

  const { register, handleSubmit } = useForm({
    defaultValues: {
      title: "Draft Job",
      job_type: "Contract",
    },
  });

  const [state, setState] = useState({
    id: props.id,
    job_status: "",
    job_type: "Contract",
    education_type: "",
    expertise: "",
    skill: [],
    open: true,
    client: client,
    createJobFailure: "",
    updateJobFailure: "",
    createQuestions: false,
    draftJob: null,
    jobs: null,
    jobListRedirection: false,
  });

  useEffect(() => {
    dispatch(getSkills());
    dispatch(getClients());
    jobs && makeDecision(jobs);
  }, []);

  const makeDecision = (jobs) => {
    let draftCheck = jobs.filter((index) => {
      // eslint-disable-next-line
      return index.title == "Draft Job";
    });
    Array.isArray(draftCheck) && draftCheck.length
      ? setState({
          ...state,
          id: draftCheck[0].id,
          description: "",
          title: draftCheck[0].title,
        })
      : dispatch(
          createJob(
            {
              client_id: localStorage.client_id,
              title: "Draft Job",
              job_status: "Draft",
              job_type: "Contract",
              description: "",
            },
            (data) => {
              setState({
                ...state,
                id: data.id,
              });
            }
          )
        );
  };

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  const handleCreateJob = (data) => {
    let skill_array = [];
    mySkillSet.forEach((skill) => {
      skill_array.push(skill.id);
    });
    dispatch(
      createJobsSkill({
        skill_ids: skill_array,
        job_id: state.id,
      })
    );
    dispatch(
      createJobsEducation({
        education_type: state.education_type,
        job_id: state.id,
      })
    );
    dispatch(
      updateJob({
        id: state.id,
        title: data.title,
        job_status: "Published",
        job_type: state.job_type,
        description: data.description,
        rate_amount: data.rate_amount,
        education_type: state.education_type,
        expertise: state.expertise,
        experience_years: data.experience_years,
        industry_designation: data.industry_designation,
      })
    );
    setState({
      ...state,
      createQuestions: true,
    });
  };

  const handleEducation = (event) => {
    setState({
      ...state,
      education_type: event.target.value,
    });
  };

  const handleJobType = (event) => {
    setState({
      ...state,
      job_type: event.target.value,
    });
  };

  const handleExpertise = (event) => {
    setState({
      ...state,
      expertise: event.target.value,
    });
  };

  const makeReverseRedirection = () => {
    setState({
      ...state,
      createQuestions: false,
    });
    props.handleCreateJobComplete();
  };

  const { createQuestions, jobListRedirection } = state;

  return isPostJobLoading || isPutJobLoading ? (
    <Grid className={postStyles.circular}>
      <CircularProgress size={70} />
    </Grid>
  ) : jobListRedirection ? (
    <JobList isRedirected="true" />
  ) : createQuestions ? (
    <ClientAddQuestion
      job_id={state.id}
      makeReverseRedirection={makeReverseRedirection}
    />
  ) : (
    <Fragment>
      {draft_job && (
        <Success
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={state.open}
          label={draft_job}
          autoHideDuration={6000}
          onClose={handleClose}
          severity="success"
        />
      )}
      <Grid container className="create-root">
        <form onSubmit={handleSubmit(handleCreateJob)}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={postStyles.titleContainer}
          >
            <Typography className={postStyles.main_title} variant="h4">
              Create a New Job
            </Typography>
            <Typography variant="body1" paragraph>
              Job Name
            </Typography>
            <TextField
              name="title"
              className={postStyles.jobTitle}
              variant="outlined"
              inputRef={register}
              placeholder="Please enter job title"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={postStyles.titleContainer}
          >
            <Typography variant="body1" paragraph>
              General Description
            </Typography>
            <Container className={postStyles.descriptionBox}>
              <TextField
                name="description"
                fullWidth
                inputRef={register}
                multiline
                rows={8}
                // onChange={handleEditorChange}
                InputProps={{ disableUnderline: true }}
              />
            </Container>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={postStyles.titleContainer}
          >
            <Typography variant="body1" paragraph>
              Expertise
            </Typography>
            <RadioGroup
              aria-label="expertise"
              className={postStyles.radioGroup}
            >
              <FormControlLabel
                name="beginnner"
                value={"Beginner" || null}
                onChange={handleExpertise}
                checked={state.expertise === "Beginner"}
                control={<Radio className={postStyles.radio} />}
                label={<Typography variant="body1">Beginner</Typography>}
                labelPlacement="end"
              />
              <FormControlLabel
                name="intermediate"
                value={"Intermediate" || null}
                onChange={handleExpertise}
                checked={state.expertise === "Intermediate"}
                control={<Radio className={postStyles.radio} />}
                label={<Typography variant="body1">Intermediate</Typography>}
                labelPlacement="end"
              />
              <FormControlLabel
                name="expert"
                value={"Expert" || null}
                onChange={handleExpertise}
                checked={state.expertise === "Expert"}
                control={<Radio className={postStyles.radio} />}
                label={<Typography variant="body1">Expert</Typography>}
                labelPlacement="end"
              />
            </RadioGroup>
          </Grid>

          <Grid container spacing={2} className={postStyles.titleContainer}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="body1" paragraph>
                Job Type
              </Typography>
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                select
                onChange={handleJobType}
                defaultValue={""}
                name="job_type"
                autoFocus
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="body1" paragraph>
                Education
              </Typography>
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                select
                onChange={handleEducation}
                defaultValue={""}
                name="education_type"
                autoFocus
              >
                {options2.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={postStyles.titleContainer}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="body1" paragraph>
                Experience Required
              </Typography>
              <TextField
                name="experience_years"
                className={postStyles.input}
                defaultValue={state.experience_years}
                variant="outlined"
                inputRef={register}
                placeholder="3 Years"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="body1" paragraph>
                Skills
              </Typography>
              <AutoComplete
                data={Skills ? Skills : {}}
                jobSkills={(value) => skillHandler(value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={postStyles.titleContainer}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="body1" paragraph>
                Hourly Rate
              </Typography>
              <TextField
                name="rate_amount"
                className={postStyles.input}
                variant="outlined"
                inputRef={register}
                placeholder="Please enter Hourly Rate"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="body1" paragraph>
                Industry Designation
              </Typography>
              <TextField
                name="industry_designation"
                className={postStyles.input}
                variant="outlined"
                inputRef={register}
                placeholder="Please enter Industry Designation"
              />
            </Grid>
          </Grid>
          <Grid container className={(postStyles.titleContainer, "button")}>
            <Grid>
              <IgButton
                type="submit"
                variant="contained"
                color="primary"
                className="drafts"
                label="Save as Draft"
                onClick={() => history.push("/dashboard-client")}
              ></IgButton>
            </Grid>
            <Grid>
              <Grid container>
                <Grid item className="subroot">
                  <IgButton
                    variant="contained"
                    color="primary"
                    className="cancel"
                    label="Cancel"
                    onClick={() => history.push("/dashboard-client")}
                  ></IgButton>
                </Grid>
                <Grid item className="subroot">
                  <IgButton
                    className="create"
                    variant="contained"
                    color="primary"
                    label="Create"
                    type="submit"
                  ></IgButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
      {state.updateJobFailure || state.createJobFailure ? (
        <Error
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={state.open}
          autoHideDuration={3000}
          onClose={handleClose}
          label={state.updateJobFailure || state.createJobFailure}
          severity="error"
        />
      ) : null}
    </Fragment>
  );
};

export default withRouter(CompanyPostAJob);
