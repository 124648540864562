import React from 'react';
import {useSelector} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid } from "@material-ui/core";
import UsersSkill from "../UsersSkill/UsersSkill";
import UsersInterest from "../UsersInterest/UsersInterest";
import UsersLanguage from "../../v2/Components/UsersLanguage/UsersLanguage";
import ShowIdentity from "../../v2/Components/IdentityForm/IdentityForm";
import ShowExperience from "../ShowExperience/ShowExperience";
import { history } from "../../Utils/history";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 745,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
}));

function getSteps() {
  return ['Add Skills', 'Add Interests', 'Add Languages', 'Add Identitys', 'Add Experiences'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <UsersSkill />;
    case 1:
      return <UsersInterest />;
    case 2:
      return <UsersLanguage />;
    case 3:
      return <ShowIdentity />;
    case 4:
      return <ShowExperience />;
    default:
      return 'Unknown step';
  }
}

export default function HorizontalNonLinearAlternativeLabelStepper() {
  const { UserSkills, UserInterests, UserLanguages, Identitie, Experience } = useSelector(state => state.freelancer)
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set());
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const totalSteps = () => {
    return getSteps().length;
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const skippedSteps = () => {
    return skipped.size;
  };

  const completedSteps = () => {
    return completed.size;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps() - skippedSteps();
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed
        // find the first step that has been completed
        steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = new Set(completed);
    newCompleted.add(activeStep);
    setCompleted(newCompleted);
    // history.push('/browse-jobs')

    /**
     * Sigh... it would be much nicer to replace the following if conditional with
     * `if (!this.allStepsComplete())` however state is not set when we do this,
     * thus we have to resort to not being very DRY.
     */
    if (completed.size !== totalSteps() - skippedSteps()) {
      handleNext();
    }
  };
  // eslint-disable-next-line
  const handleReset = () => {
    setActiveStep(0);
    setCompleted(new Set());
    setSkipped(new Set());
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  function isStepComplete(step) {
    return completed.has(step);
  }
  const conditionalDisable = (step) => {
    if (step === 0) {
      if (UserSkills.length > 0) {
        return false
      }
      else {
        return true;
      }
    }
    else if (step === 1) {
      if (UserInterests.length > 0) {
        return false
      }
      else {
        return true;
      }
    }
    else if (step === 2) {
      if (UserLanguages.length > 0) {
        return false
      }
      else {
        return true;
      }
    }
    else if (step === 3) {
      if (Identitie && Identitie.length > 0) {
        return false
      }
      else {
        return true;
      }
    }
    else if (step === 4) {
      if (Experience && Experience.length > 0) {
        return false
      }
      else {
        return true;
      }
    }
  }
  return (
    <Grid className={classes.root}>
      <Stepper alternativeLabel nonLinear activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const buttonProps = {};
          //   if (isStepOptional(index)) {
          //     buttonProps.optional = <Typography variant="caption">Optional</Typography>;
          //   }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (

            <Step key={label} {...stepProps}>
              <StepButton
                onClick={handleStep(index)}
                completed={isStepComplete(index)}
                {...buttonProps}
              >
                {label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          //   <div>
          //     <Typography className={classes.instructions}>
          //       All steps completed - you&apos;re finished
          //     </Typography>
          //     <Button onClick={handleReset}>Reset</Button>
          //   </div>
          history.push('./browse-jobs')
        ) : (
            <div>
              <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
              <div style={{ padding: 24, display: 'flex', justifyContent: 'center' }}>
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                  Back
              </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={conditionalDisable(activeStep)}
                  className={classes.button}
                >
                  Next
              </Button>
                {isStepOptional(activeStep) && !completed.has(activeStep) && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSkip}
                    className={classes.button}
                  >
                    Skip
                  </Button>
                )}

                {activeStep !== steps.length &&
                  (completed.has(activeStep) ? (
                    <Typography variant="caption" className={classes.completed}>
                      Step {activeStep + 1} already completed
                    </Typography>
                  ) : (
                      <Button variant="contained" color="primary" onClick={handleComplete} disabled={conditionalDisable(activeStep)}>
                        {completedSteps() === totalSteps() - 1 ? 'Finish' : 'Complete Step'}
                      </Button>
                    ))}
              </div>
            </div>
          )}
      </div>
    </Grid>
  );
}