import React from "react";
import "./UserSkill.css";
import withStyles from "@material-ui/core/styles/withStyles";
import IgButton from "../../Components/Form/IgButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Tooltip,
  Button,
  IconButton,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  DialogContentText,
  CircularProgress,
  DialogTitle
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getSkills } from "../../Actions/userAction";
import {
  createUsersSkill,
  getUsersSkills,
  updateUsersSkill,
  deleteUsersSkill,
} from "../../Actions/freelancerAction";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#F6F8FB",
    paddingLeft: 10,
    marginTop: -10,
  },
  paper: {
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
  pageTitle: {
    paddingBottom: 10,
    paddingTop: 10,
  },
  SkillBox: {
    minHeight: 60,
  },

});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class UsersSkills extends React.PureComponent {
  constructor(props) {
    super(props);
    // console.log("Props", props);
    this.state = {
      UserSkills: this.props.UserSkills,
      Message: false,
      skill_id: "",
      level: "Beginner",
      dialog: false,
      open: false,
      editDialog: false,
      dialog_id: "",
      skillCreateError: "",
      change: "new",
    };
  }
  static getDerivedStateFromProps(props, state) {
    if (props.UserSkills !== state.UserSkills) {
      return { UserSkills: props.UserSkills };
    } else {
      if (props.skillCreateError !== state.skillCreateError) {
        return { skillCreateError: props.skillCreateError, open: true };
      } else {
        return null;
      }
    }
  }
  handleClose = () => {
    this.setState({
      open: false,
    });
  };
  componentDidMount() {
    this.props.getSkills();
    this.props.getUsersSkills();
  }

  handleLevelChange = (event) => {
    this.setState({
      level: event.target.value,
    });
  };
  handleSkill = (event, params) => {
    if (params) {
      const { id } = params;
      const { allSkills } = this.props;
      let a = allSkills.filter((index) => {
        return index.id === id;
      });
      if (a.length > 0) {
        this.setState({ skill_id: a[0].id });
      }
    }

  };
  handleResetForm = () => {
    this.setState({
      skill_id: "",
      change: Math.random(),
      level: "Beginner",
    });
  };
  handleDelete = (id) => {
    this.props.deleteUsersSkill(id);
  };
  handleCreateUsersSkill = (event) => {
    event.preventDefault();
    const { skill_id, level } = this.state;
    const data = {
      skill_id,
      level,
    };
    // console.log("Before Submit", this.state);
    this.props.createUsersSkill(data, this.handleResetForm);
  };
  handleEditDialog = () => this.setState({ editDialog: false });
  render() {
    const { classes } = this.props;
    const { change, dialog_id } = this.state;
    let UserSkills = this.state.UserSkills;
    if (UserSkills) {
      UserSkills.forEach((element) => {
        if (element.level === "Expert") {
          element.tagcol = "col-blue";
        } else if (element.level === "Intermediate") {
          element.tagcol = "col-green";
        } else {
          element.tagcol = "col-yellow";
        }
      });
    }
    const { allSkills, skillCreateError, isPutUsersSkillLoading, isPostUsersSkillLoading } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              className={classes.pageTitle}
            >
              {" "}
                Add Skills
              </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <form onSubmit={this.handleCreateUsersSkill}>
              <Paper className={classes.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={10} sm={10} md={4} lg={5}>
                    {allSkills ? (
                      <Autocomplete
                        id="clear-on-escape"
                        clearOnEscape
                        disableClearable
                        key={change}
                        options={allSkills}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) =>
                          this.handleSkill(event, value)
                        }
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            style={{ width: "100%" }}
                            label="Skills"
                          />
                        )}
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={10} sm={10} md={6} lg={5}>
                    <RadioGroup
                      row
                      aria-label="level"
                      name="level"
                      onChange={this.handleLevelChange.bind(this)}
                    >
                      <FormControlLabel
                        value="Beginner"
                        checked={this.state.level === "Beginner"}
                        control={<Radio style={{ color: "#1a237e" }} />}
                        label="Beginner"
                        labelPlacement="end"

                      />
                      <FormControlLabel
                        value="Intermediate"
                        checked={this.state.level === "Intermediate"}
                        control={<Radio style={{ color: "#1a237e" }} />}
                        label="Intermediate"
                        labelPlacement="end"
                        variant="primary"
                      />
                      <FormControlLabel
                        value="Expert"
                        checked={this.state.level === "Expert"}
                        control={<Radio style={{ color: "#1a237e" }} />}
                        label="Expert"
                        labelPlacement="end"
                        variant="primary"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={10} sm={10} md={2} lg={2}>
                    <IgButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      label="Add skill"
                      loading={isPostUsersSkillLoading}
                    // iconname={AddIcon}
                    ></IgButton>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              className={classes.pageTitle}
            >
              Skills({UserSkills && UserSkills.length})
              </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} wrap={true}>
          {UserSkills
            ? UserSkills.map((index) => (
              <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <Paper className={classes.paper}>

                  <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={8} xl={9}>
                      <div className={classes.SkillBox}>
                        <Typography variant="h5">
                          {" "}
                          {index.skill.name}
                        </Typography>
                        <Typography variant="subtitle2">{index.level}</Typography>
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={4} xl={3}>
                      {isPutUsersSkillLoading && dialog_id === index.id ? (
                        <CircularProgress size={25} />
                      ) : (
                        <Tooltip title="Edit Skill" aria-label="edit" arrow>
                          <IconButton
                            aria-label="edit"
                            onClick={() =>
                              this.setState({
                                editDialog: true,
                                dialog_id: index.id,
                              })
                            }
                          >
                            <EditIcon style={{ fontSize: 18 }} />
                          </IconButton>
                        </Tooltip>
                      )}
                      {this.state.editDialog &&
                        this.state.dialog_id === index.id && (
                          <UpdateUsersSkill
                            // eslint-disable-next-line
                            handleEditDialog={this.handleEditDialog}
                            isPutUsersSkillLoading={isPutUsersSkillLoading}
                            getUsersSkills={this.props.getUsersSkills}
                            ExperienceUpdateUsersSkilldialogState={
                              this.state
                            }
                            data={index}
                            updateUsersSkill={this.props.updateUsersSkill}
                          />
                        )}
                      <Tooltip
                        title="Delete Skill"
                        aria-label="delete"
                        arrow
                      >
                        <IconButton
                          aria-label="delete"
                          onClick={() => this.handleDelete(index.id)}
                        >
                          <DeleteIcon style={{ fontSize: 18 }} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))
            : null}
        </Grid>
        {skillCreateError ? <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.open} autoHideDuration={3000} onClose={this.handleClose}>
          <Alert severity="error">
            {skillCreateError}
          </Alert>
        </Snackbar> : null}
      </div>
    );
  }
}
class UpdateUsersSkill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      id: this.props.data.id,
      skill_id: "",
      level: this.props.data.level,
    };
  }
  handleClose = () => {
    this.setState({
      open: false,
    });
    this.props.handleEditDialog();
  };
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };
  handleLevelChange = (event) => {
    this.setState({
      level: event.target.value,
    });
  };
  handleUpdateUsersSkill = () => {
    this.props.updateUsersSkill({
      id: this.state.id,
      level: this.state.level,
    });
    this.props.handleEditDialog();
  };
  render() {
    return (
      <div style={{ minWidth: "max-content" }}>
        <Dialog open={this.state.open} onClose={this.handleClose}>

          <DialogTitle id="form-dialog-title">Skill Level</DialogTitle>
          <DialogContent>

            <DialogContentText>
              <RadioGroup
                aria-label="level"
                name="level"
                onChange={this.handleLevelChange.bind(this)}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value="Beginner"
                  checked={this.state.level === "Beginner"}
                  control={<Radio style={{ color: "#1a237e" }} />}
                  label="Beginner"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="Intermediate"
                  checked={this.state.level === "Intermediate"}
                  control={<Radio style={{ color: "#1a237e" }} />}
                  label="Intermediate"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="Expert"
                  checked={this.state.level === "Expert"}
                  control={<Radio style={{ color: "#1a237e" }} />}
                  label="Expert"
                  labelPlacement="end"
                />
              </RadioGroup>
            </DialogContentText>
          </DialogContent>

          <DialogActions
            style={{ display: "flex" }}
          >
            <Button onClick={this.handleClose}>
              Cancel
            </Button>
            <Button variant="contained"
              color="primary" onClick={this.handleUpdateUsersSkill}>
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  //   console.log("State", state);
  const { freelancer, user } = state;
  const { allSkills } = user;
  const {
    error,
    UserSkills,
    Message,
    isPostUsersSkillLoading,
    isPutUsersSkillLoading,
    skillCreateError,
  } = freelancer;
  return {
    freelancer,
    allSkills,
    error,
    UserSkills,
    Message,
    isPostUsersSkillLoading,
    isPutUsersSkillLoading,
    skillCreateError,
  };
};
export default connect(mapStateToProps, {
  createUsersSkill,
  getSkills,
  getUsersSkills,
  updateUsersSkill,
  deleteUsersSkill,
})(withRouter(withStyles(styles)(UsersSkills)));
