import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { Card, CardContent } from "@material-ui/core"
import logo from "./../../Assets/logo.png";
import { history } from "./../../Utils/history";
import ForgotPasswordMailFooter from '../../Components/ForgotPasswordMailFooter';
import IgButton from '../../Components/Form/IgButton';
const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    backgroundColor: '#F7F9FB'
  },
  grid: {
    width: 1200,
    margin: `0 ${theme.spacing(2)}px`,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)"
    }
  },
  loadingState: {
    opacity: 0.05
  },
  card:{
    borderRadius: 10,
  },
  rangeLabel: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(3)
  },
  outlinedButtom: {
    textTransform: "uppercase",
    margin: theme.spacing(2)
  },
  actionButtom: {
    textTransform: "uppercase",
    margin: theme.spacing(2),
    width: 152,
    height: 36
  },
  blockCenter: {
    padding: theme.spacing(2),
    textAlign: "center"
  },
  block: {
    padding: theme.spacing(2)
  },
  inlining: {
    display: "inline-block",
    marginRight: 10
  },
  buttonBar: {
    display: "flex"
  },
  mainBadge: {
    textAlign: "center",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'none'
  }
});
class ResetLink extends Component {
  state = {
    loading: true,
    amount: 15000,
    period: 3,
    start: 0,
    monthlyInterest: 0,
    totalInterest: 0,
    monthlyPayment: 0,
    totalPayment: 0,
    data: []
  };
  handleHome = () => {
    history.push('/')
  }
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <Grid
            spacing={3}
            alignItems="center"
            justify="center"
            container
            alignContent='center'>
            <Grid item xs={12} md={6} style={{ margin: 30 }}>
              <Card className={classes.card}>
                <CardContent>
                  <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center', margin: 30 }}>
                    <img src={logo} alt="InsureGig Logo" className={classes.logo} />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography align='center' variant="h6" component="h6" gutterBottom style={{ paddingBlockStart: 15, fontWeight: 600, color: '#0C0033' }}>
                     Please Check Your Email
                        </Typography>
                    <Typography align='center' variant="body1"  gutterBottom style={{ color: '#0C0033' }}>
                      We just wanted to make sure that we got your address right
                    </Typography>
                  </Grid>
                  <Grid container justify='center' alignContent='center' alignItems='center'>
                    <IgButton
                      style={{ fontWeight: 600 }}
                      type="submit"
                      color="primary"
                      variant="contained"
                      className={classes.submit}
                      onClick={() => this.handleHome()}
                      label="Home">
                    </IgButton>
                  </Grid>
                </CardContent>
              </Card>
              <ForgotPasswordMailFooter></ForgotPasswordMailFooter>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProp = state => {
  return { auth: state.auth }};
export default connect(mapStateToProp)(withRouter(withStyles(styles)(ResetLink)));
