import React ,{useState , useEffect} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { Button, CircularProgress } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Grid, Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import { useDispatch , useSelector } from "react-redux";
import PropTypes from "prop-types";
import { resetPassword } from "../../../Actions/authAction";
import Topbar from "../../../Components/Topbar";
import Snackbar from "@material-ui/core/Snackbar";
import myStyle from "../../../Utils/style";
import AlertComponent from "../../Components/AlertComponent/index";
import {ResetPasswordSchema} from "../../../Utils/Schema";


const ResetPassword = (props) => {
    const dispatch = useDispatch();

    const {auth ,isUserResetPasswordLoading, resetPasswordError} = useSelector((state) => {
        const { auth } = state;
        const { isUserResetPasswordLoading, resetPasswordError } = auth;
        return { auth, isUserResetPasswordLoading, resetPasswordError };
    });

    const classes = myStyle();
    const url = window.location;
    const reset_password_token = new URLSearchParams(url.search).get('reset_password_token');
    const paramEmail = new URLSearchParams(url.search).get('email');

    const [open, setOpen] =useState(false);
    const [email] = useState(paramEmail);
    const [token] = useState(reset_password_token);

const handleClick = () => {
    setOpen(true);
};

    useEffect(() => {
        handleClick();
    }, [resetPasswordError]);
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
    const handleUserResetPassword = dispatch((data) => resetPassword({email,token,...data}));
    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(ResetPasswordSchema), defaultValues: {email, token}
    });

const currentPath = props.location.pathname;
return (
    <React.Fragment>
        <Topbar currentPath={currentPath} />
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={6}>
                <form noValidate onSubmit={handleSubmit(handleUserResetPassword)}>
                    {isUserResetPasswordLoading ? (
                <CircularProgress className={classes.loading} size={20} />
                ) : (
                    <Paper className={classes.LoginPaper}>
                    <Typography variant="h4" gutterBottom color="secondary">Reset Your Password</Typography>
                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            inputRef={register}
                            required
                            fullWidth={true}
                            name="password"
                            label="New Password"
                            type="password"
                          // id="password"
                            autoComplete="current-password"
                        />
                        {errors.password && (
                            <p className={classes.error}>
                                {errors.password.message}
                            </p>
                        )}
                        </Grid>
                        <Grid item xs={12}>
                        <TextField
                            inputRef={register}
                            required
                            fullWidth={true}
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                        />
                        {errors.confirmPassword && (
                            <p className={classes.error}>
                            {errors.confirmPassword.message}
                            </p>
                        )}
                        </Grid>
            
                        <Grid item xs={12} sm={6}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.cButton}
                        >
                            Reset Password
                        </Button>
                    </Grid>
                    </Grid>
                    {resetPasswordError && (

                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={open}
                        autoHideDuration={3000}
                        onClose={handleClose}
                    >
                        <AlertComponent onClose={handleClose} severity="error">
                            {resetPasswordError.error}
                        </AlertComponent>
                    </Snackbar>

                    )}
                </Paper>
                )}
            </form>
            </Grid>
        <Grid item xs={12} md={3}></Grid>
        </Grid>
    </div>
</React.Fragment>
);
};
ResetPassword.propType = {
    forgotPassword: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    resetPasswordError: PropTypes.object.isRequired,
};


export default (withRouter(ResetPassword));

