import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import Typography from "@material-ui/core/Typography";
import { Grid, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Checkbox, FormControlLabel } from "@material-ui/core";

import { createExperience } from "../../../Actions/freelancerAction";
import DatePickerField from "../../../Components/Form/DatePickerField";

const useStyles = makeStyles(() => {
  return {
    fullWidth: {
      width: "100%",
    },
    boxStyle: {
      flexDirection: "column",
      padding: 10,
    },
    displayFlex: {
      display: "flex",
    },
    justifyEnd: {
      justifyContent: "end",
    },
    alignEnd: {
      alignItems: "flex-end",
    },
  };
});

const AddExperienceForm = () => {
  const addExperienceFormStyle = useStyles();

  const dispatch = useDispatch();

  const [state, setState] = useState({
    experience_type: 1,
    ex_from: new Date(),
    ex_to: new Date(),
    name: "",
    description: "",
    still_cont: false,
    sub_desc_type: "",
    sub_desc_name: "",
  });

  const { register, handleSubmit, control } = useForm();

  const handleAddExperience = () => {
    dispatch(
      createExperience({
        experience_type: parseInt(state.experience_type),
        ex_from: state.ex_from,
        ex_to: state.ex_to,
        name: state.name,
        description: state.description,
        sub_desc_type: state.sub_desc_type,
        sub_desc_name: state.sub_desc_name,
        still_cont: state.still_cont,
      })
    );

    setState({
      ...state,
      experience_type: 0,
      name: "",
      description: "",
      still_cont: false,
      sub_desc_type: "",
      sub_desc_name: "",
      ex_from: new Date(),
      ex_to: new Date(),
    });
  };

  const handleExperienceType = (value) => {
    value === "Education"
      ? setState({ ...state, experience_type: 0 })
      : setState({ ...state, experience_type: 1 });
  };

  const handleStart = (val) => {
    setState({ ...state, ex_from: val });
  };

  const handleEnd = (val) => {
    setState({ ...state, ex_to: val });
  };

  const handleName = (event) => {
    setState({ ...state, name: event.target.value });
  };

  const handleDescription = (event) => {
    setState({ ...state, description: event.target.value });
  };

  const handleDescriptionName = (event) => {
    setState({ ...state, sub_desc_name: event.target.value });
  };

  const handleDescriptionType = (value) => {
    setState({ ...state, sub_desc_type: value });
  };

  const handleContinueChange = (event) => {
    setState({ ...state, still_cont: event.target.checked });
  };

  return (
    <form onSubmit={handleSubmit(handleAddExperience)}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Autocomplete
            id="clear-on-escape"
            autoComplete
            clearOnEscape
            disableClearable
            options={["Education", "Job"]}
            getOptionLabel={(option) => option}
            onChange={(event, value) => handleExperienceType(value)}
            renderInput={(params) => (
              <TextField
                inputRef={register}
                required
                {...params}
                className={addExperienceFormStyle.fullWidth}
                label="Experience Type"
              />
            )}
          />
        </Grid>

        {state.experience_type === 1 ? (
          <>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                label="Job Title"
                inputRef={register}
                className={addExperienceFormStyle.fullWidth}
                defaultValue={state.name}
                onChange={handleName}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                label="Job Description"
                inputRef={register}
                className={addExperienceFormStyle.fullWidth}
                defaultValue={state.description}
                onChange={handleDescription}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Autocomplete
                id="clear-on-escape"
                autoComplete
                clearOnEscape
                disableClearable
                options={[
                  "High School",
                  "Trade School",
                  "Associate Degree",
                  "Certifications",
                  "Undergraduate Degree",
                  "Graduate Studies",
                  "Doctor of Philosophy (PhD)",
                  "Bachelor's Degree",
                  "Master's Degree",
                  "Doctoral Degree",
                ]}
                onChange={(event, value) => handleDescriptionType(value)}
                renderInput={(params) => (
                  <TextField
                    inputRef={register}
                    required
                    {...params}
                    className={addExperienceFormStyle.fullWidth}
                    label="School/Institute/University"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                label="Course/Subject Details"
                inputRef={register}
                className={addExperienceFormStyle.fullWidth}
                defaultValue={state.sub_desc_name}
                onChange={handleDescriptionName}
              />
            </Grid>
          </>
        )}
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={4}
          className={`${addExperienceFormStyle.displayFlex} ${addExperienceFormStyle.boxStyle}`}
        >
          <Typography variant="body2">Start date</Typography>
          <DatePickerField
            changeDate={handleStart}
            selectedDate={state.ex_from}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={4}
          style={{
            display: state.still_cont === true ? "none" : "flex",
          }}
          className={addExperienceFormStyle.boxStyle}
        >
          <Typography variant="body2">End date</Typography>
          <DatePickerField changeDate={handleEnd} selectedDate={state.ex_to} />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={4}
          className={`${addExperienceFormStyle.displayFlex} ${addExperienceFormStyle.boxStyle}`}
        >
          <FormControlLabel
            control={
              <Checkbox
                control={control}
                checked={state.still_cont}
                onChange={handleContinueChange}
                color="primary"
              />
            }
            label="Still Continuing"
          />
        </Grid>
        <Grid
          item
          xs={12}
          className={`${addExperienceFormStyle.displayFlex} ${addExperienceFormStyle.justifyEnd} ${addExperienceFormStyle.alignEnd}`}
        >
          <Button variant="contained" color="primary" type="submit">
            Add Experience
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddExperienceForm;
