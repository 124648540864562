import API from "../Utils/API";
import clientsConstants from '../constants/clients'
import { history } from "./../Utils/history"

export const createClient = (userData) => dispatch => {
  dispatch({ type: clientsConstants.POST_CLIENT_REQUEST, payload: userData })
  API.post("/api/clients", userData)
    .then(result => {
      dispatch({ type: clientsConstants.POST_CLIENT_SUCCESS, payload: result.data })
      history.push('/company-info')
    })
    .catch(err => {
      dispatch({ type: clientsConstants.POST_CLIENT_FAILURE, payload: err.response.data })
      setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000)
    });
};

export const getClients = (callback) => dispatch => {
  dispatch({ type: clientsConstants.GET_ALL_CLIENTS_REQUEST })
  API.get("/api/clients").then(res => {
    const { data } = res;
    const { id } = data;
    localStorage.setItem("client_id", id);
    dispatch({ type: clientsConstants.GET_ALL_CLIENTS_SUCCESS, payload: res.data })
    {callback && callback(res.data)}
  }).catch(error => {
    dispatch({ type: clientsConstants.GET_ALL_CLIENTS_FAILURE, payload: error.response.data })
    {callback && callback()}
    setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000)
  })
}

export const updateClient = (data,callback) => dispatch => {
  let url = "/api/clients/" + data.id
  dispatch({ type: clientsConstants.PUT_CLIENT_REQUEST, payload: data })
  API.put(url, data).then(result => {
    dispatch(getClients(() => {
      dispatch({ type: clientsConstants.PUT_CLIENT_SUCCESS, payload: result.data })
      {(callback&&callback())}
    }))
  })
    .catch((err) => {
      dispatch({ type: clientsConstants.PUT_CLIENT_FAILURE, payload: err })
    }

    );
};

export const deleteClient = (client_id) => dispatch => {
  let url = "/api/clients/" + client_id
  dispatch({ type: clientsConstants.DELETE_CLIENT_REQUEST })
  API.delete(url).then(result => {
    return dispatch({ type: clientsConstants.DELETE_CLIENT_SUCCESS, payload: result })
  })
    .catch((err) =>

      dispatch({
        type: clientsConstants.DELETE_CLIENT_FAILURE,
        payload: err
      })
    );
};
export const createJob = (userData , callback) => (dispatch, getState) => {
  dispatch({ type: clientsConstants.POST_JOB_REQUEST, payload: userData })
  API.post("/api/jobs", userData)
    .then(result => {
      dispatch({ type: clientsConstants.POST_JOB_SUCCESS, payload: result.data })
      {callback && callback(result.data)}
    })
    .catch(err => {

      dispatch({ type: clientsConstants.POST_JOB_FAILURE, payload: err.response.data });
    });
};
export const getJobs = (job_status) => dispatch => {
  let url = `/api/jobs/?job_status=${job_status}`
  dispatch({ type: clientsConstants.GET_ALL_JOBS_REQUEST })
  API.get(url).then(res => {
    return dispatch({
      type: clientsConstants.GET_ALL_JOBS_SUCCESS,
      payload: res.data
    });
  })
    .catch(err =>
      dispatch({
        type: clientsConstants.GET_ALL_JOBS_FAILURE,
        payload: err
      })
    );
};

export const getHomeJobs = (job_status) => dispatch => {
  let url = `/api/home-jobs/?job_status=${job_status}`
  dispatch({ type: clientsConstants.GET_HOME_JOBS_REQUEST })
  API.get(url).then(res => {
    return dispatch({
      type: clientsConstants.GET_HOME_JOBS_SUCCESS,
      payload: res.data
    });
  })
    .catch(err =>
      dispatch({
        type: clientsConstants.GET_HOME_JOBS_FAILURE,
        payload: err
      })
    );
};

export const getAppliedJobs = () => dispatch => {
  dispatch({ type: "GET_APPLIED_JOBS_REQUEST" })
  API.get("/api/jobs").then(response => {
    return dispatch({ type: "GET_APPLIED_JOBS_SUCCESS", payload: response.data });
  })
    .catch(error =>
      dispatch({ type: "GET_APPLIED_JOBS_FAILURE", payload: error.response.data })
    );
};

export const updateJob = (data , callback) => dispatch => {
  let url = "/api/jobs/" + data.id
  dispatch({ type: clientsConstants.PUT_JOB_REQUEST, payload: data })
  API.put(url, data).then(result => {
    dispatch({ type: clientsConstants.PUT_JOB_SUCCESS, payload: result.data })
    {callback && callback(result.data)}
    setTimeout(() => dispatch({ type: "CLEARING DRAFT JOB" }), 3000)
  })
    .catch((err) => {
      dispatch({ type: clientsConstants.PUT_JOB_FAILURE, payload: err.response.data })
    }
    )
};

export const deleteJob = (job_id, callback) => dispatch => {
  let url = "/api/jobs/" + job_id
  dispatch({ type: clientsConstants.DELETE_JOB_REQUEST })
  API.delete(url).then(result => {
    dispatch({ type: clientsConstants.DELETE_JOB_SUCCESS, payload: result })
    dispatch(getClients(callback))
  })
    .catch((err) => {
      dispatch({ type: clientsConstants.DELETE_JOB_FAILURE, payload: err })
      history.push('./failure-page');
    }

    );
};

export const createJobsSkill = (userData) => dispatch => {
  dispatch({ type: clientsConstants.POST_JOBS_SKILL_REQUEST, payload: userData })
  API.post("/api/jobs_skills", userData)
    .then(result => {
      dispatch({ type: clientsConstants.POST_JOBS_SKILL_SUCCESS, payload: result.data })
      dispatch(getJobsSkills(userData.job_id))
    })
    .catch(err => {
      dispatch({ type: clientsConstants.POST_JOBS_SKILL_FAILURE, payload: err.response.data });
    });
};

export const getJobsSkills = (job_id) => dispatch => {
  let url = `/api/jobs_skills?job_id=${job_id}`
  dispatch({ type: clientsConstants.GET_ALL_JOBS_SKILLS_REQUEST })
  API.get(url).then(res => {
    return dispatch({
      type: clientsConstants.GET_ALL_JOBS_SKILLS_SUCCESS, payload: res.data
    });
  }).catch(err => dispatch({ type: clientsConstants.GET_ALL_JOBS_SKILLS_FAILURE, payload: err }));
};

export const deleteJobsSkill = (data) => dispatch => {
  let url = "/api/jobs_skills/" + data.jobs_skill_id
  dispatch({ type: clientsConstants.DELETE_JOBS_SKILL_REQUEST, payload: data })
  API.delete(url).then(result => {
    dispatch({ type: clientsConstants.DELETE_JOBS_SKILL_SUCCESS, payload: result.data })
    dispatch(getJobsSkills(data.job_id))
  })
    .catch((err) => {
      dispatch({ type: clientsConstants.DELETE_JOBS_SKILL_FAILURE, payload: err })
    }

    );
};


export const createJobsEducation = (data) => dispatch => {
  dispatch({ type: clientsConstants.POST_JOBS_EDUCATION_REQUEST, payload: data })
  API.post("/api/jobs_educations", data)
    .then(result => {
      dispatch({ type: clientsConstants.POST_JOBS_EDUCATION_SUCCESS, payload: result.data })
      dispatch(getJobsEducations(data.job_id))
    })
    .catch(err => {
      dispatch({ type: clientsConstants.POST_JOBS_EDUCATION_FAILURE, payload: err.response.data });
    });
};

export const getJobsEducations = (job_id) => dispatch => {
  let url = `/api/jobs_educations/?job_id=${job_id}`
  dispatch({ type: clientsConstants.GET_ALL_JOBS_EDUCATIONS_REQUEST })
  API.get(url).then(res => {
    return dispatch({
      type: clientsConstants.GET_ALL_JOBS_EDUCATIONS_SUCCESS,
      payload: res.data
    });
  })
    .catch(err =>
      dispatch({
        type: clientsConstants.GET_ALL_JOBS_EDUCATIONS_FAILURE,
        payload: err
      })
    );
};

export const deleteJobsEducation = (data) => dispatch => {
  let url = "/api/jobs_educations/" + data.jobs_education_id
  dispatch({ type: clientsConstants.DELETE_JOBS_EDUCATION_REQUEST })
  API.delete(url).then(result => {
    dispatch({ type: clientsConstants.DELETE_JOBS_EDUCATION_SUCCESS })
    dispatch(getJobsEducations(data.job_id))
  }).then(
  )
    .catch((err) => {
      dispatch({ type: clientsConstants.DELETE_JOBS_EDUCATION_FAILURE, payload: err })
    }

    );
};

export const createQuestion = (userData, job_id) => dispatch => {
  dispatch({ type: clientsConstants.POST_QUESTION_REQUEST, payload: userData })
  API.post("/api/questions", userData)
    .then(result => {
      dispatch(getQuestions(job_id , () => {
        dispatch({ type: clientsConstants.POST_QUESTION_SUCCESS, payload: result.data })
      }))
    })
    .catch(err => dispatch({ type: clientsConstants.POST_QUESTION_FAILURE, payload: err.response.data }));
};

export const getQuestions = (job_id , callback) => (dispatch, state) => {
  let url = `/api/questions/?job_id=${job_id}`
  dispatch({ type: clientsConstants.GET_ALL_QUESTIONS_REQUEST })
  API.get(url).then(res => {
    dispatch({ type: clientsConstants.GET_ALL_QUESTIONS_SUCCESS, payload: res.data });
    {callback&& callback()}
  })
  .catch((error) => {
    try {
      dispatch({
        type: clientsConstants.GET_ALL_QUESTIONS_FAILURE,
        payload: error.response.data,
      });
    } catch {
      // console.log(error);
    }
  });
};


export const deleteQuestion = (question_id, job_id) => dispatch => {
  let url = "/api/questions/" + question_id
  dispatch({ type: clientsConstants.DELETE_QUESTION_REQUEST })
  API.delete(url).then(result => {
    dispatch(getQuestions(job_id , () => {
      dispatch({ type: clientsConstants.DELETE_QUESTION_SUCCESS, payload: result })
    }))
  })
    .catch((err) =>
      dispatch({ type: clientsConstants.DELETE_QUESTION_FAILURE, payload: err.response.data })
    );
};

export const getDashboardClient = () => dispatch => {
  dispatch({ type: clientsConstants.GET_ALL_DASHBOARD_CLIENT_REQUEST })
  API.get("api/dashboard_client").then(result => {
    dispatch({ type: clientsConstants.GET_ALL_DASHBOARD_CLIENT_SUCCESS, payload: result.data });
  })
    .catch(err =>
      dispatch({ type: clientsConstants.GET_ALL_DASHBOARD_CLIENT_FAILURE, payload: err.data })
    );
};

export const clearNewApplicationState = () => dispatch => {
  dispatch({type: "CLEARING NEW APPLICATION STATE"})
};

export const clearLatestJobState = () => dispatch => {
  dispatch({type: "CLEARING LATEST JOB STATE"})
};

export const clearDraftJobState = () => dispatch => {
  dispatch({type : "CLEARING DRAFT JOB"})
}

