/* eslint-disable */
import React, { useEffect, useState } from "react";
import "./AppliedJobs.css";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAppliedJobs } from "../../../Actions/clientAction";
import { makeStyles } from "@material-ui/core";
import {
  CircularProgress,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Button,
  Grid,
} from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";
import UpdateApplication from "../UpdateApplication/UpdateApplication";
import { Empty } from "antd";
import renderHTML from "react-render-html";
import _ from "lodash";

const styles = makeStyles((theme) => {
  return {
    titleContainer: {
      margin: "25px 15px",
      position: "relative",
    },
    inline: {
      display: "inline",
    },
    job_info_box: {
      backgroundColor: "#FFFFFF",
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: 25,
      borderRadius: 5,
      height: "fit-content",
    },
    notApplied: {
      fontSize: 28,
      color: "rgba(0, 0, 0, 0.25)",
      margin: "auto",
      marginTop: 100,
    },
    box: { cursor: "pointer" },
    loadingbox: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "22%",
      paddingLeft: "47%",
    },
    job_info_text: {
      padding: "8px 5px",
    },
    title: {
      fontSize: 28,
      marginTop: "5px",
      marginLeft: "5px",
      marginBottom: "10px",
      display: "flex",
      flexWrap: "wrap",
      lineHeight: "30px",
    },
    contact: {
      marginLeft: "5px",
      lineHeight: "16px",
    },
    posted: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginTop: "5px",
      marginLeft: "5px",
    },
    avatar: {
      width: 25,
      height: 25,
      borderRadius: "50%",
      marginTop: 0,
    },
    image: {
      width: 80,
      height: 80,
      borderRadius: "50%",
      marginTop: "15px",
    },
    clientName: {
      color: "#ffffff",
      fontSize: "45px",
    },
    bold: { fontWeight: "bold" },
    url: {
      fontWeight: "bold",
      width: 200,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    myApplication: {
      fontSize: 28,
      marginLeft: 14,
      lineHeight: 1,
    },
    appDesc: {
      backgroundColor: "#dbebf9",
      width: "50%",
      overflow: "scroll",
    },
    appDesc1: {
      backgroundColor: "#f5f5f5",
      width: "50%",
      overflow: "scroll",
    },
    attachment: {
      backgroundColor: "#f5f5f5",
      width: "50%",
      height: 31,
    },
    attachmentTypo: {
      fontWeight: "bold",
      width: 200,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    update: {
      float: "right",
      margin: 8,
      background: "#00D3A7",
      color: "#ffffff",
      border: "1px solid #00D3A7",
    },
    divider: {
      margin: "5px",
    },
    descriptionContainer: {
      margin: "10px 0px",
      // marginLeft:"5px",
      position: "relative",
      fontWeight: "bold",
    },
    description: {
      fontWeight: "normal",
      overflowY: "scroll",
      height: "100px",
      marginTop: 10,
      fontSize: "14px",
    },
    lessDesc: {
      height: 30,
    },
    less: {
      position: "absolute",
      top: "-5px",
      right: 0,
    },
    nodata: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    fitImage: {
      objectFit: "contain",
      height: "auto",
    },
  };
});

const AppliedJobs = () => {
  const dispatch = useDispatch();
  const appliedStyles = styles();

  const { applied_jobs, isGetAppliedJobsLoading } = useSelector((state) => {
    const { applied_jobs, isGetAppliedJobsLoading } = state.client;
    return { applied_jobs, isGetAppliedJobsLoading };
  });

  const [state, setState] = useState({
    job: "",
    currentJob: "",
    updateApplicationScreen: false,
  });

  useEffect(() => {
    setAppliedJob();
  }, [applied_jobs]);

  useEffect(() => {
    dispatch(getAppliedJobs());
  }, []);

  const setAppliedJob = () => {
    setState({
      ...state,
      job: applied_jobs && applied_jobs.length ? applied_jobs[0] : {},
      currentJob: applied_jobs && applied_jobs.length ? applied_jobs[0] : {},
    });
  };

  const handleUpdateApplicationScreen = () => {
    setState({
      ...state,
      updateApplicationScreen: !state.updateApplicationScreen,
    });
    dispatch(getAppliedJobs());
  };

  const handleSetJobApplied = (job) => {
    setState({
      ...state,
      job: job,
      currentJob: job,
    });
  };

  const truncate = (text, max) => {
    const Text = text.trim().split(" ");
    const more = Text.length > max ? "..." : "";
    return Text.slice(0, max).join(" ") + more;
  };

  let max = 20;

  const [more, setMore] = useState({
    isMore: false,
    Id: "",
  });

  const handleMore = (id) => {
    setMore({
      ...more,
      isMore: true,
      Id: id,
    });
  };

  const handleLess = () => {
    setMore({
      ...more,
      isMore: false,
      size: false,
    });
  };

  const { updateApplicationScreen, job, currentJob } = state;
  const { my_job_application } = job;

  return isGetAppliedJobsLoading ? (
    <Grid className={appliedStyles.loadingbox}>
      <CircularProgress size={70} />
    </Grid>
  ) : updateApplicationScreen ? (
    <UpdateApplication
      handleUpdateApplicationScreen={handleUpdateApplicationScreen}
      application={currentJob.my_job_application}
      myApplication={currentJob}
    />
  ) : !(undefined !== applied_jobs && applied_jobs.length) ? (
    <Typography variant="h5" gutterBottom className={appliedStyles.notApplied}>
      No jobs applied yet
    </Typography>
  ) : (
    <Grid className="container">
      <List className="applied-root">
        <Typography variant="h3" style={{ textAlign: "center" }}>
          Applied Jobs
        </Typography>
        <Divider component="li" className={appliedStyles.divider} />
        {Object.keys(applied_jobs).length
          ? applied_jobs.map((job, i) => {
              return (
                <Grid
                  key={i}
                  onClick={() => handleSetJobApplied(job)}
                  className={appliedStyles.box}
                >
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        alt={job.client.name}
                        variant="rounded"
                        src={job.client.company_logo.url}
                        classes={{ 
                          img: appliedStyles.fitImage,
                        }}
                      >
                        <BusinessIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={job.title}
                      secondary={
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          @{job.client.name}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <Divider component="li" className={appliedStyles.divider} />
                </Grid>
              );
            })
          : null}
      </List>
      {Object.keys(job).length ? (
        <Grid className="job-info-container">
          <Grid className={appliedStyles.job_info_box}>
            <Grid className={appliedStyles.image_container}>
              {job.client.company_logo.url ? (
                <Avatar
                  className={appliedStyles.image}
                  src={job.client.company_logo.url}
                  alt={job.client.name}
                  classes={{ 
                    img: appliedStyles.fitImage,
                  }}
                />
              ) : (
                <Avatar className={appliedStyles.image}>
                  <Typography className={appliedStyles.clientName}>
                    {job.client.name[0]}
                  </Typography>
                </Avatar>
              )}
            </Grid>
            <Grid container className={appliedStyles.job_info_text}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h5" className={appliedStyles.title}>
                  {job.title}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h6" className={appliedStyles.contact}>
                  {job.client.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h6" className={appliedStyles.posted}>
                  Posted by: &nbsp;&nbsp;
                  <Avatar
                    className={appliedStyles.avatar}
                    alt={job.user.first_name}
                    src={job.user.image_url.url}
                  />
                  &nbsp;&nbsp;{job.user.first_name} {job.user.last_name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={appliedStyles.divider} />

          <Grid container className={appliedStyles.titleContainer}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography variant="body1" className={appliedStyles.bold}>
                Job Posting Date:
              </Typography>
              <Typography variant="body1">
                {new Intl.DateTimeFormat("en-GB", {
                  year: "numeric",
                  month: "long",
                  day: "2-digit",
                }).format(Date.parse(job.created_at))}
              </Typography>
            </Grid>
            {/* <Grid item xs={6} sm={6} md={4} lg={4} className={appliedStyles.titleContainer}>
              <Box >
                <Typography variant="body1" className={appliedStyles.bold}>
                  Job Status:
                </Typography>
                <Typography variant="body1">{job.job_status}</Typography>
              </Box>
            </Grid> */}
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography variant="body1" className={appliedStyles.bold}>
                Job Type:
              </Typography>
              <Typography variant="body1">{job.job_type}</Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={appliedStyles.titleContainer}
          >
            <Typography
              variant="body1"
              component={"div"}
              className={
                (appliedStyles.bold, appliedStyles.descriptionContainer)
              }
            >
              Job Description:
              <Typography
                component={"div"}
                className={
                  job.description.length > max
                    ? appliedStyles.description
                    : appliedStyles.lessDesc
                }
              >
                {job.description ? (
                  more.isMore &&
                  more.Id === job.id &&
                  job.description.length > max ? (
                    <>
                      <a className={appliedStyles.less} onClick={handleLess}>
                        Read Less
                      </a>
                      {renderHTML(job.description)}
                    </>
                  ) : (
                    <>
                      {job.description.length < max ? (
                        renderHTML(job.description)
                      ) : (
                        <>
                          {renderHTML(truncate(job.description, max))}
                          <a onClick={() => handleMore(job.id)}>Read more</a>
                        </>
                      )}
                    </>
                  )
                ) : (
                  <Grid className={appliedStyles.nodata}>
                    <Empty
                      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                      imageStyle={{
                        height: 30,
                        marginTop: "-10px",
                      }}
                      description={"No Description"}
                    />
                  </Grid>
                )}
              </Typography>
            </Typography>
          </Grid>

          <Grid container className={appliedStyles.titleContainer}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography variant="body1" className={appliedStyles.bold}>
                Offered Rate:
              </Typography>
              <Typography variant="body1">{job.rate_amount}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography variant="body1" className={appliedStyles.bold}>
                Phone Number:
              </Typography>
              <Typography variant="body1">{job.client.phone_number}</Typography>
            </Grid>
          </Grid>
          <Grid container className={appliedStyles.titleContainer}>
            <Typography variant="body1" className={appliedStyles.bold}>
              Company Website:&nbsp;
            </Typography>
            <Typography variant="body1" className={appliedStyles.url}>
              <a onClick={() => window.open(job.client.website_url, "_blank")}>
                {job.client.website_url}
              </a>
            </Typography>
          </Grid>
          <Grid container className={appliedStyles.titleContainer}>
            <Typography variant="body1" className={appliedStyles.bold}>
              Company LinkedIn Address:&nbsp;
            </Typography>
            <Typography variant="body1" className={appliedStyles.url}>
              <a onClick={() => window.open(job.client.linked_url, "_blank")}>
                {job.client.linked_url}
              </a>
            </Typography>
          </Grid>
          <br />
          <Divider className={appliedStyles.divider} />
          <br />
          <Typography
            variant="h5"
            gutterBottom
            className={appliedStyles.myApplication}
          >
            My Application
          </Typography>
          <br />
          <Divider className={appliedStyles.divider} />
          <Grid container className={appliedStyles.titleContainer}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography variant="body1" className={appliedStyles.bold}>
                Interest:
              </Typography>
              <Typography component={"span"} variant="body1">
                {job.my_job_application.description}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography variant="body1" className={appliedStyles.bold}>
                Experience:
              </Typography>
              <Typography component={"span"} variant="body1">
                {job.my_job_application.description_1}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={appliedStyles.titleContainer}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography variant="body1" className={appliedStyles.bold}>
                Comment:
              </Typography>
              <Typography component={"span"} variant="body1">
                {my_job_application.description_2}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography variant="body1" className={appliedStyles.bold}>
                Payment type:
              </Typography>
              <Typography variant="body1">
                {job.my_job_application.payment_type}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={appliedStyles.titleContainer}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography variant="body1" className={appliedStyles.bold}>
                Proposed rate:
              </Typography>
              <Typography variant="body1">
                {job.my_job_application.proposed_rate}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography variant="body1" className={appliedStyles.bold}>
                Expected start date:
              </Typography>
              <Typography variant="body1">
                {new Intl.DateTimeFormat("en-GB", {
                  year: "numeric",
                  month: "long",
                  day: "2-digit",
                }).format(
                  Date.parse(job.my_job_application.expected_start_date)
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid className={appliedStyles.titleContainer}>
            <Typography variant="body1" className={appliedStyles.bold}>
              Attachment url:&nbsp;
            </Typography>
            <Typography
              variant="body1"
              className={appliedStyles.attachmentTypo}
            >
              <a
                onClick={() =>
                  window.open(
                    job.my_job_application.attachment_url.url,
                    "_blank"
                  )
                }
              >
                {job.my_job_application.attachment_url.url}
              </a>
            </Typography>
          </Grid>

          {my_job_application &&
            my_job_application.application_status === null && (
              <Button
                onMouseOver={(e) => (e.target.style.color = "#00D3A7")}
                onMouseOut={(e) => (e.target.style.color = "#ffffff")}
                className={appliedStyles.update}
                onClick={handleUpdateApplicationScreen}
              >
                Update Application
              </Button>
            )}
        </Grid>
      ) : null}
    </Grid>
  );
};

export default withRouter(AppliedJobs);
