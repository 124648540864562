import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Typography, Grid } from "@material-ui/core";
import './UserStats.css';

const useStyles = makeStyles((theme) => ({
  label: {
    color: "#000000",
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    letterSpacing: "0.1px",
    marginLeft:"5px"
  },
  sublabel: {
    color: "#0C0033",
    fontSize: "",
    opacity: 0.5,
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    letterSpacing: "0.1px",
    marginLeft:"5px"
  },
}));

export default function UserStats(props) {
  const classes = useStyles();
  const { value, label, sublabel , color } = props;

  return (
    <Paper elevation={15} className="paper">
      <Grid container>
        <Grid item>
          <Typography variant="subtitle1" className={classes.label}>
            {label}
          </Typography>         
          <Typography variant="subtitle1" className={classes.sublabel}>
            {sublabel}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" style={{ background : color , color:"#ffffff" }} className="value">
            {value}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
