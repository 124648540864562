import React from "react";
import QuestionListItem from "./QuestionListItem";
import { useSelector } from "react-redux";

const QuestionList = (props) => {
  const { questions } = useSelector((state) => {
    const { client } = state;
    const { questions } = client;
    return { questions };
  });

  return (
    questions &&
    questions.map((index, i) => (
      <QuestionListItem
        item={index}
        id={index.id}
        deleteItem={() => props.deleteItem(index.id)}
        key={index.id}
      />
    ))
  );
};

export default QuestionList;
