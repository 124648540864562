import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { history } from "../../../Utils/history";
import {
  getQuestions,
  deleteJob,
  createJobsSkill,
  getJobsSkills,
  createJobsEducation,
  getJobsEducations,
  deleteJobsSkill,
  deleteJobsEducation,
  getClients,
} from "../../../Actions/clientAction";
import {
  getJobsApplications,
  getJobsAnswers,
} from "../../../Actions/freelancerAction";
import { getSkills } from "../../../Actions/userAction";
import { getRates } from "../../../Actions/userAction";
import FeedbackList from "../../../Components/FeedbackList";
import AutoComplete from "../../../Components/Form/Autocomplete";
import IgButton from "../../../Components/Form/IgButton";
import EditJob from "../EditJob/EditJob";
import ApplicantDetails from "../ApplicantDetails/ApplicantDetails";
import ClientAddQuestion from "../ClientAddQuestion/ClientAddQuestion";
import JobList from "../JobList/JobList";
import { Table, Button, Row, Typography, Spin, Empty } from "antd";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  MenuItem,
  Container,
  makeStyles,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Divider from "@material-ui/core/Divider";
import CancelIcon from "@material-ui/icons/Cancel";
import { options2 } from "../../../Utils/clientData";
import CandidateStatus from "./CandidateStatus";
import "./JobDetails.css";

const { Text } = Typography;
let data = [];
let mySkillSet = [];
const skillHandler = (value) => {
  mySkillSet = value;
};

const styles = makeStyles(() => {
  return {
    applicant: {
      display: "flex",
      flexDirection: "row",
      marginTop: 15,
      justifyContent: "center",
    },
    subApplicant: {
      display: "flex",
      flexDirection: "row",
      maxWidth: "max-content",
    },
    nodata: {
      height: 50,
      borderRadius: 25,
      marginTop: -15,
    },
    status: {
      display: "flex",
      flexDirection: "column",
      marginTop: -10,
      marginLeft: 10,
      lineHeight: "17px",
    },
    expectedDate: { marginLeft: 60 },
    action: {
      backgroundColor: "#0b196a",
      borderColor: "#0b196a",
      color: "white",
    },
    headerContainer: {
      padding: "15px 0px",
    },
    skillEducationContainer: {
      padding: "15px 0px 5px",
    },
    title: {
      textAlign: "left",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "28px",
      color: "#000000",
    },
    titleName: { fontWeight: 300 },
    headerButtons: {
      position: "absolute",
      right: "0",
    },
    jobSidetitles: { fontWeight: "bold" },
    editIcon: {
      cursor: "pointer",
      color: "#0B196A",
      fontSize: "14px",
      marginLeft: "8px",
    },
    EditIcon: {
      marginRight: "4px",
    },
    deleteIcon: {
      marginRight: "4px",
    },
    skillContainer: {
      width: "100%",
      maxHeight: 400,
    },
    skills: {
      background: "rgba(0, 0, 0, 0.1)",
      borderRadius: "70px",
      maxWidth: "max-content",
      padding: "5px 10px",
      margin: 5,
      display: "flex",
      alignItems: "center",
      position: "relative",
    },
    educationGrid: {
      width: "100%",
      maxHeight: 400,
    },
    education: {
      borderRadius: "70px",
      background: "rgba(0, 0, 0, 0.1)",
      maxWidth: "max-content",
      padding: "5px 10px",
      margin: 5,
      display: "flex",
      alignItems: "center",
      position: "relative",
    },
    cancelIcon: {
      fontSize: "14px",
      cursor: "pointer",
    },
    questionButton: { margin: 8 },
    editButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "none",
      color: "#0B196A",
      background: "none",
      fontSize: "14px",
    },
    cancelButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "5px",
      border: "none",
      color: "#EB5757",
      background: "none",
      fontSize: "14px",
    },
    autocomplete: {
      padding: "0px 20px",
    },
    divider: {
      background: "#E0E0E0",
    },
    applicantList: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "25px",
      color: "#4F4F4F",
    },
  };
});

const JobDetails = (props) => {
  const dispatch = useDispatch();
  const detailStyles = styles();
  let job = "";

  const { Id } = props;

  const {
    JobsApplication,
    isGetAllJobsApplicationsLoading,
    Rates,
    isGetAllRatesLoading,
    allSkills,
    questions,
    job_skills,
    job_educations,
    jobs,
    isDeleted,
    isGetAllClientsLoading,
  } = useSelector((state) => {
    const { JobsApplication, isGetAllJobsApplicationsLoading } =
      state.freelancer;
    const { user } = state;
    const { Rates, isGetAllRatesLoading, allSkills } = user;
    const {
      questions,
      job_skills,
      job_educations,
      clients,
      isDeleted,
      isGetAllClientsLoading,
    } = state.client;
    const { jobs } = clients;
    jobs.map((each) => {
      return props.job && props.job && each.id === props.job.id && (job = each);
    });
    return {
      JobsApplication,
      isGetAllJobsApplicationsLoading,
      isGetAllClientsLoading,
      Rates,
      isGetAllRatesLoading,
      questions,
      job_skills,
      allSkills,
      job_educations,
      jobs,
      isDeleted,
    };
  });

  const [state, setState] = useState({
    update: false,
    sortedInfo: null,
    questions: [],
    editJobData: null,
    applicantDetails: null,
    job_id: job.id,
    questionsProp: null,
    jobListRedirection: false,
    skillDialogOpen: false,
    educationDialogOpen: false,
    current_skill: "",
    job_education: "",
    currentJob: "",
  });

  let {
    applicantDetails,
    job_id,
    questionsProp,
    jobListRedirection,
    sortedInfo,
    editJobData,
    skillDialogOpen,
    educationDialogOpen,
    job_education,
    currentJob,
  } = state;

  currentJob && (job = state.currentJob);

  data = JobsApplication;

  useEffect(() => {
    let newJob = currentJob ? currentJob : job;
    dispatch(getClients());
    dispatch(getJobsSkills(newJob.id));
    dispatch(getJobsEducations(newJob.id));
    dispatch(getQuestions(newJob.id));
    dispatch(getSkills());
    dispatch(getJobsApplications(newJob.id));
    dispatch(getRates(newJob.id));
  }, [state.currentJob]);

  useEffect(() => {
    const newJob = job ? job : props.job;
    dispatch(getSkills());
    dispatch(getJobsApplications(newJob.id));
    dispatch(getQuestions(newJob.id));
    dispatch(getJobsSkills(newJob.id));
    dispatch(getJobsEducations(newJob.id));
    dispatch(getRates(newJob.id));
    setState({
      ...state,
      job_id: newJob.id,
      rateLoaded: true,
      editJobData: null,
      currentJob: "",
      applicantDetails: "",
    });
  }, [Id]);

  const handleSkillDialogOpen = () => {
    setState({
      ...state,
      skillDialogOpen: true,
    });
  };
  const handleSkillDialogClose = () => {
    setState({
      ...state,
      skillDialogOpen: false,
    });
  };
  const handleEducationDialogOpen = () => {
    setState({
      ...state,
      educationDialogOpen: true,
    });
  };
  const handleEducationDialogClose = () => {
    setState({
      ...state,
      educationDialogOpen: false,
    });
  };
  const handleView = (id) => {
    let applicantDetails = data.filter((index) => {
      return index.id === id;
    });
    dispatch(getJobsAnswers(applicantDetails.id));
    setState({
      ...state,
      applicantDetails: applicantDetails,
    });
  };

  const handleEditJobPost = (job) => {
    setState({
      ...state,
      editJobData: job,
    });
  };

  const handleDeleteJobPost = (job) => {
    dispatch(
      deleteJob(job.id, () => {
        dispatch(
          getClients((data) => {
            setState({
              ...state,
              currentJob: data.jobs[data.jobs.length - 1],
            });
          })
        );
      })
    );
  };

  const handleAddQuestions = (job) => {
    setState({
      ...state,
      job_id: job.id,
      questionsProp: questions,
    });
  };

  const makeReverseRedirection = () => {
    setState({
      ...state,
      job_id: "",
      questionsProp: null,
      editJobData: "",
      applicantDetails: "",
    });
  };

  const handleJobEducationChange = (event) => {
    setState({
      ...state,
      job_education: event.target.value,
    });
  };

  const handleSkill = (event) => {
    setState({
      ...state,
      current_skill: event.target.value,
    });
  };

  const handleAddSkill = () => {
    let skill_array = [];
    mySkillSet.forEach((skill) => {
      skill_array.push(skill.id);
    });
    dispatch(
      createJobsSkill({
        job_id: state.job_id,
        skill_ids: skill_array,
      })
    );
    handleSkillDialogClose();
  };
  const handleDeleteJobSkill = (jobs_skill_id) => {
    dispatch(
      deleteJobsSkill({
        job_id: state.job_id,
        jobs_skill_id: jobs_skill_id,
      })
    );
  };
  const handleDeleteJobEducation = (jobs_education_id) => {
    dispatch(
      deleteJobsEducation({
        job_id: state.job_id,
        jobs_education_id: jobs_education_id,
      })
    );
  };
  const handleCreateJobEducation = () => {
    dispatch(
      createJobsEducation({
        education_type: state.job_education,
        job_id: state.job_id,
      })
    );
    handleEducationDialogClose();
  };

  sortedInfo = sortedInfo || {};
  const columns = [
    {
      title: "Applicant Name",
      dataIndex: "user",
      colSpan: 2,
      render: (dataIndex) => {
        return {
          children: (
            <Row className={detailStyles.applicant}>
              <Row className={detailStyles.subApplicant}>
                {undefined !== dataIndex.image_url.url ? (
                  <img
                    src={dataIndex.image_url.url}
                    alt=""
                    className={detailStyles.nodata}
                  />
                ) : null}
                <Row className={detailStyles.status}>
                  <Text>
                    {dataIndex.first_name + " " + dataIndex.last_name}
                  </Text>
                  <CandidateStatus data={data} statusC={dataIndex} />
                </Row>
              </Row>
            </Row>
          ),
          props: { colSpan: 2 },
        };
      },
    },
    {
      title: "Rate",
      dataIndex: "proposed_rate",
      render: (dataIndex) => <Text>${dataIndex}/hour</Text>,
      sorter: (a, b) => a.proposed_rate - b.proposed_rate,
      sortOrder: sortedInfo.columnKey === "proposed_rate" && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: "Expected start date",
      dataIndex: "expected_start_date",
      colSpan: 2,
      render: (dataIndex) => {
        return {
          children: (
            <Text className={detailStyles.expectedDate}>
              {new Intl.DateTimeFormat("en-GB", {
                year: "numeric",
                month: "long",
                day: "2-digit",
              }).format(Date.parse(dataIndex))}
            </Text>
          ),
          props: {
            colSpan: 2,
          },
        };
      },
      sorter: (a, b) => a.expected_start_date - b.expected_start_date,
      sortOrder:
        sortedInfo.columnKey === "expected_start_date" && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (dataIndex) => (
        <Button
          className={detailStyles.action}
          onClick={() => {
            handleView(dataIndex);
          }}
        >
          View
        </Button>
      ),
    },
  ];

  return applicantDetails && applicantDetails ? (
    <ApplicantDetails
      makeReverseRedirection={makeReverseRedirection}
      applicantData={applicantDetails}
      Id={job.id}
      job={job}
      deleteRedirection={props.deleteRedirection}
    />
  ) : jobListRedirection && jobListRedirection ? (
    <JobList isRedirected="true" />
  ) : job_id && questionsProp ? (
    <ClientAddQuestion
      job_id={job.id}
      questions={questionsProp}
      makeReverseRedirection={makeReverseRedirection}
    />
  ) : editJobData && editJobData ? (
    <EditJob
      job={editJobData}
      Id={editJobData.id}
      makeReverseRedirection={makeReverseRedirection}
      deleteRedirection={props.deleteRedirection}
    />
  ) : (
    <Container className="detail-root">
      {jobs.length === 0 ? (
        <Typography className="data">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Typography>
      ) : (
        job && (
          <>
            <Grid container className="mainContainer">
              <Grid item xs={12} sm={7} md={7} lg={7}>
                <Typography className={detailStyles.title}>
                  Job Name :{" "}
                  <Typography
                    component="span"
                    className={detailStyles.titleName}
                  >
                    {job.title}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} className="buttons">
                <Button
                  type="submit"
                  className={detailStyles.editButton}
                  onClick={() => handleEditJobPost(job)}
                >
                  <EditIcon
                    fontSize="small"
                    className={detailStyles.EditIcon}
                  />{" "}
                  Edit
                </Button>
                <Button
                  type="submit"
                  className={detailStyles.cancelButton}
                  onClick={() => handleDeleteJobPost(job)}
                >
                  <DeleteIcon
                    fontSize="small"
                    className={detailStyles.deleteIcon}
                  />{" "}
                  Delete
                </Button>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              container
              spacing={6}
              className={detailStyles.headerContainer}
            >
              <Grid item>
                <Typography
                  variant="body1"
                  className={detailStyles.jobSidetitles}
                >
                  Job status
                </Typography>

                <Typography variant="body1">{job.job_status}</Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  className={detailStyles.jobSidetitles}
                >
                  Job Type
                </Typography>

                <Typography variant="body1">{job.job_type}</Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  className={detailStyles.jobSidetitles}
                >
                  Job Created{" "}
                </Typography>

                <Typography variant="body1">
                  {job.created_at &&
                    new Intl.DateTimeFormat("en-GB", {
                      year: "numeric",
                      month: "long",
                      day: "2-digit",
                    }).format(Date.parse(job.created_at && job.created_at))}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  className={detailStyles.jobSidetitles}
                >
                  Last Updated
                </Typography>

                <Typography variant="body1">
                  {job.updated_at &&
                    new Intl.DateTimeFormat("en-GB", {
                      year: "numeric",
                      month: "long",
                      day: "2-digit",
                    }).format(Date.parse(job.updated_at && job.updated_at))}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              className={detailStyles.headerContainer}
            >
              <Grid item>
                <Typography
                  variant="body1"
                  className={detailStyles.jobSidetitles}
                >
                  Job Description :{" "}
                </Typography>

                <Typography variant="body1">{job.description}</Typography>
              </Grid>
            </Grid>

            {undefined !== job_skills && job_skills.length ? (
              <>
                <Grid container className={detailStyles.headerContainer}>
                  <Typography
                    variant="body1"
                    className={detailStyles.jobSidetitles}
                  >
                    Skills{" "}
                    <EditIcon
                      onClick={handleSkillDialogOpen}
                      className={detailStyles.editIcon}
                    />
                  </Typography>

                  <Grid
                    container
                    direction="row"
                    wrap="wrap"
                    className={
                      (detailStyles.skillContainer,
                      detailStyles.skillEducationContainer)
                    }
                  >
                    {job_skills.map((index, i) => {
                      return (
                        <Typography
                          key={i}
                          variant="body1"
                          className={detailStyles.skills}
                        >
                          {index.skill.name}&nbsp;
                          <CancelIcon
                            className={detailStyles.cancelIcon}
                            onClick={() => handleDeleteJobSkill(index.id)}
                          />
                        </Typography>
                      );
                    })}
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Typography
                  variant="body1"
                  className={detailStyles.jobSidetitles}
                >
                  Skills required:
                  <EditIcon
                    onClick={handleSkillDialogOpen}
                    className={detailStyles.editIcon}
                  />
                </Typography>
              </>
            )}
            <Dialog
              fullWidth={true}
              maxWidth="xs"
              open={skillDialogOpen}
              onClose={handleSkillDialogClose}
              disableEnforceFocus
              className={detailStyles.skillDialog}
            >
              <DialogContent>
                <DialogContentText>Add skills</DialogContentText>
                {allSkills ? (
                  <AutoComplete
                    label="Skills"
                    disablePortal
                    className={detailStyles.autocomplete}
                    data={allSkills}
                    onChange={handleSkill}
                    jobSkills={(value) => skillHandler(value)}
                  />
                ) : null}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleSkillDialogClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleAddSkill} color="primary">
                  Add
                </Button>
              </DialogActions>
            </Dialog>
            {undefined !== job_educations && job_educations.length ? (
              <>
                <Typography
                  variant="body1"
                  className={detailStyles.jobSidetitles}
                >
                  Education{" "}
                  <EditIcon
                    onClick={handleEducationDialogOpen}
                    className={detailStyles.editIcon}
                  />
                </Typography>
                <Grid
                  container
                  direction="row"
                  wrap="wrap"
                  className={
                    (detailStyles.educationGrid,
                    detailStyles.skillEducationContainer)
                  }
                >
                  <Typography
                    variant="body1"
                    className={detailStyles.education}
                  >
                    {job_educations[0].education_type}&nbsp;
                    <CancelIcon
                      className={detailStyles.cancelIcon}
                      onClick={() =>
                        handleDeleteJobEducation(job_educations[0].id)
                      }
                    />
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                <Grid container className={detailStyles.headerContainer}>
                  <Typography
                    variant="body1"
                    className={detailStyles.jobSidetitles}
                  >
                    Job Education required:{" "}
                    <EditIcon
                      className={detailStyles.editIcon}
                      onClick={handleEducationDialogOpen}
                    />
                  </Typography>
                </Grid>
              </>
            )}
            <Dialog
              fullWidth={true}
              maxWidth={"xs"}
              open={educationDialogOpen}
              onClose={handleEducationDialogClose}
            >
              <DialogContent>
                <DialogContentText>Add Job Education</DialogContentText>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  select
                  value={job_education}
                  onChange={handleJobEducationChange}
                  label="Add Job Education"
                  autoFocus
                >
                  {options2.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleEducationDialogClose}>Cancel</Button>
                <Button onClick={handleCreateJobEducation}>Add</Button>
              </DialogActions>
            </Dialog>
            <Grid container className={detailStyles.headerContainer}>
              <Grid item>
                <Typography
                  variant="body1"
                  className={detailStyles.jobSidetitles}
                >
                  Rate Offered
                </Typography>

                <Typography variant="body1">{job.rate_amount}</Typography>
              </Grid>
            </Grid>
            <Divider className={detailStyles.divider} />
            <Grid container className={detailStyles.headerContainer}>
              <IgButton
                type="submit"
                variant="contained"
                color="primary"
                className={detailStyles.questionButton}
                label={
                  "View All Questions " +
                  "(" +
                  ((questions && questions.length) || "0") +
                  ")"
                }
                onClick={() => handleAddQuestions(job)}
              ></IgButton>
            </Grid>
            <Divider className={detailStyles.divider} />
            <Spin spinning={isGetAllJobsApplicationsLoading}>
              <Grid container className={detailStyles.headerContainer}>
                <Typography
                  variant="body1"
                  className={detailStyles.applicantList}
                >
                  Applicant List ({JobsApplication.length})
                </Typography>
              </Grid>
              <div className="applicant_table">
                <Table
                  className={detailStyles.headerContainer}
                  columns={columns}
                  rowKey={(key) => key.id}
                  dataSource={JobsApplication}
                  filter="true"
                />
              </div>
            </Spin>

            <Spin spinning={isGetAllRatesLoading}>
              <FeedbackList data={Rates} />
            </Spin>
          </>
        )
      )}
    </Container>
  );
};

export default withRouter(JobDetails);
