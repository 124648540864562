import React from 'react';
import { Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../../../Screens/MyAccount/theme'
import 'react-perfect-scrollbar/dist/css/styles.css';
import '../../../Screens/MyAccount/assets/scss/index.scss';
import Routes from './Routes';
import { history } from "../../../Utils/history";

const App = () => {
    return (
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <Routes />
        </Router>
      </ThemeProvider>
    );
}

export default App;

