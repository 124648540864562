import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { Grid, Paper } from "@material-ui/core";
// import Topbar from "./../../Components/Topbar";
import { connect } from "react-redux";
import IgButton from "../../Components/Form/IgButton";



const people_search = require("./../../Assets/images/undraw_people_search.svg");
const wall = require("./../../Assets/images/undraw_wall.svg");
const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#F7F9FB'
    },
    grid: {
        width: 1200,
        [theme.breakpoints.down("sm")]: {
            width: "calc(100% - 20px)"
        }
    },
    gridTop: {
        width: 1200,
        [theme.breakpoints.down("sm")]: {
            width: "calc(100% - 20px)"
        }
    },
		paper: {
			padding: theme.spacing(3),
			textAlign: "left",
			backgroundColor: "#FFFFFF",
			color: theme.palette.text.secondary,
			// marginTop: 50,
			borderRadius: 3,
			boxShadow: "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
		},

    // topBar: {
	// 		display: "flex",
	// 		justifyContent: "space-between",
	// 		alignItems: "center",

	// 	},
    loadingState: {
        opacity: 0.05
    },
    loadingMessage: {
        position: "absolute",
        top: "40%",
        left: "40%"
    },
    mediaImage: {
        width: "238px",
        margin: "0 auto",
				marginBottom: "20px",
				display: "flex",
				justifyContent: "center",
    },

    submit: {
        margin: theme.spacing(3, 0, 2),
        textTransform: 'none'
    }
});

class EmptyDashboardCompany extends Component {
    state = {
        loading: true,
        amount: 15000,
        period: 3,
        start: 0,
        monthlyInterest: 0,
        totalInterest: 0,
        monthlyPayment: 0,
        totalPayment: 0,
        data: []
	};

	handleBrowsJob = () => {
		window.location.href='/client-job-list';
	  };

	handleCreateJob = () => {
		window.location.href='/client-job-list';
	  };
render() {
        const { classes } = this.props;
        // const currentPath = this.props.location.pathname;

return (
<React.Fragment>
	<CssBaseline />
		{/* <Topbar currentPath={currentPath} /> */}
			<div className={classes.root}>
				<Grid container justify="center">
					<Grid
						spacing={3}
						alignItems="center"
						justify="center"
						container
						className={classes.grid}
					>

<Grid item xs={12} style={{	marginTop: 90}}>
	<Typography variant="h5" gutterBottom style={{ color: "#000000", textAlign: "center" }}>
		Loking to hire a freelancer? Start with posting a new job or explore talent.
	</Typography>
</Grid>

<Grid item xs={12} md={4}>
	<Paper className={classes.paper}>
		<div>
	<div className={classes.mediaImage}>
		<img src={wall} alt="InsureGig" style={{ height: "200px", width: "300px" }}/>
	</div>

	<Typography  variant="body1" gutterBottom style={{ color: "#0C0033", textAlign: 'center'}}>
		Post a job in InsureGig website and find suitable candidate for your requirements
	</Typography>

			<div style={{ display: "flex", justifyContent: "center" }}>
				<IgButton
						type="submit"
						color="primary"
						variant="contained"
						className={classes.submit}
						label="Post a job"
						onClick={() => this.handleCreateJob()}

				>
				</IgButton>
			</div>
		</div>
	</Paper>
</Grid>

		<Grid item xs={12} md={4}>
			<Paper className={classes.paper}>
				<div>
					<div className={classes.mediaImage}>
						<img src={people_search} alt="InsureGig" style={{ height: "220px", width: "300px" }}
						/>
					</div>


						<Typography align='center' variant="body1" gutterBottom style={{color: "#0C0033"}}>
							Browse through different talents in InsureGig website
						</Typography>
				<div style={{ display: "flex", justifyContent: "center" }}>
						<IgButton
								type="submit"
								color="primary"
								variant="contained"
								className={classes.submit}
								label="Browse Talent"
								onClick={() => this.handleBrowsJob()}

						>
						</IgButton>
					</div>
				</div>
			</Paper>
		</Grid>
	</Grid>
</Grid>



	</div>
</React.Fragment>
        );
    }
}

const mapStateToProp = state => {
    return {
        auth: state.auth
    };
};
export default connect(mapStateToProp)(
    withRouter(withStyles(styles)(EmptyDashboardCompany))
);
