import React from 'react';
import { updateUserInfo } from '../../../../../../Actions/authAction';
import { PROVINCES, STATES } from "../../../../../../Utils/Provinces&States";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card, CardHeader, CardContent, CardActions, Divider, Grid, Button, TextField, InputAdornment, MenuItem } from '@material-ui/core';
class AccountDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      first_name: this.props.UsersProfile.first_name,
      last_name: this.props.UsersProfile.last_name,
      phone_number: this.props.UsersProfile.phone_number,
      country: this.props.UsersProfile.country,
      state: this.props.UsersProfile.state,
      UsersProfile: this.props.UsersProfile
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.UsersProfile !== state.UsersProfile) {
      state.first_name = props.UsersProfile.first_name
      state.last_name = props.UsersProfile.last_name
      state.phone_number = props.UsersProfile.phone_number
      state.state = props.UsersProfile.state
      return { UsersProfile: props.UsersProfile }
    }
    else {
      return null
    }
  }
  handleFirstNameChange = (event) => {
    this.setState({ first_name: event.target.value });
  }
  handleLastNameChange = (event) => {
    this.setState({ last_name: event.target.value });
  }
  handlePhoneChange = (event) => {
    this.setState({ phone_number: event.target.value });
  }
  handleStateChange = (event) => {
    this.setState({ state: event.target.value });
  }
  handleUpdateValues = () => {
    this.props.updateUserInfo({
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      phone_number: this.state.phone_number,
      state: this.state.state
    })
  }
  render() {
    return (
      <Card>
        <form
          onSubmit={this.handleUpdateValues}
          autoComplete="off">
          <CardHeader
            subheader="The information can be edited"
            title="Profile"
          />
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="First name"
                  margin="dense"
                  name="first_name"
                  onChange={this.handleFirstNameChange}
                  required
                  value={this.state.first_name}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Last name"
                  margin="dense"
                  name="last_name"
                  onChange={this.handleLastNameChange}
                  required
                  value={this.state.last_name}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Phone Number"
                  margin="dense"
                  name="phone_number"
                  onChange={this.handlePhoneChange}
                  value={this.state.phone_number}
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                          variant="outlined"
                          margin="dense"
                          required
                          fullWidth
                          select
                          value={this.state.state}
                          onChange={this.handleStateChange}
                          id="state"
                          label="State/Provinces"
                          name="state"
                          autoComplete="state"
                          autoFocus>
                          {this.state.country === "Canada" ? PROVINCES.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          )) :
                            STATES.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                        </TextField>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              type="submit"
              color="primary"
              variant="contained">
              Save details
            </Button>
          </CardActions>
        </form>
      </Card>
    );
  };
}

const mapStateToProps = state => {
  const { user } = state
  const { UsersProfile } = user
  return ({ UsersProfile })
};
export default connect(mapStateToProps, { updateUserInfo })(withRouter(AccountDetails));