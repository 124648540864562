import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  makeStyles,
  Typography,
  Grid,
  Card,
  Divider,
  Box,
  Button,
  CircularProgress,
} from "@material-ui/core";
import Topbar from "../../../v2/Pages/Topbar/Topbar";
import { getHomeJobs } from "../../../Actions/clientAction";
// import EmptyData from "../../Components/Profile/Empty";
import { Empty } from "antd";
import renderHTML from "react-render-html";
import _ from "lodash";
import { history } from "../../../Utils/history";

const styles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
      backgroundColor: "#F6F8FB",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItem: "center",
    },
    job_root: { padding: "10px 30px", marginLeft: "50px" },
    header: {
      marginTop: theme.spacing(5),
      marginBottom: "20px",
      position: "relative",
      width: "100%",
    },
    title: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "36px",
      lineHeight: "42px",
      color: "#000000",
    },
    job_card: {
      padding: "15px 15px",
      marginTop: "15px",
      position: "relative",
    },
    divider: {
      border: "none",
      borderBottom: "1px dashed #BDBDBD",
      margin: "15px 0px 10px",
    },
    name: {
      fontSize: "18px",
      fontWeight: "500",
      lineHeight: "24px",
      marginLeft: "5px",
    },
    type: {
      margin: "10px 0px 15px",
      width: "fit-content",
      height: "30px",
      padding: "5px 7px",
      backgroundColor: "#ffffff",
      border: "1px solid rgba(0, 0, 0, 0.36)",
      boxSizing: "border-box",
      borderRadius: "70px",
      position: "relative",
    },
    date: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      letterSpacing: "0.1px",
      color: "#9B9B9B",
      marginTop: "5px",
      marginBottom: "8px",
      marginLeft: "5px",
    },
    companyHeading: {
      fontSize: "16px",
      lineHeight: "24px",
      color: "#828282",
      marginLeft: "5px",
    },
    company: {
      fontSize: "16px",
      lineHeight: "24px",
      color: "#292B70",
    },
    description: {
      margin: "10px 0px",
      marginLeft: "5px",
    },
    less: {
      position: "absolute",
      top: "65px",
      right: "17px",
    },
    nodata: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    buttons: { position: "absolute", right: 0 },
    cardHeader: {
      width: "100%",
    },
    applyButton: {
      position: "absolute",
      right: 20,
      backgroundColor: "#292B70",
      color: "#ffffff",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "18px",
      display: "flex",
      alignItems: "center",
      letterSpacing: "0.16px",
    },
    loading: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "10%",
    },
  };
});
const FindJob = () => {
  const findStyles = styles();
  const dispatch = useDispatch();

  const { homeJobs, isGetHomeJobsLoading } = useSelector((state) => {
    const { homeJobs, isGetHomeJobsLoading } = state.client;
    return { homeJobs, isGetHomeJobsLoading };
  });

  useEffect(() => {
    dispatch(getHomeJobs());
  }, []);

  const truncate = (text, max) => {
    const Text = text.trim().split(" ");
    const more = Text.length > max ? "..." : "";
    return Text.slice(0, max).join(" ") + more;
  };

  let max = 20;

  const [more, setMore] = useState({
    isMore: false,
    Id: "",
  });

  const handleMore = (id) => {
    setMore({
      ...more,
      isMore: true,
      Id: id,
    });
  };

  const handleLess = () => {
    setMore({
      ...more,
      isMore: false,
      size: false,
    });
  };

  return (
    <React.Fragment>
      <Topbar />
      <div className={findStyles.root}>
        <div className={findStyles.job_root}>
          <Grid container className={findStyles.header}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography className={findStyles.title}>Find a Jobs</Typography>
            </Grid>
            {/* <Grid item item xs={6} sm={10} md={12} lg={6} className={findStyles.buttons}>
                    <Button variant="outlined">Clear filters</Button>&nbsp;
                    <Button variant="outlined" style={{textOverflow:"ellipsis"}}>Clear filters and Sorters</Button>
                </Grid> */}
          </Grid>
          <Grid container>
            {isGetHomeJobsLoading ? (
              <Grid container className={findStyles.loading}>
                <CircularProgress size={70} />
              </Grid>
            ) : (
              homeJobs &&
              homeJobs.map((job, i) => {
                return (
                  <Grid item key={i} xs={12} sm={12} md={12} lg={12}>
                    <Card className={findStyles.job_card}>
                      <Grid container className={findStyles.cardHeader}>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <Typography className={findStyles.name}>
                            {job.title}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            className={findStyles.applyButton}
                            onClick={() => history.push("/register")}
                          >
                            Apply
                          </Button>
                        </Grid>
                      </Grid>
                      <Box
                        component="div"
                        display="inline"
                        className={findStyles.date}
                      >
                        {job.job_status}&nbsp; |&nbsp;{" "}
                        {new Intl.DateTimeFormat("en-GB", {
                          year: "numeric",
                          month: "long",
                          day: "2-digit",
                        }).format(Date.parse(job.created_at && job.created_at))}
                      </Box>
                      <Box className={findStyles.type} component="span">
                        {job.job_type}
                      </Box>
                      <Divider className={findStyles.divider} />
                      <Box
                        component="div"
                        display="inline"
                        className={findStyles.companyHeading}
                      >
                        Company:{" "}
                        <Box component="span" className={findStyles.company}>
                          {job.client.name}
                        </Box>
                      </Box>
                      <Typography
                        component="div"
                        className={findStyles.description}
                        style={{
                          overflowY: job.description.length > max && "scroll",
                          height: "100px",
                          marginTop: 10,
                          fontSize: "14px",
                        }}
                      >
                        {job.description ? (
                          more.isMore &&
                          more.Id === job.id &&
                          job.description.length > max ? (
                            <>
                              <a
                                className={findStyles.less}
                                onClick={handleLess}
                              >
                                Read Less
                              </a>
                              {renderHTML(job.description)}
                            </>
                          ) : (
                            <>
                              {job.description.length < max ? (
                                renderHTML(job.description)
                              ) : (
                                <>
                                  {renderHTML(truncate(job.description, max))}
                                  <a onClick={() => handleMore(job.id)}>
                                    Read more
                                  </a>
                                </>
                              )}
                            </>
                          )
                        ) : (
                          <Grid container className={findStyles.nodata}>
                            <Empty
                              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                              imageStyle={{
                                height: 60,
                              }}
                              description={"No description"}
                            />
                          </Grid>
                        )}
                      </Typography>
                    </Card>
                  </Grid>
                );
              })
            )}
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
};
export default FindJob;
