import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

export default function SimpleSelect(props) {
  let options = props.options;
  return (
    <Select variant="outlined" name={props.name} value={props.val} onChange={props.onChange} style={{ width: '100%', marginTop: 8 }}>
      {options.map((element, index) => (
        <MenuItem key={index} value={element.value}>
          {element.label}
        </MenuItem>
      ))}
    </Select>
  );
}

// import React from "react";
// import Select from "react-select";
// import PropTypes from "prop-types";
// import { FormLabel } from "@material-ui/core";

// class SelectField extends React.Component {
//   handleChange = value => {
//     // this is going to call setFieldValue and manually update values.topcis
//     this.props.onChange(this.props.name, value);
//   };

//   handleBlur = () => {
//     // this is going to call setFieldTouched and manually update touched.topcis
//     this.props.onBlur(this.props.name, true);
//   };

//   render() {
//     return (
//       <div style={{ margin: "0rem 0 1rem" }}>
//         <FormLabel htmlFor={this.props.id} style={{ marginBottom: "1rem" }}>
//           {this.props.label}
//         </FormLabel>
//         <Select
//           id={this.props.id}
//           options={options}
//           // isMulti={this.props.multi}
//           onChange={this.handleChange}
//           // onBlur={this.handleBlur}
//           value={this.props.value}
//         />
//         {/* {!!this.props.error && this.props.touched && (
//           <div style={{ color: "red", marginTop: ".5rem" }}>
//             {this.props.error}
//           </div>
//         )} */}
//       </div>
//     );
//   }
// }

// SelectField.propTypes = {
//   id: PropTypes.string.isRequired,
//   name: PropTypes.string.isRequired,
//   options: PropTypes.array.isRequired,
//   multi: PropTypes.bool.isRequired
// };

// export default SelectField;