import React from 'react';
import { updateUserInfo } from '../../../../../../Actions/authAction';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  Button,
  // LinearProgress,
  Dialog, DialogActions, DialogContent, DialogTitle
} from '@material-ui/core';
import { Upload, Icon, message } from 'antd';
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}
const styles = theme => ({
  root: {},
  details: {
    display: 'flex'
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 110,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  }
});
class AccountProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      loading: false,
      imageUrl: '',
      open: false,
    }
  }
  setLoading = (val) => {
    this.setState({ loading: val })
  }
  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
        }),
      );
    }
  };
  handleClickOpen = () => {
    this.setState({ open : true })
  };
  handleClose = () => {
    this.setState({ open : false} )
  };
  updateavatar=(imageUrl)=> {
    this.props.updateUserInfo({
      image_url: this.state.imageUrl
    })
  }
  render(){
    const { classes } = this.props;
    const { imageUrl } = this.state;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <Card>
        <CardContent>
          <div className={classes.details}>
            <div>
              <Typography gutterBottom variant="h2">{this.props.UsersProfile.first_name} {this.props.UsersProfile.last_name}</Typography>
              <Typography className={classes.locationText} color="textSecondary" variant="body1">{this.props.UsersProfile.email}</Typography>
              <Typography className={classes.locationText} color="textSecondary" variant="body1">Province/State: {this.props.UsersProfile.state}</Typography>
            </div>
            <Avatar className={classes.avatar} src={this.props.UsersProfile.image_url.url}/>
          </div>
          {/* <div className={classes.progress}>
            <Typography variant="body1">Profile Completeness: 70%</Typography>
            <LinearProgress
              value={70}
              variant="determinate"
            />
          </div> */}
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.uploadButton}
            color="primary"
            variant="text" onClick={this.handleClickOpen}
          >
            Upload picture
          </Button>
          <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="form-dialog-title">Update Avatar</DialogTitle>
            <DialogContent>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={beforeUpload}
              onChange={this.handleChange}
            >
              {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                label="Update Identity"
                onClick={()=>this.updateavatar(imageUrl)}
                > Update
              </Button>
            </DialogActions>
          </Dialog>
          {/* <Button variant="text">Remove picture</Button> */}
        </CardActions>
      </Card>
    );
  };
}

AccountProfile.propTypes = {
  className: PropTypes.string
};
const mapStateToProps = state => {
  const { user } = state
  const { UsersProfile } = user
  return ({ UsersProfile })
};
export default connect(mapStateToProps, { updateUserInfo })(withRouter(withStyles(styles)(AccountProfile)));