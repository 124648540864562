// import { GET_ERRORS } from "./types";
import API from "../Utils/API";
import usersConstants from '../constants/users'

export const getUsersProfiles = (current_user_id , callback) => dispatch =>{
  let url="";
  if(current_user_id){ url= `/api/users_profiles?user_id=${current_user_id}` }
  else{ url= "/api/users_profiles?" }
  dispatch({type: usersConstants.GET_ALL_USERS_PROFILES_REQUEST})
  API.get(url).then(res =>{
    return dispatch({
    type: usersConstants.GET_ALL_USERS_PROFILES_SUCCESS,
    payload: res.data
  });
  {callback && callback()}
})
.catch(err =>{
  dispatch({
    type: usersConstants.GET_ALL_USERS_PROFILES_FAILURE,
    payload: err.response.data
  })
  {callback && callback()}
}
);
};


export const getInterests = () => dispatch =>{
  let url= "/api/interests"
  dispatch({type: usersConstants.GET_ALL_INTERESTS_REQUEST})
  API.get(url).then(res =>{
    return dispatch({
    type: usersConstants.GET_ALL_INTERESTS_SUCCESS,
    payload: res.data
  });
})
.catch(err =>
  dispatch({
    type: usersConstants.GET_ALL_INTERESTS_FAILURE,
    payload: err.response.data
  })
);
};

export const getInterest = () => dispatch =>{
  let url= "/api/interests/find"
  dispatch({type: usersConstants.GET_FIND_INTEREST_REQUEST})
  API.get(url).then(res =>{
    return dispatch({
    type: usersConstants.GET_FIND_INTEREST_SUCCESS,
    payload: res.data
  });
})
.catch(err =>
  dispatch({
    type: usersConstants.GET_FIND_INTEREST_FAILURE,
    payload: err.response.data
  })
);
};


export const getLanguages = () => dispatch =>{
  let url= "/api/languages"
  dispatch({type: usersConstants.GET_ALL_LANGUAGES_REQUEST})
  API.get(url).then(res =>{
    return dispatch({
    type: usersConstants.GET_ALL_LANGUAGES_SUCCESS,
    payload: res.data
  });
})
.catch(err =>
  dispatch({
    type: usersConstants.GET_ALL_LANGUAGES_FAILURE,
    payload: err.response.data
  })
);
};

export const getLanguage = () => dispatch =>{
  let url= "/api/languages/find"
  dispatch({type: usersConstants.GET_FIND_LANGUAGE_REQUEST})
  API.get(url).then(res =>{
    return dispatch({
    type: usersConstants.GET_FIND_LANGUAGE_SUCCESS,
    payload: res.data
  });
})
.catch(err =>
  dispatch({
    type: usersConstants.GET_FIND_LANGUAGES_FAILURE,
    payload: err.response.data
  })
);
};

export const getSkills = () => dispatch =>{
  let url= "/api/skills"
  dispatch({type: usersConstants.GET_ALL_SKILLS_REQUEST})
  API.get(url).then(res =>{
    return dispatch({
    type: usersConstants.GET_ALL_SKILLS_SUCCESS,
    payload: res.data
  });
})
.catch(err =>
  dispatch({
    type: usersConstants.GET_ALL_SKILLS_FAILURE,
    payload: err.response.data
  })
);
};

export const getSkill = () => dispatch =>{
  let url= "/api/skills/find"
  dispatch({type: usersConstants.GET_FIND_SKILL_REQUEST})
  API.get(url).then(res =>{
    return dispatch({
    type: usersConstants.GET_FIND_SKILL_SUCCESS,
    payload: res.data
  });
})
.catch(err =>
  dispatch({
    type: usersConstants.GET_FIND_SKILL_FAILURE,
    payload: err.response.data
  })
);
};


export const createComment = (userData, history) => dispatch => {
    dispatch({type: usersConstants.POST_COMMENT_REQUEST})
    API.post("/api/comments", userData)
      .then(result => {
        dispatch({type: usersConstants.POST_COMMENT_SUCCESS})
        history.push("");
        getComments()
      })
      .catch(err => dispatch({type: usersConstants.POST_COMMENT_FAILURE, payload: err.response.data}));
  };

  export const getComments = (job_id) => dispatch =>{
    let url= `/api/comments?job_id=${job_id}`
    dispatch({type: usersConstants.GET_ALL_COMMENTS_REQUEST})
    API.get(url).then(res =>{
      return dispatch({
      type: usersConstants.GET_ALL_COMMENTS_SUCCESS,
      payload: res.data
    });
  })
  .catch(err =>
    dispatch({
      type: usersConstants.GET_ALL_COMMENTS_FAILURE,
      payload: err.response.data
    })
  );
  };

  export const updateComment = (comment_id) => dispatch => {
    let url= "/api/comments/" + comment_id
    dispatch({ type: usersConstants.PUT_COMMENT_REQUEST})
    API.put(url).then(result => {
        return dispatch({type: usersConstants.PUT_COMMENT_SUCCESS})
      })
      .catch((err) =>
        dispatch({ type: usersConstants.PUT_COMMENT_FAILURE, payload: err.response.data })
    );
  };

  export const deleteComment = (comment_id) => dispatch => {
    let url= "/api/comments/" + comment_id
    dispatch({ type: usersConstants.DELETE_COMMENT_REQUEST})
    API.delete(url).then(result => {
        return dispatch({type: usersConstants.DELETE_COMMENT_SUCCESS})
      })
      .catch((err) =>
        dispatch({ type: usersConstants.DELETE_COMMENT_FAILURE, payload: err.response.data })
    );
  };


  export const createRate = (userData, history) => dispatch => {
    dispatch({type: usersConstants.POST_RATE_REQUEST})
    API.post("/api/rates", userData)
      .then(result => {
        dispatch({type: usersConstants.POST_RATE_SUCCESS})
      })
      .catch(err => {
        dispatch({ type: usersConstants.POST_RATE_FAILURE, payload: err.response.data });
      });

  };

  export const getRates = (ratable_id) => dispatch =>{
    let url= `/api/rates?ratable_id=${ratable_id}`
    dispatch({type: usersConstants.GET_ALL_RATES_REQUEST, payload: ratable_id})
    API.get(url).then(res =>{
      return dispatch({
      type: usersConstants.GET_ALL_RATES_SUCCESS,
      payload: res.data
    });
  })
  .catch(err =>
    dispatch({
      type: usersConstants.GET_ALL_RATES_FAILURE,
      payload: err.response.data
    })
  );
  };

  export const updateRate = (rate_id) => dispatch => {
    let url= "/api/rates/" + rate_id
    dispatch({ type: usersConstants.PUT_RATE_REQUEST})
    API.put(url).then(result => {
        return dispatch({type: usersConstants.PUT_RATE_SUCCESS})
      })
      .catch((err) =>
        dispatch({ type: usersConstants.PUT_RATE_FAILURE, payload: err.response.data })
    );
  };

  export const deleteRate = (rate_id) => dispatch => {
    let url= "/api/rates/" + rate_id
    dispatch({ type: usersConstants.DELETE_RATE_REQUEST})
    API.delete(url).then(result => {
        return dispatch({type: usersConstants.DELETE_RATE_SUCCESS})
      })
      .catch((err) =>
        dispatch({ type: usersConstants.DELETE_RATE_FAILURE, payload: err.response.data })
    );
  };